import router from './router'
import store from './store'
import { Message } from 'element-ui'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/js/auth' // get token from cookie
// import getPageTitle from '@/util/get-page-title'

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    // start progress bar
    // NProgress.start()

    // set page title
    // document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    if(to.query.token){
        localStorage.setItem("Authorization",to.query.token)
    }
    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            // NProgress.done()
        } else {
            const hasGetUserInfo = sessionStorage.getItem("info")
            if (hasGetUserInfo) {
                next()
            } else {
                next()
                /* 暂时没有做任何操作
                try {
                    // get user info
                    await store.dispatch('user/getInfo')

                    next()
                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    Message.error(error || 'Has Error')
                    next(`/login?redirect=${to.path}`)
                    // NProgress.done()
                }
                 */
            }
        }
    } else {
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // console.log(whiteList.indexOf(to.path),'whiteList.indexOf(to.path)')
            // in the free login whitelist, go directly
            // console.log(to.path,'to.path')
            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            // console.log('我进来了11111111111111111111111')
            // console.log(to.path,'to.path')
            if(to.path == '/four' || to.path == '/tbauthorize'){
                next()
            }else{
                next(`/login?redirect=${to.path}`)
            }
            
            // NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    // NProgress.done()
})
