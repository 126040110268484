<template>
  <div class="app">
    <el-form ref="form" :model="form" class="flex">
      <el-form-item label>
        <el-select
        class="elinput_right"
          style="width:300px;margin:0 0 20px 0"
          filterable
          multiple
          collapse-tags
          v-model="form.phoneType"
          placeholder="请选择商品"
          @change="changeSelect2"
        >
          <!-- :label="item.goodsName"是name -->
          <!-- :value="item.goodsId"是id -->
          <el-option
            v-for="(item,index) in typeOptions"
            :key="index"
            :label="item.goodsName"
            :value="item.goodsId"
          >
            <el-popover placement="right" width="100" trigger="hover" content>
              <img
                slot="reference"
                @mouseover="mouseover"
                onmouseover="this.style.border='1px solid #000'"
                onmouseout="this.style.border='1px solid #fff'"
                :src="item.goodsUrl"
                alt
                style="width:50px;height:50px"
              />
              <div style="width:100px;height:100px;background:#C9D4EC">
                <img style="width:100%;height:100%" :src="item.goodsUrl" alt />
              </div>
            </el-popover>
            <span
              class="goodsname"
              style="padding:10px;margin: 0px 0 0 28px;display: flex;"
            >{{item.goodsName}}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { commodity, productlaunch } from "@/request/api.js"; // //营销活动监控第二个筛选中商品列表接口

export default {
  data() {
    return {
     
      goodsUrl: "",
      brandOptions: ["商品"], //, "商品分类"],
      brandObj: {},
      form: [],
      array: [],
      typeOptions: [],
      form: {
        phoneBrand: "商品",
        phoneType: ""
      }
    };
  },
  props: {
    acid: {
      type: Array,
      default: []
    }
  },
  watch: {
    acid: {
      handler(newVal) {
        this.changeSelect1()
      },
      immediate: true
    }
  },
  created() {
    // console.log();
  },
  mounted() {
    this.changeSelect1()
  },
  methods: {
    changeSelect1() {
      // console.log(this.form.phoneBrand); //第一步获取第选中的值然后发出请求
      if (this.form.phoneBrand == "商品") {
        commodity({ acticityId: this.acid.join(",") }).then(res => {
          res.data.forEach(ele => {
            ele["goodsId"] = ele.goodsId;
            ele["goodsName"] = ele.goodsName;
            ele["goodsUrl"] = ele.goodsUrl;
          });
          this.typeOptions = res.data;
        });
      } else if (this.form.phoneBrand == "商品分类") {
      }
      // 清空手机型号内容
      this.form.phoneType = "";
      // 遍历手机品牌的下拉选项数组
      for (const k in this.brandOptions) {
        // 手机品牌内容 是否等于 手机品牌的下拉选择数组中的某一项
        if (this.form.phoneBrand === this.brandOptions[k]) {
          this.typeOptions = this.brandObj[this.form.phoneBrand];
        }
      }
    },
    changeSelect2() {
      console.log(this.form.phoneType);
      this.$emit("handlechange3", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
      console.log("11", this.form.phoneBrand, this.form.phoneType);
    },
    mouseover() {

    }
  }
};
</script>
<style  scoped>
 /deep/.el-select .el-tag {
  max-width: 55% !important;
}
 
.elinput_right /deep/ .el-input__inner {
  border-radius: 0px 5px 5px 0px;
}

.el-select-dropdown__item{
     width: 613px;
    height: 85px;
    white-space: normal;
    align-items: center;
    display: flex;
}
</style>


