<template>
  <div class="company" v-loading="loading">
    <!-- 搜索部分 -->
    <div class="head_search_box">
      <div class="head_search">
        <el-input
          style="width: 300px"
          placeholder="请输入公司名称"
          v-model="keyword"
          clearable
          @keydown.enter.native="search"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
        v-if="this.userinfo.is_admin == 1"
          v-model="dlsListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeDls"
        >
          <el-option
            v-for="item in dlsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="addgs">
        <el-button type="primary" @click="drawer = true">添加公司</el-button>
        <el-dialog
          title="添加公司"
          :close-on-click-modal="close_model"
          :visible.sync="drawer"
          center
          append-to-body
          width="30%"
        >
          <el-form :model="addAdvertising" :label-width="addLabelWidth">
            <el-form-item
              style="margin-bottom: 10px; width: 25vw"
              label="公司名称"
            >
              <el-input
                style="width: 90%"
                v-model="addAdvertising.name"
                autocomplete="off"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 25vw" label="服务费比例">
              <el-input
                style="width: 90%"
                v-model="addAdvertising.ratio"
                autocomplete="off"
                placeholder="服务费比例"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="addLabelWidth">
              <el-radio v-model="addAdvertising.status" label="1"
                >启用</el-radio
              >
              <el-radio v-model="addAdvertising.status" label="0"
                >禁用</el-radio
              >
            </el-form-item>
            <el-form-item style="width: 25vw" label="备注">
              <el-input
                type="textarea"
                v-model="addAdvertising.memo"
                autocomplete="off"
                placeholder="备注"
                style="width: 90%"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add" style="width: 100px"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 表格部分 -->
    <div class="f2">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="departName" label="公司名称" width="500">
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间"> </el-table-column>
        <el-table-column prop="commission" label="服务费比例">
          <template slot-scope="scope"> {{ scope.row.commission }}% </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :formatter="statusFormatter1"
          :filters="[
            { text: '启用', value: 1 },
            { text: '禁用', value: 0 },
          ]"
          :filter-method="filterTag"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status == '启用' ? 'success' : 'danger'"
              disable-transitions
              >{{ getstatus(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="memo" label="备注"> </el-table-column>

        <el-table-column prop="operation" label="操作" fixed="right">
          <template slot-scope="scope" style="display: flex">
            <el-button @click="hand1(scope.row)" type="text" size="small"
              >修改</el-button
            >

            <el-button class="zhgl" @click="hand2(scope.row)" type="text" size="small"
              >账号管理</el-button
            >
            <el-dialog
              title="修改信息"
              :close-on-click-modal="close_model"
              :visible.sync="dialogFormVisible"
              center
              append-to-body
              width="30%"
            >
              <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item
                  style="margin-bottom: 10px; width: 25vw"
                  label="公司名称"
                >
                  <el-input
                    v-model="form.name"
                    autocomplete="off"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item style="width: 25vw" label="服务费比例">
                  <el-input
                    v-model="form.ratio"
                    autocomplete="off"
                    placeholder="服务费比例"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="状态"
                  style="margin-bottom: 10px; width: 25vw"
                >
                  <el-radio v-model="form.status" label="1">启用</el-radio>
                  <el-radio v-model="form.status" label="0">禁用</el-radio>
                </el-form-item>
                <el-form-item
                  style="margin-bottom: 10px; width: 25vw"
                  label="备注"
                >
                  <el-input
                    type="textarea"
                    v-model="form.memo"
                    autocomplete="off"
                    placeholder="备注"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="updateAdvertising"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Number(totalNum) ? Number(totalNum) : 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { advertising, Addadvertising,getFactor } from "@/request/api";

export default {
  data() {
    return {
      dlsListVal:0,
      dlsList:[],
      loading: false,
      tableData: [
        {
          account_name: "王小虎",
          status: "启用",
        },
      ],
      drawer: false,
      close_model: false,
      curPage: 1,
      totalNum: 0,
      pageSize: 10,
      keyword: "",
      addAdvertising: {
        name: "",
        ratio: "",
        status: "1",
        memo: "",
      },
      addLabelWidth: "120px",
      formLabelWidth: "120px",
      userinfo: [],
      dialogFormVisible: false,
      form: {
        name: "",
        ratio: "",
        status: "1",
        memo: "",
      },
      advertisingId: "",
    };
  },
  watch: {},
  computed: {},
  created() {},

  mounted() {
    
      this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
      // this.getAdvertisingList();
      if(this.userinfo.is_admin == 1){
        this.getFactor();
      }else if(this.userinfo.is_admin == 2){
        this.dlsListVal = this.userinfo.id_factor;
        this.getAllAdvertising();
      }else{
        this.getAllAdvertising();
      }

    
  },

  methods: {

    //切换代理商
    changeDls(e){
      console.log(e,'e')
      this.dlsListVal = e;
      this.getAllAdvertising();
    },
    //获取代理商公司下拉框
    getFactor(){
        getFactor({}).then(r=>{
        // console.log(r,'r')
        this.dlsList = r.data;
        this.dlsListVal = this.dlsList[0].id;
        this.getAllAdvertising();
      })  
    },
    //获取代理商列表
    getAllAdvertising() {
      this.loading = true;
      // console.log(this.dlsListVal,'this.dlsListVal')
      advertising({
        page: this.curPage,
        number: this.pageSize,
        search: this.keyword,
        factorId:this.dlsListVal,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.totalNum = res.total;
          this.tableData = res.data;
          // console.log(res, "res");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索代理商
    search() {
      this.loading = true;
      this.curPage = 1;
      this.getAllAdvertising();
    },
    //添加代理商
    add() {
      if (!this.addAdvertising.name) {
        this.$message({
          message: "请填写名称",
          type: "warning",
        });
        return;
      }
      Addadvertising({
        id: 0,
        name: this.addAdvertising.name,
        
        id_admin: this.userinfo.uid,
        commission: this.addAdvertising.ratio,
        memo: this.addAdvertising.memo,
        status: this.addAdvertising.status,
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.getAllAdvertising();
          this.drawer = false;
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
          });
        }
        // console.log(res,'res')
      });
    },
    //点击修改，打开嵌套修改弹窗
    hand1(val) {
      // console.log(val, "拿到的数据");
      this.dialogFormVisible = true;
      this.advertisingId = val.departId;
      this.form.name = val.departName;
      this.form.memo = val.memo;
      this.form.ratio = val.commission;
      if (val.status == "启用") {
        this.form.status = "1";
      } else if (val.status == "禁用") {
        this.form.status = "0";
      }

      //   console.log( this.form.status,' this.form.status')
      // console.log(val,'val')
    },
    //修改代理商
    updateAdvertising() {
      Addadvertising({
        id: this.advertisingId,
        name: this.form.name,
        id_admin: this.userinfo.uid,
        commission: this.form.ratio,
        memo: this.form.memo,
        status: Number(this.form.status),
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getAllAdvertising();
          this.dialogFormVisible = false;
        } else {
          this.$message({
            message: "修改失败",
            type: "error",
          });
        }
        // console.log(res, "res");
      });
    },
    //点击跳转账号管理页面
    hand2(val) {
      this.$router.push({
        path: "/SystemSetup/accountManagement",
        query: { name: val.departName },
      });
    },
    //改变每条页数
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log('this.pageSize', this.pageSize)
      this.getAllAdvertising();
    },
    //改变页码
    handleCurrentChange(val) {
      // console.log('current page', val);
      this.curPage = val;
      this.getAllAdvertising();
    },
    filterTag(value, row) {
      return row.status === value;
    },
    statusFormatter1(row, column) {
      const status = row.status;
      if (status == 1) {
        return "启用";
      } else {
        return "禁用";
      }
    },
    getstatus(status) {
      // console.log(status, "status");
      return status;
    },
  },
};
</script>
 <style scoped>
.company .f2 {
  padding: 15px;
  background-color: #fff;
}
.company .page {
  text-align: right;
  margin: 15px 2px 0 0;

}
@media screen and (max-width: 1200px) {
  .head_search_box{
    flex-direction: column;
  }
  .zhgl{
    margin-left: 0px !important;
  }
  .addgs{
    margin-top: 10px;
  }
}
</style>
