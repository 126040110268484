<template>
  <div class="company" v-loading="loading">
    <!-- 搜索部分 -->
    <div class="head_search_box">
      <div class="head_search">
        <el-input
          style="width: 300px"
          placeholder="请输入账号名称"
          v-model="keyword"
          clearable
          @keydown.enter.native="search"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
        v-if="this.userinfo.is_admin == 1"
          v-model="dlsListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeDls"
        >
          <el-option
            v-for="item in dlsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
          <el-select
              v-model="minitype"
              placeholder="请选择"
              class="addgs"
              style="margin-left: 10px"
              @change="changeMinitype"
          >
              <el-option
                  :key="0"
                  label="全部"
                  :value="0"
              >
              </el-option>
              <el-option
                  :key="1"
                  label="帮你选_优选商品导购"
                  :value="1"
              >
              </el-option>
              <el-option
                  :key="2"
                  label="大麦"
                  :value="2"
              >
              </el-option>
          </el-select>
      </div>
       <div class="addgs">
        <el-button type="primary" @click="openDialog">添加</el-button>
        <el-dialog
          title="添加"
          :close-on-click-modal="close_model"
          :visible.sync="drawer"
          center
          append-to-body
          width="30%"
        >
          <el-form :label-width="addLabelWidth">
            <el-form-item style="margin-bottom: 10px; width: 25vw" label="卖家账号">
              <el-input
                style="width: 90%"
                v-model="taobaoNick"
                autocomplete="off"
                placeholder="请输入卖家账号"
              ></el-input>
            </el-form-item>
            <el-form-item
              style="margin-bottom: 10px; width: 25vw"
              label="关联账号"
            >
              <el-select
                v-model="Tbvalue"
                placeholder="请选择关联账号"
                style="width: 90%"
                filterable
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.id"
                  :label="item.account"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
              <el-form-item
                  style="margin-bottom: 10px; width: 25vw"
                  label="小程序类型"
              >
                  <el-select
                      v-model="Tbvalue1"
                      placeholder="请选择关联账号"
                      style="width: 90%"
                      filterable
                  >
                      <el-option
                          :key="1"
                          label="帮你选_优选商品导购"
                          :value="1"
                      >
                      </el-option>
                      <el-option
                          :key="2"
                          label="大麦"
                          :value="2"
                      >
                      </el-option>
                  </el-select>
              </el-form-item>
          </el-form>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="add">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 表格部分 -->
    <div class="f2">
      <el-table :data="tableData" border style="width: 100%">


        <el-table-column prop="nick" label="卖家号">
        </el-table-column>
        <el-table-column prop="mininame" label="小程序名称">
        </el-table-column>
        <el-table-column prop="account" label="账号名称"> </el-table-column>
        <el-table-column prop="name" label="所属公司"></el-table-column>
        <el-table-column prop="expiresTime" label="小程序到期时间"></el-table-column>
        <el-table-column prop="firstAuthorizeTime" label="首次授权时间">

        </el-table-column>
        <el-table-column
          prop="msn"
          label="状态"
          width="80"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="status"
          label="状态"
          :filters="[
            { text: '禁用', value: 0 },
            { text: '已同意', value: 1 },
            { text: '待审核', value: 2 },
            { text: '已拒绝', value: 3 },
          ]"
          :filter-method="filterTag"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status == '正常' ? 'success' : 'danger'"
              disable-transitions
              >{{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="versionName" label="版本" width="80">
        </el-table-column>
        <el-table-column prop="operation" label="操作" fixed="right" width="80">
          <template slot-scope="scope" style="display: flex">        
            <el-button
              @click="handleAgent(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Number(totalNum) ? Number(totalNum) : 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {Accountmanage, miniapplist,miniappadd,miniappdel,getFactor} from "@/request/api";

export default {
  data() {
    return {
      dlsListVal:0,
      dlsList:[],
      close_model: false,
      loading: false,
      Tboptions: [],
      Tbvalue: "",
      Tbvalue1: "",
        taobaoNick:"",
      statusList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "0",
          label: "禁用",
        },

        {
          value: "2",
          label: "待审核",
        },
        {
          value: "3",
          label: "审核拒绝",
        },
      ],
      statusvalue: "0",
      tableData: [],
      drawer: false,
      curPage: 1,
      totalNum: 0,
      pageSize: 10,
      addAdvertising: {
        name: "",
        ratio: "",
        status: "1",
        memo: "",
      },
        minitype:0,
      addLabelWidth: "120px",
      formLabelWidth: "120px",
      userinfo: [],
      advertisingId: "",
        keyword: "",
        companyList:[]
      
    };
  },
  watch: {},
  computed: {},
  created() {},

  mounted() {
    // this.getAllMiniApp();
    this.getAccountmanage()
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    if(this.userinfo.is_admin == 1){
        this.getFactor();
      }else if(this.userinfo.is_admin == 2){
        this.dlsListVal = this.userinfo.id_factor;
        this.getAllMiniApp();
      }else{
        this.getAllMiniApp();
      }
  },

  methods: {
    //切换代理商
    changeDls(e){
      // console.log(e,'e')
      this.dlsListVal = e;
      this.getAllMiniApp();
    },
      changeMinitype(e){
        this.minitype = e;
        this.getAllMiniApp()
      },
    //获取代理商公司下拉框
    getFactor(){
        getFactor({}).then(r=>{
        // console.log(r,'r')
        this.dlsList = r.data;
        this.dlsListVal = this.dlsList[0].id;
        this.getAllMiniApp();
      })  
    },
    //添加账号
    add() {
      if (!this.taobaoNick ) {
        this.$message({
          message: "请输入卖家账号",
          type: "worning",
        });
        return;
      }
      if (!this.Tbvalue) {
        this.$message({
          message: "请选择关联账号",
          type: "worning",
        });
        return;
      }
        if (!this.Tbvalue1) {
            this.$message({
                message: "请选择小程序类型",
                type: "worning",
            });
            return;
        }

        miniappadd({nick:this.taobaoNick,user_id:this.Tbvalue,type: this.Tbvalue1}).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.getAllMiniApp()
              this.drawer = false;
            }
        })
      
    },
    //打开添加弹窗
    openDialog() {
        this.drawer = true;
        this.taobaoNick = "";
        this.Tbvalue = "";
        this.getAccountmanage();
        // console.log(this.companyList,'this.companyList')
    },
    //切换状态显示
    changeStatus(e) {
      this.curPage = 1;
      this.getAllAdvertising();
    },
    //获取代理商列表
    getAllMiniApp() {
        miniapplist({page:this.curPage,page_size:this.pageSize,keyword:this.keyword,factorId:this.dlsListVal,type: this.minitype}).then(res => {
            if(res.code == 1){
                this.tableData = res.data.list
                this.totalNum = res.data.total;
            }
        })
    },
      getAccountmanage(){
          Accountmanage({page:1,number:100000,factorId:this.dlsListVal}).then(res => {
              if(res.code == 1){
                    this.companyList = res.data;
                    if(this.userinfo.is_admin == 1){
                    this.companyList =  this.companyList.filter((ele) => ele.id_factor == this.dlsListVal)
                    }
              }
          })
      },
    //搜索代理商
    search() {
      this.curPage = 1;
      this.getAllMiniApp();
    },

    //改变每条页数
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log('this.pageSize', this.pageSize)
      this.curPage = 1;
      this.getAllMiniApp();
    },
    //改变页码
    handleCurrentChange(val) {
      // console.log('current page', val);
      this.curPage = val;
      this.getAllMiniApp();
    },
    //删除按钮
    handleAgent(row,) {
      this.$confirm("确认删除吗?", "提示").then((result) => {
        if (result == "confirm") {
          miniappdel({ id: row.id}).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getAllMiniApp();
            }
          });
        }
      });
    },

    // filterTag(value, row) {
    //   return row.status === value;
    // },
  },
};
</script>
 <style scoped>
.company .f2 {
  padding: 15px;
  background-color: #fff;
}
.company .page {
  text-align: right;
  margin: 15px 2px 0 0;
}
@media screen and (max-width: 1200px) {
  .head_search {
    flex-direction: column;
  }
  .addgs {
    margin-top: 10px;
  }
}
</style>