<template>
    <section class="decoration-edit">
        <section class="l" @click="selectType(0)">
            <ul 
                @dragstart="dragStart"
                @dragend="dragEnd"
            >
                <li 
                    v-for="(val, key, index) in typeList"
                    draggable 
                    v-if="val.icon && key != 'link'"
                    :data-type="key"
                    :key="index + 1"
                >
                    <span :class="val.icon"></span>
                    <p>{{val.tit}}</p>
                </li>
            </ul>
        </section>
        <section class="c">
            <!-- header 不可拖拽 --> 
            <!-- <div class="top-nav" @click="selectType(0)">
                <img src="./../assets/images/topNavBlack.png">
                <span class="tit">{{ info.title }}</span>
            </div> -->
            <div 
                class="view-content"
                @drop="drog"
                @dragover="dragOver"
                :style="{ backgroundColor: info.backgroundColor }"
            >
            <div style="position:relative" class="page-view">
                <Draggable
                    v-model="view"
                    draggable=".item"
                >
                    <template v-for="(item, index) in view">
                        <div
                            v-if="index > 0"
                            :data-index="index"
                            :key="index"
                            class="item"
                            @click="selectType(index)"
                        >
                            <!-- waiting -->
                            <template v-if="item.status && item.status == 2">
                                <div class="wait"> {{ item.type }} </div>
                            </template>
                            <template v-else>
                                <component 
                                    :is="typeList[item.type]['com']" 
                                    :data="item"
                                    :className="className[item.tabType]"
                                ></component>
                            </template>
                            <i @click="deleteItem($event, index)" class="el-icon-error"></i>
                        </div>
                    </template>
                    <Link :data="link" @change="linkChange" @click="selectType(-1)"/>
                </Draggable>
            </div>
                <!-- <div class="runsTwo"
                    style="width: 100%;height: 112px;background-color: #83ccd277;position:absolute;right: 0px;top: 350px;z-index: 99;cursor: pointer;">
                    点击区域
                </div> -->
            </div>
        </section>
        <section class="r">
            <div class="submit-btn">
                <el-button @click="submit" type="primary" plain>提交页面</el-button>
                <el-button @click="back" plain>返回</el-button>
            </div>
            <EditForm
                v-if="isRight"
                :data="props"
            ></EditForm>
        </section>
        
</div>
    </section>
</template>

<script>
import Draggable from "vuedraggable";
import { upload, loadingpage_add, loadingpage_detail } from "@/request/api";
import EditForm from "@/components/Edit/index";
// import Product from '@/components/View/Product'
import Images from "@/components/View/Images";
import Link from "@/components/View/Link";
import Info from "@/components/View/Info";
// import Banner from '@/components/View/Banner'
import Config from "@/components/View/config";
export default {
    components: {
        EditForm,
        Draggable,
        // Product,
        Images,
        // Banner,
        Link,
        Info,
    },
    data() {
        return {
            typeList: Config,
            // {
            //     info: {
            //         name: '链接',
            //         icon: 'el-icon-link',
            //         com: Info
            //     },
            //     link: {
            //         name: '链接',
            //         icon: 'el-icon-link',
            //         com: Link
            //     },
            //     // banner: {
            //     //     name: '轮播图',
            //     //     icon: 'el-icon-picture',
            //     //     com: Banner
            //     // },
            //     // product: {
            //     //     name: '商品',
            //     //     icon: 'el-icon-s-goods',
            //     //     com: Product
            //     // },
            //     images: {
            //         name: '图片',
            //         icon: 'el-icon-picture',
            //         com: Images
            //     },
            //     // button: {
            //     //     name: '链接',
            //     //     icon: 'el-icon-link',
            //     //     com: Link
            //     // }
            // },
            view: [
                {
                    type: "info",
                    title: "",
                    pageclick: false,
                },
                // {
                //     type: 'link',
                //     title: '点击链接',
                //     data: [],
                // },
                {
                    type: "images",
                    title: "图片",
                    data: [],
                },
            ],
            link: {
                type: "link",
                title: "点击链接",
                data: {
                    top: 200,
                    height: 100,
                },
            },
            title: "页面标题",
            type: "",
            index: null, // 当前组件的索引
            isPush: false, // 是否已添加组件

            props: {}, // 传值
            isRight: true,

            className: {
                1: "one",
                2: "two",
                3: "three",
            },
            goods_id: 0,
            page_id: 0,
        };
    },
    computed: {
        info() {
            return this.view[0];
        },
    },
    mounted() {
        if (this.$route.query.id != undefined) {
            this.goods_id = this.$route.query.id;
        }
        if (this.$route.query.page_id != undefined) {
            this.page_id = this.$route.query.page_id;
            this.pageInfo();
        }
        this.$nextTick(() => {
            this.selectType(0);
        })
        // var drag = document.querySelector('.runsTwo')

        // drag.onmousedown = function runs(e) {
        //     var e = e || window.event;
        //     var diffX = e.clientX - drag.offsetLeft;
        //     var diffY = e.clientY - drag.offsetTop;

        //     if (typeof drag.setCapture != 'undefined') {
        //         drag.setCapture();
        //     }
        //     pauseEvent(e);

        //     function pauseEvent(e) {
        //         if (e.stopPropagation) e.stopPropagation()
        //         if (e.preventDefault) e.preventDefault();
        //         e.cancelBubble = true;
        //         e.returnValue = false;
        //         return false;
        //     }

        //     document.onmousemove = function (e) {
        //         var e = e || window.event;
        //         var left = e.clientX - diffX;
        //         var top = e.clientY - diffY;
        //         // console.log('top', top, left);
        //         left = 0; //不能左右移动
        //         // if (left < 0) {
        //         //     left = 0;
        //         // } else if (left > window.innerWidth - drag.offsetWidth) {
        //         //     left = window.innerWidth - drag.offsetWidth;
        //         // }
        //         if (top < 0) {
        //             top = 0;
        //         } else if (top > window.innerHeight - drag.offsetHeight) {
        //             top = window.innerHeight - drag.offsetHeight;
        //         }

        //         drag.style.left = left + 'px';
        //         drag.style.top = top + 'px';
        //         if (e.preventDefault) {
        //             e.preventDefault();
        //         }
        //     };
        //     document.onmouseup = function (e) { //当鼠标弹起来的时候不再移动
        //         this.onmousemove = null;
        //         this.onmouseup = null; //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）

        //         //修复低版本ie bug
        //         if (typeof drag.releaseCapture != 'undefined') {
        //             drag.releaseCapture();
        //         }

        //     };
        // };
    },
    methods: {
        back() {
            this.$router.back();
        },
        pageInfo() {
            // 网络
            loadingpage_detail({id: this.page_id}).then(res => {
                console.log('loadingpage_detail', res);
                if (res.code == 1) {
                    let data = JSON.parse(res.data.data);
                    this.view = data.filter(ele => ele.type != 'link');
                    this.link = data.find(ele => ele.type == 'link');
                    console.log("view", this.view, "link", this.link);
                    this.selectType(0);
                }
            })
        },
        linkChange(data) {
            console.log("change top", data);
        },
        submit() {
            // JSON 转换会丢失 formData
            const form = JSON.parse(JSON.stringify(this.view));
            let pageHeight = document.querySelector(".page-view").clientHeight
            // console.log(this.link);
            // return
            this.link.data.page_height = pageHeight;
            
            if (form.length == 1) {
                this.$message.error("请添加模块！");
                return;
            }
            let info = form.find((ele) => ele.type == "info");
            if (info && info.title == "") {
                this.$message.error("请添写标题！");
                this.selectType(0);
                return;
            }
            for (let i of form) {
                if (i.data && i.data.length == 0 && i.type != "link") {
                    console.log(i);
                    this.$message.error("请填写完整信息！");
                    return;
                }
                if (i.type === "product") {
                    i.data = i.data.map((val) => val.productId).join(",");
                }
            }
            form.push(JSON.parse(JSON.stringify(this.link)));
            // this.$message.success('数据提交成功，请按F12打开控制台查看待提交数据集合！')

            // console.log(form)  // 提交的数据，根据接口形式修改

            // return

            // 上传图片，修改数据字段（兼容接口
            const uploadPromise = {};
            for (let i = 0; i < this.view.length; i++) {
                if (["images", "banner"].includes(this.view[i].type)) {
                    uploadPromise[i] = [];
                    let list = this.view[i]["data"];
                    list.forEach((item, index) => {
                        if(item.form != undefined){
                            uploadPromise[i].push(
                                new Promise((resolve, reject) => {
                                    const link = item.link;
                                    const name = item.name;
                                    this.imageUpload(item.form)
                                        .then((res) => {
                                            // 上传图片接口
                                            form[i]["data"][index] = {
                                                url: res.data.url,
                                                link,
                                                name,
                                            };
                                            resolve(res);
                                        })
                                        .catch((err) => {
                                            reject(err);
                                        });
                                })
                            );
                        }
                    });
                }
            }

            let [len, count] = [Object.keys(uploadPromise).length, 0];
            if (len) {
                for (let i in uploadPromise) {
                    Promise.all(uploadPromise[i])
                        .then((res) => {
                            count++;
                            console.log("提交结果", res, form, count, len);
                            if (count === len) {
                                // 提交数据
                                loadingpage_add({
                                    goods_id: this.goods_id,
                                    page_id: this.page_id,
                                    data: JSON.stringify(form),
                                }).then((result) => {
                                    console.log("result", result);
                                    if (result.code == 1) {
                                        this.$message.success("操作成功");
                                        this.$router.back();
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err);
                        });
                }
            } else {
                // 提交数据
            }
        },
        // 切换视图组件
        selectType(index) {
            this.isRight = false;
            if (index == -1) {
                this.props = this.link;
            } else {
                this.props = this.view[index];
            }
            console.log("selectType", this.props);
            this.$nextTick(() => (this.isRight = true));
        },
        deleteItem(e, index) {
            e.preventDefault();
            e.stopPropagation();
            this.view.splice(index, 1);
            this.isRight = false;
            this.props = {};
        },
        // 拖拽类型
        dragStart(e) {
            this.type = e.target.dataset.type;
        },
        // 结束拖拽
        dragEnd(e) {
            this.$delete(this.view[this.index], "status");
            this.isPush = false;
            this.type = null;
        },
        // 已放置到指定位置
        drog(e) {
            if (!this.type) {
                // 内容拖拽
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            this.dragEnd();
        },
        // 移动中
        dragOver(e) {
            if (!this.type) {
                // 内容拖拽
                return;
            }
            e.preventDefault();
            e.stopPropagation();

            let className = e.target.className;
            let name = className !== "view-content" ? "item" : "view-content";

            const defaultData = {
                type: this.type, // 组件类型
                status: 2, // 默认状态
                data: [], // 数据
                options: {}, // 选项操作
            };

            if (name == "view-content") {
                if (!this.isPush) {
                    this.index = this.view.length;
                    this.isPush = true;
                    this.view.push(defaultData);
                }
            } else if (name == "item") {
                let target = e.target;
                let [y, h, curIndex] = [
                    e.offsetY,
                    target.offsetHeight,
                    target.dataset.index,
                ];
                let direction = y < h / 2;

                if (!this.isPush) {
                    // Push to Top or Bottom
                    if (direction) {
                        if (curIndex == 0) {
                            this.view.unshift(defaultData);
                        } else {
                            this.view.splice(curIndex, 0, defaultData);
                        }
                    } else {
                        curIndex = +curIndex + 1;
                        this.view.splice(curIndex, 0, defaultData);
                    }
                } else {
                    // Moving
                    if (direction) {
                        var i = curIndex == 0 ? 0 : curIndex - 1;
                        var result = this.view[i]["status"] == 2;
                    } else {
                        var i = +curIndex + 1;
                        var result =
                            this.view.length > i && this.view[i]["status"] == 2;
                    }

                    if (result) return;

                    const temp = this.view.splice(this.index, 1);
                    this.view.splice(curIndex, 0, temp[0]);
                }
                this.index = curIndex;
                this.isPush = true;
            }
        },
        imageUpload(form) {
            // 上传代码
            return upload(form);
        },
    },
};
</script>
<style lang="scss">
.decoration-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    background: #f7f8f9;
    height: 700px;
    position: relative;
    .l,
    .r {
        width: 340px;
        height: 100%;
        padding: 15px 0;
        background: #fff;
    }
    .l {
        width: 110px;
        ul {
            margin: 0;
            padding: 0;
            li {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: default;
                list-style: none;
                font-size: 14px;
                color: #666;
                float: left;
                margin: 0 10px;
                border-radius: 6px;
                transition: all 0.3s;
                cursor: pointer;
                &:hover {
                    background: #efefef;
                }
                span {
                    display: block;
                    font-size: 40px;
                    margin-bottom: 8px;
                    color: #999;
                }
                p {
                    display: block;
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
    .c {
        width: auto;
        max-width: 400px;
        position: relative;
        .top-nav {
            position: absolute;
            top: 0;
            background: #fff;
            z-index: 999;
            transition: all 0.3s;
            & * {
                pointer-events: none;
            }
            &:hover {
                transform: scale(0.95);
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0 0 10px #afafaf;
            }
            .tit {
                position: absolute;
                left: 50%;
                bottom: 10px;
                transform: translateX(-50%);
            }
            img {
                max-width: 100%;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
                image-rendering: crisp-edges;
                -ms-interpolation-mode: nearest-neighbor;
            }
        }
        .view-content {
            width: 400px;
            height: 700px;
            background: #f5f5f5;
            overflow-y: auto;
            overflow-x: hidden;
            // margin-top: 200px;
            // padding-top: 72px;
            box-shadow: 0 2px 6px #ccc;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background: #dbdbdb;
            }
            &::-webkit-scrollbar-track {
                background: #f6f6f6;
            }

            .item {
                transition: all 0.3s;
                background: #fff;
                &:hover {
                    transform: scale(0.99);
                    // border-radius: 10px;
                    box-shadow: 0 0 2px #afafaf;
                    .el-icon-error {
                        display: block;
                    }
                }
                div {
                    pointer-events: none;
                }
                .wait {
                    background: #deedff;
                    height: 35px;
                    text-align: center;
                    line-height: 35px;
                    font-size: 12px;
                    color: #666;
                }
                .el-icon-error {
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    color: red;
                    font-size: 25px;
                    cursor: pointer;
                    display: none;
                    z-index: 9999;
                }
            }
        }
    }
    .submit-btn {
        // position: absolute;
        // bottom: 30px;
        // left: 50%;
        // transform: translateX(-50%);
        border-bottom: #f7f8f9;
        border-bottom-width: 12px;
        border-bottom-style: solid;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-evenly;
    }
}
</style>