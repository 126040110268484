<template>
    <div 
        class="button-box link" 
        :class="className"
    >
        <template v-if="data.data && data.data.length > 0">
            {{data.data}}
        </template>
        <template v-else>
            <div class="button-null"><span class="el-icon-link"></span></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Info',
    props: ['data', 'className'],
    mounted() {
        var drag = document.querySelector('.link')
        let that = this;
        drag.onmousedown = function runs(e) {
            var e = e || window.event;
            var diffX = e.clientX - drag.offsetLeft;
            var diffY = e.clientY - drag.offsetTop;
            if (typeof drag.setCapture != 'undefined') {
                drag.setCapture();
            }
            pauseEvent(e);
            function pauseEvent(e) {
                if (e.stopPropagation) e.stopPropagation()
                if (e.preventDefault) e.preventDefault();
                e.cancelBubble = true;
                e.returnValue = false;
                return false;
            }

            document.onmousemove = function (e) {
                var e = e || window.event;
                var left = e.clientX - diffX;
                var top = e.clientY - diffY;
                // console.log('top', top, left);
                left = 0; //不能左右移动
                // if (left < 0) {
                //     left = 0;
                // } else if (left > window.innerWidth - drag.offsetWidth) {
                //     left = window.innerWidth - drag.offsetWidth;
                // }
                if (top < 0) {
                    top = 0;
                } else if (top > window.innerHeight - drag.offsetHeight) {
                    top = window.innerHeight - drag.offsetHeight;
                }

                drag.style.left = left + 'px';
                drag.style.top = top + 'px';
                if (e.preventDefault) {
                    e.preventDefault();
                }
            };
            document.onmouseup = function (e) { //当鼠标弹起来的时候不再移动
                this.onmousemove = null;
                this.onmouseup = null; //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
                that.$emit("click", e)
                //修复低版本ie bug
                if (typeof drag.releaseCapture != 'undefined') {
                    drag.releaseCapture();
                }

            };
        };
    }
}
</script>

<style lang="scss" scoped>
.button-box{
    font-size: 0;
    width: 100%;
    height: 112px;
    background-color: #83ccd277;
    position:absolute;
    right: 0px;
    top: 350px;
    z-index: 99;
    cursor: pointer;
    img{
        width: 100%;
        max-width: 100%;
    }
    .button-null{
        font-size: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c1c1c1;
        width: 100%;
    }
    a{
        display: block;
    }
    &.two{
        display: flex;
        flex-wrap: wrap;
        box-sizing: content-box;
        a{
            width: 50%;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            display: block;
            padding-bottom: 50%;
            background: #e4e4e4;
            img{
                position: absolute;
            }
        }
    }
    &.three{
        height: 100px;
        overflow-y: auto;
        white-space: nowrap;
        box-sizing: content-box;
        a{
            width: 100px;
            height: 100px;
            margin: 0 4px;
            display: inline-block;
            vertical-align: bottom;
            background: #e4e4e4;
        }
    }
}
</style>