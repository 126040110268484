<template>
    <div class="setup">

        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                    style="width:300px"
                    placeholder="请输入域名"
                    v-model="keyword"
                    clearable
                    >
                </el-input>
                <el-button type="primary" @click="search">搜索</el-button>
            </div>
            <div>
                <el-button type="primary" @click="drawer = true;">添加绑定</el-button>
                    <!-- <el-dialog title="添加绑定" :visible.sync="drawer" center append-to-body width="700px">
                        <el-form :model="add">
                            <el-form-item>
                                <el-input v-model="add.name" autocomplete="off" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" :label-width="addLabelWidth">
                                <el-radio v-model="add.status" label="1">启用</el-radio>
                                <el-radio v-model="add.status" label="2">禁用</el-radio>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="add.memo" autocomplete="off" placeholder="备注"></el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="Add">确 定</el-button>
                        </div>
                    </el-dialog> -->
            </div>
        </div>

        <!-- 表格部分 -->
        <div class="f2">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                    prop="name"
                    label="公司名称"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="account"
                    label="子账户"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="domain"
                    label="域名"
                    width="350">
                </el-table-column>
                <el-table-column
                    prop="IP"
                    label="IP"
                    width="350"
                >
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="修改时间"
                    >
                </el-table-column>
                <el-table-column
                    prop="memo"
                    label="备注"
                    >
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    >
                    <template slot-scope="scope" style="display:flex;">
                        <el-button @click="hand1(scope.$index, scope.row)" type="text" size="small">修改</el-button>
                            <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" center append-to-body width="38%">
                                <el-form :model="form">
                                    <el-form-item>
                                        <el-input v-model="form.name" autocomplete="off" placeholder="请输入公司名称"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态" :label-width="formLabelWidth">
                                            <el-radio v-model="form.status" label="1">启用</el-radio>
                                            <el-radio v-model="form.status" label="2">禁用</el-radio>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input v-model="form.memo" autocomplete="off" placeholder="备注"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                                    <el-button type="primary" @click="edit">确 定</el-button>
                                </div>
                            </el-dialog>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: '',
                dialogFormVisible: false,
                form: {
                    name: '',
                    status:'1'
                },
                formLabelWidth: '45px',
                curPage:1,
                totalNum:0,
                pageSize:10,
                tableData:[{
                    name:'xxx',

                }],
            }
        },

        methods: {
            // 搜索
            search() {
                
            },
            hand1() {
                this.dialogFormVisible = true
            },
            edit() {

            },
            handleSizeChange(val) {
                this.pageSize = val
                console.log('this.pageSize', this.pageSize)
                // this.getGoodsList(this.keyword)
            },
            handleCurrentChange(val) {
                console.log('current page', val);
                this.curPage = val;
                // this.getGoodsList(this.keyword)
            },
        },
    }
</script>

<style scoped>

.setup .f2 {
    padding: 15px;
    background-color: #fff;
}
.setup .page {
    text-align: right;
    margin: 15px 2px 0 0;
    padding-bottom: 50px;
}
</style>