<template>
  <div class="datacenter">
        <!-- 详细数据第一版块 -->
        <!-- <el-card class="box-card"> -->
        <div class="card" v-loading="loading">  
          <div class="f1" v-if="shoWorderData">
            <!-- 第一个框 -->
<!--            <div class="c1">-->
<!--              <div class="c1-1" style="margin-right: 5px;">-->
<!--                <div>付款总订单</div>-->
<!--                <div class="num">{{orderData.pay_today}}</div>-->
<!--              </div>-->
<!--              <div class="c1-2" style="margin-left: 0vh;">-->
<!--                <div>订单总金额</div>-->
<!--                <div class="num">{{orderData.pay_total}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="font-size:1.5rem;height:5vh">|</div>-->
            <!-- 第二个框 -->
            <div class="c2">
              <div class="c2-1" style="margin-right: 5px;">
                <div>天猫成交单数</div>
                <div class="num">{{orderData.tm_pay_today}}</div>
              </div>
              <div class="c2-1" style="margin-right: 5px;">
                <div>订单总金额</div>
                <div class="num">{{orderData.tm_pay_total}}</div>
              </div>

              <div class="c2-2" style="margin-right: 5px;">
                <div>退单量</div>
                <div class="num">{{orderData.tm_back_order}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>退款金额</div>
                <div class="num">{{orderData.tm_back_total}}</div>
              </div>
              <div class="c3-2" style="margin-left: 10px;margin-right: 5px;">
                <div>退款率</div>
                <div class="num">{{orderData.tm_refund_rate}}</div>
              </div>
              <div class="c3-1" style="margin-right: 5px;">
                <div>有效订单数</div>
                <div class="num">{{orderData.tm_valid_order}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>有效订单金额</div>
                <div class="num">{{orderData.tm_refund_amount}}</div>
              </div>
              <div class="c3-2">
                <div>有效ROI</div>
                <div class="num">{{orderData.tm_roi}}</div>
              </div>
            </div>
            <div style="font-size:1.5rem;height:5vh">|</div>
            <!-- 第三个框 -->
            <div class="c2">
              <div class="c2-1" style="margin-right: 10px">
                <div>京东成交单数</div>
                <div class="num">{{orderData.jd_pay_orders}}</div>
              </div>
              <div class="c2-1" style="margin-right: 5px;">
                <div>订单总金额</div>
                <div class="num">{{orderData.jd_pay_total}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>退单量</div>
                <div class="num">{{orderData.jd_back_order}}</div>
              </div>
              <div class="c3-2">
                <div>退款金额</div>
                <div class="num">{{orderData.jd_back_total}}</div>
              </div>
              <div class="c3-2" style="margin-left: 10px;margin-right: 5px;">
                <div>退款率</div>
                <div class="num">{{orderData.jd_refund_rate}}</div>
              </div>
              <div class="c3-1" style="margin-right: 5px;">
                <div>有效订单数</div>
                <div class="num">{{orderData.jd_valid_order}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>有效订单金额</div>
                <div class="num">{{orderData.jd_refund_amount}}</div>
              </div>
              <div class="c3-2">
                <div>有效ROI</div>
                <div class="num">{{orderData.jd_roi}}</div>
              </div>
            </div>
            <div style="font-size:1.5rem;height:5vh">|</div>
            <!-- 第四个框 -->
            <div class="c3">
              <div class="c3-1" style="margin-right: 5px">
                <div>拼多多付款订单</div>
                <div class="num">{{orderData.pdd_pay_orders}}</div>
              </div>
              <div class="c2-1" style="margin-right: 5px;">
                <div>订单总金额</div>
                <div class="num">{{orderData.pdd_pay_total}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>退单量</div>
                <div class="num">{{orderData.pdd_back_order}}</div>
              </div>
              <div class="c3-2">
                <div>退款金额</div>
                <div class="num">{{orderData.pdd_back_total}}</div>
              </div>
              <div class="c3-2" style="margin-left: 10px;margin-right: 5px;">
                <div>退款率</div>
                <div class="num">{{orderData.pdd_refund_rate}}</div>
              </div>
              <div class="c3-1" style="margin-right: 5px;">
                <div>有效订单数</div>
                <div class="num">{{orderData.pdd_valid_order}}</div>
              </div>
              <div class="c3-2" style="margin-right: 5px;">
                <div>有效订单金额</div>
                <div class="num">{{orderData.pdd_refund_amount}}</div>
              </div>
              <div class="c3-2">
                <div>有效ROI</div>
                <div class="num">{{orderData.pdd_roi}}</div>
              </div>
            </div>
          </div>
        </div>  
        <!-- </el-card> -->
        <!-- 第一版块结束 -->

        <!-- 详细数据第二版块 -->
        <div class="f2">
          <el-tabs v-model="active" @tab-click="handleClick" v-loading="loading"> 
            <el-tab-pane label="订单明细" name="trade_detail">
              <div class="search2">
                <!-- 搜索框 -->
                <el-select
                  style="width:13vh;margin:0 20px 0 0"
                  v-model="tradeSearchForm.platform"
                  @change="getDataCenter"
                  placeholder="请选择平台"
                >
                  <el-option
                    v-for="item in tradeSearchPlatform"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>

                <el-select
                  style="width:13vh;margin:0 20px 0 0"
                  v-model="tradeSearchForm.orderType"
                  @change="getDataCenter"
                  placeholder="请选择平台"
                >
                  <el-option
                    v-for="item in tradeSearchSource"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
                <el-select
                  style="width:13vh;margin:0 20px 0 0"
                  v-model="tradeSearchForm.timeType"
                  @change="getDataCenter"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in tradeSearchTimeType"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  style="margin:0 20px 0 0"
                  v-model="tradeSearchForm.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  @change="getDataCenter"
                >
                </el-date-picker>
                <el-select
                  style="margin:0 20px 0 0"
                  multiple
                  collapse-tags
                  clearable
                  v-model="tradeSearchForm.mainAccountId"
                  @change="getDataCenter"
                  placeholder="请选择代理商账户"
                >
                  <el-option
                    v-for="item in agent"
                    :key="item.mainAccountId"
                    :label="item.mainAccountName"
                    :value="item.mainAccountId"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-if="this.id_role != 3"
                  style="margin:0 20px 0 0"
                  clearable
                  v-model="childId"
                  @change="getDataCenter"
                  placeholder="请选择子账户名称"
                >
                  <el-option
                    v-for="item in childList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
                <el-select
                  style="width:27vh;margin:0 20px 0 0"
                  multiple
                  collapse-tags
                  clearable
                  v-model="tradeSearchForm.acticityId"
                  @change="getDataCenter"
                  placeholder="请选择活动"
                >
                <el-checkbox v-model="hdchecked" @change="changeAll" style="margin: 0px 0px 0px 170px;padding: 10px;">全选</el-checkbox>
                  <el-option
                    v-for="item in campaignList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  style="width:13vh;margin:0px 20px 0 0"
                  v-model="tradeSearchForm.orderStatus"
                  @change="getDataCenter"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in tradeSearchOrderStatus"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
                <el-input
                  style="width:40vh;margin:15px 20px 0 0"
                  v-model="trade_parent_id_val" 
                  placeholder="请输入订单编号"
                  clearable
                >
                  <el-button slot="append" @click="searchTrade_Parent_Id()">搜索</el-button>
                </el-input>
                <!-- 下载订单按钮 -->
                <span style="margin:15px 0 0 0">
                  <el-button type="primary" @click="showDownDialog()">下载订单</el-button>
                </span>
              </div>
              <!-- 表格内容 -->
              <div class="table2" style="margin: 0 1vh; "  >
                <el-table :data="tradeList" border style="width: 100%; " >
                  <el-table-column
                    prop="tb_paid_time"
                    label="下单时间"
                    width="190"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="trade_parent_id"
                    label="订单编号"
                    width="210"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="platform"
                    label="平台"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column prop="item_id" label="商品ID" width="180">
                  </el-table-column>
                  <el-table-column
                    prop="item_title"
                    label="商品标题"
                    width="200"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="agent_name"
                    label="代理商帐户"
                    width="180"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column
                    prop="account_name"
                    label="子账户名称"
                    width="200"
                    show-overflow-tooltip
                  >
                </el-table-column>
                  <el-table-column
                    prop="plan_name"
                    label="计划名称"
                    width="180"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column prop="alipay_total_price" width="150" label="付款金额">
                  </el-table-column>
                  <el-table-column prop="tk_status" label="订单状态">
                  </el-table-column>
                  <el-table-column prop="sum_order" label="汇总订单">
                  </el-table-column>
                </el-table>
              </div>
              <!-- 页码 -->
              <div class="pages">
                <el-pagination
                  @size-change="tradeSizeChange"
                  @current-change="tradeCurrentChange"
                  :current-page="tradePage"
                  :page-size="tradeSearchForm.pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="tradeTotal"
                >
                </el-pagination>
              </div>
            </el-tab-pane>

            <!-- 3.订单下载任务 -->
            <el-tab-pane label="订单下载任务" name="trade_down">
              <!-- 表格开始 -->
              <div class="table3" style="margin: 2vh 1vh 2vh 1vh;">
                <el-table :data="downList" border  style="width: 100%">
                  <el-table-column prop="status" label="任务状态" width="150">
                  </el-table-column>
                  <el-table-column prop="platform" label="渠道" width="100">
                  </el-table-column>
                  <el-table-column prop="name" label="下载文件名称" >
                  </el-table-column>
                  <el-table-column
                    prop="create_time"
                    label="报表下载请求时间"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="operation" label="操作" width="120">
                    <template slot-scope="scope">
                      <div class="flex">
                        <!-- <div>
                          <el-popover
                            placement="top"
                            width="50"
                            trigger="hover"
                          >
                            <div>
                              <div>
                                <el-button
                                  type="text"
                                  @click="showDownDialog(scope.row)"
                                  >任务详情</el-button
                                >
                              </div>
                            </div>
                            <i
                              class="el-icon-edit-outline"
                              slot="reference"
                            ></i>
                          </el-popover>
                        </div>
                        &nbsp;&nbsp; -->
                        <!-- 任务详情结束 -->
                        <!-- 3.2下载表格 -->
                        <div
                          v-if="scope.row.status == '完成'"
                          class="cursor"
                          @click="download(scope.row)"
                        >
                          <el-button type="text">下载表格</el-button>
                        </div>
                        &nbsp;&nbsp;
                        <!-- 3.3删除 -->
                          <div class="cursor">
                            <el-popconfirm title="确定删除吗？" @confirm="del(scope.$index,scope.row)">
                              <el-button slot="reference" type="text">删除</el-button>
                            </el-popconfirm>
                          </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      <!-- </el-tab-pane> -->


    <!-- 任务详情的嵌套 -->
    <el-dialog
      append-to-body
      :visible.sync="exportDialogVisible"
      width="750px"
      :title="isShowCreateDown? '生成订单下载任务' : '订单下载任务详情'"
      center
      :close-on-click-modal="false"	
    >

      <el-form :inline="true" :model="downSearchForm" :label-position="labelPosition" label-width="100px">
        <div style="display:flex;justify-content:space-evenly;">
          
          <el-form-item label="代理商账户">
            <el-select
                  v-model="downSearchForm.mainAccountId"
                  @change="getDataCenter"
                  multiple
                  collapse-tags
                  clearable
                  style="width:22.3vh"
                  placeholder="请选择代理商"
                >
                  <el-option
                    v-for="item in agent"
                    :key="item.mainAccountId"
                    :label="item.mainAccountName"
                    :value="item.mainAccountId"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
          <el-form-item label="平台">
            <el-select
                  style="width:24vh"
                  v-model="downSearchForm.orderType"
                  @change="getDataCenter"
                  placeholder="请选择平台"
                >
                  <el-option
                    v-for="item in tradeSearchSource"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
          </div><br>
        
          <!-- <el-form-item label="活动">
            <el-select
                  v-model="downSearchForm.campIds"
                  @change="getDataCenter"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in campaignList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
        </div><br> -->
        <div style="display:flex;justify-content:space-evenly;">
          <el-form-item label="订单状态">
            <el-select
                style="22vh"
                v-model="downSearchForm.orderStatus"
                @change="getDataCenter"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in tradeSearchOrderStatus"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                >
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="活动">
            <el-select
                  style="width:24vh"
                  v-model="downSearchForm.acticityId"
                  multiple
                  collapse-tags
                  clearable
                  @change="getDataCenter"
                  placeholder="请选择活动"
                >
                  <el-option
                    v-for="item in campaignList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
        </div><br>
        <div style="display:flex;justify-content:space-evenly;">
          <el-form-item label="时间类型">
            <el-select
                  style="22vh"
                  v-model="downSearchForm.timeType"
                  @change="getDataCenter"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in tradeSearchTimeType"
                    :key="item.val"
                    :label="item.name"
                    :value="item.val"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
        <!-- <div style="width:25vh"> -->
          <el-form-item label="时间">
              <el-date-picker
                  style="width:24vh"
                  v-model="downSearchForm.time"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  @change="getDataCenter"
                >
              </el-date-picker>
          </el-form-item>
        </div><br/>
        <div style="margin: 0 0 0 10px">
          <el-form-item label="文件命名">
            <el-input
                  style="width:58.8vh"
                  placeholder="请输入文件名称"
                  clearable
                  v-model="downSearchForm.fileName"
                />
          </el-form-item>
          <el-form-item label="">
          </el-form-item>
        </div><br>
        
      <!-- </div> -->
      <div v-if="isShowCreateDown" style="text-align:center; margin: 4vh 0 0 0; ">
        <el-button type="primary" @click="exportTrade" style="width:150px;margin: 1vh 0 4vh 0;">确定</el-button>
      </div>

      </el-form>
    </el-dialog>
    <!-- 嵌套结束 -->
    
  </div>
</template>

<script>
import {
  platForm,
  dataCenter,
  orderData,
  createdOrderTask,
  listOrderTask,
  activitylist,
  delOrder,
  agentList,
  getChildList
} from "@/request/api.js";

import $utils from "@/js/utils.js";
export default {
  // 数据部分
  data() {
    return {
      shoWorderData:false,//订单列表展示值
      hdchecked:false,
      trade_parent_id_val:'', //搜索订单编号值
      id_role:'',
      childId:'',
      childList:[],
      labelPosition:'right',
      loading: true,
      isShowCreateDown: true,
      // 嵌套 文件命名输入框
      input: "",
      // 订单明细的页码
      tradePage: 1,
      // 第一个页签
      activeName: "first",
      // 第二个页签
      active: "trade_detail",

      value: [],
      value1: "",
      value2: "",
      options: [],

      // 订单下载任务的表格
      table3: [
        {
          status: "已完成",
          channel: "淘宝联盟",
          name: "20220603~20220609-订单明细数据-1654739259386",
          time: "2022/06/09 09:48:45",
        },
      ],
      // 订单明细的表格
      campaignList: [],
      tradeList: [
        {
          code: 1585589377234251983,
          id: 633883606554,
          title: "脚医生正甲贴甲沟嵌甲炎专用拉拉脚趾甲指甲纠矫正器贴",
          money: "85.00",
          status: "已付款",
        },
      ],
      tradeSearchPlatform: [],
      tradeSearchSource: [
        {
          name: "淘宝联盟",
          val: "TBK",
        },
        {
          name: "京东联盟",
          val: "JD",
        },
         {
          name: "多多进宝",
          val: "PDD",
        },
        {
          name: "淘宝小程序",
          val: "TB-XCC",
        },
      ],
      tradeSearchTimeType: [
        {
          name: "付款时间",
          val: "tb_paid_time",
        },
        {
          name: "创建时间",
          val: "tk_create_time ",
        },
        {
          name: "结算时间",
          val: "tk_earning_time",
        },
      ],
      tradeSearchOrderStatus: [
        {
          name: "全部状态",
          val: 0,
        },
        {
          name: "订单结算",
          val: 3,
        },
        {
          name: "订单付款",
          val: 12,
        },
        {
          name: "订单失效",
          val:13,
        },
        {
          name: "订单成功",
          val: 14,
        },
        {
          name: "未付款",
          val: 99,
        },
      ],
      // 代理商选择
      agent:[],
      tradeSearchForm: {
        platform:1,
        acticityId:'',
        // mainAccountId:'',
        orderType: "TBK",
        orderStatus: 0,
        page: 1,
        pageSize: 10,
        startDate: "",
        endDate: "",
        timeType: "tb_paid_time",
        time: '',
      },
      tradeTotal: 0,
      exportDialogVisible: false,
      downList: [],
      orderData: {
        jd_back_order:'-',
        tm_back_order:'-',
        total_back_orders:'-',
        pdd_pay_orders:'-',
        tm_pay_total:'-',
        jd_pay_total:'-',
        pdd_pay_total:'-',
        pdd_back_order:'-',
        tm_pay_today: '-',
        tm_total_orders: '-',
        pay_today: '-',
        total_orders: '-',
        jd_pay_orders:'-',
        jd_total_orders:'-',
        pdd_roi:'-',
        jd_roi:'-',
        tm_roi:'-',
        pdd_refund_amount:'-',
        jd_refund_amount:'-',
        tm_refund_amount:'-',
        pdd_valid_order:'-',
        jd_valid_order:'-',
        tm_valid_order:'-',
        pdd_refund_rate:'-',
        jd_refund_rate:'-',
        tm_refund_rate:'-',
        pdd_back_total:'-',
        jd_back_total:'-',
        tm_back_total:'-',
        pay_total:'-',
      },
      downSearchForm: {
        acticityId:'',
        mainAccountId: '',
        orderType: "TBK",
        orderStatus: 0,
        page: 1,
        pageSize: 10,
        startDate: "",
        endDate: "",
        timeType: "tb_paid_time",
        // fileName: "订单明细数据" +  new Date(parseInt(new Date().getTime()))  + ".xlsx",
        fileName: "订单明细数据" + new Date(parseInt(new Date().getTime())).getFullYear() + '年' + 
                  (new Date(parseInt(new Date().getTime())).getMonth() + 1) + '月' + new Date(parseInt(new Date().getTime())).getDate() + '日' + ".xlsx",
                   
        time: ["", ""],
        // campIds: [],
      },
      // 时间选择器
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [
        {
          date: "1",
          name: "王小虎1",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      // tableData:[],
    };
  },

  // 方法部分
  methods: {

    //搜索订单编号
    searchTrade_Parent_Id(){
      console.log(this.trade_parent_id_val,'123456789')

      this.tradeSearchForm.search_order = this.trade_parent_id_val;
      this.getDataCenter()
    },
    /**
         * 获取平台列表
         */
    getPlatform(){
        this.loading = true;
        platForm().then(res => {
            if(res.code == 1){
                console.log(res,'平台列表');
                this.tradeSearchPlatform = res.data
                this.loading = false
            }
        })
    },

    getchildAccount(){
      getChildList({page:1,pageSize:100}).then(res=>{
        console.log(res,'res1111111')
        this.childList = res.data;
      })
    },
    // 点击删除 删除活动
    del(index, row) {
      // this.$message('暂未实现')
      delOrder({
        ids: [row.id],
      }).then((r) => {
        // console.log(r);
        if (r.code == 1) {
          this.downList.splice(index, 1);
          // this.getActivicy();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }else{
          this.$message('暂未实现')
        }
        // this.getActivicy.splice(index,1)
      });
    },
    download(row) {
      // console.log(row,'1231321');
      window.open(row.path);
      // const elink = document.createElement('a')
      //   elink.href = row.path //file.url
      //   elink.download = row.name //file.name
      //   elink.style.display = 'none'
      //   //link.target="_blank";
      //   elink.click()
    },
    showDownDialog(row) {
      // row 存在是查看详情， 否则是创建任务
      this.isShowCreateDown = !row
      // console.log('isShowCreateDown', this.isShowCreateDown)
      let arr = [
        new Date(new Date().getTime() - 3600 * 24 * 1000 * 7),
        new Date(),
      ];
      this.downSearchForm.startDate = $utils.format(arr[0], "yyyy-MM-dd");
      this.downSearchForm.endDate = $utils.format(arr[1], "yyyy-MM-dd");
      this.downSearchForm.time = [
        this.downSearchForm.startDate,
        this.downSearchForm.endDate,
      ];
      this.exportDialogVisible = true;
    },
    exportTrade() {
      this.loading = true;
      // console.log(JSON.parse(JSON.stringify(this.downSearchForm)),'JSON.parse(JSON.stringify(this.downSearchForm))')
      let param = JSON.parse(JSON.stringify(this.downSearchForm));
      param['startDate'] = param.time[0];
      param['endDate'] = param.time[1];
      createdOrderTask(param).then((res) => {
        this.loading = false;
        this.exportDialogVisible = false;
        this.active = "trade_down";
        this.getlistOrderTask();
        if (res.code == 1) {
          this.$message({
            message: "任务创建成功",
            type: "success",
          });
        }
      });

    },
    handleClick(tab, event) {
      // console.log(tab, event);
      if (tab.name == "trade_detail") {
      } else if (tab.name == "trade_down") {
        this.getlistOrderTask();
      }
    },
    tradeSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.tradeSearchForm.pageSize = val;
      this.tradeSearchForm.page = 1;
      this.getDataCenter()
      this.sx2()
    },
    tradeCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.tradeSearchForm.page = val;
      this.getDataCenter()
      this.sx2()
    },
    getlistOrderTask() {
      this.loading = true
      listOrderTask().then((res) => {
        // console.log("ddd", res);
        if(res.code == 1){
          this.loading = false
          this.downList = res.data;
        }
      });
    },
    //选择活动全选功能
    changeAll(e){
      console.log(e,'e')
      this.tradeSearchForm.acticityId = e?this.campaignList.map((item)=>item.id) : [];
      let param = JSON.parse(JSON.stringify(this.tradeSearchForm));
      this.getOrderData(this.tradeSearchForm.time[0],this.tradeSearchForm.time[1])

    },
    getOrderData(start,end) {
      orderData({startDate:start,endDate:end,acticityId:this.tradeSearchForm.acticityId,childid:this.childId}).then((res) => {
        console.log('===orderData===',res);
        if (res.code == 1) {
          this.orderData = res.data;
          this.shoWorderData = true;
        }else{
          this.shoWorderData = false;
        }
      })
    },
    sx2() {
      let param = JSON.parse(JSON.stringify(this.tradeSearchForm));
       agentList(param).then(r=>{
        //  console.log(r,'123456');
         this.agent = r.data;
       })
    },
    // 显示活动列表
    getDataCenter(val) {
      console.log(val,'val')
      let param = JSON.parse(JSON.stringify(this.tradeSearchForm));
      console.log(param,'param')
      param['childid'] = this.childId;
      param['startDate'] = param.time[0];
      param['endDate'] = param.time[1];
      // console.log('parma', param);
      
      dataCenter(param).then((res) => {
        // console.log("dataCenter", res);
        this.loading = false;
 
        this.tradeList = res.data.list;
        console.log(this.tradeList,'this.tradeList')
        this.tradeTotal = res.data.total;
        
      });
        this.sx2()
        if(param){
          this.getOrderData(param.time[0],param.time[1])
        }
        
    },
    getActivitylist() {
      activitylist().then((res) => {
        console.log("activitylist", res);
        this.campaignList = res.data[0].campaignList;
        console.log(this.campaignList);
        this.getOrderData();
        this.getDataCenter();
        this.getchildAccount();
        this.sx2();
      });
    },
  },

  mounted() {
    this.getPlatform();
    let arr = [
      new Date(new Date().getTime() - 3600 * 24 * 1000 * 7),
      new Date(),
    ];
    this.tradeSearchForm.startDate = $utils.format(arr[0], "yyyy-MM-dd");
    this.tradeSearchForm.endDate = $utils.format(arr[1], "yyyy-MM-dd");
    this.tradeSearchForm.time = [this.tradeSearchForm.startDate, this.tradeSearchForm.endDate];
    this.getActivitylist();
    let  userinfo = JSON.parse(sessionStorage.getItem("info"));
    console.log(userinfo,'userinfo',userinfo.id_role,'userinfo.id_role')
    this.id_role = userinfo.id_role;
  },
};
</script>

<style scoped>

/* // 详细数据第一版块 */
.datacenter .f1 {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 2vh 0;
  font-size: 13px;
}
.datacenter .card{
  margin: 0 0 2vh 0;
}

.datacenter .c1,.c2,.c3{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/deep/ .el-tabs__header{
    margin: 0px 1vh;
}
.datacenter .f2 {
  background-color: rgb(255, 255, 255);
  /* padding: 1vh 0; */
}
.datacenter .f2 .el-tabs {
  padding: 0 1vh;
}
.datacenter .f2 .search1 {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}
.datacenter .f2 .box {
  margin-left: 6vh;
  margin-right: 18vh;
}
.datacenter .upload {
  padding-left: 30vh;
}
.datacenter .f2 .search2 {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin: 2vh 1vh;
}

/* // 订单汇总表格 */
.datacenter .table1 {
  width: 160vh;
  padding-left: 0.5vh;
  padding-bottom: 1vh;
}
/* // 订单汇总  搜索栏  数据来源 */
.datacenter .search1-wz {
  margin-left: 2vh;
}

/* // 广告监测日志
// 广告标题 */
.datacenter .ad-title {
  text-align: left;
  background-color: #fff;
}

.datacenter .ad-title2 {
  margin-left: 7vh;
}

.datacenter .ad-main {
  padding: 2vh;
}

/* // 页码 */
.datacenter .pages {
  text-align: right;
  margin: 2vh 2vh 2vh 0;
}


.datacenter .data {
  background-color: #fff;
  padding: 2vh;
}

.datacenter .data-title {
  text-align: left;
}

.datacenter .num{
  color: red;
  font-size: 1.5rem;
}
.datacenter .dactive{
  margin-right: 2vh;
}

.datacenter .qt{
  display: flex;
}
.datacenter .dialog1{
  display: flex;
  flex-direction: column;
}
.datacenter .dialog2{
  display: flex;
  flex-direction: column;
}

.datacenter .a1{
  display: flex;
  flex-direction: column;
  /* margin-right: 20px !important; */
}
 
/* /deep/.el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
} */
</style>