<template>
  <div>
    <el-tabs v-loading="loading" v-model="activeName" @tab-click="tabClick" style>
      <!-- 一楼：搜索栏 -->
      <div class="flex" style="padding: 10px;">
        <columnclick
          style="margin: 0 0 0px 10px;"
          :param="'100005'"
          @accept="accountname"
          :columnsInfo="planColumns"
          @confirm="toConfirm('plan', $event)"
        ></columnclick>
        <el-button
          type="primary"
          style="margin-left: 16px;width:120px;height:40px"
          @click="handlePlan"
        >导出报表</el-button>
      </div>
      <el-tab-pane label name="plan" style>
        <el-table
          border
          :data="array"
          style="width: 100%;"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column fixed prop="planName" label="计划" width="450"></el-table-column>
          <el-table-column fixed prop="operation" label="操作" width="120">
            <div class="flex" slot-scope="allhandid">
              <div
                style="color: #0a63fc"
                class="cursor"
                @click="openAnalyse('plan', array[allhandid.$index],allhandid)"
                type="primary"
              >推广分析 </div>
            </div>
          </el-table-column>
          <el-table-column
            width="120"
            v-for="(item, index) in filterPlanColumns"
            :prop="item.val"
            :label="item.name"
            sortable
            :key="index"
            v-if="item.val != 'planName' && item.name != '操作'"
          >
          
        </el-table-column>
        </el-table>
        <div class="block flex_between margin_top">
          <span class="demonstration"></span>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 15, 50]"
            :page-size="1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(arrayPlan) ? Number(arrayPlan) : 0"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <drawer
      :orderFrom="orderfromplan"
      :filterDateStart="filterDateStartPlan"
      :filterDateEnd="filterDateEndPlan"
      :right_paramsPlan="right_params"
      ref="analyseView"
      :costplan="costplan"
      :clickplan="clickplan"
      :convertNumplan="convertNumplan"
      :payAmountplan="payAmountplan"
      :roiplan="roiplan"
      :showNumplan="showNumplan"
    ></drawer>
  </div>
</template>

<script>
import {
  planningdata,
  commodity,
  accountlis,
  downloadPlanData,
  cornMetricsByKeyId,
  columndefault
} from "@/request/api.js"; //
import columnclick from "./goods_columnclick";
import drawer from "./goods_drawer";
import advertisement from "./goods_advertisement";
import $utils from "@/js/utils";
const CFG_NAME = "good_tab_config";

export default {
  components: {
    columnclick,
    drawer,
    advertisement
  },
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          goodIds: [],
          acticityId: "",
          targetIds: [],
          targetType: "",
          roiMax: "",
          roiMin: "",
          costMax: "",
          costMin: "",
          orderFrom: ""
        };
      }
    },
    orderchange2: {
      type: String,
      default: ""
    },
    orderfromplan: {
      type: String,
      default: ""
    },
    valgoodsplan: {
      type: Array,
      default: ""
    },
    keywordPlan: {
      type: String,
      default: ""
    },
    parmval: {
      type: String,
      default: ""
    },
    // 计划账户筛选数据包传递
    accountarrayPlan: {
      type: Array,
      default: ""
    },
    totalnumPlan: {
      type: null,
      default: ""
    },
    val: {
      type: Array,
      default: ""
    },
    filterDateStartPlan: {
      //从父组件传递开始时间
      type: String,
      default: ""
    },
    filterDateEndPlan: {
      //从父组件传递结束时间
      type: String,
      default: ""
    },
    right_params: {
      type: String,
      default: ""
    },
    valone1: {
      type: Number,
      default: ""
    },
    acid:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      costplan:'',
      clickplan:'',
      convertNumplan:'',
      payAmountplan:'',
      roiplan:'',
      showNumplan:'',

      orderFrom: "",
      filterDateEnd: "",
      filterDateStart: "",
      right_paramsPlan: "",
      checked: "false",
      tab: "",
      loading: false,
      activeName: "plan",
      filterAccountData: null,
      page: 1,
      pageSize: 10,
      totalnum: 0,
      filterType: "",
      accountColumns: [
        {
          type: "属性指标",
          name: "账户ID",
          val: "accountId"
        },

        {
          type: "展现及花费",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "展现及花费",
          name: "平均点击单价",
          val: "cpc"
        },
        {
          type: "展现及花费",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "展现及花费",
          name: "订单成本",
          val: "orderCost"
        },
        {
          type: "转化及成交",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "转化及成交",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "转化及成交",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "成交ROI",
          val: "roi"
        }
        // {
        //   type: "展现及花费",
        //   name: "当日转化金额",
        //   val: "convertAmount"
        // }
      ],
      adgroupColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "订单成本",
          val: "orderCost"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        // {
        //   type: "展现及花费",
        //   name: "当日转化金额",
        //   val: "convertAmount"
        // },
        // {
        //   type: "展现及花费",
        //   name: "当日转化ROI",
        //   val: "convertRoi"
        // },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        }
      ],
      // 计划数据表格表头数据列
      planColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "展现及花费",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "展现及花费",
          name: "订单成本",
          val: "orderCost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "展现及花费",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "展现及花费",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "转化及成交",
          name: "平均点击单价",
          val: "cpc"
        }
      ],
      array: [],
      arrayPlan: {
        type: Number,
        default: 1
      },
      midell: this.valone1
    };
  },
  watch: {
    // filterData(newVal) {
    //   console.log('ddddd')
    //   this.handlerFilterAccount();
    // },
    filterData: {
      handler(newVal) {
        console.log("goods_tabs filter data handler", newVal);
        // this.handlerFilterAccount();
      },
      deep: true
    },
    accountarrayPlan(accountarrayPlan) {
      this.array = this.accountarrayPlan;
    },
    totalnumPlan(totalnumPlan) {
      this.arrayPlan = this.totalnumPlan;
    },
    valone1(valone1) {
      console.log(valone1, "console.log(this.valone1);");
      this.page = this.valone1;
    },
    page(page, midell) {
      console.log(this.page, "page");
      this.midell = this.page;
    },

    parmval(e) {
      this.handlerFilterAccount(e);
    }
  },
  computed: {
    filterPlanColumns() {
      return this.planColumns.filter(ele => ele.checked);
    }
  },
  created() {
    console.log();
  },
  // orderchange2动态订单来源下标
  mounted() {
    console.log();
    // this.handlerFilterAccount();
    if (this.valone1) {
      this.page = this.valone1;
    }

      columndefault({
        btnCode: "100005",
      }).then((res) => {
        
        let list = res.data.sort((a, b) => {
          return a.colTypeCode - b.colTypeCode;
        });
        let data1 = list[0].colTypeCode;
        let typeList = ["属性指标", "展现及花费", "转化及成交"];
        let index = 0;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          list[i].name = item.colName;
          list[i].val = item.colField;
          if (data1 == item.colTypeCode) {
            list[i].type = typeList[index];
          } else {
            data1 = item.colTypeCode;
            index++;
            list[i].type = typeList[index];
          }
        }
        this.planColumns = list;
        console.log(this.planColumns,'this.planColumns')
        this.planColumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.planColumns.forEach((ele, idx) => {
          // ele.checked = true;
          
          this.$set(this.planColumns[idx], "checked", true);
        });
        // console.log(this.planColumns,'this.planColumns')
        // console.log(localStorage.setItem("datakanban",list),'localStorage.setItem("datakanban",list);')
        // localStorage.setItem("good_tab_config", JSON.stringify(list));
      });
    

    this.planColumns.forEach((ele, idx) => {
      if (!this.planColumns[idx].checked) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
    
  },
  created() {
    let cfg = localStorage.getItem(CFG_NAME);
    if (cfg) {
      cfg = JSON.parse(cfg);
      if (cfg.account) {
        this.accountColumns = cfg.account;
      }
      if (cfg.adgroup) {
        this.adgroupColumns = cfg.adgroup;
      }
      if (cfg.plan) {
        this.planColumns = cfg.plan;
      }
    }
    this.accountColumns.forEach((ele, idx) => {
      if (
        this.accountColumns[idx].checked == undefined ||
        this.accountColumns[idx].checked == null
      ) {
        this.$set(this.accountColumns[idx], "checked", true);
      }
    });
    this.adgroupColumns.forEach((ele, idx) => {
      if (
        this.adgroupColumns[idx].checked == undefined ||
        this.adgroupColumns[idx].checked == null
      ) {
        this.$set(this.adgroupColumns[idx], "checked", true);
      }
    });
    this.planColumns.forEach((ele, idx) => {
      if (
        this.planColumns[idx].checked == undefined ||
        this.planColumns[idx].checked == null
      ) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
  },
  methods: {
    handlePlan() {
      downloadPlanData({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: this.acid,  // 
        filterDateStart: this.filterDateStartPlan,
        filterDateEnd: this.filterDateEndPlan,
        orderFrom: this.orderfromplan,
        accountIds: this.val,
        search: this.keywordPlan,
        btnCode: "100005",
        goodIds: this.valgoodsplan
      }).then(res => {
        $utils.exportRaw(res.data.filename, res.data.file);
      });
    },
    // 按钮
    accountname(accountname) {
      this.Planname = accountname.colName;
      console.log(accountname.colName);
    },
    // 推广分析
    openAnalyse(type, data, tabId) {
      this.$refs.analyseView.show(type, data, tabId);
      console.log(data.planId);
      this.chooseKeyIdplan=data.planId
      cornMetricsByKeyId({
           filterDateStart: this.filterDateStartPlan,
           filterDateEnd: this.filterDateEndPlan,
            chooseKeyType:"plan",
            chooseKeyId: this.chooseKeyIdplan,
            orderFrom:this.orderchange2,
      }).then(res=>{
            console.log(res.data);
            this.costplan=res.data.cost
            this.clickplan=res.data.click
            this.convertNumplan=res.data.convertNum
            this.payAmountplan=res.data.payAmount
            this.roiplan=res.data.roi
            this.showNumplan=res.data.showNum
      })
    },
    // 按钮
    toConfirm(type, data) {
      console.log("自定义列", type, this.accountColumns);
      if (type == "account") {
        this.accountColumns = data;
      } else if (type == "adgroup") {
        this.adgroupColumns = data;
      } else {
        this.planColumns = data;
      }
      // 缓存选中列
      let cfg = localStorage.getItem(CFG_NAME);
      if (cfg) {
        cfg = JSON.parse(cfg);
      } else {
        cfg = {};
      }
      cfg[type] = data;
      localStorage.setItem(CFG_NAME, JSON.stringify(cfg));
    },
    // tabs切换事件
    tabClick(tab, page) {
      tab = tab.name;
      console.log(tab);
      this.filterType = "";
      this.filterAccount = "";
      this.handleClick(tab, 1);
    },
    handleClick(tab, page) {
      this.page = page;
      console.log(this.page, "this.page111111111111");
      this.handleFilterPlan();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleClick(this.activeName, 1);
    },
    //*************计划分页点击发起请求
    handleCurrentChange(val) {
      console.log(val, "123");
      this.handleClick(this.activeName, val);
    },
    // 点击tabs请求
    handlerFilterAccount(type) {
      console.log(type);
      if (
        !this.filterData.acticityId ||
        this.filterData.acticityId.length == 0
      ) {
        return;
      }
      let param = {
        tabType: type, //页签类别
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      param = Object.assign(param, this.filterData);
      this.planningdata(param).then(res => {
        this.array = res.data;
        this.array.forEach(showNum => {
          showNum.showNum = parseInt(showNum.showNum);
        });
        this.array.forEach(click => {
          click.click = parseInt(click.click);
        });
        this.array.forEach(convertNum => {
          convertNum.convertNum = parseInt(convertNum.convertNum);
        });
        console.log(this.array);
        this.arrayPlan = res.total;
        console.log(this.arrayPlan, "this.arrayPlan");
      });
    },

    // 查看直接从账户跳转到计划
    handleFilterPlan(type, account) {
      this.activeName = "plan";
      // console.log(JSON.parse(this.acid),'11111111111111111111')
      
        let param = {
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: this.acid,
        filterDateStart: this.filterDateStartPlan,
        filterDateEnd: this.filterDateEndPlan,
        orderFrom: this.orderfromplan,
        accountIds: this.val,
        search: this.keywordPlan,
        goodIds: this.valgoodsplan,
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      // console.log(JSON.parse(this.acid),'param')
      this.loading = true;
      this.planningdata(param).then(res => {
        this.loading = false;
        console.log("计划数据", res.data);
        this.array = res.data;
        this.arrayPlan = res.total;
        console.log(this.array);
      });
    },
    planningdata(param) {
      return new Promise((resovle, reject) => {
        this.loading = true;
        if (param["filterDateStart"]) {
          // console.log('我进来了')
          
          // console.log("报表tab页接口", param);
          // console.log(JSON.stringify(param.acticityId));
          // this.acid = JSON.stringify(param.acticityId);
          planningdata(param)
            .then(res => {
              // console.log(123,'123')
              this.loading = false;
              resovle(res);
            })
            .catch(e => {
              reject(e);
            });
        }
      });
    }
    
  }
};
</script>
 <style scoped>
.el-table th.el-table__cell > .cell {
  width: 400px;
}
/deep/.el-tabs__active-bar {
  display: none;
}
/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/#tab-plan {
  position: absolute;
}

/deep/.el-select__tags-text {
  width: 10px;
}
#pane-plan {
  padding: 18px;
}
</style>