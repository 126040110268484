<template>
  <div class="edit">
    <!-- 标题栏 -->
    <div class="title">
      <span class="title1">巨量引擎</span>
      <el-button class="title2" type="primary" @click="left"
        ><i class="el-icon-back"></i>返回</el-button
      >
    </div>
    <el-divider></el-divider>
    <!-- 基本信息 -->
    <div class="title3">基本信息</div>
    <!-- 基本信息内容 -->
    <!-- <div class="f1" v-loading="loading"> -->
    <div class="f1">
      <div class="c1">
        <span>投放广告时间</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          <el-radio-group v-model="activityInfo.activity_type">
            <el-radio-button label="2" icon="share"
              >短期投放
              <el-tooltip
                class="item"
                effect="dark"
                content="短期投放适用于阶段性，非持续性的营销活动"
                placement="top"
              >
                <i class="el-icon-warning"></i> </el-tooltip
            ></el-radio-button>
            <el-radio-button label="1"
              >长期投放
              <el-tooltip
                class="item"
                effect="dark"
                content="长期投放适用于持续性的营销活动"
                placement="top"
              >
                <i class="el-icon-warning"></i> </el-tooltip
            ></el-radio-button>
          </el-radio-group>
        </span>
      </div>
      <div class="c2" v-show="activityInfo.activity_type == 2">
        <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="c2">
        <!-- <span>投放类别</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          <el-radio-group v-model="radio2">
            <el-radio-button label="单品投放"
              >单品投放
              <el-tooltip
                class="item"
                effect="dark"
                content="主打单产品进行投放"
                placement="top"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </el-radio-button>
            <el-radio-button label="多品投放"
              >多品投放
              <el-tooltip
                class="item"
                effect="dark"
                content="选取多产品进行投放"
                placement="top"
              >
                <i class="el-icon-warning"></i> </el-tooltip
            ></el-radio-button>
          </el-radio-group>
        </span> -->
      </div>
    </div>
    <!-- 关联投放标题 -->
    <div class="title4">
      关联投放账户&nbsp;&nbsp;&nbsp;&nbsp;请配置与投放<span>商品</span>相对应的账户
    </div>
    <!-- 关联内容 -->
    <div class="f2">
      <div class="a">
        <div class="a1">
          <el-input
            placeholder="请输入账户名称和ID"
            clearable
            v-model="filterInput"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="a2">
          <el-popconfirm title="确定删除吗？" @confirm="del()">
            <!-- <i class="el-icon-delete" slot="reference"></i> -->
            <el-button slot="reference" type="danger" icon="el-icon-delete" 
              >删除关联</el-button
            >
          </el-popconfirm>
          <el-button type="primary" icon="el-icon-document-add" @click="open"
            >关联账户</el-button
          >
          <el-dialog title="关联账户" :visible.sync="dialogTableVisible">
            <div style="height:60vh;overflow-y: hidden;">
              <div class="search">
                <el-input placeholder="请输入账户名称和ID" v-model="filterIdAndName" clearable>
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="table_list">
                <el-table
                  :data="filterGridData"
                  style="width: 100%;"
                  row-key="code"
                  default-expand-all
                  ref="multipleTable"
                  @selection-change="handleSelectionChange"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                  <el-table-column
                    type="selection"
                    prop="checkbox"
                    label="全选"
                  ></el-table-column>
                  <el-table-column
                    property="name"
                    label="账户名称"
                    width="240"
                  ></el-table-column>
                  <el-table-column
                    property="remark"
                    label=""
                  ></el-table-column>
                  <el-table-column
                    property="code"
                    label="账户ID"
                  ></el-table-column>
                </el-table>
              </div>
              <!-- 关联用户按钮 -->
              <div class="btn">
                <!-- <el-button @click="dialogTableVisible = false">关 闭</el-button> -->
                <el-button type="primary" @click="relate">关联用户</el-button>
              </div>
            </div>
          </el-dialog>
        </div>
        <!-- 嵌套结束 -->
      </div>
      <!-- 关联投放用户  表格内容 -->
      <div class="a3">
        <el-table :data="filterTableData" style="width: 100%; max-height: 250">
          <el-table-column
            type="selection"
            prop="checkbox"
            label="全选"
            width="150"
          ></el-table-column>
          <el-table-column
            property="account_name"
            label="账户名称"
          ></el-table-column>
          <el-table-column
            property="account_id"
            label="账户ID"
          ></el-table-column>
          <el-table-column property="operate" label="操作">
            <template slot-scope="scope">
              <div class="cursor">
                <el-popconfirm title="确定删除吗？" @confirm="del(scope.row,scope.$index)">
                  <i class="el-icon-delete" slot="reference"></i>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 关联投放表格结束 -->
    <!-- 监测链接标题 -->
    <div class="title5" v-if="listData.id">监测链接</div>
    <!-- 监测链接 内容 -->
    <div class="f3" v-if="listData.id">
      <div class="a1">
        <div class="a1-1">已过审且在投的计划需要手动添加监测链接</div>
        <div class="a1-2">
          <el-button
            v-if="tabData.length > 0"
            type="primary"
            @click="exportFile"
            >导出全部监测链接</el-button
          >
        </div>
      </div>
      <!-- 表格内容 -->
      <div class="a2">
        <el-table :data="tabData" stripe style="width: 100%">
          <el-table-column prop="name" label="名称" width="300">
          </el-table-column>
          <el-table-column prop="url" label="链接" width="1200">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 监测内容结束 -->

    <!-- 底部标题 -->
    <div class="f4">
      <div class="f4-1">
        <span>活动名称</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="activityInfo.name"
            maxlength="20"
            show-word-limit
          >
          </el-input>
        </span>
      </div>
      <div class="f4-2">
        <el-button type="primary" @click="update">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { edit, relation, saveedit } from "../../request/api.js";
import $utils from "@/js/utils.js";
export default {
  data() {
    return {
      // 外层表格
      filterTableData: [],
      // 嵌套表格
      filterGridData: [{
          id: 1,
          date: '2016-05-02',
          name: '王小虎',
          code: '上海市普陀区金沙江路 1518 弄'
        }, {
          id: 2,
          date: '2016-05-04',
          name: '王小虎',
          code: '上海市普陀区金沙江路 1517 弄'
        }, {
          id: 3,
          date: '2016-05-01',
          name: '王小虎',
          code: '上海市普陀区金沙江路 1519 弄',
          children: [{
              id: 31,
              date: '2016-05-01',
              cname: '王小虎',
              code: '上海市普陀区金沙江路 1519 弄'
            }, {
              id: 32,
              date: '2016-05-01',
              cname: '王小虎',
              code: '上海市普陀区金沙江路 1519 弄'
          }]
        }, {
          id: 4,
          date: '2016-05-03',
          name: '王小虎',
          code: '上海市普陀区金沙江路 1516 弄'
        }],

      loading: true,
      dates: [],
      text: "",
      filterInput: "",
      filterIdAndName: "",
      radio1: "1",
      radio2: "单品投放",
      saveType: "save",
      // 关联用户的嵌套
      dialogTableVisible: false,
      listData: {
        id: 0,
      },
      gridData: [],
      tableData: [],
      multipleTable: [],
      // 监测链接 表格内容
      tabData: [],
      activityInfo: {
        name: "",
        activity_type: 1,
        platform: 1,
        start_time: "",
        end_time: "",
      },
    };
  },
  computed: {
    filterGridData() {
      if (this.filterIdAndName == '') {
        return this.filterGridData;
      }
      return this.filterGridData.filter(ele => ele.name.indexOf(this.filterIdAndName) != -1 || ele.code.indexOf(this.filterIdAndName) != -1);
    },
    filterTableData() {
      // console.log('t', this.tableData)
      if (this.filterInput == "") {
        return this.filterTableData;
      } else {
        return this.filterTableData.filter(
          (ele) => ele.account_name.indexOf(this.filterInput) != -1
        );
      }
    },
  },
  methods: {
    exportFile() {
      let name =
        "活动" +
        this.activityInfo.name +
        "_" +
        $utils.format(new Date(), "yyyy-mm-dd") +
        ".txt";
      let data = "活动" + this.activityInfo.name + "\r\n";
      this.tabData.forEach((ele) => {
        data += ele.name + ":" + ele.url + "\r\n";
      });
      $utils.exportRaw(name, data);
    },
    handleSelectionChange(val) {
      console.log('handleSelectionChange', val);
      // this.tableData = val; //  this.multipleTable 选中的值
      this.multipleTable = val;
    },
    left() {
      this.$router.go(-1);
    },
    del(row,index) {
      console.log('del', row, index, this.filterTableData);
      if (row) {
        // debugger;
        this.filterTableData = this.filterTableData.filter((ele) => ele.account_id != row.account_id);
        // this.tableData.splice(index, 1)
        // let arr = []
        //  this.multipleTable.forEach(item=> {
        //   this.tableData.forEach(i=> {
        //     console.log(item,i)
        //     if(item.code == i.account_id){
        //       arr.push(item)
        //     }
        //   })
        // })
        // this.multipleTable = arr
      } else {
        this.filterTableData = [];
      }
      // this.tableData.splice(index);
    },
    relate() {
      // this.multipleTable.forEach((ele) => {
      //   ele.account_name = ele.name;
      //   ele.account_id = ele.code;
      // });
      // console.log(this.multipleTable);
      this.filterTableData = this.multipleTable;
      this.dialogTableVisible = false;
    },
    open() {
      this.dialogTableVisible = true;
      this.filterGridData.forEach((item) => {
        this.filterTableData.forEach((i) => {
          if (item.code == i.account_id) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            });
          }
        });
      });
    },
    // 活动详情
    detail() {
      console.log(123)
      edit({
        activityId: this.listData.id,
        type: "list",
      }).then((res) => {
        // this.loading = false;
        console.log("detail", res);
        //   this.radio1 = this.listData.activityType;
        this.activityInfo = res.data.activityInfo;
        let sdate = new Date(this.activityInfo.start_time);
        let edate = new Date(this.activityInfo.end_time);
        if (
          isNaN(sdate) ||
          isNaN(edate) ||
          sdate.getTime() == 0 ||
          edate.getTime() == 0
        ) {
          this.initDate();
        }
        //回显数据
        // this.activityInfo.start_time = $utils.format(arr[0], 'yyyy-MM-dd');
        // this.activityInfo.end_time = $utils.format(arr[1], 'yyyy-MM-dd');
        // this.dates = [this.activityInfo.start_time, this.activityInfo.end_time];
        //   let sdate = new Date(this.activityInfo.start_time);
        //   let edate = new Date(this.activityInfo.end_time);
        //   return [sdate, edate]
        // },
        // this.text = this.listData.name;
        // this.dates = [this.listData.start_time, this.listData.end_time];
        // this.tableData = this.multipleTable;
        // console.log('gridData', this.gridData,  res.data.children);
        let lists = [];
        res.data.children.forEach(ele => {
          let obj = this.filterGridData.find(e => e.account_id == ele.account_id);
          if (obj) {
            lists.push(obj);
          } else {
            console.error("没有找到对应帐户！", ele);
          }
        })
        // console.log("list ", lists);
        this.filterTableData = lists;
        this.tabData = res.data.platform;
        // this.gridData.forEach((item) => {
        //   this.tableData.forEach((i) => {
        //     if (item.code == i.account_id) {
        //       this.multipleTable.push(item);
        //     }
        //   });
        // });
      });
    },
    //保存
    update() {
      let arr = this.filterTableData.map((item) => {
        return item.id;
      });
      let param = Object.assign(this.activityInfo, {
        status: "1",
        type: this.saveType,
        activityId: this.listData.id,
        connected_account: arr,
        activityType: this.activityInfo.activity_type,
      });
      if (this.listData.id) {
        param["activityId"] = this.listData.id;
      }
      console.log("param", param);
      param["start_time"] = this.dates[0]; //parseInt(new Date( this.dates[0]).getTime() / 1000);
      param["end_time"] = this.dates[1]; //parseInt(new Date( this.dates[1]).getTime() / 1000);
      
      saveedit(param).then((r) => {
        console.log(r);
        if (r.code == 1) {
          this.$router.push("/manage/Manage");
        }
      });
    },
    initDate() {
      let arr = [
        new Date(),
        new Date(new Date().getTime() + 3600 * 24 * 1000 * 30),
      ];
      this.activityInfo.start_time = $utils.format(arr[0], "yyyy-MM-dd");
      this.activityInfo.end_time = $utils.format(arr[1], "yyyy-MM-dd");
      this.dates = [this.activityInfo.start_time, this.activityInfo.end_time];
    },
  },

  mounted() {
    if (this.$route.query.data) {
      this.listData = JSON.parse(this.$route.query.data);
      this.saveType = "edit";
      console.log(this.listData);
    }
    this.initDate();
    this.detail();
    // // 取得所有帐户
    relation({

    }).then((r) => {
      console.log("所有帐户信息", r);
      if (r && r.data && r.data.children) {
        let data = r.data.children;
        data.forEach(ele => {
          ele.account_id = ele.code
          ele.account_name = ele.name
        })
        this.filterGridData = data;
        if (this.listData.id) {
          this.detail();
        } else {
          this.loading = false;
        }
      }
    });

    // relation().then(r=>{
    //   console.log('关联列表',r);
    //   this.filterGridData = r.data
    // })

  },
};
</script>

<style lang="scss" scoped>
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
::-webkit-scrollbar-corner {
  background: #fff;
  border-radius: 4px;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 4px;
  background-color: #fff;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  background-color: #e0e5f6;
}
.table_list{
  height: 84%;
  overflow-y: scroll;
}
.edit .nav-icon {
  display: flex;
  align-items: center;
}
.edit .el-icon-s-fold {
  font-size: 3vh;
}
.edit .nav-icon span {
  font-size: 2vh;
  padding-left: 1vh;
}
// 标题栏
.edit .title {
  display: flex;
  align-items: center;
  margin-top: 2vh;
}
.edit .title1 {
  text-align: left;
  flex: 1;
}
// 基本信息标题
.edit .title3 {
  text-align: left;
  margin-top: 2vh;
  margin-left: 4vh;
}
// 基本信息内容
.edit .f1 {
  margin-top: 2vh;
  background-color: #fff;
}
.edit .f1 .c1 {
  display: flex;
  align-items: center;
  padding: 2vh 0 0 1vh;
}
.edit .f1 .c2 {
  text-align: left;
  // margin-left: 2vh;
  padding: 2vh 0 2vh 3.8vh;
}
// 关联投放标题
.edit .title4 {
  margin: 2vh 0 0 4vh;
  text-align: left;
}
// 关联内容
.edit .f2 {
  margin-top: 2vh;
  background-color: #fff;
}
.edit .f2 .a {
  display: flex;
  justify-content: space-between;
}
.edit .f2 .a1,
.a2 {
  display: inline-block;
  padding: 2vh;
  margin-bottom: 5vh;
}
.edit .f2 .a2 .search {
  width: 35vh;
}
.edit .f2 .a3 {
  text-align: center;
}
.edit .f2 .a2 .btn {
  display: flex;
  width: 48%;
  justify-content: flex-end;
  position: fixed;
  text-align: right;
  margin-top: 2vh;
}

.edit .title5 {
  text-align: left;
  margin: 2vh 0 2vh 4vh;
}
.edit .f3 {
  background-color: #fff;
}
.edit .f3 .a1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit .f3 .a1-1 {
  padding-left: 2vh;
}
.edit .f3 .a1-2 {
  padding: 1vh 2vh 0 0;
}

// 底部样式
.edit .f4 {
  background-color: #fff;
  // position: fixed;
  // bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 82.5%;
  box-shadow: 0 2px 4px rgba(187, 55, 55, 0.12), 0 0 6px rgba(31, 29, 29, 0.04);
  z-index: 999;
}
.edit .f4-1,
.f4-2 {
  padding: 1vh;
}
.edit .f4-1 {
  display: flex;
  align-items: center;
}
// 按钮提示文字样式
.edit .el-icon-warning {
  color: rgb(214, 214, 214);
}
.edit .el-icon-warning:hover {
  color: rgb(15, 111, 207);
}
 .el-button--danger{
  margin: 0 10px 0 0 ;
}
</style>