<template>
    <div class="main-account">

        <!-- 一楼：搜索栏 -->
        <div class="head_search_box">
            <!-- 搜索框 -->
            <div class="head_search">
                <el-select
                 v-model="searchData.platform"
                 filterable 
                 placeholder="请选择"
                 @change="searchAccount"
                >
                    <el-option
                        key=""
                        label="全部平台"
                        value=""
                    >
                    </el-option>
                    <el-option
                        v-for="item in option2"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-input
                    style="width:300px"
                    placeholder="请输入授权账户名称"
                    v-model="searchData.keyword"
                    clearable
                    @keydown.enter.native="search"
                >
                    <template slot="append"><el-button type="primary" @click="search">搜索</el-button></template>
                </el-input>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-select
                 v-model="searchData.company_id"
                 filterable 
                 placeholder="请选择公司"
                 @change="searchAccount">
                    <el-option
                        key=""
                        label="全部"
                        value=""
                    >
                    </el-option>
                    <el-option
                        v-for="item in companyList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-select
                 v-model="searchData.type"
                 filterable 
                 placeholder="请选择"
                 @change="searchAccount"
                >
                    <el-option
                        v-for="item in option"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- 按钮：新增主账户 -->
            <div>
                <el-button type="primary" @click="drawer = true" style="margin-left: 16px;">新增</el-button>
                <el-dialog 
                    title="新增主账户" 
                    :visible.sync="drawer" 
                    center 
                    append-to-body 
                    width="30%"
                    :close-on-click-modal="false">
                        <el-form :model="add" :rules="rules" label-position="right" label-width="140px">
                            <el-form-item label="请输入主账户名称" style="width: 25vw" prop="name">
                                <el-input v-model="add.name" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="平台" style="width: 25vw">
                                <div style="text-align:left;">
                                    <el-radio-group v-model="add.platform">
                                        <el-radio label="1">巨量引擎</el-radio>
                                        <el-radio label="2">腾讯广告</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                            <el-form-item label="备注" style="width: 25vw">
                                <el-input 
                                    v-model="add.memo" 
                                    autocomplete="off"
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 3}"
                                    ></el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" style="text-align:center; margin:0 0 4vh 0;">
                            <el-button type="primary" @click="AddAccount" style="width:150px">确 定</el-button>
                        </div>
                </el-dialog>
            </div>
        </div>

        <!-- 二楼：表格内容 -->
        <div class="f2" v-loading="loading">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                    prop="account_name"
                    label="主账户名称"
                    width="150"
                    >
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="账户ID"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="platform"
                    label="平台"
                    width="90"
                    :formatter="statusFormatter1"
                    >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="授权账户名称"
                    width="260">
                    <template slot-scope="scope" >
                    <div style="display: flex;justify-content: space-between;">       
                        <el-input v-model="scope.row.name" placeholder="请输入新名称" v-if="scope.row.editstatus == true">
                            <el-button slot="append" icon="el-icon-check" @click="editUsername(scope.row)"></el-button>
                        </el-input>
                        <span v-else style="padding-top: 2px;">{{scope.row.name}}</span>
                        <el-button v-if="scope.row.editstatus == false" icon="el-icon-edit" size="mini" @click="showEdit(scope.row)"></el-button>
                    </div>
                       
                    </template>
                </el-table-column>
                <el-table-column
                    prop="company_id"
                    label="关联公司"
                    width="240">
                    <!-- <template slot-scope="scope">
                        <router-link style="margin-left: 10px;" :to="{path:'/taobaoGoods/landingpage',query:{id:scope.row.id}}">点击预览</router-link>
                    </template> -->
                </el-table-column>
          <!--  <el-table-column
                   prop="platform"
                    label="平台"
                    width="150"
                 >
                </el-table-column>      -->
                <!-- <el-table-column
                    label="淘宝详情链接"
                    width="170">
                    <template slot-scope="scope">
                        <a style="margin-left: 10px;" :href="'https://detail.tmall.com/item.htm?id='+scope.row.item_id" target="_blank">点击预览</a>
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="refresh_token_expires_in"
                    label="授权到期时间"
                    width="100"
                >
                    <template slot-scope="scope">
                        <el-tag type="danger" size="mini" v-if="scope.row.status == '待审核' || scope.row.status == '已拒绝' || scope.row.status == '未授权'">{{scope.row.status}}</el-tag>
                        <span  v-else :style="scope.row.warning == true ? 'color:red': ''">{{scope.row.refresh_token_expires_in}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="memo"
                    label="新版数据"
                    width="80">
                    <div slot-scope="scope">
                        <el-switch
                            :value="scope.row.status_v2 == 1"
                            active-color="#13ce66"
                            @change="switchStatusV2(scope.row,$event)"
                        >
                        </el-switch>
                    </div>
                </el-table-column>
                <el-table-column
                    prop="memo"
                    label="备注"
                    width="170">
                </el-table-column>
                <el-table-column
                        prop="expires_in"
                        label="access_token到期时间"
                        width="180"
                >
                    <template slot-scope="scope">
                        <el-tag type="danger" size="mini" v-if="scope.row.status == '待审核' || scope.row.status == '已拒绝' || scope.row.status == '未授权'">{{scope.row.status}}</el-tag>
                        <span  v-else :style="scope.row.access_warning == true ? 'color:red': ''">{{scope.row.expires_in}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="更新时间"
                    width="160"
                    >
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    width="260"
                    >
                    <template slot-scope="scope" style="display:flex;justify-content:space-between;">
                        <el-button @click="hand1(scope.row)" type="text" size="small">子账户</el-button>
                        <el-button @click="oauth(scope.row)" type="text" size="small">授权</el-button>
                        <el-button @click="syncSubAccounts(scope.row)" type="text" size="small">同步子账户</el-button>
                        <el-button @click="accountEdit(scope.row)" type="text" size="small">编辑</el-button>
                            <el-dialog 
                                width="30%"
                                title="编辑信息" 
                                :visible.sync="dialogFormVisible" 
                                center 
                                append-to-body
                                :close-on-click-modal="false">
                                <el-form :model="form" :rules="rules" label-position="right" label-width="140px">
                                    <el-form-item label="请输入主账户名称" style="width: 25vw" prop="account_name">
                                        <el-input v-model="form.account_name" autocomplete="off"></el-input>
                                    </el-form-item>
                                    <el-form-item label="备注" style="width: 25vw">
                                        <el-input 
                                            v-model="form.memo" 
                                            autocomplete="off"
                                            type="textarea"
                                             :autosize="{ minRows: 2, maxRows: 3}"
                                            ></el-input>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" style="margin: 0 0 4vh 0">
                                    <el-button type="primary" @click="edit" style="width:150px">确 定</el-button>
                                </div>
                            </el-dialog>
                        <el-popconfirm title="确定删除吗？" @confirm="accountDelete(scope.$index, scope.row)">
                            <el-button slot="reference" type="text" size="small">&nbsp;&nbsp;&nbsp;删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
            

        </div>
    </div>
</template>

<script>
import {
    platForm,
    accountlist,
    addaccount,
    delagent,
    editPrincipalAccount,
    asyncSubAccount, companylist,
    status_v2
} from '@/request/api';
    export default {
        data() {
            return {
                nikename:'',
                showInput:false,
                loading:false,
                // id:[],
                dialogFormVisible: false,
                form: {
                    name: '',
                    status:''
                },
                formLabelWidth: '45px',
                addLabelWidth:'50px',
                option:[
            {
                value: '',
                name: '全部状态'
            }, {
                value: '1',
                name: '未授权'
            }, {
                value: '2',
                name: '授权到期'
            },
            ],
            value:'',
            option2:[ ],

            totalNum: 0,
            pageSize: 10,
            curPage: 1,
            agentList: [],
            agentId: '',
            // 嵌套添加商品  
            drawer: false,
            // 搜索框
            keyword: '',
            // 表格内容  
            tableData: [],
            // 添加列表
            add: {
                name:'',
                status:'1',
                memo:'',
                platform: '1',
                company_id:'',
            },
            //表单验证
            rules: {
                account_name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
            },
                companyList:[],
                searchData: {
                    keyword:'',
                    company_id:'',
                    type:'',
                    platform: '',
                },
                company_id:''


        }
    },
    mounted () {
        this.getCompanyList();
        this.getPlatform();
        var query = this.$route.query
        if(query.company_id != undefined){
            this.company_id = query.company_id
        }else{
            this.getList();
        }
        
    },
    methods: {
        // 平台
        statusFormatter1(row, column) {
            const platform = row.platform;
            if (platform == 1) {
                return "巨量引擎";
            } else {
                return "腾讯广告";
            }
        },
        showEdit(row){
            // console.log(row,'row')
            row.editstatus = true;
            let dataArrTemp = JSON.stringify(this.tableData); //解决数据更新，视图不刷新bug
                this.tableData = JSON.parse(dataArrTemp);
        },
        //修改授权账户名称
        editUsername(row){
            // console.log(row,'row')
            editPrincipalAccount({id: row.id,type:2,nikename:row.name}).then((res) => {
                if(res.code == 1){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                    this.getList();
                    row.editstatus = false;
                    // console.log(row,'row')
                }
            })
        },
        
        /**
         * 获取平台列表
         */
        getPlatform(){
            this.loading = true;
            platForm().then(res => {
                if(res.code == 1){
                    console.log(res,'平台列表');
                    this.option2 = res.data
                    // this.searchData.platform = res.data[0].id;
                    this.loading = false
                    // if(this.company_id != ""){
                    //     setTimeout(() =>{
                    //         this.searchData.company_id = Number(this.company_id)
                    //         this.getList()
                    //     },300)

                    // }
                }
            })
        },

        /**
         * 获取公司列表
         */
        getCompanyList(){
            this.loading = true;
            companylist().then(res => {
                if(res.code == 1){
                    this.companyList = res.data
                    this.loading = false
                    if(this.company_id != ""){
                        setTimeout(() =>{
                            this.searchData.company_id = Number(this.company_id)
                            this.getList()
                        },300)

                    }
                }
            })
        },
        statusFormatter2(row, column) {
            const status = row.direct_state;
            if (status == 1) {
                return "正常";
            } else {
                return "禁用";
            }
        },
        hand1(row) {
            // console.log(row);
            this.$router.push('/manage/ChildAccount?id=' + row.code + "&platform="+row.platform)
        },
        handleSizeChange(val) {
            this.pageSize = val
            // console.log('this.pageSize', this.pageSize)
            this.getList()
        },
        handleCurrentChange(val) {
            // console.log('current page', val);
            this.curPage = val;
            this.getList()
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {
                });
        },
        filterTag(value, row) {
            return row.authorization_status === value;
        },
        // getAgentList() {
        //     if (this.agentList.length <= 0) {
        //         agentlist().then(res => {
        //             if (res.code == 1) {
        //                 this.agentList = res.data.list;
        //             }
        //         })
        //     }
        // },
        // 确定添加
        saveAccount() {

        },
        // 输入框搜索
        search() {
            this.curPage = 1;
            accountlist({
                name: this.searchData.keyword,
                page: this.curPage,
                pageSize: this.pageSize,
            }).then(r=>{
                // console.log(r);
                this.totalNum = r.total;
                this.tableData = r.data
            })
        },
        // 下拉框搜索
        searchAccount(e) {
            console.log(e)
            // this.searchData.platform = e;
            this.curPage = 1
            this.getList()
        },
        // 确定新增
        AddCompany() {

        },
        // 主账户管理列表
        getList() {
            this.loading = true;
            let params = {
                name: '',
                page: this.curPage,
                pageSize: this.pageSize,
                platform: this.searchData.platform
            }
            accountlist(params).then(r=>{
                if(r.code == 1){
                    this.loading = false;
                    this.totalNum = r.total
                    this.tableData = r.data
                    this.tableData.map((item, index) => {
                            item.editstatus = false; //需要先在tableData数组中每个元素添加showRightOp为false
                            });
                            // console.log(this.tableData,'this.tableData')
                }else{
                    this.loading = false;
                    this.$message({
                        message:r.msg,
                        type:'error'
                    })
                }
                
            })
            
        },
        // 添加主账户
        AddAccount() {
            addaccount({
                name: this.add.name,
                associated_companies: this.add.company_id,
                platform: this.add.platform,
                status: this.add.status,
                memo: this.add.memo,
            }).then(r=>{
                if(r.code == 1){
                    this.$message({
                        message:'添加成功',
                        type:'success'
                    })
                    this.getList();
                    this.drawer = false
                }else{
                    this.$message({
                        message:'添加失败',
                        type:'error'
                    })
                }
            })
        },

        accountEdit(item) {
            this.form = Object.assign({},item)
            this.dialogFormVisible = true
        },
        // 删除
        accountDelete(index,row) {
            this.$confirm("确认删除此账户吗？","提示").then(res => {
                if(res == "confirm"){
                    delagent({
                        ids: row.id,
                        platform: row.platform
                    }).then(r=>{
                        // console.log(r);
                        if(r.code == 1){
                            this.tableData.splice(index, 1);
                            this.$message({
                                message:'删除成功',
                                type:'success'
                            })
                            this.getList()
                        }else{
                            this.$message({
                                message:'删除失败',
                                type:'error'
                            })
                        }
                    })
                }
            })

        },
        // 确定编辑
        edit() {
            editPrincipalAccount({...this.form,company_id:this.id,type:1}).then(res => {
                if(res.code == 1){
                    this.$message({
                        message:'修改成功',
                        type:'success'
                    })
                    this.getList();
                    this.dialogFormVisible = false
                }
            })
        },
        /**
         * 授权
         * @param row
         */
        oauth(row){
            window.open(row.oauth_url,'')
        },
        /**
         * 同步子账户
         * @param row
         */
        syncSubAccounts(row){
            this.$confirm("确认同步子账户?","提示").then(r =>{
                if(r == "confirm"){
                    asyncSubAccount({id:row.id,platform: row.platform}).then(res => {
                        if(res.code == 1){
                            this.$message.success(res.msg)
                        }
                    })
                }
            })

        },
        switchStatusV2(row,e){
            var status = e ? 1 : 0
            status_v2({id: row.id, status_v2: status}).then(res =>{
                if(res.code == 1){
                    row.status_v2 = status
                    this.$message.success(res.msg)
                }else{
                    row.status_v2 = !e
                    this.$message.error(res.msg)
                }
            })
        }
    },
}
</script>

<style scoped>

/* .main-account .f1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 20px 0;
    background-color: #fff;
}
.main-account .f1-search {
    display: flex;
    align-items: center;
} */
.main-account .f2 {
    padding: 20px;
    background-color: #fff;
}

.main-account .page {
    text-align: right;
    margin: 15px 2px 0 0;
    /* padding-bottom: 50px; */
}

.main-account .drawer-main {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.main-account .empty {
    height: 68%;
}
a{
    text-decoration: none;
    color: #0a63fc;
}

.el-form-item{
    margin-bottom: 22px;
}

.main-account .head_search_box,.head_search{
    flex-wrap: wrap;
}
</style>