<template>
  <div class="company" v-loading="loading">
    <!-- 搜索部分 -->
    <div class="head_search_box">
      <div class="head_search">
        <el-input
          style="width: 300px"
          placeholder="请输入账号"
          v-model="searchData.keyword"
          clearable
          @keydown.enter.native="search"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-date-picker
          v-model="searchData.month"
          style="margin-left: 10px"
          @change="changeMonth"
          :picker-options="timeChange"
          :clearable="false"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <el-select
          v-model="Teamtype"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeStatus"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-checkbox
          v-model="Starchecked"
          style="margin-left: 10px"
          @change="changeStar"
          >只显示带星账号</el-checkbox
        >
        <el-select
          v-model="searchData.state"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeStatu"
        >
          <el-option
            v-for="item in statuList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="derive" style="display: flex">
        <el-button
          type="primary"
          @click="downloadAgent"
          style="margin-left: 16px; width: 120px; height: 40px"
          >导出报表</el-button
        >
      </div>
    </div>

        <!-- 数据块展示部分 -->
    <div class="f2 flex_between" style="flex-wrap: wrap">
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">凤梨账号</span>
          <div style="font-size: 28px" v-if="allboard.fl_account == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.fl_account }}</div>
        </div>
      </div>

      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">元英账号</span>
          <div style="font-size: 28px" v-if="allboard.yy_account == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.yy_account }}</div>
        </div>
      </div>

      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">其他账号</span>
          <div style="font-size: 28px" v-if="allboard.rests_account == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.rests_account }}</div>
        </div>
      </div>

      

      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">凤梨佣金</span>
          <div style="font-size: 28px" v-if="allboard.fl_brokerage == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.fl_brokerage }}</div>
        </div>
      </div>
      
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">元英佣金</span>
          <div style="font-size: 28px" v-if="allboard.yy_brokerage == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.yy_brokerage }}</div>
        </div>
      </div>

      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">其他佣金</span>
          <div style="font-size: 28px" v-if="allboard.rests_brokerage == null">0</div>
          <!-- <div style="font-size: 28px">0</div> -->
          <div style="font-size: 28px" v-else>{{ allboard.rests_brokerage }}</div>
        </div>
      </div>
    </div>
    <!-- 表格部分 -->
    <div class="f2">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @cell-mouse-enter="hoverRow"
        @cell-mouse-leave="leaveRow"
      >
        <el-table-column prop="name" label="账号名称" fixed="left" width="180">
          <template slot-scope="scope" style="display: flex">
            <span>{{ scope.row.name }}</span>
            <i
              class="el-icon-star-off"
              style="cursor: pointer;margin-left: 5px;"
              v-show="scope.row.starstatus == true && scope.row.STATUS != 1"
              @click="clickRow(scope.row, 1)"
            ></i>
            <i
              class="el-icon-star-on"
              style="cursor: pointer;margin-left: 5px;"
              v-show="scope.row.STATUS == 1"
              @click="clickRow(scope.row, 0)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column prop="grouping" label="分组" width="150">
          <template slot-scope="scope" style="display: flex">
            <el-select v-model="scope.row.grouping" placeholder="请选择" @change="clickType(scope.row.id,$event)">
                  <el-option
                    v-for="item in fzList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>  
            <!-- <el-button
              :type="changeType(scope.row)"
              size="small"
              @click="clickType(scope.row.id, 1)"
              >凤梨</el-button
            >
            <el-button
              class="yuanying"
              :type="changeBType(scope.row)"
              size="small"
              @click="clickType(scope.row.id, 2)"
              >元英</el-button
            >
            <el-button
              class="yuanying"
              :type="changeCType(scope.row)"
              size="small"
              @click="clickType(scope.row.id, 3)"
              >其他</el-button
            > -->
          </template>
        </el-table-column>
        <el-table-column prop="platform" label="平台" width="80"> </el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          width="80"
          :filters="[
            { text: '正常', value: '正常' },
            { text: '已删除', value:'已删除' },
          ]"
          :filter-method="filterTag"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.state == '正常' ? 'success' : 'danger'"
              disable-transitions
              >{{ scope.row.state }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="compellation" label="姓名" width="160">
            <template slot-scope="scope" >
                    <div style="display: flex;justify-content: space-between;">       
                        <el-input v-model="scope.row.compellation" placeholder="请输入新名称" v-if="scope.row.namestatus == true">
                            <el-button slot="append" icon="el-icon-check" @click="editUsername(scope.row)"></el-button>
                        </el-input>
                        <span v-else style="padding-top: 2px;">{{scope.row.compellation}}</span>
                        <el-button v-if="scope.row.namestatus == false" icon="el-icon-edit" size="mini" @click="showEdit(scope.row)"></el-button>
                    </div>
                       
                    </template>
          </el-table-column>

          <el-table-column prop="mobile" label="手机" width="200">
            <template slot-scope="scope" >
                    <div style="display: flex;justify-content: space-between;">       
                        <el-input v-model="scope.row.mobile" placeholder="请输入新手机号" v-if="scope.row.phonestatus == true">
                            <el-button slot="append" icon="el-icon-check" @click="editphone(scope.row)"></el-button>
                        </el-input>
                        <span v-else style="padding-top: 2px;">{{scope.row.mobile}}</span>
                        <el-button v-if="scope.row.phonestatus == false" icon="el-icon-edit" size="mini" @click="showPhoneEdit(scope.row)"></el-button>
                    </div>
                       
                    </template>
          </el-table-column>
        <el-table-column prop="account_type" label="类型" width="200">
            <template slot-scope="scope" >
                <el-select v-model="scope.row.account_type" placeholder="请选择" @change="changeAccountType(scope.row,$event)">
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>       
            </template>
          </el-table-column> 
        <el-table-column prop="alipay_total_price" label="订单金额" width="90">
        </el-table-column>
        <el-table-column prop="total_commission_fee" label="预估佣金" width="80">
        </el-table-column>
        <el-table-column prop="total" label="实际佣金" width="80">
        </el-table-column>
        <el-table-column prop="alimama_share_fee" label="服务费" width="80">
        </el-table-column>
        
        <el-table-column prop="month" label="月份" width="100"> </el-table-column>
        <el-table-column prop="operation" label="操作" fixed="right" width="100">
          <template slot-scope="scope" style="display: flex">
            <el-button @click="handleAgent(scope.row)" type="text" size="small"
              >导出明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          :current-page="curPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Number(totalNum) ? Number(totalNum) : 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getorders, unionOrdersExport, collect, accGroup,statistics,getAccountTypeList } from "@/request/api";
import $utils from "@/js/utils";
export default {
  data() {
    return {
      allboard:{}, //统计数据
      Teamtype: "0",
      Starchecked: false,
      starstatus: false,
      loading: false,
      typeList:[], //类型列表

      fzList:[
        {
          value: 1,
          label: "凤梨",
        },
        {
          value: 2,
          label: "元英",
        },
        {
          value: 3,
          label: "其他",
        },
      ],//分组列表
      

      statusList: [
          {
          value: "0",
          label: "全部",
        }, 
        {
          value: "1",
          label: "凤梨",
        },
        {
          value: "2",
          label: "元英",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
      statuList: [ //状态下拉
        {
          value: 1,
          label: "正常",
        },
        {
          value: 3,
          label: "已删除",
        },
        {
          value:"",
          label:"全部"
        }
      ],
      statusvalue: "0",
      tableData: [ //表格数组
        {
          account_name: "王小虎",
          status: "启用",
        },
      ],
      drawer: false,
      curPage: 1,
      totalNum: 0,
      pageSize: 10,
      addAdvertising: {
        name: "",
        ratio: "",
        status: "1",
        memo: "",
      },
      addLabelWidth: "120px",
      formLabelWidth: "120px",
      userinfo: [],
      advertisingId: "",
      searchData: {
        state:'',
        keyword: "",
        month: [new Date().setHours(0, 0, 0, 0) , new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24 - 1],
      },
      timeChange: {
        disabledDate: (time) => {
          // .vue文件
          // 获取当天0点时间（中国标准时间）
          return time.getTime() >= new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24 || time.getTime() < new Date('2022-06-1');
        },
      },
      is_collect:'',
      startTime: "",
      endTime: "",
    };
  },
  watch: {},
  computed: {},
  created() {},

  mounted() {
    //  this.searchData.month = $utils.format(new Date(), "yyyy-MM");
     
    
    
    // console.log(this.startTime,'this.startTime')
    this.cshsearch();
    
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    this.statistics();
  },

  methods: {
    //状态筛选
    filterTag(value, row,column) {
      // console.log(value,'value',row)
      return row.state === value;
    },
    //
    changeStatu(e){
      console.log(e,'e')
      this.searchData.statu = e;
      this.getAllAdvertising();
    },
    cshsearch(){
      const start = new Date().setHours(0, 0, 0, 0);
      const end = new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24 - 1;
      // console.log(start,end,'end')
      this.searchData.month[0] = new Date(start);
      this.searchData.month[1] = new Date(end);
      //  console.log(this.searchData.month[1],'this.searchData.month[1]')
      this.getAllAdvertising();
    },
    //切换类型
    changeAccountType(row,type){
      
      accGroup({ id: row.id, type:'account_type',account_type:Number(type) }).then((res) => {
        // console.log(res,'res')
            if (res.code == 1) {
              
              this.changeStar(this.Starchecked)
            } 
          });
    },
    //显示手机输入框
    showPhoneEdit(row){
      row.phonestatus = true;
            let dataArrTemp = JSON.stringify(this.tableData); //解决数据更新，视图不刷新bug
                this.tableData = JSON.parse(dataArrTemp);
    },
    editphone(row){
       accGroup({ id: row.id, type:'mobile',mobile:Number(row.mobile) }).then((res) => {
        // console.log(res,'res')
            if (res.code == 1) {
              row.phonestatus = false;
              this.changeStar(this.Starchecked)
            } else {
              row.phonestatus = false;
            }
          });
        },
    //显示姓名输入框
     showEdit(row){
            // console.log(row,'row')
            row.namestatus = true;
            let dataArrTemp = JSON.stringify(this.tableData); //解决数据更新，视图不刷新bug
                this.tableData = JSON.parse(dataArrTemp);
        },
        //修改名称
        editUsername(row){

          accGroup({ id: row.id, type:'name',name:row.compellation }).then((res) => {
        // console.log(res,'res')
            if (res.code == 1) {
              row.namestatus = false;
              this.changeStar(this.Starchecked)
            } else {
              row.namestatus = false;
            }
          });
            // console.log(row,'row')
            // editPrincipalAccount({id: row.id,type:2,nikename:row.name}).then((res) => {
            //     if(res.code == 1){
            //         this.$message({
            //             message:'修改成功',
            //             type:'success'
            //         })
            //         this.getList();
            //         row.namestatus = false;
            //         // console.log(row,'row')
            //     }
            // })
        },
    //获取统计数据
    statistics(){
      // console.log( this.startTime,' this.startTime')
      statistics({startTime: this.startTime,endTime: this.endTime,}).then(r=>{
        // console.log(r,'r')
        this.allboard = r.data
      })
      getAccountTypeList({}).then(r=>{
        this.typeList = r.data
        // console.log(r,'r')
      })
    },
    //切换显示星号
    changeStar(e) {
      if (e == true) {
        // console.log(this.curPage,'this.curPage')
        this.curPage = 1;
        // console.log(this.curPage,'this.curPage1111')
        this.is_collect = 1;
        this.loading = true;
        this.getAllAdvertising();
        
      } else {
        this.is_collect = '';
        this.loading = true;
        this.getAllAdvertising();
      }
    },
    //切换分组
    clickType(id, type) {
      this.$confirm("确认修改吗?", "提示").then((result) => {
        if (result == "confirm") {
         accGroup({ id: id, parameter: type,type:'parameter' }).then((res) => {
            // console.log(res,'res')
            if (res.code == 1) {
              statistics({startTime: this.startTime,endTime: this.endTime,}).then(r=>{
                // console.log(r,'r')
                this.allboard = r.data;
                this.changeStar(this.Starchecked)
              })
              
              
            } else {
              this.$message.error(res.msg);
            }
          });
            }
      });
      
    },
    //button分组
    changeType(row) {
      if (row.grouping == 1) {
        return "primary";
      } else {
        return "plain";
      }
    },
    changeBType(row) {
      // console.log(row.grouping,'row.grouping')
      if (row.grouping == 2) {
        return "primary";
      } else {
        return "plain";
      }
    },
     changeCType(row) {
      if (row.grouping == 3) {
        return "primary";
      } else {
        return "plain";
      }
    },
    //导出全部报表
    downloadAgent() {
      this.is_collect = '';
      if(this.Starchecked == true){
        this.is_collect = 1;
      }
      if (!this.searchData.month) {
        this.searchData.month = [];
        const start = new Date().setHours(0, 0, 0, 0);
        const end = new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24 - 1;
        this.startTime = $utils.format(new Date(start), "yyyy-MM-dd hh:mm:ss"); //转换开始时间
        this.endTime = $utils.format(new Date(end), "yyyy-MM-dd hh:mm:ss"); //转换结束时间
      } else {
        this.startTime = $utils.format(this.searchData.month[0], "yyyy-MM-dd hh:mm:ss"); //转换开始时间
        this.endTime = $utils.format(this.searchData.month[1], "yyyy-MM-dd hh:mm:ss"); //转换结束时间
      }
      if(this.searchData.state == ''){
        this.searchData.state == ''
      }else{
        this.searchData.state = Number(this.searchData.state)
      }
      getorders({
        name: this.searchData.keyword,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.curPage,
        pageSize: this.pageSize,
        type: 1,
        grouping: Number(this.Teamtype),
        is_collect:this.is_collect,
        state:this.searchData.state
      }).then((res) => {
        if (res.code == 1) {
          // console.log(res, "res");
          $utils.exportRaw(res.data.filename, res.data.file);
        }
      });
    },
    //标注
    clickRow(row, type) {
      // console.log(row, "row");
      if (type == 1) {
        row.STATUS = 1;
      } else {
        row.STATUS = 0;
      }
      collect({ id: row.id, is_collect: type }).then((res) => {
        // console.log(res,'res')
      });
    },
    //鼠标移入table
    hoverRow(row) {
      row.starstatus = true;
      let dataArrTemp = JSON.stringify(this.tableData); //解决数据更新，视图不刷新bug
      this.tableData = JSON.parse(dataArrTemp);
    },
    //鼠标移出table
    leaveRow(row) {
      // console.log(row,'this.tableData')
      row.starstatus = false;
      this.tableData.map((item, index) => {
        //循环匹配ID，如果ID相同就隐藏
        if (item.id == row.id) {
          item.starstatus = false;
        }
      });
    },
    //切换月份
    changeMonth(val) {
      console.log(val);
      // this.searchData.month = $utils.format(e, "yyyy-MM");
      if (!val) {
        val = [];
        const start = new Date().setHours(0, 0, 0, 0);
        const end = new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24 - 1;
        this.startTime = $utils.format(new Date(start), "yyyy-MM-dd hh:mm:ss"); //转换开始时间
        this.endTime = $utils.format(new Date(end), "yyyy-MM-dd hh:mm:ss"); //转换结束时间
      } else {
        this.startTime = $utils.format(val[0], "yyyy-MM-dd hh:mm:ss"); //转换开始时间
        this.endTime = $utils.format(val[1], "yyyy-MM-dd hh:mm:ss"); //转换结束时间
      }
      this.curPage = 1;
      this.statistics();
      this.changeStar(this.Starchecked)
      // console.log(start,'start')
    },
    //切换分组显示
    changeStatus(e) {
      this.curPage = 1;
      this.loading = true;
      this.changeStar(this.Starchecked)
      
    },
    //获取代理商列表
    getAllAdvertising() {

     if (this.searchData.month[0] && this.searchData.month[1]) {
      // console.log($utils,'this.searchData.month[0]')
        this.startTime = $utils.format(this.searchData.month[0], "yyyy-MM-dd hh:mm:ss"); //转换开始时间
        this.endTime = $utils.format(this.searchData.month[1], "yyyy-MM-dd hh:mm:ss"); //转换结束时间
      }
      if(this.searchData.state == ''){
        this.searchData.state == ''
      }else{
        this.searchData.state = Number(this.searchData.state)
      }
      getorders({
          name: this.searchData.keyword,
          startTime: this.startTime,
          endTime: this.endTime,
          page: this.curPage,
          pageSize: this.pageSize,
          grouping: this.Teamtype,
          is_collect:this.is_collect,
          state:this.searchData.state
        }).then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.totalNum = res.total;
            this.tableData = res.data;
            this.tableData.map((item, index) => {
              item.starstatus = false; //需要先在tableData数组中每个元素添加showRightOp为false
              item.namestatus = false;
              item.phonestatus = false;
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //搜索代理商
    search() {
      this.curPage = 1;
      this.changeStar(this.Starchecked)
    },

    //改变每条页数
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log('this.pageSize', this.pageSize)
      this.curPage = 1;
      this.loading = true;
      this.getAllAdvertising();
    },
    //改变页码
    handleCurrentChange(val) {
      // console.log('current page', val);
      this.curPage = val;
      this.loading = true;
      this.getAllAdvertising();
    },
    //导出明细
    handleAgent(row) {
      unionOrdersExport({ id: row.id,platform:row.platform ,startTime: this.startTime,endTime: this.endTime,}).then((res) => {
        if (res.code == 1) {
          // console.log(res, "res");
          $utils.exportRaw(res.data.filename, res.data.file);
        }
      });
    },
  },
};
</script>
 <style scoped>
.company .f2 {
  padding: 15px;
  background-color: #fff;
}
.company .page {
  text-align: right;
  margin: 15px 2px 0 0;
}
.flex_between{
  justify-content: space-between;
}
.data_Presentation {
  width: 16%;
  height: 91px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 1);
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .data_Presentation {
    width: 49%;
    margin-bottom: 1vh;
  }
  .head_search {
    flex-direction: column;
  }
  .addgs {
    margin-top: 10px;
  }
  .derive {
    margin-top: 10px;
  }
  .yuanying{
    margin-left: 0;
    margin-top: 4px;
  }
      
}
</style>