/*
 * @Author: xiaogong 508089805@qq.com
 * @Date: 2022-06-15 13:53:39
 * @LastEditors: xiaogong 508089805@qq.com
 * @LastEditTime: 2022-07-01 09:56:55
 * @FilePath: /jude/js/utils.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    format(date, fmt) {
        var o = {
            // "y" : date.getFullYear(),
            "M+": date.getMonth() + 1,                 //月份 
            "d+": date.getDate(),                    //日 
            "h+": date.getHours(),                   //小时 
            "m+": date.getMinutes(),                 //分 
            "s+": date.getSeconds(),                 //秒 
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
            "S": date.getMilliseconds()             //毫秒 
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    },
    exportRaw(name, data) {
        let bom = new ArrayBuffer(3);
        let view = new DataView(bom, 0);
        view.setInt8(0, 239);//ef
        view.setInt8(1, 187);//bb
        view.setInt8(2, 191);//ff

        var urlObject = window.URL || window.webkitURL || window
        var exportBlob = new Blob([bom, data])
        var saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        saveLink.href = urlObject.createObjectURL(exportBlob)
        saveLink.download = name
        var ev = document.createEvent('MouseEvents')
        ev.initMouseEvent(
            'click', true, false, window, 0, 0, 0, 0, 0
            , false, false, false, false, 0, null
        )
        saveLink.dispatchEvent(ev)
    },
    copyClipboard(txt) {
        var input = document.createElement("input"); // 创建input对象
        input.value = txt; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        
    },
}