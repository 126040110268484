<template>
    <div class="company" v-loading="loading">
      <!-- 搜索部分 -->
      <div class="head_search_box">
        <div class="head_search">
          <el-input
            style="width: 300px"
            placeholder="请输入代理商名称"
            v-model="keyword"
            clearable
            @keydown.enter.native="search"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <div class="addgs">
          <el-button type="primary" @click="drawer = true">添加代理商</el-button>
          <el-dialog
            title="添加代理商"
            :close-on-click-modal="close_model"
            :visible.sync="drawer"
            center
            append-to-body
            width="30%"
          >
            <el-form :model="addAdvertising" :label-width="addLabelWidth">
              <el-form-item
                style="margin-bottom: 10px; width: 25vw"
                label="代理商名称"
              >
                <el-input
                  style="width: 90%"
                  v-model="addAdvertising.name"
                  autocomplete="off"
                  placeholder="请输入公司名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="状态" :label-width="addLabelWidth">
                <el-radio v-model="addAdvertising.status" label="1"
                  >启用</el-radio
                >
                <el-radio v-model="addAdvertising.status" label="0"
                  >禁用</el-radio
                >
              </el-form-item>
              <el-form-item style="width: 25vw" label="备注">
                <el-input
                  type="textarea"
                  v-model="addAdvertising.memo"
                  autocomplete="off"
                  placeholder="备注"
                  style="width: 90%"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="add" style="width: 100px"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="f2">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="代理商名称" >
          </el-table-column>
          <el-table-column prop="add_time" label="添加时间"> </el-table-column>
          <el-table-column prop="update_time" label="修改时间"> </el-table-column>
          <!-- <el-table-column prop="commission" label="服务费比例">
            <template slot-scope="scope"> {{ scope.row.commission }}% </template>
          </el-table-column> -->
          <el-table-column
            prop="status"
            label="状态"
            :formatter="statusFormatter1"
            :filters="[
              { text: '启用', value: 1 },
              { text: '禁用', value: 0 },
            ]"
            :filter-method="filterTag"
          >
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.status == '1' ? 'success' : 'danger'"
                disable-transitions
                >{{ getstatus(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>
  
          <el-table-column prop="memo" label="备注"> </el-table-column>
  
          <el-table-column prop="operation" label="操作" fixed="right">
            <template slot-scope="scope" style="display: flex">
              <el-button @click="hand1(scope.row)" type="text" size="small"
                >修改</el-button
              >
  
              <el-button class="zhgl" @click="hand2(scope.row)" type="text" size="small"
                >账号管理</el-button
              >
              <el-button class="zhgl" @click="del(scope.row)" type="text" size="small"
                >删除</el-button
              >
              <el-dialog
                title="修改信息"
                :close-on-click-modal="close_model"
                :visible.sync="dialogFormVisible"
                center
                append-to-body
                width="30%"
              >
                <el-form :model="form" :label-width="formLabelWidth">
                  <el-form-item
                    style="margin-bottom: 10px; width: 25vw"
                    label="公司名称"
                  >
                    <el-input
                      v-model="form.name"
                      autocomplete="off"
                      placeholder="请输入公司名称"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="状态"
                    style="margin-bottom: 10px; width: 25vw"
                  >
                    <el-radio v-model="form.status" label="1">启用</el-radio>
                    <el-radio v-model="form.status" label="0">禁用</el-radio>
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 10px; width: 25vw"
                    label="备注"
                  >
                    <el-input
                      type="textarea"
                      v-model="form.memo"
                      autocomplete="off"
                      placeholder="备注"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="updateAdvertising"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(totalNum) ? Number(totalNum) : 0"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {  editAgents,listOfAgents,addAgents,delAgents } from "@/request/api";
  import $utils from "@/js/utils";
  export default {
    data() {
      return {
        loading: false,
        tableData: [
          {
            account_name: "王小虎",
            status: "启用",
          },
        ],
        drawer: false,
        close_model: false,
        curPage: 1,
        totalNum: 0,
        pageSize: 10,
        keyword: "",
        addAdvertising: {
          name: "",
          ratio: "",
          status: "1",
          memo: "",
        },
        addLabelWidth: "120px",
        formLabelWidth: "120px",
        userinfo: [],
        dialogFormVisible: false,
        form: {
          name: "",
          ratio: "",
          status: "1",
          memo: "",
        },
        advertisingId: "",
      };
    },
    watch: {},
    computed: {},
    created() {},
  
    mounted() {
      this.getAllAdvertising();
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    },
  
    methods: {
      //获取代理商列表
      getAllAdvertising() {
        this.loading = true;
        listOfAgents({
          page: this.curPage,
          pageSize: this.pageSize,
          name: this.keyword,
        }).then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.totalNum = res.total;
            this.tableData = res.data;
            this.tableData.forEach((ele) => {
              if(ele.add_time == ''){
                ele.add_time = ''
              }else{
                ele.add_time = $utils.format(new Date(ele.add_time), "yyyy-MM-dd hh:mm:ss"); 
              }
              
              if(ele.update_time == ''){
                ele.update_time = ''
              }else{
                ele.update_time = $utils.format(new Date(ele.update_time), "yyyy-MM-dd hh:mm:ss"); 
              }            
            })
          } else {
            this.$message.error(res.msg);
          }
          
        });
      },
      //搜索代理商
      search() {
        this.loading = true;
        this.curPage = 1;
        this.getAllAdvertising();
      },
      //添加代理商
      add() {
        if (!this.addAdvertising.name) {
          this.$message({
            message: "请填写名称",
            type: "warning",
          });
          return;
        }
        addAgents({
          name: this.addAdvertising.name,
          memo: this.addAdvertising.memo,
          status: this.addAdvertising.status,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getAllAdvertising();
            this.drawer = false;
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
        });
      },
      //点击修改，打开嵌套修改弹窗
      hand1(val) {
        // console.log(val, "拿到的数据");
        this.dialogFormVisible = true;
        this.form.name = val.name;
        this.form.memo = val.memo;
        this.advertisingId = val.id;
        this.form.status = val.status.toString();
      },
      //修改代理商
      updateAdvertising() {
        editAgents({
          id: this.advertisingId,
          name: this.form.name,
          memo: this.form.memo,
          status: Number(this.form.status),
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getAllAdvertising();
            this.dialogFormVisible = false;
          } else {
            this.$message({
              message: "修改失败",
              type: "error",
            });
          }
          // console.log(res, "res");
        });
      },
      //点击跳转账号管理页面
      hand2(val) {
        this.$router.push({
          path: "/SystemSetup/dlsManagement",
          query: { name: val.name,id:val.id },
        });
      },
      del(val){
        this.$confirm("您确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delAgents({
            id: val.id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getAllAdvertising();
            } else {
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        });
      },
      //改变每条页数
      handleSizeChange(val) {
        this.pageSize = val;
        // console.log('this.pageSize', this.pageSize)
        this.getAllAdvertising();
      },
      //改变页码
      handleCurrentChange(val) {
        // console.log('current page', val);
        this.curPage = val;
        this.getAllAdvertising();
      },
      filterTag(value, row) {
        // console.log(value,'value')
        return row.status === value;
      },
      statusFormatter1(row, column) {
        // console.log(row.status,'row.status')
        const status = row.status;
        if (status == 1) {
          return "启用";
        } else {
          return "禁用";
        }
      },
      getstatus(status) {
        // console.log(status, "status");
        if (status == 1) {
          return "启用";
        }else{
          return "禁用";
        }
        // return status;
      },
    },
  };
  </script>
   <style scoped>
  .company .f2 {
    padding: 15px;
    background-color: #fff;
  }
  .company .page {
    text-align: right;
    margin: 15px 2px 0 0;
  
  }
  @media screen and (max-width: 1200px) {
    .head_search_box{
      flex-direction: column;
    }
    .zhgl{
      margin-left: 0px !important;
    }
    .addgs{
      margin-top: 10px;
    }
  }
  </style>
  