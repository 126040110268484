<template>
  <div class="company">
    <!-- 搜索部分 -->
    <div class="head_search_box">
      <div class="head_search">
        <el-select
          v-model="ptListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-right: 10px"
          @change="changePt"
        >
          <el-option
            v-for="item in ptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-date-picker
          class="datepicker"
          v-model="getdate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="timeChange"
          @change="changedate"
        >
        </el-date-picker>
        <div class="search_input">
          <el-input
            class="search_input_item"
            placeholder="请输入公司名称或公司账号"
            v-model="keyword"
            clearable
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <el-select
          v-if="this.userinfo.is_admin == 1"
          v-model="dlsListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeDls"
        >
          <el-option
            v-for="item in dlsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="derive" style="display: flex">
        <el-button
          type="primary"
          @click="handlekanban"
          style="margin-left: 16px; width: 120px; height: 40px"
          >导出报表</el-button
        >
        <columnclick
          style="margin: 0 0 10px 10px"
          :param="'100008'"
          @accept="accountname"
          :columnsInfo="planColumns"
          @confirm="toConfirm"
        ></columnclick>
      </div>
    </div>
    <!-- 数据块展示部分 -->
    <div class="f2 flex_between" style="flex-wrap: wrap" v-loading="databoard" element-loading-text="数据加载中...">
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">展示数</span>
          <div style="font-size: 28px" v-if="allList.showNum == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.showNum }}</div>
        </div>
      </div>
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">消耗</span>
          <div style="font-size: 28px" v-if="allList.cost == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.cost }}</div>
        </div>
      </div>
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">服务费</span>
          <div style="font-size: 28px" v-if="allList.commission == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.commission }}</div>
        </div>
      </div>
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">下单量</span>
          <div style="font-size: 28px" v-if="allList.payCount == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.payCount }}</div>
        </div>
      </div>
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">下单金额</span>
          <div style="font-size: 28px" v-if="allList.payAmount == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.payAmount }}</div>
        </div>
      </div>
      <div class="data_Presentation">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">下单ROI</span>
          <div style="font-size: 28px" v-if="allList.roi == null">0</div>
          <div style="font-size: 28px" v-else>{{ allList.roi }}</div>
        </div>
      </div>
      <div class="data_Presentation" style="margin-top: 15px">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">淘宝下单金额</span>
          <div style="font-size: 28px" v-if="allList.payAmountTb == null">
            0
          </div>
          <div style="font-size: 28px" v-else>{{ allList.payAmountTb }}</div>
        </div>
      </div>
      <div class="data_Presentation" style="margin-top: 15px">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">京东下单金额</span>
          <div style="font-size: 28px" v-if="allList.payAmountJd == null">
            0
          </div>
          <div style="font-size: 28px" v-else>{{ allList.payAmountJd }}</div>
        </div>
      </div>
      <div class="data_Presentation" style="margin-top: 15px">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">拼多多下单金额</span>
          <div style="font-size: 28px" v-if="allList.payAmountPdd == null">
            0
          </div>
          <div style="font-size: 28px" v-else>{{ allList.payAmountPdd }}</div>
        </div>
      </div>
      <div class="data_Presentation" style="margin-top: 15px">
        <div style="text-align: center; margin: auto">
          <span style="font-size: 13px">淘宝小程序下单金额</span>
          <div style="font-size: 28px" v-if="allList.payAmountTbxcx == null">
            0
          </div>
          <div style="font-size: 28px" v-else>{{ allList.payAmountTbxcx }}</div>
        </div>
      </div>
      <div
        class="data_Presentation displaynone"
        style="background: none !important"
      ></div>
      <div
        class="data_Presentation displaynone"
        style="background: none !important"
      ></div>
      <div
        class="data_Presentation displaynone"
        style="background: none !important"
      ></div>
    </div>

    <div class="f2" v-loading="echartLoading" element-loading-text="数据加载中...">
      <div class="echart" id="mychart" :style="myChartStyle"></div>
    </div>
    
    <!-- 表格部分 -->
    <div class="f2" v-loading="loading" element-loading-text="数据加载中...">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          v-for="(item, index) in filterPlanColumns"
          :key="index"
          sortable
          :prop="item.val"
          :label="item.name"
          :width="getwidth(item.name)"
         
        >
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Number(totalNum) ? Number(totalNum) : 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getdataboard,
  getagent,
  columndefault,
  getkanbanplan,
  getcosttrend,
  getFactor,
  platform
} from "@/request/api";
import columnclick from "../../echarts/goods_columnclick";
import $utils from "@/js/utils";
import * as echarts from "echarts";



const CFG_NAME = "good_tab_config";
export default {
  components: {
    columnclick,
  },
  data() {
    return {
      ptList:[],
      ptListVal:null,
      dlsList:[],
      dlsListVal:0, //代理商下拉选择
      // 计划数据表格表头数据列
      planColumns: [],
      loading: false,
      databoard:false,
      echartLoading:false,
      allList: {
        showNum: 0, //展示数
        cost: 0, //消耗
        commission: 0, //佣金
        payCount: 0, //下单量
        payAmount: 0, //下单金额
        roi: 0, //下单ROI
        payAmountPdd: 0, //拼多多下单金额
        payAmountJd: 0, //京东下单金额
        payAmountTb: 0, //淘宝下单金额
        payAmountTbxcx: 0, //淘宝小程序下单金额
      },
      getdate: [],
      options: [],
      value: "",
      tableData: [
        {
          account_name: "王小虎",
          status: "启用",
        },
      ],
      curPage: 1,
      totalNum: 0,
      pageSize: 10,
      keyword: "",
      accountList: {
        name: "",
        status: "1",
        memo: "",
      },
      startTime: "",
      endTime: "",
      Planname: [],
      myChart:null,
      myChartStyle: { width: "100%", height: "400px" }, //图表样式
      xData:[],
      yData0:[],
      currentDataA:[],
      currentDataB:[],
      ymax:0,
      maxAlist:[],
      maxBlist:[],
      timeChange: {
        disabledDate: time => {
          // .vue文件
          // 获取当天0点时间（中国标准时间）
          return (
            time.getTime() >= new Date().setHours(0, 0, 0, 0) + 3600 * 1000 * 24
          );
        }
      }, 
      starTime:"",
      userinfo:{
        is_admin:''
      },
    };
  },
  watch: {
    
  },
  computed: {},
  created() {
    
    // console.log('this.planColumns',this.planColumns)
  },
  computed: {
    filterPlanColumns() {
      console.log(this.planColumns,'this.planColumns')
      return this.planColumns.filter((ele) => ele.checked);
    },
  },
  mounted() {
 
    
    let info = localStorage.getItem('info')
    // console.log(info,'info')
    if(info){
      // console.log('我是localStorage')
      this.userinfo = JSON.parse(info)
      this.cshsearch();
    }else{
      // console.log('我是sessionStorage',JSON.parse(sessionStorage.getItem('info')))
      // let info = sessionStorage.getItem('userinfo')
      this.userinfo = JSON.parse(sessionStorage.getItem('info'))
      this.cshsearch();
    }
    
    
    // console.log(this.userinfo.is_admin,'userinfo')
    const start = new Date();
    const end = new Date();
    this.getdate[0] = start;
    this.getdate[1] = end;
    
    let datakanban = localStorage.getItem("datakanban");
    // console.log(datakanban,'啊世界的好礼啊就反过来看撒风景阿斯利康')
    if (datakanban) {
      this.planColumns = JSON.parse(datakanban);
    } else {
      columndefault({
        btnCode: "100008",
      }).then((res) => {
        let list = res.data.sort((a, b) => {
          return a.colTypeCode - b.colTypeCode;
        });
        let data1 = list[0].colTypeCode;
        let typeList = ["属性指标", "展现及花费", "转化及成交"];
        let index = 0;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          list[i].name = item.colName;
          list[i].val = item.colField;
          if (data1 == item.colTypeCode) {
            list[i].type = typeList[index];
          } else {
            data1 = item.colTypeCode;
            index++;
            list[i].type = typeList[index];
          }
        }
        this.planColumns = list;
        this.planColumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.planColumns.forEach((ele, idx) => {
          // ele.checked = true;
          this.$set(this.planColumns[idx], "checked", true);
        });
        // console.log(this.planColumns,'this.planColumns')
        // console.log(localStorage.setItem("datakanban",list),'localStorage.setItem("datakanban",list);')
        localStorage.setItem("datakanban", JSON.stringify(list));
      });
    }

    this.planColumns.forEach((ele, idx) => {
      if (!this.planColumns[idx].checked) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
    this.getplatform();
    // this.colseNowDate();
    // this.getAdvertisingList();
  },

  methods: {

    //切换平台
    changePt(e){
      this.ptListVal = e;
      this.search();
    },
    //切换代理商
    changeDls(e){
      console.log(e,'e')
      this.dlsListVal = e;
      this.search();
    },
    //获取代理商公司下拉框
    getFactor(){
        getFactor({}).then(r=>{
        // console.log(r,'r')
        this.dlsList = r.data;
        this.dlsListVal = this.dlsList[0].id
        this.search();
      })
      
    },
     //   初始化图表,
    initEcharts() {
      // console.log('我进来了')
      // Object. Object.assign(this.maxAlist)
      if(this.maxAlist.length == 0 && this.maxBlist.length == 0 ){
        console.log('我进来了1111111111')
      }else{
       console.log(this.maxAlist,this.maxBlist,'this.maxBlist')
       let maxA = this.maxAlist.sort((a,b)=>{//排序数组，获取两个数组最大值，以做比较
          return a.value - b.value;
        }).reverse()[0].value;
        // console.log(maxA,'max')
        let maxB = this.maxBlist.sort((a,b)=>{
          return a.value - b.value;
        }).reverse()[0].value;
        // console.log(maxB,'maxB')
        if(maxA > maxB ){  //判断数据谁比较大,比较后，计算出当前除于整数得出最大值用做y轴

          let maxa = Number(maxA/1000);
          console.log(maxa,'maxa')
          if(Number.isInteger(maxA/1000)){
           this.ymax =  (Math.trunc(maxa)+1) * 1000
          //  console.log('1', this.ymax)
          }else{
           this.ymax =  (Math.trunc(maxa)+1) * 1000
          //  console.log('2', this.ymax)
          }
          // console.log(this.ymax,'this.ymax')
        }else{
          // this.ymax = maxB
          let maxb = Number(maxB/1000);
          if(!Number.isInteger(maxB/1000)){
           this.ymax =  (Math.trunc(maxb)+1) * 1000
          //  console.log('11', this.ymax)
          }else{
           this.ymax =  (Math.trunc(maxb)+1) * 1000
          //  console.log('22', this.ymax)
          }

        }
      }
       
      let series = [
        {
          areaStyle: {
            opacity: 0.1,
            color: "#357CF0",
            origin: "start"
          }, //填充
          name: "消耗",
          data: this.currentDataA,
          type: "line", // 类型设置为折线图
          smooth: true, //是否平滑曲线
          label: {
            show: false,
            position: "top",
            textStyle: {
              fontSize: 16
            }
          }
        },
        {
          areaStyle: {
            opacity: 0.1,
            color: "#E593DC",
            origin: "start"
          }, //填充
          name: "往日消耗",
          data: this.currentDataB,
          type: "line", // 类型设置为折线图
          smooth: true,
          label: {
            show: false, //是否显示坐标轴上面的数值
            position: "bottom",
            textStyle: {
              fontSize: 16
            }
          },
          yAxisIndex: 1
        }
      ];
      let yAxis = [
        {
          type:"value",
          name: "消耗",
          min: 0,
          max: this.ymax,
          position: "left"
        },
        {
          type:"value",
          min: 0,
          max: this.ymax,
          splitLine: { show: false },
          position: "left"
        },
      ];
      // console.log(yAxis, 'yAxis');
      const option = {
        color: ["#357CF0", "#E593DC", "#FF0000", "#00FF00"], //更改填充的颜色
        tooltip: {
          show: true,
          trigger: "axis",
                formatter: (params, ticket, callback) => {
                  let tooltipName = params[0].name + params[1].name 
                  return tooltipName;
                }

        },
        legend: {
          data:["消耗","往日消耗"]
        },
        xAxis: {
          data: this.xData
        },
        yAxis: yAxis,
        series: series
      };
      // console.log(option,'option')
      // console.log(yAxis, "option");
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById("mychart"));
      }
      this.myChart.clear();
      this.myChart.setOption(option);
      // var rangeY = this.myChart.getModel().getComponent('yAxis').axis.scale._extent;
      // console.log(rangeY,';rangeY')
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    //设置table的宽
    getwidth(name) {
      let tablewidth = "";
      switch (name) {
        case "公司名称":
          tablewidth = "250";
          break;
        case "公司账号":
          tablewidth = "200";
          break;
        default:
          tablewidth = "120";
      }
      return tablewidth;
    },
    
    //导出报表
    handlekanban() {
      this.loading = true;
      // console.log(this.startTime, this.endTime, "this.endTime");
      // console.log(this.getdate, "this.endTime");
      //计算，将当期日期-1天
      //此时得到的是中国标准时间，格式不是yyyy-MM-dd，需要用dateFormat这个函数转换下
      if (!this.getdate) {
        this.getdate = [];
        const start = new Date();
        const end = new Date();
        this.startTime = $utils.format(start, "yyyy-MM-dd"); //转换开始时间
        this.endTime = $utils.format(end, "yyyy-MM-dd"); //转换结束时间
      } else {
        this.startTime = $utils.format(this.getdate[0], "yyyy-MM-dd"); //转换开始时间
        this.endTime = $utils.format(this.getdate[1], "yyyy-MM-dd"); //转换结束时间
      }
      //导出看板列表
      getkanbanplan({
        startTime: this.startTime,
        endTime: this.endTime,
        search: this.keyword,
        btnCode: "100008",
        factorId:this.dlsListVal,
        platform : this.ptListVal
      }).then((res) => {
        if (res.code == 1) {
          // console.log(res.data);
          // const elink = document.createElement("a");
          // elink.href = res.data.file; //file.url
          // elink.download = res.data.filename; //file.name
          // elink.style.display = "none";
          // //link.target="_blank";
          // elink.click();
          $utils.exportRaw(res.data.filename, res.data.file);
          this.loading = false;
        } else {
          this.$message({
            message: "导出失败",
            type: "worning",
          });
          this.loading = false;
        }
      });
    },
    // 按钮
    toConfirm(data) {
      // console.log("自定义列", data);
      // 缓存选中列
      this.planColumns = data;
      // let datakanban = localStorage.getItem("datakanban");
      localStorage.setItem("datakanban", JSON.stringify(this.planColumns));
    },
    // 按钮
    accountname(accountname) {},
    //日期选择事件
    changedate(val) {
      // console.log(val, "时间");
      this.loading = true;
      //计算，将当期日期-1天
      //此时得到的是中国标准时间，格式不是yyyy-MM-dd，需要用dateFormat这个函数转换下
      // console.log(val,'val[0]')
      if (!val) {
        val = [];
        const start = new Date();
        const end = new Date();
        this.startTime = $utils.format(start, "yyyy-MM-dd"); //转换开始时间
        this.endTime = $utils.format(end, "yyyy-MM-dd"); //转换结束时间
      } else {
        this.startTime = $utils.format(val[0], "yyyy-MM-dd"); //转换开始时间
        this.endTime = $utils.format(val[1], "yyyy-MM-dd"); //转换结束时间
      }
      this.search();
    },

    //初始化搜索
    cshsearch() {
      const start = new Date();
      const end = new Date();
      this.loading = true;
      // console.log(this.getdate,'this.getdate')
      // console.log('我进来了')
      this.startTime = $utils.format(start, "yyyy-MM-dd"); //转换开始时间
      this.endTime = $utils.format(end, "yyyy-MM-dd"); //转换结束时间
      
      // console.log(this.userinfo,'user')
      if(this.userinfo.is_admin == 1){
        // this.userinfo.is_admin = 0;
        this.getFactor();
        
      }else if(this.userinfo.is_admin == 2){
        this.dlsListVal = this.userinfo.id_factor;
        this.search();
      }else{
        this.search();
      }
      

    },
    //获取平台
    getplatform(){
      platform({}).then(res=>{
        // console.log(res,'res')
        this.ptList = res.data;
        this.ptListVal = res.data[0].id;
      })
    },
    //获取数据看板统计
    search() {
      this.loading = true;
      this.databoard = true;
      this.echartLoading = true;
      //计算，将当期日期-1天
      // console.log(this.getdate,'this.getdate')
      //此时得到的是中国标准时间，格式不是yyyy-MM-dd，需要用dateFormat这个函数转换下
      if (this.getdate[0] && this.getdate[1]) {
        this.startTime = $utils.format(this.getdate[0], "yyyy-MM-dd"); //转换开始时间
        this.endTime = $utils.format(this.getdate[1], "yyyy-MM-dd"); //转换结束时间
      }
      //获取展示块数据
      getdataboard({
        startTime: this.startTime,
        endTime: this.endTime,
        search: this.keyword,
        factorId:this.dlsListVal,
        platform:this.ptListVal
      }).then((res) => {
        this.databoard = false;
        // console.log('res',res)
        this.allList.showNum = res.data.showNum;
        this.allList.cost = res.data.cost;
        this.allList.payCount = res.data.payCount;
        this.allList.payAmount = res.data.payAmount;
        this.allList.commission = res.data.commission;
        this.allList.roi = res.data.roi;
        this.allList.payAmountTb = res.data.payAmountTb;
        this.allList.payAmountJd = res.data.payAmountJd;
        this.allList.payAmountPdd = res.data.payAmountPdd;
        this.allList.payAmountTbxcx = res.data.payAmountTbxcx;
      });
      //获取列表数据
      getagent({
        startTime: this.startTime,
        endTime: this.endTime,
        search: this.keyword,
        page: this.curPage,
        pageSize: this.pageSize,
        factorId:this.dlsListVal,
        platform:this.ptListVal
      }).then((r) => {
        this.loading = false;
        this.tableData = r.data;
        for (let i = 0; i < this.tableData.length; i++) { //循环数组，转成数字类型以便排序
          // this.tableData[i].cost = Number(this.tableData[i].cost);
         
          this.tableData[i].ratio = this.tableData[i].ratio + "%";
        }
        // console.log(this.tableData,'this.tableData')
        this.totalNum = r.total;
      });
      //获取数据消耗看板
      getcosttrend({
        startTime: this.startTime,
        endTime: this.endTime,
        search: this.keyword,
        factorId:this.dlsListVal,
        platform:this.ptListVal
      }).then((res) => {
        if (res.code == 1) {
          this.echartLoading = false;
            this.xData = [];
        this.currentDataA = [];
        this.currentDataB = [];
        var dotHtml1 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#0090ff";></span>';
          var dotHtml2 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:red"></span>';
        res.data.forEach(ele => {
          let name =
              dotHtml1 +ele.dateTimeA +" " +'消耗' + ": " +ele.costA ;
              let oldname = 
              "<br/>" +
              dotHtml2 +
              ele.dateTimeB +
              " " +
              "往日消耗" +
              ": " +
              ele.costB;
          this.xData.push(ele.dateTimeA);
          this.currentDataA.push({
              value: ele.costA,
              name: name,
              tooltip: {
                formatter: (params, ticket, callback) => {
                  // callback("AAAA")
                  // callback(ticket, params.name)
                  // console.log("================", params);
                  return params.name;
                }
              }
            });
            this.currentDataB.push({
              value: ele.costB,
              name: oldname,
              tooltip: {
                formatter: (params, ticket, callback) => {
                  // callback("AAAA")
                  // callback(ticket, params.name)
                  // console.log("================", params);
                  return params.name;
                }
              }
            });
        })
        // console.log('我进来了')
         this.maxAlist = this.currentDataA.concat([]);
          this.maxBlist = this.currentDataB.concat([]);
          
          this.initEcharts();
          }
         
        
      });
    },
    //改变每条页数
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    //改变页码
    handleCurrentChange(val) {
      this.curPage = val;
      this.search();
    },
  },
};
</script>
 <style scoped>
.company .f2 {
  padding: 15px;
  background-color: #fff;
}
.company .page {
  text-align: right;
  margin: 15px 2px 0 0;
}
.data_Presentation {
  width: 16%;
  height: 91px;
  border-radius: 5px;
  background-color: rgba(242, 242, 242, 1);
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.search_input_item {
  width: 300px;
  margin-left: 20px;
}
@media screen and (max-width: 1200px) {
  .data_Presentation {
    width: 49%;
    margin-bottom: 1vh;
  }
  .head_search {
    flex-direction: column;
  }
  .search_input {
    margin-top: 13px;
    width: 100%;
    margin-left: 0;
  }
  .search_input_item {
    width: 100%;
    margin-left: 0;
  }

  datepicker /deep/ .el-popper {
    margin-left: 500px !important;
  }
  datepicker /deep/ .el-date-range-picker__content {
    width: auto !important;
  }
  datepicker /deep/.el-date-range-picker .el-picker-panel__content {
    margin-left: 190px !important;
  }
}
</style>
<style scoped>
@media screen and (max-width: 1200px) {
  .el-popper {
    margin-left: 500px !important;
  }
  .el-date-range-picker__content {
    width: auto !important;
  }
  .el-date-range-picker .el-picker-panel__content {
    margin-left: 79vw !important;
  }
  .head_search_box {
    flex-direction: column;
  }
  .derive {
    margin-top: 10px;
  }
  .displaynone {
    display: none;
  }
}
</style>