<template>

  <!-- 超管导航菜单 -->
  <!-- <el-menu
    v-if="userinfo.is_admin == 1"
    class="el-menu-vertical-demo"
    @select="linkTo"
    router
    background-color="#29292f"
    text-color="#fff"
    :active-text-color="iconColor"
    :default-active="activePath"
    :collapse="collapsed"
    border=false
  >
    <h3 class="wz">星川数据</h3> -->
    <!-- 第一个导航 -->
      <!-- <el-menu-item index="/DataKanban/datakanban"> -->
        <!-- <i class="el-icon-data-analysis"></i>
        <span slot="title">数据看板</span>
      </el-menu-item> -->
    <!-- 第二个导航 -->
      <!-- <el-menu-item index="/SystemSetup/advertisingAgency">
        <i class="el-icon-setting"></i>
        <span slot="title">广告代理商管理</span>
      </el-menu-item> -->
      <!-- 第三个导航 -->
      <!-- <el-menu-item index="/SystemSetup/accountManagement">
        <i class="el-icon-setting"></i>
        <span slot="title">代理商账号</span>
      </el-menu-item> -->
    <!-- 第四个导航 -->
      <!-- <el-menu-item index="/setup/setup">
        <i class="el-icon-folder-opened"></i>
        <span slot="title">电商授权</span>
      </el-menu-item> -->




  <!-- </el-menu> -->
  <!-- 用户导航 -->

  <el-menu
    class="el-menu-vertical-demo"
    @select="linkTo"
    router
    background-color="#29292f"
    text-color="#fff"
    :active-text-color="iconColor"
    :default-active="activePath"
    :collapse="collapsed"
    border=false
  >

    <h3 class="wz">{{sytitle}}</h3>

    <template v-for="(item,index) in menuList">
      <el-menu-item v-if="item.type == 1" :index="item.url + ''">
        <i :class="item.icon"></i>
        <span slot="title">{{item.title}}</span>
      </el-menu-item>
      <el-submenu v-else :index="index + ''">
        <template slot="title">
          <i :class="item.icon"></i>
          <span slot="title">{{item.title}}</span>
        </template>
        <el-menu-item v-for="child in item.child" :index="child.url" :key="child.url" style="padding-left:62px;">
          <span slot="title">{{child.title}}</span>
        </el-menu-item>
      </el-submenu>
    </template>
    <!-- <el-menu-item index="/goods/Goods"  >
      <i class="el-icon-data-line"></i>
      <span slot="title">营销活动监控</span>
    </el-menu-item> -->
    <!-- 第一个导航栏 -->
    <!-- <el-menu-item index="/report/Report">
      <i class="el-icon-data-analysis"></i>
      <span slot="title">数据报表</span>
    </el-menu-item> -->
    <!-- 第二个导航栏 -->
    <!-- <el-submenu index="/analyse/Analyse">
      <template slot="title">
        <i class="el-icon-pie-chart"></i>
        <span slot="title">营销活动分析</span>
      </template>
      <el-menu-item index="/analyse/Analyse" style="padding-left:50px;">clickID报表</el-menu-item>
    </el-submenu> -->
    <!-- <el-menu-item index="/datacenter">
      <i class="el-icon-coin"></i>
      <span slot="title">订单报表</span>
    </el-menu-item> -->
    <!-- 第三个导航栏 -->
    <!-- <el-submenu index="3">
      <template slot="title">
        <i class="el-icon-s-operation"></i>
        <span slot="title">投放配置</span>
      </template>
      <el-menu-item index="/taobaoGoods/taobaoGoods" style="padding-left:62px;">
        <span slot="title">商品管理</span>
      </el-menu-item>
      <el-menu-item index="/chain/chain" style="padding-left:62px;">
        <span slot="title">落地页转链</span>
      </el-menu-item>
    </el-submenu> -->

    <!-- 第四个导航栏 -->
    <!-- <el-submenu index="4">
      <template slot="title">
        <i class="el-icon-folder-opened"></i>
        <span slot="title">业务管理</span>
      </template>
        <el-menu-item index="/manage/CompanyManage" style="padding-left:62px;">
          <span slot="title">公司管理</span>
        </el-menu-item>
        <el-menu-item index="/manage/MainAccount" style="padding-left:62px;">
          <span slot="title">主账户管理</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="/setup/setup" style="padding-left:62px;">
          <span slot="title">电商授权(超管功能)</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="/setup/auth" style="padding-left:62px;">
          <span slot="title">授权管理</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="/manage/Manage" style="padding-left:62px;">
          <span slot="title">活动配置</span>
        </el-menu-item>
    </el-submenu> -->

    

    <!-- 第六个导航栏  -->
    <!-- <el-submenu index="6" > -->

    <!-- 第五个导航栏  -->
    <!-- <el-submenu index="5" >

      <template slot="title">
        <i class="el-icon-setting"></i>
        <span slot="title">系统设置</span>
      </template>

     <el-menu-item index="/SystemSetup/advertisingAgency" style="padding-left:62px;">
        <span slot="title">广告代理商管理</span>
     </el-menu-item>
     <el-menu-item index="/SystemSetup/accountManagement" style="padding-left:62px;">
        <span slot="title">代理商账号管理</span>
     </el-menu-item>

    </el-submenu> -->
    <!-- 底部图标 -->
    <!-- <div class="bottom-icon"> -->
      <!-- <el-tooltip class="item" effect="dark" content="数据中心" placement="top">
        
        <i class="el-icon-coin" :style="{color:activePath=='/datacenter' ? iconColor : defColor}" @click="linkTo('/datacenter')"></i>
      </el-tooltip> -->
      <!-- <el-tooltip class="item" effect="dark" content="消息通知" placement="top">
        <i class="el-icon-message-solid"></i>
      </el-tooltip> -->
      <!-- <el-tooltip class="item" effect="dark" content="设置" placement="top">
        <i @click="linkTo('/setup/Setup')" :style="{color:activePath=='/setup/Setup' ? iconColor : defColor}" class="el-icon-s-tools"></i>
      </el-tooltip> -->
      <!-- <el-tooltip class="item" effect="dark" content="个人中心" placement="top"> -->
      <!-- <i class="el-icon-user-solid" @click="handleperson"> -->
      <!-- <el-popover placement="right-end" :title="nickName" width="200" trigger="hover">
        <i class="el-icon-user-solid cursor" slot="reference"></i>
        <div
          class="margin_top cursor"
          onmouseover="this.style.color='#0A63FC'"
          onmouseout="this.style.color=''"
        >员工账号管理</div>
        <div
          @click="linkTo('/user/cersonalcenter')"
          class="margin_top cursor"
          onmouseover="this.style.color='#0A63FC'"
          onmouseout="this.style.color=''"
        >个人中心</div>
        <div
        @click="logOut"
          class="margin_top cursor"
          onmouseover="this.style.color='#0A63FC'"
          onmouseout="this.style.color=''"
        >退出登录</div>
      </el-popover> -->

      <!-- </i> -->
      <!-- </el-tooltip> -->
    <!-- </div> -->
  </el-menu>

  

  
</template> 


<script>
export default {
  data() {
    return {
      sytitle:'',
      collapsed: false,
      visible: false,
      // activePath:'',
      activePath: this.$route.path,
      iconColor: '#409EFF',
      defColor:'#fff',
      curIcon:'',
      nickName: '会员',
      // 开启折叠显示功能
      // isCollapse: true
      userinfo:[],
      menuList:[],// 菜单
    };
  },
  created(){
    this.activePath = this.$router.currentRoute.path;
    let info = sessionStorage.getItem("info");
    
    let siteName = ''
    let localhost = window.location.href;
    if(localhost.indexOf('xingchuan360.com') != -1 || localhost.indexOf('jd.qupeng.vip') != -1 ){
        siteName = '星川数据'
    }else if(localhost.indexOf('fz.uxiu.vip') != -1){
        siteName = '方舟数据'
    }
    
    if (info) {
      info = JSON.parse(info);
      if (info && info.nickname) {
        this.nickName = info.nickname;
      }
      if(info.is_admin == 1){
        this.sytitle = siteName + 'BOSS端'
      }else if(info.is_admin == 0){
        this.sytitle = siteName
        // console.log('123')
      }else if(info.is_admin == 2){
        this.sytitle = siteName + '-代理商'
      }
      this.menuList = info.menuList;
      console.log(info.menuList,'1231321312');
    }else{
       info = localStorage.getItem("info");
       info = JSON.parse(info);
      if (info && info.nickname) {
        this.nickName = info.nickname;
      }
      if(this.$router.currentRoute.path == '/'){
        this.$router.push(info.route)
      }

      if(info.is_admin == 1){
        this.sytitle = siteName + 'BOSS端'
      }else if(info.is_admin == 0){
        this.sytitle = siteName

      }else if(info.is_admin == 2){
        this.sytitle = siteName + '-代理商'
      }
      this.activePath = this.$router.currentRoute.path;
      this.menuList = info.menuList;
    }
    // 监听
    this.$root.$on('toggle', value => {
      this.collapsed = !value
    })

    
  },
  
 
  beforeDestroy(){
    this.$root.$off('getuser')
  },
  mounted(){
    
     this.$root.$on('getuser',(data)=>{
      this.userinfo = data;
      // console.log(this.userinfo,'this.userinfo')
        // console.log(data,'获取到的数据')
        // this.goadmin();
      });
      // console.log(this.userinfo.is_admin,'this.userinfo.is_admin')
  },
  methods: {
    
    //超管跳转看板页面
    goadmin(){
      console.log(this.userinfo.is_admin,'this.userinfo.is_admin')
      if(this.userinfo.is_admin == 1){
        // console.log('我进来了')
        this.$router.push('/DataKanban/datakanban')
      }
      
    },
    logOut(){
      this.$confirm('您确定要退出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sessionStorage.removeItem('Authorization')
          sessionStorage.removeItem('info')
          sessionStorage.removeItem('activePath')
          sessionStorage.removeItem('curIcon')
          this.$router.push('/Login')       
        });
    },
    linkTo(url) {
      // console.log('link to', url)
      this.activePath = url;
      this.$router.push(this.activePath);
    },
  },
  // 跳转页面时 导航栏随之变化
  watch: {
    '$route'(to,from){
        this.activePath = to.path
    }
  },

};
</script>

<style>
.el-menu{
  border: none;
}
.el-menu-item.is-active {
  background-color: #141417!important;
  border-right: 4px solid #409EFF;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 200px; */
  width: 200px !important;
  min-height: 400px; 
  text-align: center !important;
  position: fixed;
}

/* 左侧导航栏的宽度和高度 */
.el-menu--collapse {
  box-sizing: border-box;
  width: auto;
  /* height: 100%; */
}
/* 左侧导航栏的高度..... */
.el-menu {
  height: 100%;
}
.wz {
  color: white;
  /* width: 110px; */
  /* margin: 30px auto; */
 
}
/* 底部图标 */
.bottom-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  color: white;
  position: absolute;
  bottom: 3vh;
  font-size: 2.2vh;
}
/* .el-icon-coin:before{
    content: '';
    color: aliceblue;
  } */
.el-icon-coin {
  /* padding-right: 1vh; */
  color: rgb(255, 255, 255);
}
.el-icon-message-solid {
  padding-right: 1vh;
}
/* .el-icon-s-tools {
  padding-left: 2vh;
}
.el-icon-user-solid {
  padding-left: 3vh;
} */
/* 悬停图标变小手 */
.bottom-icon .item:hover {
  cursor: pointer;
}

.el-menu .el-menu-item,.el-menu .el-submenu__title{
  display: flex;
  align-items: center;
}





.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0px;
}
/* .el-menu-bg {
  background-color: #1f2d3d !important;
} */
/* .el-menu {
  border: none;
} */
.logobox {
  height: 40px;
  line-height: 40px;
  /* color: #9d9d9d; */
  font-size: 20px;
  text-align: center;
  /* padding: 20px 0px; */
}

</style>