<template>
  <div>
    <el-tabs v-loading="loading" v-model="activeName" @tab-click="tabClick">
      <!-- 一楼：搜索栏 -->
      <div class="flex" style="padding:10px">
        <columnclick
          style="margin: 0 0 0px 10px;"
          :param="'100004'"
          @accept="accountname"
          :columnsInfo="accountColumns"
          @confirm="toConfirm('mainAccount', $event)"
        ></columnclick>
        <el-button
          @click="handleparAccount"
          type="primary"
          style="margin-left: 16px;width:120px;height:40px"
        >导出报表</el-button>
      </div>
      <el-tab-pane label="主账户" name="mainAccount">
        <el-table border :data="array" style="width: 100%">
          <el-table-column fixed prop="mainAccountName" label="账户" width="260"></el-table-column>
          <el-table-column fixed prop="operation" label="操作" width="180">
            <div slot-scope="zhnum" class="flex">
              <div
                style="color: #0a63fc"
                class="cursor"
                @click="openAnalyse('mainAccount', array[zhnum.$index],zhnum)"
                type="primary"
              >详细分析</div>
            </div>
          </el-table-column>
          <el-table-column
            width="150"
            v-for="(item, index) in filterAccountColumns"
            :prop="item.val"
            :label="item.name"
            sortable
            :key="index"
            v-if=" item.val != 'operation'"
          ></el-table-column>
        </el-table>
        <div class="block flex_between margin_top">
          <span class="demonstration"></span>
          <!-- size-change 每页条数 -->
          <!-- current-change 当前页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 15, 50]"
            :page-size="1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(arrayParaccount) ? Number(arrayParaccount) : 0"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <drawer
      :orderFrom="orderfromparAccount"
      :filterDateStart="filterDateStartparAccount"
      :filterDateEnd="filterDateEndparAccount"
      :right_paramsPlan="right_params"
      ref="analyseView"
      :costparaccount="costparaccount"
      :clickparaccount="clickparaccount"
      :convertNumparaccount="convertNumparaccount"
      :payAmountparaccount="payAmountparaccount"
      :roiparaccount="roiparaccount"
      :showNumparaccount="showNumparaccount"
    ></drawer>
  </div>
</template>

<script>
import {
  masteraccountdata,
  commodity,
  downloadMainAccountData,
  cornMetricsByKeyId,
  columndefault
} from "@/request/api.js"; //
import columnclick from "./goods_columnclick";
import drawer from "./goods_drawer";
import advertisement from "./goods_advertisement";
import $utils from "@/js/utils";
const CFG_NAME = "good_tab_config_paraccount";
export default {
  components: {
    columnclick,
    drawer,
    advertisement,
  },
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          goodIds: [],
          acticityId: "",
          targetIds: [],
          targetType: "",
          roiMax: "",
          roiMin: "",
          costMax: "",
          costMin: "",
          orderFrom: ""
        };
      }
    },
    orderchange2: {
      type: String,
      default: ""
    },
    parmval: {
      type: String,
      default: ""
    },
    // 主账户账户筛选数据包传递
    accountarrayParaccount: {
      type: Array,
      default: ""
    },
    totalnumparAccount: {
      type: null,
      default: ""
    },
    orderfromparAccount: {
      type: String,
      default: ""
    },
    valgoodsparAccount: {
      type: Array,
      default: ""
    },
    keywordparAccount: {
      type: String,
      default: ""
    },
    filterDateStartparAccount: {
      //从父组件传递开始时间
      type: String,
      default: ""
    },
    filterDateEndparAccount: {
      //从父组件传递结束时间
      type: String,
      default: ""
    },
    val: {
      type: Array,
      default: ""
    },
    right_params: {
      type: String,
      default: ""
    },
    valone1: {
      type: Number,
      default: ""
    },
  },
  data() {
    return {
      costparaccount: "",
      clickparaccount: "",
      convertNumparaccount: "",
      payAmountparaccount: "",
      roiparaccount: "",
      showNumparaccount: "",
      sss: false,
      right_paramsparAccount: "",
      checked: "false",
      tab: "",
      loading: false,
      activeName: "mainAccount",
      filterAccountData: null,
      page: 1,
      pageSize: 10,
      totalnum: 0,
      filterType: "",
      // 主账户数据表格表头数据列
      accountColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "展现及花费",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "转化及成交",
          name: "平均点击单价",
          val: "cpc"
        }
      ],
      adgroupColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        },
        {
          type: "展现及花费",
          name: "当日转化ROI",
          val: "convertRoi"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        }
      ],
      planColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        },
        {
          type: "展现及花费",
          name: "当日转化ROI",
          val: "convertRoi"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        }
      ],
      array: [],
      arrayParaccount: {
        type: Number,
        default: 1
      },
      midell: this.valone1
    };
  },
  watch: {
    filterData: {
      handler(newVal) {
        console.log("goods_tabs filter data handler", newVal);
        // this.handlerFilterAccount()
      },
      deep: true
    },
    accountarrayParaccount(accountarrayParaccount) {
      this.array = this.accountarrayParaccount;
      console.log(this.array, "aaaa");
    },
    totalnumparAccount(totalnumparAccount) {
      this.arrayParaccount = this.totalnumparAccount;
      console.log(this.arrayParaccount, "arrayParaccount");
    },
    parmval(e) {
      this.handlerFilterAccount(e);
    },
    valone1(valone1) {
      console.log(valone1, "console.log(this.valone1);");
      this.page = this.valone1;
    },
    page(page, midell) {
      console.log(this.page, "page");
      this.midell = this.page;
    }
  },
  computed: {
    filterAccountColumns() {
      return this.accountColumns.filter(ele => ele.checked);
    }
  },
  // orderchange2动态订单来源下标
  mounted() {
    console.log();
    if (this.valone1) {
      this.page = this.valone1;
    }
    columndefault({
        btnCode: "100004",
      }).then((res) => {
        let list = res.data.sort((a, b) => {
          return a.colTypeCode - b.colTypeCode;
        });
        let data1 = list[0].colTypeCode;
        let typeList = ["属性指标", "展现及花费", "转化及成交"];
        let index = 0;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          list[i].name = item.colName;
          list[i].val = item.colField;
          if (data1 == item.colTypeCode) {
            list[i].type = typeList[index];
          } else {
            data1 = item.colTypeCode;
            index++;
            list[i].type = typeList[index];
          }
        }
        this.accountColumns = list;
        console.log(this.planColumns,'this.planColumns')
        this.accountColumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.accountColumns.forEach((ele, idx) => {
          // ele.checked = true;
          
          this.$set(this.accountColumns[idx], "checked", true);
        });
      });
    // this.handlerFilterAccount();
  },
  created() {
    let cfg = sessionStorage.getItem(CFG_NAME);
    if (cfg) {
      cfg = JSON.parse(cfg);
      if (cfg.account) {
        this.accountColumns = cfg.account;
      }
      if (cfg.adgroup) {
        this.adgroupColumns = cfg.adgroup;
      }
      if (cfg.mainAccount) {
        this.accountColumns = cfg.mainAccount;
      }
    }
    this.accountColumns.forEach((ele, idx) => {
      if (
        this.accountColumns[idx].checked == undefined ||
        this.accountColumns[idx].checked == null
      ) {
        this.$set(this.accountColumns[idx], "checked", true);
      }
    });
    this.adgroupColumns.forEach((ele, idx) => {
      if (
        this.adgroupColumns[idx].checked == undefined ||
        this.adgroupColumns[idx].checked == null
      ) {
        this.$set(this.adgroupColumns[idx], "checked", true);
      }
    });
    this.planColumns.forEach((ele, idx) => {
      if (
        this.planColumns[idx].checked == undefined ||
        this.planColumns[idx].checked == null
      ) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
  },
  methods: {
    // // 账户下载表格
    handleparAccount() {
      downloadMainAccountData({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartparAccount,
        filterDateEnd: this.filterDateEndparAccount,
        orderFrom: this.orderfromparAccount,
        accountIds: this.val,
        search: this.keywordparAccount,
        btnCode: "100004",
        goodIds: this.valgoodsparAccount
      }).then(res => {
        console.log(res);
        $utils.exportRaw(res.data.filename, res.data.file);
      });
    },
    // 按钮
    accountname(accountname) {
      console.log(accountname);
      this.name = accountname.colName;
    },
    // 推广分析
    openAnalyse(type, data, tabId) {
      console.log("ffffff");
      this.$refs.analyseView.show(type, data, tabId);
              this.chooseKeyIdparaccount=data.mainAccountId
      cornMetricsByKeyId({
           filterDateStart: this.filterDateStartparAccount,
           filterDateEnd: this.filterDateEndparAccount,
            chooseKeyType:"mainAccount",
            chooseKeyId: this.chooseKeyIdparaccount,
            orderFrom:this.orderchange2,
      }).then(res=>{
            console.log(res.data);
            this.costparaccount=res.data.cost
            this.clickparaccount=res.data.click
            this.convertNumparaccount=res.data.convertNum
            this.payAmountparaccount=res.data.payAmount
            this.roiparaccount=res.data.roi
            this.showNumparaccount=res.data.showNum
      })
    },
    // 按钮
    toConfirm(type, data) {
      this.accountColumns = data;
      console.log(this.accountColumns, "123123123");

      // 缓存选中列
      let cfg = sessionStorage.getItem(CFG_NAME);
      if (cfg) {
        cfg = JSON.parse(cfg);
      } else {
        cfg = {};
      }
      cfg[type] = data;
      sessionStorage.setItem(CFG_NAME, JSON.stringify(cfg));
    },
    // tabs切换事件
    tabClick(tab, page) {
      tab = tab.name;
      console.log(tab);
      this.filterType = "";
      this.filterAccount = "";
      this.handleClick(tab, 1);
    },
    handleClick(tab, page) {
      this.page = page;
      console.log("tab", tab, "page", this.page);
      this.handleFilterPlan();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleClick(this.activeName, 1);
    },
    //*************计划分页点击发起请求
    handleCurrentChange(val) {
      this.handleClick(this.activeName, val);
    },
    // 点击tabs请求
    handlerFilterAccount(type) {
      // console.log('handlerFilterAccount');
      if (
        !this.filterData.acticityId ||
        this.filterData.acticityId.length == 0
      ) {
        return;
      }
      let param = {
        platform:sessionStorage.getItem("cur_platform"),
        tabType: type, //页签类别
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      param = Object.assign(param, this.filterData);
      this.masteraccountdata(param).then(res => {
        console.log("子账户数据", res.data);
        this.array = res.data;
        this.array.forEach(showNum => {
          showNum.showNum = parseInt(showNum.showNum);
          console.log(showNum.showNum);
        });
        this.array.forEach(click => {
          click.click = parseInt(click.click);
        });
        this.array.forEach(convertNum => {
          convertNum.convertNum = parseInt(convertNum.convertNum);
        });
        this.arrayParaccount = res.total;
        console.log(this.arrayParaccount);
      });
    },
    // 查看直接从账户跳转到计划
    handleFilterPlan(type, account) {
      this.activeName = "mainAccount";
      let param = {
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartparAccount,
        filterDateEnd: this.filterDateEndparAccount,
        orderFrom: this.orderfromparAccount,
        accountIds: this.val,
        search: this.keywordparAccount,
        goodIds: this.valgoodsparAccount,
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      this.loading = true;
      this.masteraccountdata(param).then(res => {
        this.loading = false;
        // console.log("主账户数据", res.data);
        this.array = res.data;
        this.arrayParaccount = res.total;
        console.log(this.array);
      });
    },
    masteraccountdata(param) {
      return new Promise((resovle, reject) => {
        this.loading = true;
        if (param["filterDateStart"]) {
          console.log("报表tab页接口", param);
          this.filterDateStart = param.filterDateStart;
          this.filterDateEnd = param.filterDateEnd;
          // console.log(this.filterDateStart);
          // console.log(JSON.stringify(param.acticityId));
          this.acid = JSON.stringify(param.acticityId);
          masteraccountdata(param)
            .then(res => {
              this.loading = false;
              resovle(res);
            })
            .catch(e => {
              reject(e);
            });
        }
      });
    }
  }
};
</script>
 <style scoped>
.el-table th.el-table__cell > .cell {
  width: 400px;
}
/deep/.el-select__tags-text:first-child {
  width: 20px;
}
.head_search_box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* margin: 20px 0; */
  background-color: #fff;
}
.head_search1 {
  /* display: flex; */
  /* align-items: center; */
}

/deep/.el-tabs__active-bar {
  display: none;
}
/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/#tab-mainAccount {
  position: absolute;
}
.head_search1 {
  display: flex;
  flex-wrap: wrap;
}
.head_search_box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
#pane-mainAccount {
  padding: 18px;
}
</style>