 <!-- 活动监控账户详细数据点击自定义列复选框弹出层 --> 
<template>
  <div>
    <el-button type="primary" icon="el-icon-edit" @click="toShow">自定义列</el-button>

    <el-dialog title :visible.sync="dialogVisible" width="50%" :show-close="false" append-to-body>
      <div class="flex_between">
        <div class="flex line-height45">可选择列</div>
        <div class="flex">
          <div class="line-height45 margin_left20">已选{{columns.filter(ele => ele.checked).length}}项</div>
          <el-button class @click="clear()">清空</el-button>
        </div>
      </div>
      <div class="flex_between margin_top" style="height: 48vh;">
        <div class="dialog_left pandding" style="overflow-y: scroll;">
          <div v-for="t in typeList" :key="t">
            <div style="width: 100%; height: 50px; background: #f4f5f9">
              <div class="flex_between pandding7" style="line-height: 37px">
                <div>{{t}}</div>
                <el-checkbox :indeterminate="isIndeterminate(t)" v-model="checkAll[t]" @change="selectAll(t,$event)">全选</el-checkbox>
              </div>
            </div>
            <div style="margin: 15px 0;text-algin:left;" class="flex line-height40 flex_wrap">
              <el-checkbox
                v-for="item in getColumns(t)"
                @change="changeCheck($event, item)"
                :value="item.checked"
                :label="item.name"
                :key="item.val"
                :disabled="item.disabled || false"
              >{{ item.name }}</el-checkbox>
              <!-- </el-checkbox-group> -->
            </div>
          </div>
        </div>
        <div class="dialog_right">
          <!-- 拖拽插件 -->
          <vuedraggable class="wrapper" v-model="dragcolumns" style="overflow-y: scroll;height: 100%;padding-right: 10px;padding-left: 10px;">
            <!-- 过度效果 -->
            <transition-group>
              <div v-for="(item,index) in dragcolumns" :key="item.name" class="li">
                  <i class="el-icon-s-unfold" style="margin: 0 0 0 10px;cursor: pointer;"></i>
                  <span style="margin-left: 10px;flex: 1;">{{ item.name }}</span>
                  <i v-if="!item.disabled" @click="handledele(item, index)" class="el-icon-circle-close" style="margin-right: 10px;cursor: pointer;"></i>
              </div>
            </transition-group>
          </vuedraggable>
          <!-- <li v-for="(item, index) in columns.filter(ele => ele.checked)" :key="index" class="li">
            {{ item.name }}
            <i @click="handledele(index, item)" class="el-icon-circle-close"></i>
          </li> -->
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toConfirm">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { columndefault,columndeuser,columnpreservation } from "@/request/api.js"; //
import vuedraggable from "vuedraggable";
export default {
  components: { 
    vuedraggable 
    },
  props: {
    columnsInfo: {
      type: Array,
      default: () => {
        return [];
      }
    },
    param: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  data() {
    return {
      checkAll: [],
      // index: ,
      typeList: ["属性指标", "展现及花费", "转化及成交"],
      dialogVisible: false,
      columns: [],
      accountColumns: [],
      index:''
      
    
    };
  },
  created() {
  },
  mounted() {},
  computed: {
    dragcolumns: {
        get() {
            return this.columns.filter(ele => ele.checked)
        },
        set(value) {
            let arr = this.columns.filter(ele => !ele.checked)
            this.columns =  arr.concat(value);
        }
    },
    getColumns() {
      return type => {
        // console.log('getColumns', type, this.columns) //, "columnsInfo", this.columnsInfo);
        this.columns.forEach(ele => {
          ele.checked = ele.checked
          if(ele.type == '属性指标' &&( ele.colField == 'depart' || ele.colField == 'account' || ele.colField == 'planName' || ele.colField == 'operations')){
            ele.disabled = true
          }
          // console.log(ele.checked);
        });
        return this.columns.filter(ele => ele.type == type);
      };
    },
    isIndeterminate() {
      return type => {
        //是否有选中
        let checkedIdx = this.columns.findIndex(ele => ele.type == type && ele.checked)
        //是否全部选中
        // console.log(this.columns.findIndex(ele => ele.type == type && !ele.checked),'this.columns.findIndex(ele => ele.type == type && !ele.checked)')
        let uncheckedIdx = this.columns.findIndex(ele => ele.type == type && !ele.checked)
        // console.log('isIndeterminate', type, this.columns)
        return checkedIdx != -1 && uncheckedIdx != -1;
      };
    }
  },
  watch: {
    columnsInfo: {
      handler(newVal) {
        // console.log('====columnsData==', newVal)
        this.columns = JSON.parse(JSON.stringify(newVal));
        this.typeList.forEach(t => {
          let uncheckedIdx = this.columns.findIndex(ele => ele.type == t && !ele.checked)
          this.checkAll[t] = uncheckedIdx == -1;
        })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 自定义列表按钮默认值
    toShow() {
      columndefault({
        btnCode: this.param
      }).then(res => {
        // console.log(res.data);
        this.accountColumns = res.data;
        this.accountColumns.forEach(
          (colName, colTypeCode, checked, colCode, colField) => {
            this.colName = colName;
            // console.log(
            //   colName.colName,
            //   colName.colTypeCode,
            //   colName.checked,
            //   colName.colCode,
            //   colName.colField
            // );
            this.$emit("accept", this.colName);
          }
          
        );
      });
      console.log(this.columnsInfo,'this.columnsInfo')
      this.dialogVisible = true;
      this.columns = JSON.parse(JSON.stringify(this.columnsInfo));
      console.log(this.columns,'columns');
    },
    toConfirm() {
      console.log(this.columns,'this.columns')
      this.$emit("confirm", this.columns);
      //数组排序
      console.log(this.dragcolumns,'this.dragcolumns')
      let list = this.dragcolumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        let dragcolumnslist = [];
        //过滤 只取ID
        list.filter(ele=>{
          dragcolumnslist.push(ele.colCode)
        })
        // console.log(list,'list')
        console.log(list[0].colCode,'list[0].colCode')
        if(list[0].colCode == undefined || list[0].colCode == null){
          // console.log('我进来了')
          this.dialogVisible = false;
          return 
        }else{
          // console.log('没有')
          //保存自定义列
        columnpreservation({
          btnCode:this.param,
          colList:dragcolumnslist
        }).then(res=>{
          this.dialogVisible = false;
          // console.log(res,'res')
        })
        
        }
      
    },
    changeCheck(val, item) {
      // console.log(val, item);
      let idx = this.columns.findIndex(ele => ele.val == item.val);
      // this.columns[idx].checked = val;
      // console.log(this.columns.findIndex(ele => ele.val == item.val),'this.columns.findIndex(ele => ele.val == item.val)')
      this.$set(this.columns[idx], "checked", val);
      // this.$set(this.columns[idx], 'type', '11');
    },
    selectAll(t, checked) {
      // console.log("selectAll",t, checked, this.columns)
      this.checkAll[t] = checked;
      this.columns.forEach(ele => {
        if (ele.type == t) {      
          console.log(ele.checked = checked,'ele.checked = checked;')
          ele.checked = checked;
          if(ele.name == '公司名称' || ele.name == '公司账号' || ele.name == '计划' || ele.name == '操作'){
                ele.checked = true;
            }
        }
        // else{
        //    ele.checked = true;
        //   console.log( ele.checked);

        // }
      });
      // console.log(this.columns,'this.c')
    },
    clear() {
      this.columns.forEach(ele => {
        ele.checked = false;
        if(ele.name == '公司名称' || ele.name == '公司账号' || ele.name == '计划'){
                ele.checked = true;
            }
        // console.log(ele.checked);
      });
      for (const key in this.checkAll) {
        this.checkAll[key] = false;
      }
    },
    handledele(item, index) {
      console.log("handledele", item, index);
      // this.$set(this.columns, 'checked', false);
      // this.columns[index].checked = false;
      let idx = this.columns.findIndex(ele => ele.val == item.val);
      // this.columns[idx].checked = false;
      this.$set(this.columns[idx], "checked", false);
      // console.log(this.columns[idx]);
    }
  }
};
</script>

<style>
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body{
    padding: 20px;
  }
.dialog_left {
  width: 70%;
  /* height: 400px; */
  border: 1px solid #aeb3cd;
}
.dialog_right {
  width: 25%;
  /* height: 500px; */
  border: 1px solid #aeb3cd;
}
.li {
  display: flex;
  align-items: center;
  list-style: none;
  line-height: 30px;
  background: #d1e0ff;
  margin: 6px 0px 8px 0;
  height: 30px;
}
.v-modal {
  z-index: 10000;
}
</style>