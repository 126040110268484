<!-- 活动监控实时趋势第一个多选筛选 --> 

<template>
  <div style class="box">
    <el-select
   
      class="elinput"
      v-model="activityIndex"
      @change="changeActivity"
      style="width:120px;margin: 0px 0 20px 0;"
    >
      <el-option v-for="(item, index) in activitys" :key="index" :label="item.platformName" :value="index"></el-option>
    </el-select>
    <el-select
    filterable 
      class="elinput_right"
      multiple
      v-model="questionForm"
      collapse-tags
      style="margin-left: 0px;width: 280px;margin: 0 20px 0px 0;"
      placeholder="请选择活动"
      @remove-tag="handleclear"
      @change="changeSelect"
    >
      <div>
        <!-- <el-input
          style="width: 270px;margin: 0 auto;display: flex;"
          size="mini"
          class="search_input"
          type="text"
          v-model="searchText"
          @input="focusCustomer"
          clearable
          placeholder="搜索"
        ></el-input> -->
      </div>
      <el-checkbox v-model="checked" @change="selectAll" style="margin: 0px 0 0 230px;padding:10px">全选</el-checkbox>
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
        @click.native="handleoption(item.id)"
        style="width:300px"
        :disabled="trendDisabled"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import pubsub from 'pubsub-js'
import { activitylist } from "@/request/api.js"; // 营销监控筛选接口

export default {
  props: ["activitys",'trendDisabled'],

  data() {
    return {
      searchText: "",
      activityIndex: 0,
      questionForm: [],
      options: [],
      value1: [],
      value2: [],
      hw: [],
      value: [],
      checked: false
    };
  },
  watch: {
    activitys(newVal) {
      console.log("shaixuan activitys=====", newVal);
      if (newVal.length <= this.activityIndex) {
        this.activityIndex = 0;
      }
      this.options = newVal[this.activityIndex].campaignList;
      console.log(this.options);
    }
  },
  created() {
    // console.log(sessionStorage.getItem("key")); //动态token
  },
  mounted() {

    // console.log('shaixuan mounted')
    let cur_campaign_ids = sessionStorage.getItem("cur_campaign_ids");
    // this.checked = JSON.parse(sessionStorage.getItem("ischecked"))
    // console.log(sessionStorage.getItem("ischecked"),'sessionStorage.getItem("ischecked")');
    this.options = this.activitys[this.activityIndex].campaignList;
    if (cur_campaign_ids) {
      cur_campaign_ids = JSON.parse(cur_campaign_ids);
      this.questionForm = cur_campaign_ids;
    } else if (this.options.length > 0) {
      this.questionForm = [this.options[0].id];
    }
    this.emit();
  },
  methods: {
    focusCustomer(val) {
      //     options是后端请求到的数据
      //         reg ：trim是去除input两边的空格
      // var reg = this.searchText.trim();
      //    如果输入框里有value值
      if (val.length > 0) {
        //newArr 是 data中声明的空数组
        this.newArr = this.options.filter(item => {
          //    判断输入框的内容在下拉框是否存在
          var numberfliter = item.name.toUpperCase().indexOf(val.toUpperCase());
          //    为0的时候，代表存在，-1为不存在
          if (numberfliter == 0) {
            //    直接返回有相同的给newArr数组
            return item;
          }
        });
        console.log(this.newArr, "this.newArr");
        //    listt是选项变量的数组
        this.options = this.newArr;
      } else {
        console.log('我进来了s')
        //    如果没有搜索词，就显示所有的数据
        this.options = this.options;
        activitylist({}).then(res => {
            console.log(res);
          })
        
      }
    },
    changeActivity(e) {
      // TODO:
      console.log('change---activity',e)
      this.options = this.activitys[e].campaignList;
      sessionStorage.setItem("cur_campaign_ids",[]);
      sessionStorage.setItem("cur_platform",this.activitys[e].platformName);
      this.questionForm = []
      this.checked = false

      pubsub.publish('changeActivity',this.activitys[e].platformName)
    },
    // 全选功能控制单选
    selectAll() {
      this.questionForm = [];
      if (this.checked) {
        
        this.options.forEach(item => {
          this.questionForm.push(item.id);
        });
      } else {
        
        this.questionForm = [];
      }
      this.emit();
      this.$emit("update:updateMultipleSelect", this.questionForm);
    },
    // 单选控制全选
    changeSelect(val) {
      if (val.length === this.options.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      this.$emit("update:updateMultipleSelect", this.questionForm);
    },
    // 单选点击
    handleoption(id) {
      this.emit();
    },
    // Activitylist() {
    //   activitylist({
    //     Authorization: sessionStorage.getItem("key") //
    //   }).then(res => {
    //     this.options = res.data[0].campaignList;
    //     // console.log(this.options);
    //     if (this.options.length == 0) {
    //       return;
    //     } else {
    //       this.checked = true;
    //     }
    //     let cur_campaign_ids = sessionStorage.getItem("cur_campaign_ids");
    //     if (cur_campaign_ids) {
    //       cur_campaign_ids = JSON.parse(cur_campaign_ids);
    //       this.questionForm = cur_campaign_ids;
    //     } else {
    //       this.questionForm = [this.options[0].id];
    //     }
    //     this.emit();
    //   });
    // },
    emit() {
      this.hw = JSON.parse(JSON.stringify(this.questionForm));
      sessionStorage.setItem("cur_campaign_ids", JSON.stringify(this.hw));
      sessionStorage.setItem("ischecked",this.checked)
      this.$emit("handlechange1", this.hw);
    },
    handleclear() {
      this.questionForm = [];
      this.emit();
      console.log(1);
    }
  }
};
</script> 
<style scoped>
.elinput /deep/ .el-input__inner {
  border-right: none !important;
  border-radius: 5px 0px 0px 5px;
}
.elinput_right /deep/ .el-input__inner {
  border-radius: 0px 5px 5px 0px;
}
@media screen and (max-width: 1200px) {
  .elinput /deep/ .el-input__inner {
    border-right: 1px solid #dcdfe6 !important;
  }
  .elinput {
    width: 320px !important;
  }
  .box {
    width: 300px;
    margin: 0 0 20px 0;
  }
}
</style>