
<template>
  <el-menu
    class="el-menu-demo"
    mode="horizontal"
    background-color="rgb(0,102,204)"
    text-color="#fff"
    active-text-color="#fff"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="正在加载中"
  >
    <el-button class="buttonimg">
      <img
        class="showimg"
        :src="collapsed ? imgsq : imgshow"
        @click="toggle(collapsed)"
        style="color: red"
      />
    </el-button>
    <span class="title">{{ $route.name }}</span>
    <el-submenu index="2" class="submenu">
      <!-- <template slot="title">{{user.userRealName}}</template> -->
      <template slot="title">
         <span v-if="is_admin != 1">{{ username }}({{departName}})</span
        >
        <span v-else>{{ username }}</span
        >
      </template>
      <el-menu-item
        v-for="(item, index) in switch_user"
        :key="index"
        @click="changeUser(item)"
      >
        {{ item.s_account }}
      </el-menu-item>
      <!-- <el-menu-item>员工账号管理</el-menu-item> -->
      <el-menu-item @click="goChild" v-if="id_role == 1 && is_admin == 0"
        >子账号管理</el-menu-item
      >
      <el-menu-item @click="linkTo('/user/cersonalcenter')"
        >个人中心</el-menu-item
      >
      <el-menu-item @click="logOut">退出登录</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { userinfo, changeUser } from "@/request/api";
export default {
  name: "navcon",
  data() {
    return {
      nickName: "",
      collapsed: true,
      imgshow: require("../assets/img/showimg.png"),
      imgsq: require("../assets/img/hiddenimg.png"),
      username: "",
      departName:'',
      switch_user: [],
      fullscreenLoading: false,
      id_role: 1,
      is_admin: "",
    };
  },

  created() {
    this.activePath = this.$router.currentRoute.path;

    if (
      this.$router.currentRoute.query.s_account &&
      this.$router.currentRoute.query.s_uid
    ) {
      this.fullscreenLoading = true;
      //   console.log("main", this.$router)
      changeUser(this.$router.currentRoute.query).then((res) => {
        // console.log(res);
        if (res.code === 1) {
          sessionStorage.setItem("Authorization", res.data.token);
          sessionStorage.setItem("info", JSON.stringify(res.data));
          userinfo({}).then((response) => {
            this.username = response.data.account;
            
            this.switch_user = response.data.switch_user;
            this.$root.$emit("getuser", response.data);
            sessionStorage.setItem("userinfo", JSON.stringify(response.data)); //存储用户所有信息
            this.fullscreenLoading = false;
            if (this.$router.currentRoute.query.audit_path){
                this.$router.replace({ path: this.$router.currentRoute.query.audit_path });
            }else{
                this.$router.replace({ path: "/report/Report" });
            }
            location.reload();
          });
        }
      });
    }
    let info = sessionStorage.getItem("info");
    if (info) {
      info = JSON.parse(info);
      if (info && info.nickname) {
        this.nickName = info.nickname;
      }
    }
    this.getalluserinfo();
  },
  mounted() {
    this.whatScreen();
    window.onresize = () => {
      this.whatScreen();
    };
  },
  methods: {
    //获取用户信息
    getalluserinfo() {
      userinfo({}).then((response) => {
        this.username = response.data.account;
        this.departName = response.data.depart;
            // console.log(this.departName,' this.departName')
        this.switch_user = response.data.switch_user;
        this.id_role = response.data.id_role;
        this.is_admin = response.data.is_admin;
        this.$root.$emit("getuser", response.data);
        console.log(response,'response')
        sessionStorage.setItem("userinfo", JSON.stringify(response.data)); //存储用户所有信息
      });
    },
    //切换用户登录
    changeUser(e) {
      // console.log(e,'e')
      changeUser({
        s_uid: e.s_uid,
        s_account: e.s_account,
      }).then(({ data }) => {
        // console.log(data,'data')
        // console.log(sessionStorage.getItem('info',JSON.stringify(data.token)),"123")
        sessionStorage.setItem("Authorization", data.token);

        userinfo({}).then((response) => {
          this.username = response.data.account;
          this.switch_user = response.data.switch_user;
          this.departName = response.data.depart;
            // console.log(this.departName,' this.departName')
          sessionStorage.setItem("userinfo", JSON.stringify(response.data)); //存储用户所有信息

          //  console.log(sessionStorage.getItem('userinfo',JSON.stringify(response.data)),"123")
          window.location.reload();
        });
      });
    },

    whatScreen() {
      var width = document.querySelector("html").offsetWidth;
      // console.log('screen width', width);
      if (width < 750) {
        this.toggle(true);
      }
    },
    // 切换显示
    toggle(showtype) {
      this.collapsed = !showtype;
      this.$root.$emit("toggle", this.collapsed);
    },
    // 退出登录
    logOut() {
      this.$confirm("您确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let admininfo = localStorage.getItem("info");
        admininfo = JSON.parse(admininfo);
        let userinfo = sessionStorage.getItem("info");
        userinfo = JSON.parse(userinfo);
        // console.log(userinfo,'userinfo')
        if (admininfo && userinfo) {
          sessionStorage.removeItem("Authorization");
          sessionStorage.removeItem("info");
          sessionStorage.removeItem("userinfo");
          sessionStorage.removeItem("activePath");
          sessionStorage.removeItem("curIcon");
          this.$router.push("/Login");
        }
        if (admininfo && !userinfo) {
          localStorage.removeItem("Authorization");
          localStorage.removeItem("info");
          localStorage.removeItem("userinfo");
          localStorage.removeItem("activePath");
          localStorage.removeItem("curIcon");
          sessionStorage.removeItem("Authorization");
          sessionStorage.removeItem("info");
          sessionStorage.removeItem("userinfo");
          sessionStorage.removeItem("activePath");
          sessionStorage.removeItem("curIcon");
          this.$router.push("/Login");
        } else {
          // console.log("只有用户");
          sessionStorage.removeItem("Authorization");
          sessionStorage.removeItem("info");
          sessionStorage.removeItem("userinfo");
          sessionStorage.removeItem("activePath");
          sessionStorage.removeItem("curIcon");
          this.$router.push("/Login");
        }
      });
    },
    // 跳转
    linkTo(url) {
      // console.log('link to', url)
      this.activePath = url;
      this.$router.push(this.activePath);
    },
    goChild() {
      this.$router.push("/manage/ChildManage");
    },
  },
};
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}
.submenu {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}
.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
  display: flex;
}
.showimg {
  width: 30px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}
.showimg:active {
  border: none;
}
.title {
  color: #fff;
  position: absolute;
  left: 60px;
  top: 17px;
  font-size: 1.2rem;
}
.el-submenu:hover ::v-deep .el-submenu__title {
  background: rgb(0 85 171) !important;
}
.el-menu-item:hover {
  background-color: rgb(0, 85, 171) !important;
}
/deep/ .el-submenu__title i {
  color: white !important;
}
</style>