<template>
    <div class="company">

        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                    style="width:300px;"
                    placeholder="请输入公司名称"
                    v-model="keyword"
                    clearable
                    @keydown.enter.native="searchCompany"
                    >
                    <template slot="append"><el-button type="primary" @click="searchCompany">搜索</el-button></template>
                </el-input>
                <!-- <el-button type="primary" @click="searchCompany">搜索</el-button> -->
            </div>
            <!-- 20220704取消添加公司
            <div>
                <el-button type="primary" @click="drawer = true;">新增</el-button>
                    <el-dialog title="新增公司" :visible.sync="drawer" center append-to-body width="700px">
                        <el-form :model="add">
                            <el-form-item>
                                <el-input v-model="add.name" autocomplete="off" placeholder="请输入公司名称"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" :label-width="addLabelWidth">
                                <el-radio v-model="add.status" label="1">启用</el-radio>
                                <el-radio v-model="add.status" label="2">禁用</el-radio>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="add.memo" autocomplete="off" placeholder="备注"></el-input>
                            </el-form-item>
                        </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="AddCompany">确 定</el-button>
                        </div>
                    </el-dialog>
            </div>
            -->
        </div>

        <!-- 表格部分 -->
        <div class="f2" v-loading="loading">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                    prop="name"
                    label="公司名称"
                    width="400">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="100"
                    :formatter="statusFormatter1"
                    :filters="[{ text: '启用', value: 1 }, { text: '禁用', value: 0 }]"
                    :filter-method="filterTag"
                    filter-placement="bottom-end"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.status == 1 ? 'success' : 'danger'"
                            disable-transitions>{{getstatus(scope.row.status)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="domain"
                    label="绑定域名"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.domain">{{scope.row.domain}}</span>
                        <el-button v-else type="primary" size="mini" @click="companyEdit(scope.$index, scope.row)">绑定域名</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="is_https"
                    label="域名证书"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="certificateEdit(scope.$index, scope.row)">上传域名证书</el-button>
                        <el-dialog
                            width="350px"
                            title="上传域名证书"
                            :visible.sync="certificateEditVisible"
                            center
                            append-to-body
                            :close-on-click-modal="closeModal">
                            <div class="drawer-main">
                                <el-form :model="form" label-position="right" label-width="110px">
                                  <div style="text-align:center;justify-content:space-around;">  
                                    <el-form-item label="密钥(KEY)" style="width: 15vw">
                                        <el-upload
                                            action=""
                                            class="upload-demo"
                                            multiple
                                            :limit="1"
                                            :data="key"
                                            accept=".key,.KEY"
                                            :http-request="uploadCertificate"
                                            :file-list="keyList"
                                        >
                                            <el-button size="small" type="primary" style="width:120px">上传</el-button>
                                            <div slot="tip" class="el-upload__tip" style="width:100px">只能上传key文件</div>
                                        </el-upload>
                                    </el-form-item>
                                  </div><br>
                                  <div style="text-align:center;justify-content:space-around;"> 
                                    <el-form-item label="证书(PEM格式)" style="width: 15vw">
                                        <el-upload
                                            action=""
                                            class="upload-demo"
                                            multiple
                                            :limit="1"
                                            :data="pem"
                                            accept=".pem,.PEM"
                                            :file-list="pemList"
                                            :http-request="uploadCertificate"
                                        >
                                            <el-button size="small" type="primary" style="width:120px">上传</el-button>
                                            <div slot="tip" class="el-upload__tip" style="width:100px">只能上传PEM文件</div>
                                        </el-upload>
                                    </el-form-item>
                                  </div>  
                                </el-form>
                            </div>
                            <div slot="footer" style="text-align:center;margin: 0vh 0 4vh 0">
                                <el-button type="primary" @click="saveCertificate" style="width:150px">确 定</el-button>
                            </div>
                        </el-dialog>
                        <span v-show="scope.row.is_https == 1" style="margin-left: 5px">已上传</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="memo"
                    label="备注"
                    >
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="更新时间"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    width="120"
                    >
                    <template slot-scope="scope" style="display:flex;">
                        <el-button @click="hand1(scope.row)" type="text" size="small">查看主账户</el-button>
                        <el-button @click="companyEdit(scope.$index, scope.row)" type="text" size="small">编辑</el-button>
                            <el-dialog 
                                width="30%"
                                title="编辑信息" 
                                :visible.sync="dialogFormVisible" 
                                center 
                                append-to-body
                                :close-on-click-modal="closeModal">
                                <div class="drawer-main">
                                  <el-form :model="form" label-position="right" label-width="110px">
                                    <div style="background-color:rgb(255,252,222);padding: 10px;">
                                        请将域名解析到121.5.51.95，{{form.domain ? "当前绑定域名："+form.domain : "未绑定域名"}}
                                    </div><br>
                                    <el-form-item label="请输入绑定域名" style="width: 25vw">
                                        <el-input v-model="form.domain" autocomplete="off"></el-input>
                                    </el-form-item><br>
                                    <el-form-item label="备注" style="width: 25vw">
                                        <el-input 
                                            v-model="form.memo" 
                                            autocomplete="off"
                                            type="textarea"
                                            :autosize="{ minRows: 2, maxRows: 3}"
                                            ></el-input>
                                    </el-form-item>
                                  </el-form>
                                </div>
                                <div slot="footer" style="text-align:center;margin: 1vh 0 4vh 0">
                                    <el-button type="primary" @click="edit" style="width:150px">确 定</el-button>
                                </div>
                            </el-dialog>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import {addcompany, companylist, editcompany, save_certificate, upload_certificate} from '@/request/api';

    export default {
        data() {
            return {
                closeModal:false,
                loading:true,
                add: {
                    name:'',
                    status:'1',
                    remark:'',
                },
                curPage:1,
                totalNum:0,
                pageSize:10,
                drawer: false,
                keyword: '',
                tableData:[{
                    account_name:'王小虎',
                    status:'启用',
                }],
                dialogFormVisible: false,
                certificateEditVisible: false,
                form: {
                    domain:'',
                    memo: ''
                },
                addLabelWidth: '45px',
                formLabelWidth: '45px',
                zs_id:0,
                zs_pem : "",
                zs_key : "",
                pemList: [],
                keyList: [],
                key:{key:"key"},
                pem:{key:"pem"},

            }
        },
        mounted () {
            this.getlist();
        },
        methods: {
            // 搜索公司
            searchCompany() {
                this.curPage = 1;
                companylist({
                    page: this.curPage,
                    pageSize: this.pageSize,
                    name: this.keyword,
                }).then(r=>{
                    // console.log(r);
                    this.totalNum = r.total
                    this.tableData = r.data
                })
            },
            hand1(row) {
                this.$router.push('/manage/MainAccount?company_id='+row.id);
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getlist()
                // this.getGoodsList(this.keyword)
            },
            handleCurrentChange(val) {
                // console.log('current page', val);
                this.curPage = val;
                this.getlist()
                // this.getGoodsList(this.keyword)
            },
            filterTag(value, row) {
                return row.status === value;
            },
            statusFormatter1(row, column) {
                const status = row.status;
                if (status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            },
            // 显示列表
            getlist() {
                companylist({
                    page: this.curPage,
                    pageSize: this.pageSize,
                }).then(r=>{
                    // console.log(r);
                    this.loading = false
                    this.totalNum = r.total
                    this.tableData = r.data
                })
            },
            // 新增公司
            AddCompany() {
                addcompany({
                    name: this.add.name,
                    status: this.add.status,
                    memo:this.add.memo,
                }).then(r=>{
                    // console.log(r);
                    if(r.code == 1){
                        this.$message({
                            message:'添加成功',
                            type:'success'
                        })
                            this.getlist()
                            this.drawer = false;
                    }else{
                        this.$message({
                            message:'添加失败',
                            type:'error'
                        })
                    }
                })
            },

            // 编辑按钮打开嵌套   编辑公司信息
            companyEdit(index,row) {
                this.form = {
                    domain: '',
                    memo: ''
                }
                this.dialogFormVisible = true
                // console.log(index,row);
                // console.log(row.id);
                this.id = row.id
                this.form = Object.assign({},row)
            },
            //
            certificateEdit(index,row){
                this.zs_key = ""
                this.zs_pem = ""
                this.zs_id = row.id
                this.keyList = []
                this.pemList = []
                this.certificateEditVisible = true
            },
            // hand2(index,row) {
            //     this.dialogFormVisible = true
            //     // console.log(index,row);
            //     // console.log(row.id);
            //     this.id = row.id
            //     console.log(this.id);
            //     companylist({
            //         name: this.add.name,
            //         companyId: this.id,
            //     }).then(r=>{
            //         console.log(r);
            //     })
            // },
            // 编辑公司信息
            edit() {
                editcompany({...this.form,
                    companyId: this.id,
                    save:1,
                }).then(r=>{
                    // console.log(r);
                    if(r.code == 1){
                        this.$message({
                            message:'修改成功',
                            type:'success'
                        })
                        this.getlist();
                        this.dialogFormVisible = false
                    }else{
                        // this.$message({
                        //     message:'修改失败,名字重复',
                        //     type:'error'
                        // })
                        this.dialogFormVisible = false
                    }
                })
            },
            getstatus(status) {
                return status == 1 ? '启用' : '禁用';
            },
            uploadCertificate(e){
                console.log(e)
                var form = new FormData
                form.append("file",e.file)
                form.append("company_id",this.zs_id)
                upload_certificate(form).then(res => {
                    if(res.code == 1){
                        this.$message.success(res.msg)
                        if(e.data.key == "key"){
                            this.zs_key = res.data.url
                        }else{
                            this.zs_pem = res.data.url
                        }
                    }else{
                        if(e.data.key == "key"){
                            this.keyList = []
                        }else{
                            this.pemList = []
                        }
                        this.$message.error(res.msg)
                    }
                })
            },
            saveCertificate(){
                if(this.zs_key == ""){
                    this.$message.error("请上传密钥(KEY)")
                    return
                }
                if(this.zs_pem == ""){
                    this.$message.error("请上传证书(PEM)")
                    return
                }
                save_certificate({company_id:this.zs_id}).then(res => {
                    this.certificateEditVisible = false
                    if(res.code == 1){
                        this.$message.success(res.msg)
                        this.getlist();
                    }
                })
            }

        }




    }
</script>

<style scoped>
/* /deep/.el-input--suffix .el-input__inner{
    width: 260px;
} */

.company .f2 {
    padding: 20px;
    background-color: #fff;
}
.company .page {
    text-align: right;
    margin: 15px 2px 0 0;
}
.company .drawer-main {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
 
</style>