<template>
    <div class="child">

        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                    style="width:300px"
                    placeholder="请输入子账户名称"
                    v-model="keyword"
                    clearable
                    >
                    <template slot="append"><el-button type="primary" @click="searchChild">搜索</el-button></template>
                </el-input>
            </div>
            <div>
                <el-button type="primary" @click="back">返回</el-button>
            </div>
        </div>

        <!-- 表格部分 -->
        <div class="f2" v-loading="loading">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                    prop="sub_account"
                    label="子账户名称"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="账户ID"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="principal_account"
                    label="所属主账户"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="更新时间"
                    >
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="活动配置"
                    >
                </el-table-column>
                <el-table-column
                    v-if="platfrom == 1"
                    prop=""
                    label="添加回传鉴权"
                    width="100">
                    <el-switch slot-scope="scope"
                               v-model="scope.row.switch_auth_status"
                               active-color="#13ce66"
                               @change="switchAuth(scope.row)"
                               :disabled="scope.row.switch_auth_status"
                               v-if="!scope.row.switch_auth_status"
                    >
                    </el-switch>
                    <span v-else>已添加</span>
                </el-table-column>
                <el-table-column
                    v-if="platfrom == 1"
                    prop=""
                    label="回传鉴权"
                    width="80">
                    <div slot-scope="scope" v-if="scope.row.switch_auth_status">
                        <el-switch
                                   v-model="scope.row.auth_enable"
                                   active-color="#13ce66"
                                   @change="authEnable(scope.row)"
                                   v-if="scope.row.auth_enable_show"
                        >
                        </el-switch>
                        <span v-else>
                            <span v-if="scope.row.observation">鉴权观察</span>
                            <span v-else>测试鉴权</span>
                        </span>
                    </div>
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    width="200"
                    >
                    <template slot-scope="scope" style="display:flex;">
<!--                        <el-button @click="dialogFormVisible = true" type="text" size="small">编辑</el-button>&nbsp;-->
                            <el-dialog title="编辑信息" :visible.sync="dialogFormVisible" center append-to-body>
                                <el-form :model="form">
                                    <el-form-item>
                                        <el-input v-model="form.name" autocomplete="off" placeholder="请输入公司名称"></el-input>
                                    </el-form-item>
                                    <el-form-item label="状态" :label-width="formLabelWidth">
                                            <el-radio v-model="form.status" label="1">启用</el-radio>
                                            <el-radio v-model="form.status" label="2">禁用</el-radio>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-input v-model="form.memo" autocomplete="off" placeholder="备注"></el-input>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                                    <el-button type="primary" @click="edit">确 定</el-button>
                                </div>
                            </el-dialog>
                            <el-button @click="hand2(scope.row)" type="text" size="small">同步消耗</el-button>
                            <el-button @click="hand3(scope.row)" type="text" size="small">查看计划</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>



    </div>
</template>

<script>
import {
    auth_enable,
    getAccountById, switch_auth
} from '@/request/api'
    export default {
        data() {
            return {
                loading:true,
                curPage:1,
                pageSize:10,
                totalNum:0,
                keyword:'',
                tableData: [{
                    name:'王小虎',
                    id:'',
                }],
                form:{
                    name:'',
                    status:'1',
                    memo:'',
                },
                dialogFormVisible: false,
                formLabelWidth: '50px',
                agent_id:0,
                platfrom: 1
            }
        },
        mounted() {
            var query = this.$route.query
            if(query.id != undefined){
                if(query.platform != undefined){
                    this.platfrom = query.platform
                }
                this.agent_id = query.id
                this.accountList()
            }
        },
        methods: {
            back(){
                this.$router.back()
            },
            hand2() {
                // console.log('1111');
            },
            // 查看计划
            hand3(row) {
                console.log(row,'----row-----');
                this.$router.push('/manage/ChildPlan?id=' + row.code + "&platform="+row.platform );
            },
            // 搜索
            searchChild() {
                this.curPage = 1
                this.accountList()
            },
            statusFormatter1(row, column) {
                const status = row.status;
                if (status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.accountList()
                // this.getGoodsList(this.keyword)
            },
            handleCurrentChange(val) {
                this.curPage = val;
                this.accountList()
                // this.getGoodsList(this.keyword)
            },
            // 确定编辑
            edit() {

            },

            accountList(){
                var params = {account_code:this.agent_id,page:this.curPage,pageSize:this.pageSize, platfrom:this.platfrom};
                if(this.keyword){
                    params.keyword = this.keyword
                }
                getAccountById(params).then(res => {
                    this.loading = false
                    if(res.code == 1){
                        this.tableData = res.data
                        this.totalNum = res.total
                    }
                })
            },
            /**
             * 添加鉴权
             * @param row
             */
            switchAuth(row){
                switch_auth({code:row.code}).then(res => {
                    if(res.code == 1){
                        this.$message.success(res.msg)
                        row.switch_auth_status = res.data.status
                    }else{
                        row.switch_auth_status = !row.switch_auth_status
                    }
                })
            },
            /**
             * 开启关闭鉴权
             * @param row
             */
            authEnable(row){
                console.log(row)
                auth_enable({code: row.code, auth_enable: row.auth_enable}).then(res => {
                    if(res.code == 1){
                        this.$message.success(res.msg)
                    }else{
                        row.auth_enable = !row.auth_enable
                    }
                })
            }

        },
    }
</script>

<style scoped>

.child .f2 {
    padding: 20px;
    background-color: #fff;
}
.child .page {
    text-align: right;
    margin: 15px 2px 0 0;
    /* padding-bottom: 50px; */
}
</style>