<template>
  <div>
    <el-select
    class="sx4box"
    style="margin:0 20px 0 0;width: 122px;"
      clearable
      size="medium"
      v-model="value"
      @change="change"
      filterable
      placeholder="转化效果评估"
    >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "0",
          label: "不归因"
        },
        {
          value: "1",
          label: "1日"
        },
        {
          value: "3",
          label: "3日"
        },
        {
          value: "7",
          label: "7日"
        },
        {
          value: "15",
          label: "15日"
        },
        {
          value: "30",
          label: "30日"
        }
      ],
      value: "0"
    };
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>

<style scoped>
/deep/.el-input--medium .el-input__inner{
  height: 41px;
  }
  @media screen and (max-width: 1200px) {
   .sx4box{
     margin:0 20px 20px 0 !important
   }
}
</style>