 <!-- 活动监控账户详细数据中详细分析第一个账户弹窗抽屉 --> 
<template>
  <div>
    <div>
      <div style="color:#0A63FC" class="cursor" @click="drawers" type="primary">详细分析</div>
      <el-drawer
        :append-to-body="true"
        title
        :visible.sync="drawer"
        :with-header="false"
        size="45%"
      >
        <div class="pandding drawer_head">账户详情</div>
        <div class="pandding flex drawer_id">
          账户:
          <div>{{drawdata1.accountName }}</div>（账户ID：
          <div>{{drawdata1.accountId }}</div>）
        </div>
        <div class="indicators">
          <div class="pandding" style="color:#a1a1a1">核心指标</div>
          <div class="flex_around line-height40">
            <div class="indicators_box">
              <div class="indicators_box_money">花费（元）</div>
              <div class="indicators_box_num">{{drawdata1.cost }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">点击量</div>
              <div class="indicators_box_num">{{drawdata1.click }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化数</div>
              <div class="indicators_box_num">{{drawdata1.convertNum }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">曝光量</div>
              <div class="indicators_box_num">{{drawdata1.showNum }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化成交金额</div>
              <div class="indicators_box_num">1{{drawdata1.payAmount }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化ROI</div>
              <div class="indicators_box_num">{{drawdata1.roi }}</div>
            </div>
          </div>
        </div>
        <div class="indicators1">
          <div class="bigbox">
            <div class="title">
              <div @click="tab(1)" :class="this.tabshow==1?'active':''">趋势图</div>
              <div @click="tab(2)" :class="this.tabshow==2?'active':''">表格</div>
            </div>
            <div class="containerbox">
              <div v-show="tabshow==1">
                <goods :drawdata1="drawdata1.accountId" :aa="drawdata1.accountId"></goods>
              </div>
              <div v-show="tabshow==2">
                <div class="pandding flex_between">
                  <div
                    class="flex"
                    style="color:#a1a1a1;
                      font-size: 15px;
                      line-height: 40px;"
                  >时间报表</div>
                  <div class="flex">
                    <columnclick></columnclick>
                  </div>
                </div>
                <trtd :drawdata1="drawdata1.accountId" :aa="drawdata1.accountId"></trtd>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
 
<script>
import goods from "../echarts/goods";
import columnclick from "../echarts/goods_columnclick";
import trtd from "../echarts/goods_trtd";

import { timetrend } from "@/request/api.js"; //营销活动监控首页-(账户 广告组 计划 [ 时间趋势 ])
export default {
  components: {
    goods,
    columnclick,
    trtd
  },
  props: {
    drawdata1: {
      type: Object,
      default: "0"
    },
  },

  watch: {
    drawdata1(drawdata1) {
      console.log(drawdata1);
    }
  },
  data() {
    return {
      aa: "",
      goods: "",
      value1: "",
      value2: "",
      drawer: false,
      tabshow: 1
    };
  },
  mounted() {},
  created() {},
  methods: {
    drawers() {
      this.drawer = true;
      this.$emit("drawerss");
    },
    tab(val) {
      this.tabshow = val;
      if (val == 1) {
      } else if (val == 2) {
      }
    }
  }
};
</script>

<style>
.el-drawer.rtl {
  background: #f5f6fa;
}
.drawer_head {
  border-bottom: 1px solid darkgray;
  color: #a1a1a1;
}

.indicators {
  width: 93%;
  height: 150px;
  background: #fff;
  margin: 0 auto;
}
.indicators1 {
  width: 93%;
  height: 500px;
  background: #fff;
  margin: 20px auto;
}
.indicators_box {
  border-left: 1px solid #e5e5e5;
  height: 75px;
  padding: 0 0 0 20px;
}

.indicators_box_money {
  color: #a1a1a1;
  font-size: 14px;
}
.indicators_box_num {
  color: #a1a1a1;
  font-size: 18px;
}
.drawer_id {
  font-size: 14px;
  color: #a1a1a1;
}
.bigbox .title {
  display: flex;
  float: right;

}
.bigbox .title div {
  border: 1px solid #c5c8dc;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 9px;
}
.containerbox {
}
.active {
  background-color: #1966ff;
  color: #ffff;
}
html,
body {
  scroll-behavior: smooth;
}
.title {
}
</style>