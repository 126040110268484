<template>
  <div class="app">
    <el-form ref="form" :model="form" class="flex">
      <el-form-item label>
        <el-select   class="elinput"   v-model="form.phoneBrand" placeholder="账户/计划" @change="changeSelect1">
          <el-option
            v-for="(item,index) in brandOptions"
            :key="index"
            :label="item.name"
            :value="item.val"
          />
        </el-select>
      </el-form-item>
      <el-form-item label>
        <el-select
        class="elinput_right"
          v-if="isSearchPlan"
          v-model="form.plan"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="请输入计划"
          @change="changeSelectPlan"
          :remote-method="searchPlan"
          :loading="loading"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.accountId"
            :label="item.accountName"
            :value="item.accountId"
          ></el-option>
        </el-select>
        <el-select
         class="elinput_right"
          style="width: 280px;margin:0 20px 0 0"
          v-if="!isSearchPlan"
          filterable
          multiple
          collapse-tags
          v-model="form.phoneType"
          placeholder="请选择账户"
          @change="changeSelect2"
        >
          <!-- :label="item.accountName"是name -->
          <!-- :value="item.accountId"是id -->
          <el-option
            v-for="(item,index) in typeOptions"
            :key="index"
            :label="item.accountName"
            :value="item.accountId"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import pubsub from 'pubsub-js'
import { accountlis, advertisement, plan } from "@/request/api.js"; // //营销活动监控第二个筛选中账户列表接口

export default {
  data() {
    return {
      // brandOptions: [{name:"代理", val:'AGENT'}, {name: "账户", val:'ACCOUNT'}, {name:"广告组", val:'AD_GROUP'}, {name:"计划",val:'PLAN'}],
      brandOptions: [
        { name: "账户", val: "ACCOUNT" },
        // { name: "广告组", val: "AD_GROUP" },
        { name: "计划", val: "PLAN" }
      ],
      brandObj: {},
      isSearchPlan: false,
      form: [],
      array: [],
      loading: false,
      typeOptions: [],
      form: {
        phoneBrand: "",
        phoneType: "",
        plan: []
      }
    };
  },
  props: {
    acid: {
      type: Array,
      default: []
    },
    targetType: {
      type: String,
      default: ""
    }
  },
  watch: {
    targetType: {
      handler(newVal) {
        this.form.phoneBrand = newVal;
        this.changeSelect1();
      },
      immediate: true
    },
    acid: {
      handler(newVal) {
        this.changeSelect1();
      },
      immediate: true
    }
  },
  methods: {
    searchPlan(queryString, cb) {
      this.loading = true;
      plan({
        acticityId: this.acid.join(","),
        searchName: queryString
      }).then(res => {
        this.loading = false;
        console.log(res.data);
        res.data.forEach(ele => {
          ele["accountId"] = ele.planId;
          ele["accountName"] = ele.planName;
        });
        this.typeOptions = res.data;
        cb(res.data);
      });
    },
    changeSelect1() {
      this.isSearchPlan = this.form.phoneBrand == "PLAN";
      this.typeOptions = [];
      console.log(this.form.phoneBrand); //第一步获取第选中的值然后发出请求
      if (this.form.phoneBrand == "ACCOUNT") {
        accountlis({ acticityId: this.acid.join(","),platform:sessionStorage.getItem("cur_platform") }).then(res => {
          console.log(res.data);
          this.typeOptions = res.data;
        });
      } else if (this.form.phoneBrand == "AD_GROUP") {
        advertisement({ acticityId: this.acid.join(","),platform:sessionStorage.getItem("cur_platform") }).then(res => {
          res.data.forEach(ele => {
            ele["accountId"] = ele.adGroupId;
            ele["accountName"] = ele.adGroupName;
          });
          this.typeOptions = res.data;
        });
      } else {
      }
      // 清空手机型号内容
      this.form.phoneType = "";
      // 遍历手机品牌的下拉选项数组
      for (const k in this.brandOptions) {
        // 手机品牌内容 是否等于 手机品牌的下拉选择数组中的某一项
        if (this.form.phoneBrand === this.brandOptions[k]) {
          this.typeOptions = this.brandObj[this.form.phoneBrand];
        }
      }
    },
    changeSelectPlan() {
      this.$emit("handlechange2", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
      this.$emit("handlechange2plan", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
      this.$emit("handlechange2Account", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
      this.$emit("handlechange2parAccount", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
      this.$emit("handlechange2Companydata", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
    },
    changeSelect2() {
      console.log(this.form.phoneType);
      this.$emit("handlechange2", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
      this.$emit("handlechange2plan", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
      this.$emit("handlechange2Account", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
      this.$emit("handlechange2parAccount", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
      this.$emit("handlechange2Companydata", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
    }
  },
  mounted(){
    // this.pid = pubsub.subscribe('changeActivity',(msgName, data)=>{
    //   this.changeSelect1()
    // })
  }
};
</script>
<style  >
/* .app {
  margin: 20px; 

} */
/* .el-form {
  margin: -20px 0 0 0;
} */
.el-form-item {
  margin-bottom: 0;
}
</style>
<style scoped>
.elinput /deep/ .el-input__inner {
  border-right: none !important;
  border-radius: 5px 0px 0px 5px;
  width: 122px;
}
.elinput_right /deep/ .el-input__inner {
  border-radius: 0px 5px 5px 0px;
}
@media screen and (max-width: 900px) {
 .elinput /deep/ .el-input__inner{
   /* margin: 0 0 20px 0; */
 }
 .app{
   margin: 0 0 20px 0;
 }
}
</style>


