<template>
    <div class="company" v-loading="loading">
        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                        style="width: 300px"
                        placeholder="请输入账号或所属公司"
                        v-model="keyword"
                        clearable
                        @keydown.enter.native="search"
                >
                    <el-button slot="append" @click="search">搜索</el-button>
                </el-input>
                <!-- 公司选择 -->
                <el-select
                        v-if="this.userinfo.is_admin == 1"
                        v-model="dlsListVal"
                        placeholder="请选择"
                        class="addgs"
                        style="margin-left: 10px"
                        @change="changeDls"
                >
                    <el-option
                            v-for="item in dlsList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
                <!-- 类型选择 -->
                <el-select
                        v-model="propListVal"
                        placeholder="请选择"
                        class="addgs"
                        style="margin-left: 10px"
                        @change="changeProp"
                >
                    <el-option
                            v-for="item in propList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="addgs">
                <el-button type="primary" @click="openDialog">添加账号</el-button>
                <el-dialog
                        title="添加账号"
                        :close-on-click-modal="close_model"
                        :visible.sync="drawer"
                        center
                        append-to-body
                        width="30%"
                >
                    <el-form :model="accountList" :label-width="addLabelWidth">
                        <el-form-item style="margin-bottom: 10px; width: 25vw" label="账号">
                            <el-input
                                    style="width: 90%"
                                    v-model="accountList.name"
                                    autocomplete="off"
                                    placeholder="请输入账号"
                            ></el-input>
                        </el-form-item>
                        <el-form-item style="margin-bottom: 10px; width: 25vw" label="密码">
                            <el-input
                                    style="width: 90%"
                                    type="password"
                                    v-model="accountList.password"
                                    autocomplete="off"
                                    placeholder="请输入密码"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                                style="margin-bottom: 10px; width: 25vw"
                                label="所属公司"
                        >
                            <el-select
                                    v-model="value"
                                    placeholder="请选择所属公司"
                                    style="width: 90%"
                            >
                                <el-option
                                        v-for="item in options"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态" :label-width="addLabelWidth">
                            <el-radio v-model="accountList.status" label="1">启用</el-radio>
                            <el-radio v-model="accountList.status" label="0">禁用</el-radio>
                        </el-form-item>
                        <el-form-item label="类型" :label-width="addLabelWidth">
                            <el-radio v-model="accountList.isProp" label="1">自运营</el-radio>
                            <el-radio v-model="accountList.isProp" label="2">代运营</el-radio>
                        </el-form-item>
                    </el-form>

                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="add">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <!-- 表格部分 -->
        <div class="f2">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="account" label="账号" width="200"> </el-table-column>
                <el-table-column prop="cost" label="花费" width="100">
                    <template slot="header" slot-scope="scope">
                        <span>花费<i class="el-icon-refresh-right" @click="changeCostVal(scope.row)" style="cursor:pointer"></i></span>
                    </template>
                    <div slot-scope="scope" >
                        <i v-if="CostVal"  class="el-icon-loading"></i>
                        <i v-else v-html="scope.row.cost" style="font-style: inherit !important;">{{scope.row.cost}}</i>
                    </div>
                </el-table-column>
                <el-table-column prop="depart" label="所属公司" > </el-table-column>
                <el-table-column prop="is_prop" sortable label="类型" width="100"> </el-table-column>

                <el-table-column
                        prop="status"
                        label="状态"
                        width="100"
                        :formatter="statusFormatter1"
                        :filters="[
            { text: '启用', value: 1 },
            { text: '禁用', value: 0 },
          ]"
                        :filter-method="filterTag"
                >
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.status == '启用' ? 'success' : 'danger'"
                                disable-transitions
                        >{{ getstatus(scope.row.status) }}
                        </el-tag>
                    </template>
                </el-table-column>
                <!--          <el-table-column-->
                <!--              prop="tb_jump_type"-->
                <!--              label="模式"-->
                <!--              :formatter="statusFormatter1"-->
                <!--          >-->
                <!--              <template slot-scope="scope">-->
                <!--                  <el-tag-->
                <!--                      :type="scope.row.tb_jump_type == 'app' ? 'info' : ''"-->
                <!--                      disable-transitions-->
                <!--                  >{{ scope.row.tb_jump_type== 'app' ? '淘宝APP' : '淘宝小程序' }}-->
                <!--                  </el-tag>-->
                <!--              </template>-->
                <!--          </el-table-column>-->
                <el-table-column prop="is_switch" label="允许切换账号" width="150">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.is_switch"
                                active-color="#13ce66"
                                inactive-color="#dcdfe6"
                                @change="isSwitch(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                
                <el-table-column prop="is_open" label="跑量助手" width="150">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.is_open"
                                active-color="#13ce66"
                                inactive-color="#dcdfe6"
                                @change="isOpen(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column prop="is_show_data" label="报表数据显示" width="150">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.is_show_data"
                                active-color="#13ce66"
                                inactive-color="#dcdfe6"
                                @change="DdSwitch(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                
                <el-table-column prop="add_time" label="添加时间" width="170"> </el-table-column>

                <el-table-column prop="operation" label="操作" fixed="right" width="200">
                    <template slot-scope="scope" style="display: flex">
                        <el-button type="text" size="small" @click="godatakanban(scope.row)"
                        >数据看板</el-button
                        >
                        <el-button type="text" size="small" @click="openUpdate(scope.row)"
                        >修改</el-button
                        >
                        <el-dialog
                                title="修改信息"
                                :close-on-click-modal="close_model"
                                :visible.sync="dialogFormVisible"
                                center
                                append-to-body
                                width="30%"
                        >
                            <el-form :model="updateform" :label-width="formLabelWidth">
                                <el-form-item
                                        style="margin-bottom: 10px; width: 25vw"
                                        label="账号"
                                >
                                    <el-input
                                            v-model="updateform.username"
                                            type="text"
                                            autocomplete="off"
                                            placeholder="请输入更改的账号名称"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item
                                        style="margin-bottom: 10px; width: 25vw"
                                        label="密码"
                                >
                                    <el-input
                                            v-model="updateform.password"
                                            type="password"
                                            autocomplete="off"
                                            placeholder="请输入更改的密码"
                                    ></el-input>
                                </el-form-item>

                                <el-form-item
                                        label="状态"
                                        style="margin-bottom: 10px; width: 25vw"
                                >
                                    <el-radio v-model="updateform.status" label="1">启用</el-radio>
                                    <el-radio v-model="updateform.status" label="0">禁用</el-radio>
                                </el-form-item>

                                <el-form-item label="类型" style="margin-bottom: 10px; width: 25vw">
                                    <el-radio v-model="updateform.isProp" label="1">自运营</el-radio>
                                    <el-radio v-model="updateform.isProp" label="2">代运营</el-radio>
                                </el-form-item>
                                <!--                <el-form-item-->
                                <!--                  label="模式"-->
                                <!--                  style="margin-bottom: 10px; width: 25vw"-->
                                <!--                >-->
                                <!--                  <el-radio v-model="updateform.tb_jump_type" label="app">淘宝APP</el-radio>-->
                                <!--                  <el-radio v-model="updateform.tb_jump_type" label="miniapp">淘宝小程序</el-radio>-->
                                <!--                </el-form-item>-->
                            </el-form>
                            <div slot="footer" class="dialog-footer">
                                <el-button type="primary" @click="updatePassword"
                                >确 定</el-button
                                >
                            </div>
                        </el-dialog>
                        <el-button style="margin-left:10px" @click="hand1(scope.row)" type="text" size="small"
                        >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10, 20, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {
    Accountmanage,
    AddAccountManage,
    AdvertisingList,
    DelAccount,
    UpdateUser,
    switchUser,
    userOpen,
    getFactor,
    getCost,
    isDdSwitch
} from "@/request/api";

var timer = {}; //定时器对象
export default {
    data() {
        return {
            CostVal:false,
            propList:[{
                id:0,
                name:'全部类型'
            },{
                id:1,
                name:'自运营'
            },{
                id:2,
                name:'代运营'
            }],
            propListVal:0,
            dlsListVal:0,
            dlsList:[],
            loading: false,
            updateform:{
                username:"",
                password:"",
                status: "1",
                isProp:"1",
            },
            dialogFormVisible: false,
            options: [],
            value: "",
            tableData: [
                {
                    account_name: "王小虎",
                    status: "启用",
                },
            ],
            drawer: false,
            close_model: false,
            curPage: 1,
            totalNum: 0,
            pageSize: 10,
            keyword: "",
            accountList: {
                name: "",
                status: "1",
                memo: "",
                isProp: "1"
            },
            addLabelWidth: "120px",
            formLabelWidth: "45px",
            form: {
                name: "",
                status: "1",
                memo: "",
            },
            advertisingId: "",
            searchname: "",
            userid:"",
            userinfo:{},
            CostStatus: 1, //是否刷新花费
            costList:[{id:1}],//总花费数组
        };
    },
    watch: {},
    computed: {},
    created() {},
    activated(){

    },
    mounted() {

        this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
        // this.getAdvertisingList();
        if(this.userinfo.is_admin == 1){
            this.getFactor();
        }else if(this.userinfo.is_admin == 2){
            this.dlsListVal = this.userinfo.id_factor;
            this.getAllAccountmanage();
        }else{
            this.getAllAccountmanage();
        }



    },

    methods: {

        //是否刷新花费
        changeCostVal(){
            this.CostStatus = 1;
            this.CostVal = true;
            let that = this;
            let idList =[];//定义空数组接收当前账号ID
            for(let i=0; i< that.tableData.length; i++){
                idList.push(that.tableData[i].id)
            }

            getCost({agentId: idList,refresh:this.CostStatus}).then(res=>{
                // console.log(that.tableData,'that.tableData')
                for(let i=0; i< res.data.length; i++){
                    if(res.data[i].agent_id == this.tableData[i].id){
                        this.tableData[i].cost = res.data[i].cost
                        let dataArrTemp = JSON.stringify( that.tableData); //解决数据更新，视图不刷新bug
                        that.tableData = JSON.parse(dataArrTemp);
                    }
                }
                this.CostVal = false;
                this.getCost();
            })
        },
        ////每隔一个小时刷新总花费
        getCost(){
            // console.log(e,'e')
            clearTimeout(timer);
            timer = setTimeout(()=>{
                this.getAllAccountmanage();
            },3600000)

        },


        //切换类型
        changeProp(e){
            this.propListVal = e;
            this.getAllAccountmanage();
        },
        //切换代理商
        changeDls(e){
            // console.log(e,'e')
            this.dlsListVal = e;
            this.getAllAccountmanage();
        },
        //获取代理商公司下拉框
        getFactor(){

            getFactor({}).then(r=>{
                // console.log(r,'r')
                this.dlsList = r.data;
                this.dlsListVal = this.dlsList[0].id;
                this.getAllAccountmanage();
            })

        },

        //获取代理商账户列表
        getAllAccountmanage() {
            this.loading = true;
            // console.log(this.$route.query.name, "this.$route.query.name");
            if (this.$route.query.name) {
                this.keyword = this.$route.query.name;
                this.searchname = this.$route.query.name;
            }
            Accountmanage({
                page: this.curPage,
                number: this.pageSize,
                search: this.keyword,
                factorId:this.dlsListVal,
                isProp: Number(this.propListVal)
            }).then((res) => {
                this.loading = false;
                if (res.code == 1) {

                    let that = this;
                    that.totalNum = res.total;
                    that.tableData = res.data;
                    let idList =[];//定义空数组接收当前账号ID
                    for(let i=0; i< that.tableData.length; i++){
                        idList.push(that.tableData[i].id)
                    }

                    getCost({agentId: idList,refresh:this.CostStatus}).then(res=>{

                        // console.log(that.tableData,'that.tableData')
                        for(let i=0; i< res.data.length; i++){
                            if(res.data[i].agent_id == this.tableData[i].id){
                                console.log('我进来了e','123123')
                                this.tableData[i].cost = res.data[i].cost
                                let dataArrTemp = JSON.stringify( that.tableData); //解决数据更新，视图不刷新bug
                                that.tableData = JSON.parse(dataArrTemp);
                            }
                        }
                        this.getCost();
                    })
                } else {
                    this.$message.error(res.msg);
                }
                // console.log(res,'res')

                // console.log(this.tableData,'this.tableData.update_time')
            });
        },
        //搜索代理商账户
        search() {
            this.loading = true;
            this.curPage = 1;
            this.getAllAccountmanage();
            // Accountmanage({
            //   page: this.curPage,
            //   number: this.pageSize,
            //   search: this.keyword,
            //   factorId:this.dlsListVal,
            //   isProp: Number(this.propListVal)
            // }).then((res) => {
            //   this.loading = false;
            //   this.tableData = res.data;
            //   this.totalNum = res.total;
            // });
        },
        //打开添加弹窗
        openDialog() {
            //获取广告代理商公司菜单列表
            AdvertisingList({ factorId:this.dlsListVal,}).then((res) => {
                this.options = res.data;
                this.drawer = true;
                // console.log(res, "res1111");
            });
            // this.accountList = [];
        },
        //添加代理商
        add() {
            if (!this.accountList.name || !this.accountList.password) {
                this.$message({
                    message: "请输入名称或密码",
                    type: "worning",
                });
                return;
            }
            if (!this.value) {
                this.$message({
                    message: "请选择所属公司",
                    type: "worning",
                });
                return;
            }
            AddAccountManage({
                id: 0,
                account: this.accountList.name,
                password: this.accountList.password,
                id_depart: this.value,
                status: this.accountList.status,
                isProp: Number(this.accountList.isProp)
            }).then((res) => {
                if (res.code == 1) {
                    this.$message({
                        message: "添加成功",
                        type: "success",
                    });
                    this.getAllAccountmanage();
                    this.drawer = false;
                } else {
                    this.$message({
                        message: "添加失败",
                        type: "error",
                    });
                }
                // console.log(res,'res')
            });
        },


        //点击删除，删除数据
        hand1(val) {
            this.$confirm("您确定要删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                DelAccount({
                    id: val.id,
                }).then((res) => {
                    if (res.code == 1) {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        this.getAllAccountmanage();
                    } else {
                        this.$message({
                            message: "删除失败",
                            type: "error",
                        });
                    }
                });
            });
            // console.log(val,'获取到的数据')
        },
        //打开修改界面
        openUpdate(row){
            // console.log(row,'122121')
            this.updateform.username =row.account
            this.userid = row.id
            if(row.is_prop == '自运营'){
                this.updateform.isProp = '1'
            }else{
                this.updateform.isProp = '2'
            }

            this.dialogFormVisible = true;
            if(row.status == "启用"){
                this.updateform.status = '1'
            }else{
                this.updateform.status = '0'
            }
        },
        //修改密码
        updatePassword(){
            // console.log(this.updateform,'this.updateform')
            UpdateUser({
                user_id:this.userid,
                account:this.updateform.username,
                password:this.updateform.password,
                status:this.updateform.status,
                isProp: Number(this.updateform.isProp)
            }).then(res=>{
                if (res.code == 1) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                    this.dialogFormVisible = false;
                    this.getAllAccountmanage()
                } else {
                    this.$message({
                        message: "修改失败",
                        type: "error",
                    });
                    this.dialogFormVisible = false;
                }
            })
        },
        //跳转看板页面
        godatakanban(row) {
            // console.log(row);
            if(row.status == "停用"){
                this.$message({
                    message: "该账号已停用",
                    type: "error",
                });
                return
            }
            // 暂无接口
            let info = localStorage.getItem('corpid');
            if(info){
                this.$router.replace({path: "/report/Report",query: { s_uid: row.id, s_account: row.account },})
                window.location.reload();
            }else{
                const newrouter = this.$router.resolve({
                    path: "/report/Report",
                    query: { s_uid: row.id, s_account: row.account },
                });
                window.open(newrouter.href, "_blank");
                // console.log(newrouter.href)
            }

        },
        //改变每条页数
        handleSizeChange(val) {
            this.pageSize = val;
            // console.log('this.pageSize', this.pageSize)
            this.getAllAccountmanage();
        },
        //改变页码
        handleCurrentChange(val) {
            // console.log('current page', val);
            this.curPage = val;
            this.getAllAccountmanage();
        },
        filterTag(value, row) {
            return row.status === value;
        },
        statusFormatter1(row, column) {
            const status = row.status;
            if (status == 1) {
                return "启用";
            } else {
                return "禁用";
            }
        },
        getstatus(status) {
            // console.log(status, "status");
            return status;
        },
        //订单展示
        DdSwitch(row){
            var isswitch = row.is_show_data == true ? "1" : "0"
            isDdSwitch({
                user_id:row.id,
                is_show_data:isswitch
            }).then(res=>{
                if (res.code == 1) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "修改失败",
                        type: "error",
                    });
                }
            })
        },
        isSwitch(row){
            var isswitch = row.is_switch == true ? "1" : "0"
            switchUser({
                user_id:row.id,
                is_switch:isswitch
            }).then(res=>{
                if (res.code == 1) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "修改失败",
                        type: "error",
                    });
                }
            })
        },
        isOpen(row){
            console.log(row,'row')
            var isOpen = row.is_open == true ? "1" : "0"
            userOpen({
                user_id:row.id,
                isOpen:isOpen
            }).then(res=>{
                if (res.code == 1) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "修改失败",
                        type: "error",
                    });
                }
            })
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$destroy();
        next();
    },//离开路由调用销毁钩子
    destroyed(){
        clearTimeout(timer);//页面销毁清理定时器
    }
};
</script>
<style scoped>
.company .f2 {
    padding: 15px;
    background-color: #fff;
}
.company .page {
    text-align: right;
    margin: 15px 2px 0 0;
}
@media screen and (max-width: 1200px) {
    .head_search_box{
        flex-direction: column;
    }
    .addgs{
        margin-top: 10px;
    }
}

</style>