<template>
    <div class="child-plan">

        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                    style="width:300px"
                    placeholder="请输入子账户名称"
                    v-model="searchData.keyword"
                    clearable
                    >
                    <template slot="append"><el-button type="primary" @click="search">搜索</el-button></template>
                </el-input>
                <!-- <el-button type="primary" @click="search">搜索</el-button> -->
            </div>
            <div>
                <el-button type="primary" @click="back">返回</el-button>
            </div>
        </div>

         <!-- 表格部分 -->
        <div class="f2">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <!--<el-table-column
                    prop="company"
                    label="公司"
                    width="320">
                </el-table-column>-->
                <el-table-column
                    prop="name"
                    label="计划">
                </el-table-column>
                <el-table-column
                    prop="ad_id"
                    label="计划ID"
                    width="220">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="160"
                >
                </el-table-column>
                <!--<el-table-column
                    prop="status1"
                    label="计划状态"
                   >
                </el-table-column>-->
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
import {
    getChildAccountPlan
} from "@/request/api"
    export default {
        data() {
            return {
                platform:1,
                drawer: false,
                keyword: '',
                curPage:1,
                pageSize:10,
                totalNum:0,
                tableData: [{
                    company:'xxx',
                    plan:'',
                    status:'',
                }],
                searchData:{
                    account_code:0,
                    keyword:""
                }
            }
        },
        mounted() {
            var query = this.$route.query
            console.log(query,'query');
            this.platform = parseInt(query.platform)
            console.log(this.platform,'this.platform');
            if(query.id != undefined && query.id){
                this.searchData.account_code = query.id
                this.getPlanList()
            }
        },
        methods: {
            back(){
                this.$router.back()
            },
            handleSizeChange(value){
                this.pageSize = value
                this.getPlanList()
            },
            handleCurrentChange(value){
                this.curPage = value
                this.getPlanList()
            },
            search() {
                this.curPage = 1
                this.getPlanList()
            },
            getPlanList(){
                getChildAccountPlan({...this.searchData,page:this.curPage,pageSize: this.pageSize,platform:this.platform}).then(res => {
                    if(res.code == 1){
                        this.tableData = res.data
                        this.totalNum = res.total
                    }
                })
            }


        },
    }
</script>

<style scoped>

.child-plan .f2 {
    padding: 20px;
    background-color: #fff;
}
.child-plan .page {
    text-align: right;
    margin: 15px 2px 0 0;
    padding-bottom: 50px;
}
</style>