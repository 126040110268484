<template>
    <div class="analyse">
        <!-- 主体内容图标 -->
        <div class="nav-icon">
            <i class="el-icon-s-fold"></i>
            <span>自定义多维报表</span>
        </div>

        <!-- 导出按钮 -->
        <div class="excel-btn">
            <el-button icon="el-icon-download" round>导出</el-button>
        </div>

        <!-- 背景 -->
        <div class="bg">
            <!-- 报表开始 -->
            <div class="f1">
                <!-- 搜索栏 -->
                <div class="search-part">
                    <!-- 搜索框 1 -->
                    <!-- <span>筛选账号</span>
                          <el-cascader
                            style="width:200px"
                            v-model="checkedForm.org_names1"
                            :options="org_options1"
                            :props="props"
                            collapse-tags
                            @change="cardChange1"
                            clearable
                            @visible-change ="shaixuan1"
                          ></el-cascader> -->
                    <span>筛选账号</span>
                    <el-select
                        v-model="checkedForm.org_names1"
                        multiple
                        collapse-tags
                        placeholder="请选择"
                        @visible-change="shaixuan1"
                        @change="initChart"
                        class="sx1"
                    >
                        <el-option
                            v-for="item in org_options1"
                            :key="item.advertiser_id"
                            :label="item.name"
                            :value="item.advertiser_id"
                        >
                        </el-option>
                    </el-select>
                    <!-- 搜索框 2 -->
                    <span>账号名称</span>
                    <el-select
                        v-model="checkedForm.org_names2"
                        multiple
                        collapse-tags
                        placeholder="请选择(多选)"
                        @visible-change="shaixuan2"
                        @change="initChart"
                        class="sx2"
                    >
                        <el-option
                            v-for="item in org_options2"
                            :key="item.advertiser_id"
                            :label="item.name"
                            :value="item.advertiser_id"
                        >
                        </el-option>
                    </el-select>
                    <!-- 搜索框 3 -->
                    <span>账号ID</span>
                    <el-select
                        v-model="checkedForm.org_names3"
                        multiple
                        collapse-tags
                        placeholder="请选择(多选)"
                        @visible-change="shaixuan3"
                        @change="initChart"
                        class="sx3"
                    >
                        <el-option
                            v-for="item in org_options3"
                            :key="item.name"
                            :label="item.advertiser_id"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                    <!-- 搜索框 4 -->
                    <span>计划ID</span>
                    <el-select
                        v-model="checkedForm.org_names4"
                        multiple
                        collapse-tags
                        placeholder="请选择(多选)"
                        @visible-change="shaixuan4"
                        @change="initChart"
                        class="sx4"
                    >
                        <el-option
                            v-for="(item, i) in org_options4"
                            :key="i"
                            :label="item.ad_id"
                            :value="item.ad_id"
                        >
                        </el-option>
                    </el-select>
                    <!-- 搜索框 5 -->
                    <span>计划状态</span>
                    <el-select
                        v-model="checkedForm.org_names5"
                        multiple
                        collapse-tags
                        placeholder="请选择(多选)"
                        @visible-change="shaixuan5"
                        @change="initChart"
                        class="sx5"
                    >
                        <el-option
                            v-for="item in org_options5"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- 表格内容 -->
            <div class="form">
                <!-- 页签页 -->
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="实时" name="first">
                        <!-- 实时趋势图 -->
                        <div class="t-title">
                            <div class="text">实时趋势_汇总</div>
                            <div>
                                <el-popover placement="top" width="50" trigger="hover">
                                    <div>
                                        <div>
                                            <el-button type="text" @click="centerDialogVisible = true"
                                            >查看数据</el-button
                                            >
                                        </div>
                                        <!-- 查看数据的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="centerDialogVisible"
                                            width="50%"
                                        >
                                            <div>数据信息</div>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div>
                                                <el-radio v-model="radio" label="1"
                                                >格式化数据</el-radio
                                                >
                                                <el-radio v-model="radio" label="2">原始数据</el-radio>
                                                <!-- <span class="copy">copy</span> -->
                                            </div>
                                            <div class="h4D">
                                                <el-table :data="h4Data" border style="width: 100%">
                                                    <el-table-column
                                                        prop="stat_datetime"
                                                        label="stat_datetime"
                                                        width="180"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column prop="cost" label="花费" width="180">
                                                    </el-table-column>
                                                    <el-table-column prop="nonroi" label="不归因ROI">
                                                    </el-table-column>
                                                    <el-table-column prop="nonamount" label="不归因成交">
                                                    </el-table-column>
                                                    <el-table-column prop="roi" label="长效归因ROI">
                                                    </el-table-column>
                                                    <el-table-column prop="amount" label="长效归因成交">
                                                    </el-table-column>
                                                    <el-table-column prop="oneroi" label="1日归因ROI">
                                                    </el-table-column>
                                                    <el-table-column prop="oneamount" label="1日归因成交">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="centerDialogVisible = false"
                        >取 消</el-button
                        >
                        <el-button
                            type="primary"
                            @click="centerDialogVisible = false"
                        >导出Excel</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <!-- 查看数据结束 -->
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <div>
                                            <el-button type="text" @click="center = true"
                                            >导出</el-button
                                            >
                                        </div>
                                        <!-- 导出的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="center"
                                            width="30%"
                                        >
                                            <div>导出</div>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div class="excel-name">
                        <span>*导出名称</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input
                                                    type="text"
                                                    value="CLICKID报表_富文本-分天_20220531_1921"
                                                />
                                            </div>
                                            <div>
                        <span>*文件格式</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio-group v-model="radio1">
                                                    <el-radio :label="3">EXCEL</el-radio>
                                                    <el-radio :label="6">图片</el-radio>
                                                    <el-radio :label="9">PDF</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div>
                        <span>*导出渠道</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio>本地</el-radio>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="center = false">取 消</el-button>
                        <el-button type="primary" @click="center = false"
                        >导出Excel</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <!-- <div>全屏</div> -->
                                    </div>
                                    <!-- <i class="el-icon-more" slot="reference"></i> -->
                                </el-popover>
                            </div>
                        </div>
                        <!-- 嵌套结束 -->
                        <!-- 实时趋势第一个echarts -->
                        <div class="form-img">
                            <div id="trend" :style="{ width: '150vh', height: '45vh' }"></div>
                        </div>
                        <!-- 实时报表栏标题栏 -->
                        <div class="time-title">
                            <!-- 实时报表 -->
                            <div class="text">实时小时报表</div>
                            <!-- <div>已选字段<i class="el-icon-caret-bottom"></i></div> -->
                            <!-- 嵌套开始‘...’ -->
                            <div>
                                <el-popover placement="top" width="50" trigger="hover">
                                    <!-- 嵌套按钮 -->
                                    <div>
                                        <div>
                                            <el-button type="text" @click="centera = true"
                                            >创建取数</el-button
                                            >
                                        </div>
                                        <!-- 查看数据的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="centera"
                                            width="30%"
                                        >
                                            <h3>创建取数任务</h3>
                                            <!-- <el-divider></el-divider>   分割线 -->
                                            <div>
                                                <div>
                                                    <span>取数名称</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="text" value="历史天报表" />
                                                </div>
                                                <div>
                                                    <span>取数类型</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio-group v-model="radio1">
                                                        <el-radio :label="3">纯数据导出</el-radio>
                                                        <el-radio :label="6">带格式导出</el-radio>
                                                        <el-radio :label="9">CSV</el-radio>
                                                    </el-radio-group>
                                                </div>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="centera = false">取 消</el-button>
                        <el-button type="primary" @click="centera = false"
                        >确定</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <!-- 创建取数结束 -->
                                        <!-- 取数任务开始 -->
                                        <div>
                                            <el-button type="text" @click="centera = true"
                                            >取数任务</el-button
                                            >
                                        </div>
                                        <!-- 查看数据的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="centera"
                                            width="30%"
                                            title="取数任务列表"
                                        >
                                            <!-- <h3>取数任务列表</h3> -->
                                            <!-- <el-divider></el-divider>   分割线 -->
                                            <div>
                                                <div>
                                                    <input type="text" placeholder="根据名称搜索" />
                                                </div>
                                                <div>
                                                    <el-table :data="tabData" stripe style="width: 100%">
                                                        <el-table-column
                                                            prop="name"
                                                            label="名称"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="start_time"
                                                            label="任务开始时间"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column prop="status" label="状态">
                                                        </el-table-column>
                                                        <el-table-column prop="operation" label="操作">
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="centera = false">关 闭</el-button>
                        <el-button type="primary" @click="centera = false"
                        >刷 新</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <div>
                                            <el-button type="text" @click="centerb = true"
                                            >导出</el-button
                                            >
                                        </div>
                                        <!-- 导出的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="centerb"
                                            width="30%"
                                            title="导出"
                                        >
                                            <!-- <div>导出</div> -->
                                            <!-- <el-divider></el-divider>   分割线 -->
                                            <div class="excel-name">
                        <span>*导出名称</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input
                                                    type="text"
                                                    value="CLICKID报表_富文本-分天_20220531_1921"
                                                />
                                            </div>
                                            <div>
                        <span>*文件格式</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio-group v-model="radio1">
                                                    <el-radio :label="3">EXCEL</el-radio>
                                                    <el-radio :label="6">图片</el-radio>
                                                    <el-radio :label="9">PDF</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div>
                        <span>*交叉表数据类型</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio-group v-model="radio1">
                                                    <el-radio :label="3">带格式导出</el-radio>
                                                    <el-radio :label="6">纯数据(单元格超过...)</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div>
                        <span>*导出渠道</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio>本地</el-radio>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                        >注意：导出excel文件仅支持前10000条记录</span
                                        >
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="centerb = false">取 消</el-button>
                        <el-button type="primary" @click="centerb = false"
                        >确 定</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <!-- 取数任务结束 -->
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <!-- <div>全屏</div> -->
                                    </div>
                                    <!-- <i class="el-icon-more" slot="reference"></i> -->
                                </el-popover>
                            </div>
                        </div>
                        <!-- 嵌套结束 -->
                        <!-- 实时小时报表 -->
                        <div class="form-list1">
                            <el-table :data="tableData" border show-summary height="62vh" :span-method="objectSpanMethod">
                                <el-table-column
                                    sortable
                                    prop="advertiserName"
                                    label="授权账号名称"
                                    width="180"

                                >
                                    <!-- FF-富库嵌甲天猫ysl-01 -->
                                </el-table-column>
                                <el-table-column prop="ad_id" label="计划ID" width="180">
                                    <!-- 嵌套计划ID -->
                                    <!-- <template slot-scope="scope">
                                        <el-button type="text" @click="dialogPlan = true">{{
                                                scope.row.ad_id
                                            }}</el-button>
                                    </template> -->
                                    <!-- 嵌套开始 -->
                                    <!-- <el-dialog
                                        append-to-body
                                        :visible.sync="dialogPlan"
                                        width="70%"
                                        :before-close="handleClose"
                                    >
                                        <dialog-plan></dialog-plan>
                                    </el-dialog> -->
                                    <!-- 嵌套结束 -->
                                </el-table-column>
                                <el-table-column prop="name" label="计划名称" width="200">
                                </el-table-column>
                                <el-table-column prop="cost" sortable label="花费">
                                </el-table-column>
                                <el-table-column
                                    prop="non_attribution_amount"
                                    label="不归因成交"
                                >
                                </el-table-column>
                                <el-table-column prop="non_attribution_roi" label="不归因ROI">
                                </el-table-column>
                                <el-table-column prop="convert_amount" label="1日归因成交">
                                </el-table-column>
                                <el-table-column prop="attribution_roi" label="1日归因ROI">
                                </el-table-column>
                                <el-table-column prop="show_num" label="展示数">
                                </el-table-column>
                                <el-table-column prop="click" label="点击数"> </el-table-column>
                                <el-table-column prop="ctr" label="点击率"> </el-table-column>
                                <el-table-column prop="convert_num" label="转化数">
                                </el-table-column>
                                <el-table-column prop="convert_cost" label="转化成本">
                                </el-table-column>
                                <el-table-column prop="convert_rate" label="转化率">
                                </el-table-column>
                                <el-table-column prop="avg_show_cost" label="千次展现费用">
                                </el-table-column>
                                <el-table-column prop="avg_click_cost" label="平均点击单价">
                                </el-table-column>
                                <el-table-column prop="like" label="点赞数"> </el-table-column>
                                <el-table-column prop="comment" label="评论数">
                                </el-table-column>
                                <el-table-column prop="share" label="分享数"> </el-table-column>
                            </el-table>
                            <!-- 页码 -->
                            <div class="page2">
                                <el-pagination
                                    @size-change="handleSizeChange1"
                                    @current-change="handleCurrentChange1"
                                    :current-page="currentPage2"
                                    :page-sizes="[10, 20, 30, 50, 80, 100]"
                                    :page-size="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total1"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="历史" name="second">
                        <div class="history1">
                            &nbsp;&nbsp;<span>报表时间</span>&nbsp;&nbsp;&nbsp;
                            <!-- <el-select v-model="valuet" filterable placeholder="请选择">
                                      <el-option
                                          v-for="item in formtime"
                                          :key="item.valuet"
                                          :label="item.label"
                                          :value="item.valuet">
                                      </el-option>
                                  </el-select> -->
                            <span class="demonstration"></span>
                            <el-date-picker
                                v-model="value2"
                                type="daterange"
                                align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                @change="changeDate"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                        <el-divider></el-divider>
                        <!--  分割线 -->
                        <!-- 历史内容部分开始 -->
                        <!-- 分天趋势图 -->
                        <div class="day-img">
                            <div class="day-title">
                                <div class="text">分天趋势_汇总</div>
                                <div>
                                    <el-popover placement="top" width="50" trigger="hover">
                                        <div>
                                            <div>
                                                <el-button
                                                    type="text"
                                                    @click="centerDialogVisible = true"
                                                >查看数据</el-button
                                                >
                                            </div>
                                            <!-- 查看数据的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="centerDialogVisible"
                                                width="50%"
                                            >
                                                <div>数据信息</div>
                                                <el-divider></el-divider>
                                                <!--  分割线 -->
                                                <div>
                                                    <el-radio v-model="radio" label="1"
                                                    >格式化数据</el-radio
                                                    >
                                                    <el-radio v-model="radio" label="2"
                                                    >原始数据</el-radio
                                                    >
                                                    <!-- <span class="copy">copy</span> -->
                                                </div>
                                                <div class="h4D">
                                                    <el-table :data="h4Data" border style="width: 100%">
                                                        <el-table-column
                                                            prop="stat_datetime"
                                                            label="stat_datetime"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="cost"
                                                            label="花费"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column prop="nonroi" label="不归因ROI">
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="nonamount"
                                                            label="不归因成交"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column prop="roi" label="长效归因ROI">
                                                        </el-table-column>
                                                        <el-table-column prop="amount" label="长效归因成交">
                                                        </el-table-column>
                                                        <el-table-column prop="oneroi" label="1日归因ROI">
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="oneamount"
                                                            label="1日归因成交"
                                                        >
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centerDialogVisible = false"
                          >取 消</el-button
                          >
                          <el-button
                              type="primary"
                              @click="centerDialogVisible = false"
                          >导出Excel</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <!-- 查看数据结束 -->
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div>
                                                <el-button type="text" @click="center = true"
                                                >导出</el-button
                                                >
                                            </div>
                                            <!-- 导出的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="center"
                                                width="30%"
                                            >
                                                <div>导出</div>
                                                <el-divider></el-divider>
                                                <!--  分割线 -->
                                                <div class="excel-name">
                          <span>*导出名称</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input
                                                        type="text"
                                                        value="CLICKID报表_富文本-分天_20220531_1921"
                                                    />
                                                </div>
                                                <div>
                          <span>*文件格式</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio-group v-model="radio1">
                                                        <el-radio :label="3">EXCEL</el-radio>
                                                        <el-radio :label="6">图片</el-radio>
                                                        <el-radio :label="9">PDF</el-radio>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                          <span>*导出渠道</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio>本地</el-radio>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="center = false">取 消</el-button>
                          <el-button type="primary" @click="center = false"
                          >导出Excel</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <el-divider></el-divider>
                                            <!--  分割线  -->
                                            <!-- <div>全屏</div> -->
                                        </div>
                                        <!-- <i class="el-icon-more" slot="reference"></i> -->
                                    </el-popover>
                                </div>
                                <!-- 嵌套结束 -->
                                <!-- <div class="title-ft"><i class="el-icon-more"></i></div> -->
                            </div>
                            <!-- 历史1的echarts -->
                            <div id="day" :style="{ width: '150vh', height: '45vh' }"></div>
                        </div>
                        <!-- 历史天报表 -->
                        <div class="day-form">
                            <div class="dayform-title">
                                <div class="text">历史天报表</div>
                                <div class="fillter">
                                    <!-- <span>过滤(0)<i class="el-icon-caret-bottom"></i></span>
                                            <span>已选字段(30)<i class="el-icon-caret-bottom"></i></span> -->
                                    <!-- 嵌套‘...’ -->
                                    <!-- <div> -->
                                    <el-popover placement="top" width="50" trigger="hover">
                                        <!-- 嵌套按钮 -->
                                        <div>
                                            <div>
                                                <el-button type="text" @click="centera = true"
                                                >创建取数</el-button
                                                >
                                            </div>
                                            <!-- 查看数据的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="centera"
                                                width="30%"
                                            >
                                                <h3>创建取数任务</h3>
                                                <!-- <el-divider></el-divider>   分割线 -->
                                                <div>
                                                    <div>
                                                        <span>取数名称</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <input type="text" value="历史天报表" />
                                                    </div>
                                                    <div>
                                                        <span>取数类型</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <el-radio-group v-model="radio1">
                                                            <el-radio :label="3">纯数据导出</el-radio>
                                                            <el-radio :label="6">带格式导出</el-radio>
                                                            <el-radio :label="9">CSV</el-radio>
                                                        </el-radio-group>
                                                    </div>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centera = false">取 消</el-button>
                          <el-button type="primary" @click="centera = false"
                          >确定</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <!-- 创建取数结束 -->
                                            <!-- 取数任务开始 -->
                                            <div>
                                                <el-button type="text" @click="centera = true"
                                                >取数任务</el-button
                                                >
                                            </div>
                                            <!-- 查看数据的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="centera"
                                                width="30%"
                                                title="取数任务列表"
                                            >
                                                <!-- <h3>取数任务列表</h3> -->
                                                <!-- <el-divider></el-divider>   分割线 -->
                                                <div>
                                                    <div>
                                                        <input type="text" placeholder="根据名称搜索" />
                                                    </div>
                                                    <div>
                                                        <el-table
                                                            :data="tabData"
                                                            stripe
                                                            style="width: 100%"
                                                        >
                                                            <el-table-column
                                                                prop="name"
                                                                label="名称"
                                                                width="180"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column
                                                                prop="start_time"
                                                                label="任务开始时间"
                                                                width="180"
                                                            >
                                                            </el-table-column>
                                                            <el-table-column prop="status" label="状态">
                                                            </el-table-column>
                                                            <el-table-column prop="operation" label="操作">
                                                            </el-table-column>
                                                        </el-table>
                                                    </div>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centera = false">关 闭</el-button>
                          <el-button type="primary" @click="centera = false"
                          >刷 新</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div>
                                                <el-button type="text" @click="centerb = true"
                                                >导出</el-button
                                                >
                                            </div>
                                            <!-- 导出的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="centerb"
                                                width="30%"
                                                title="导出"
                                            >
                                                <!-- <div>导出</div> -->
                                                <!-- <el-divider></el-divider>   分割线 -->
                                                <div class="excel-name">
                          <span>*导出名称</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input
                                                        type="text"
                                                        value="CLICKID报表_富文本-分天_20220531_1921"
                                                    />
                                                </div>
                                                <div>
                          <span>*文件格式</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio-group v-model="radio1">
                                                        <el-radio :label="3">EXCEL</el-radio>
                                                        <el-radio :label="6">图片</el-radio>
                                                        <el-radio :label="9">PDF</el-radio>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                          <span>*交叉表数据类型</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio-group v-model="radio1">
                                                        <el-radio :label="3">带格式导出</el-radio>
                                                        <el-radio :label="6"
                                                        >纯数据(单元格超过...)</el-radio
                                                        >
                                                    </el-radio-group>
                                                </div>
                                                <div>
                          <span>*导出渠道</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio>本地</el-radio>
                                                </div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                            >注意：导出excel文件仅支持前10000条记录</span
                                            >
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centerb = false">取 消</el-button>
                          <el-button type="primary" @click="centerb = false"
                          >确 定</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <!-- 取数任务结束 -->
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <!-- <div>全屏</div> -->
                                        </div>
                                        <!-- <i class="el-icon-more" slot="reference"></i> -->
                                    </el-popover>
                                    <!-- </div>    -->
                                    <!-- 嵌套结束 -->
                                </div>
                            </div>
                            <!-- 历史天报表内容 -->
                            <div class="form-list">
                                <el-table
                                    :data="dayData"
                                    border
                                    show-summary
                                    style="width: 100%"
                                    height="64vh">
                                    <el-table-column
                                        sortable
                                        prop="advertiserName"
                                        label="授权账号名称"
                                        width="180">
                                    </el-table-column>
                                    <el-table-column prop="ad_id" label="计划ID" width="180">
                                    </el-table-column>
                                    <el-table-column prop="cost" sortable label="花费">
                                    </el-table-column>
                                    <el-table-column prop="non_attribution_amount" label="不归因成交">
                                    </el-table-column>
                                    <el-table-column prop="non_attribution_roi" label="不归因ROI">
                                    </el-table-column>
                                    <el-table-column prop="convert_amount" label="一日归因成交">
                                    </el-table-column>
                                    <el-table-column prop="attribution_roi" label="一日归因ROI">
                                    </el-table-column>
                                    <el-table-column prop="show_num" label="展示数">
                                    </el-table-column>
                                    <el-table-column prop="click" label="点击数">
                                    </el-table-column>
                                    <el-table-column prop="ctr" label="点击率"> </el-table-column>
                                    <el-table-column prop="convert_num" label="转化数">
                                    </el-table-column>
                                    <el-table-column prop="convert_cost" label="转化成本">
                                    </el-table-column>
                                    <el-table-column prop="convert_rate" label="转化率">
                                    </el-table-column>
                                    <el-table-column prop="avg_show_cost" label="千次展现费用">
                                    </el-table-column>
                                    <el-table-column prop="avg_click_cost" label="平均点击单价">
                                    </el-table-column>
                                    <el-table-column prop="like" label="点赞数">
                                    </el-table-column>
                                    <el-table-column prop="comment" label="评论数">
                                    </el-table-column>
                                    <el-table-column prop="share" label="分享数">
                                    </el-table-column>
                                </el-table>
                                <!-- 页码 -->
                                <div class="pages">
                                    <el-pagination
                                        @size-change="handleSizeChange2"
                                        @current-change="handleCurrentChange2"
                                        :current-page="currentPage2"
                                        :page-sizes="[10, 20, 30, 50, 80, 100]"
                                        :page-size="pageSize2"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="total2"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                        <!-- 分天趋势计划 -->
                        <div class="day-plan">
                            <div class="plan-text">
                                <div>分天趋势计划：</div>
                                <div v-for="(item,i) in tagNameList" :key="i">{{item}}</div>
                            </div>
                            <!-- 嵌套‘...’ -->
                            <div>
                                <el-popover placement="top" width="50" trigger="hover">
                                    <!-- 嵌套按钮 -->
                                    <div>
                                        <div>
                                            <el-button type="text" @click="centerDialogVisible = true"
                                            >查看数据</el-button
                                            >
                                        </div>
                                        <!-- 查看数据的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="centerDialogVisible"
                                            width="50%"
                                        >
                                            <div>数据信息</div>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div>
                                                <el-radio v-model="radio" label="1"
                                                >格式化数据</el-radio
                                                >
                                                <el-radio v-model="radio" label="2">原始数据</el-radio>
                                                <!-- <span class="copy">copy</span> -->
                                            </div>
                                            <div class="h3D">
                                                <el-table :data="h3Data1" border style="width: 100%">
                                                    <el-table-column
                                                        prop="plan_id"
                                                        label="plan_id"
                                                        width="180"
                                                    >
                                                    </el-table-column>
                                                    <el-table-column
                                                        prop="plan_name"
                                                        label="plan_name"
                                                        width="180"
                                                    >
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="centerDialogVisible = false"
                        >取 消</el-button
                        >
                        <el-button
                            type="primary"
                            @click="centerDialogVisible = false"
                        >导出Excel</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <!-- 查看数据结束 -->
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <div>
                                            <el-button type="text" @click="center = true"
                                            >导出</el-button
                                            >
                                        </div>
                                        <!-- 导出的嵌套 -->
                                        <el-dialog
                                            append-to-body
                                            :visible.sync="center"
                                            width="30%"
                                        >
                                            <div>导出</div>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div class="excel-name">
                        <span>*导出名称</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <input
                                                    type="text"
                                                    value="CLICKID报表_富文本-分天_20220531_1921"
                                                />
                                            </div>
                                            <div>
                        <span>*文件格式</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio-group v-model="radio1">
                                                    <el-radio :label="3">EXCEL</el-radio>
                                                    <el-radio :label="6">图片</el-radio>
                                                    <el-radio :label="9">PDF</el-radio>
                                                </el-radio-group>
                                            </div>
                                            <div>
                        <span>*导出渠道</span
                        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <el-radio>本地</el-radio>
                                            </div>
                                            <span slot="footer" class="dialog-footer">
                        <el-button @click="center = false">取 消</el-button>
                        <el-button type="primary" @click="center = false"
                        >导出Excel</el-button
                        >
                      </span>
                                        </el-dialog>
                                        <el-divider></el-divider>
                                        <!--  分割线 -->
                                        <!-- <div>全屏</div> -->
                                    </div>
                                    <!-- <i class="el-icon-more" slot="reference"></i> -->
                                </el-popover>
                            </div>
                        </div>
                        <!-- 历史趋势图2 -->
                        <div class="day-img">
                            <div class="title2">
                                <div class="title2-1"></div>
                                <!-- 嵌套‘...’ -->
                                <!-- <div class="title2-2" @click="outerVisible = true"><i class="el-icon-more"></i></div> -->
                                <div>
                                    <el-popover placement="top" width="50" trigger="hover">
                                        <div>
                                            <div>
                                                <el-button
                                                    type="text"
                                                    @click="centerDialogVisible = true"
                                                >查看数据</el-button
                                                >
                                            </div>
                                            <!-- 查看数据的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="centerDialogVisible"
                                                width="50%"
                                            >
                                                <div>数据信息</div>
                                                <el-divider></el-divider>
                                                <!--  分割线 -->
                                                <div>
                                                    <el-radio v-model="radio" label="1"
                                                    >格式化数据</el-radio
                                                    >
                                                    <el-radio v-model="radio" label="2"
                                                    >原始数据</el-radio
                                                    >
                                                    <!-- <span class="copy">copy</span> -->
                                                </div>
                                                <div class="h4D">
                                                    <el-table :data="h4Data" border style="width: 100%">
                                                        <el-table-column
                                                            prop="stat_datetime"
                                                            label="stat_datetime"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="cost"
                                                            label="花费"
                                                            width="180"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column prop="nonroi" label="不归因ROI">
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="nonamount"
                                                            label="不归因成交"
                                                        >
                                                        </el-table-column>
                                                        <el-table-column prop="roi" label="长效归因ROI">
                                                        </el-table-column>
                                                        <el-table-column prop="amount" label="长效归因成交">
                                                        </el-table-column>
                                                        <el-table-column prop="oneroi" label="1日归因ROI">
                                                        </el-table-column>
                                                        <el-table-column
                                                            prop="oneamount"
                                                            label="1日归因成交"
                                                        >
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centerDialogVisible = false"
                          >取 消</el-button
                          >
                          <el-button
                              type="primary"
                              @click="centerDialogVisible = false"
                          >导出Excel</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <!-- 查看数据结束 -->
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <div>
                                                <el-button type="text" @click="center = true"
                                                >导出</el-button
                                                >
                                            </div>
                                            <!-- 导出的嵌套 -->
                                            <el-dialog
                                                append-to-body
                                                :visible.sync="center"
                                                width="30%"
                                            >
                                                <div>导出</div>
                                                <el-divider></el-divider>
                                                <!--  分割线 -->
                                                <div class="excel-name">
                          <span>*导出名称</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input
                                                        type="text"
                                                        value="CLICKID报表_富文本-分天_20220531_1921"
                                                    />
                                                </div>
                                                <div>
                          <span>*文件格式</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio-group v-model="radio1">
                                                        <el-radio :label="3">EXCEL</el-radio>
                                                        <el-radio :label="6">图片</el-radio>
                                                        <el-radio :label="9">PDF</el-radio>
                                                    </el-radio-group>
                                                </div>
                                                <div>
                          <span>*导出渠道</span
                          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <el-radio>本地</el-radio>
                                                </div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="center = false">取 消</el-button>
                          <el-button type="primary" @click="center = false"
                          >导出Excel</el-button
                          >
                        </span>
                                            </el-dialog>
                                            <el-divider></el-divider>
                                            <!--  分割线 -->
                                            <!-- <div>全屏</div> -->
                                        </div>
                                        <!-- <i class="el-icon-more" slot="reference"></i> -->
                                    </el-popover>
                                </div>
                                <!-- 嵌套结束 -->
                            </div>
                            <div id="day2" :style="{ width: '150vh', height: '45vh' }"></div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
var echarts = require("echarts")
import {
    getnid,
    getplanid,
    getscreen,
    getstatus,
    hchart,
    htable,
    timechart,
    timetable,
} from "../../request/api";
import DialogPlan from "@/components/DialogPlan.vue";

export default {
    components: {
        DialogPlan,
    },
    data() {
        return {
            // timedata:[],
            // 计划id嵌套
            dialogPlan: false,
            // 页签栏
            activeName: "first",

            // 时间选择器的方法
            value2: "",
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick(picker) {
                            const end = new Date();
                            end.setDate(end.getDate() - 1);
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "前天",
                        onClick(picker) {
                            const end = new Date();
                            end.setDate(end.getDate() - 2);
                            const start = new Date();
                            start.setDate(start.getDate() - 2);
                            // start.setTime(start.getTime() - 3600 * 1000 * 24);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近15天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },

            // 历史栏第二个 导出
            centerb: false,
            // 历史栏第二个 取数任务
            tabData: [
                {
                    name: "",
                    start_time: "",
                    status: "",
                    operation: "",
                },
            ],
            // 嵌套的 历史栏第四个
            h4Data: [
                {
                    stat_datetime: "20220526",
                    cost: "20620",
                    nonroi: "1.31",
                    nonamount: "27040",
                    roi: "1.11",
                    amount: "22892",
                    oneroi: "1.09",
                    oneamount: "22390",
                },
                {
                    stat_datetime: "20220527",
                    cost: "19340",
                    nonroi: "1.15",
                    nonamount: "22320",
                    roi: "1.03",
                    amount: "19836",
                    oneroi: "0.99",
                    oneamount: "19060",
                },
                {
                    stat_datetime: "20220528",
                    cost: "19710",
                    nonroi: "1.31",
                    nonamount: "25900",
                    roi: "1.20",
                    amount: "23632",
                    oneroi: "1.17",
                    oneamount: "23130",
                },
            ],
            // 导出中的单选框
            radio1: 3,
            // 嵌套历史栏第三个...
            centera: false,
            // 嵌套导出
            center: false,
            // 历史栏第三个
            h3Data1: [
                {
                    plan_id: "1732509290820619",
                    plan_name: "【素5】0511-1-智能放量-空标签",
                },
            ],
            // 嵌套内的 单选框
            radio: "1",
            // 内外嵌套
            outerVisible: false,
            innerVisible: false,
            // 嵌套安妮
            centerDialogVisible: false,
            // 报表时间选择框
            formtime: [
                {
                    valuet: "1",
                    label: "自定义",
                },
                {
                    valuet: "2",
                    label: "昨天",
                },
                {
                    valuet: "3",
                    label: "前天",
                },
                {
                    valuet: "4",
                    label: "年初至今",
                },
                {
                    valuet: "5",
                    label: "月初至今",
                },
                {
                    valuet: "6",
                    label: "最近3天",
                },
                {
                    valuet: "7",
                    label: "最近7天",
                },
                {
                    valuet: "8",
                    label: "最近15天",
                },
                {
                    valuet: "9",
                    label: "最近30天",
                },
            ],
            valuet: "7",

            // 历史天报表
            dayData: [],
            // 实时报表
            tableData: [],
            
            // 实时栏 页码
            // currentPage2: 1,
            // 历史栏 页码
            // currentPage4: 1,
            dialogTableVisible: false,
            gridData: [
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
            ],

            // 顶部选择框1
            props: { multiple: true },
            earlySelected: [],
            checkedForm: {
                org_names1: [],
                org_names2: [],
                org_names3: [],
                org_names4: [],
                org_names5: [],
            },
            // 对于半全选，判断全选功能的进入或是退出
            flag: false,
            // 报表 选择框1
            org_options1: [],

            // 报表 选择框2
            org_options2: [],

            // 报表 选择框3
            org_options3: [],

            // 报表 选择框4
            org_options4: [],

            // 报表 选择框5
            org_options5: [],

            // 计划栏名字
            tag:[],
            planName:'',
            tagNameList:[],
            //小时报表
            total1:0,
            currentPage:1,
            pageSize:10,
            // 历史天报表
            total2:0,
            currentPage2:1,
            pageSize2:10
        }; 
    }, 

    // 挂载
    mounted() {
        
        this.initChart()
        this.initTable()
        this.initTable1()
        this.getTag()
    },

    // 方法开始
    methods: {
        // 子节点
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            // return { rowspan: 8, colspan:1 }
            // if (columnIndex === 0) {
            //   if (rowIndex % 2 === 0) {
            //     return {
            //       rowspan: 2,
            //       colspan: 1
            //     };
            //   } else {
            //     return {
            //       rowspan: 0,
            //       colspan: 0
            //     };
            //   }
            // }
        },
        // 分天趋势计划名字
        getTagName() {
            let nerArr = []
            this.tag.forEach(item=> {
                this.checkedForm.org_names4.forEach(i=> {
                   if( item.ad_id == i){
                        nerArr.push(item.name)
                   }
                })
            })
            this.tagNameList = nerArr
            console.log(nerArr,1221)
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        // 筛选框1
        shaixuan1() {
            getscreen({
                pid: "0",
            }).then((res) => {
                console.log(res);
                this.org_options1 = res.data.list;
            });
        },
        // 筛选框2,3
        shaixuan2() {
            getnid().then((r) => {
                console.log(r);
                this.org_options2 = r.data.list;
            });
        },
        shaixuan3() {
            getnid().then((r) => {
                console.log(r);
                this.org_options3 = r.data.list;
            });
        },
        // 筛选框4
        shaixuan4() {
            getplanid().then((r) => {
                console.log(r);
                this.org_options4 = r.data.list;
            });
        },
        // 筛选框5
        shaixuan5() {
            getstatus().then((r) => {
                console.log(r);
                this.org_options5 = r.data.list;
            });
        },
        getTag() {
            getplanid().then(r=>{
                console.log(r)
                this.tag = r.data.list
            })
        },
        // 日期选择器的方法 
        changeDate() {
            console.log(this.value2)
            this.drawday();
            this.drawday2()
        },
        initChart() {
            this.currentPage = 1
            this.currentPage2 = 1
            this.drawline();
            this.drawday();
            this.drawday2();
            this.initTable()
            this.initTable1()
            this.getTagName()
        },
        // 实时小时报表
        initTable() {
             let params = {
                    advertiser_id_one:this.checkedForm.org_names1,
                    advertiser_id_two:this.checkedForm.org_names2,
                    advertiser_id_three:this.checkedForm.org_names3,
                    plan_ids:this.checkedForm.org_names4,
                    plan_status:this.checkedForm.org_names5,
                    page:this.currentPage,
                    page_size:this.pageSize
                }
            timetable(params).then((res) => {
                this.total1 = res.data.total
                var arr = res.data.table;
                if(arr.length>0){
                     this.tableData = []
                    arr.forEach(item=> {
                        item.ad.forEach(i=> {
                            i['advertiserName'] = item.advertiser.name
                        })
                         this.tableData = this.tableData.concat(item.ad)
                    })
                    // this.tableData = [...arr[0].ad,...arr[1].ad];
                }else{
                    this.tableData = arr
                }
            });
        },
        // 历史天报表
        initTable1() {
            let params ={
                advertiser_id_one:this.checkedForm.org_names1,
                advertiser_id_two:this.checkedForm.org_names2,
                advertiser_id_three:this.checkedForm.org_names3,
                plan_ids:this.checkedForm.org_names4,
                plan_status:this.checkedForm.org_names5,
                page:this.currentPage2,
                page_size:this.pageSize2,
                start_time:this.value2[0],
                end_time:this.value2[1]
            }
            htable(params).then((r) => {
                console.log(r,111);
                this.total2 = r.data.total
                var arr = r.data.table
                 if(arr.length>0){
                    this.dayData = []
                    arr.forEach(item=>{
                        item.ad.forEach(i=>{
                            i['advertiserName'] = item.advertiser.name
                        })
                        this.dayData = this.dayData.concat(item.ad)
                    })
                    // this.dayData = [...arr[0].ad,...arr[1].ad]
                }else{
                    this.dayData = arr
                }
                console.log(this.dayData,'@@@')
            });
        },
        // 历史 趋势图2
        drawday2() {
            let params = {
                    advertiser_id_one:this.checkedForm.org_names1,
                    advertiser_id_two:this.checkedForm.org_names2,
                    advertiser_id_three:this.checkedForm.org_names3,
                    plan_ids:this.checkedForm.org_names4,
                    plan_status:this.checkedForm.org_names5,
                    start_time:this.value2[0],
                    end_time:this.value2[1]
                }
            hchart(params).then((r) => {
                console.log(r);
                let day2 = this.$echarts.init(document.getElementById("day2"));
                let date = r.data.chart.date;
                let cost = r.data.chart.cost;
                let non_attribution_amount = r.data.chart.non_attribution_amount;
                let convert_amount = r.data.chart.convert_amount;
                let non_attribution_roi = r.data.chart.non_attribution_roi;
                let attribution_roi = r.data.chart.attribution_roi;
                day2.setOption({
                    tooltip: {
                        trigger: "axis",
                        axisPointer: { type: "cross" },
                    },
                    xAxis: {
                        type: "category",
                        data: date, 
                        axisLine: {
                            // lineStyle: {
                            //     type: 'dashed'   // x轴变为虚线
                            // }
                        },
                        axisTick: {
                            length: 6, // x轴向下的长度
                        },
                        axisLabel: {
                            formatter: "{value}", // x轴的每一个数值的单位
                            align: "center",
                        },
                    },
                    yAxis: [
                        //  左边y轴
                        {
                            type: "value",
                            name: "金额(元)", // 左上角单位指标
                            position: "left",
                            min: 0,
                            max: 800, // y轴坐标的值
                            // interval: 4000, // y轴刻度的间隔
                            axisLabel: {
                                formatter: "{value}元", // y轴的每一个数值的单位
                                align: "right",
                            },
                        },
                        // 右边y轴
                        {
                            type: "value",
                            name: "成交ROI", // 右上角单位指标
                            position: "right",
                            // min: 0.8,
                            // max: 1.6,
                            interval: 0.2, // y轴刻度的间隔
                            min: 0,
                            max: 1.2,
                            interval: 0.3,
                            axisLabel: {
                                align: "left", // 右对齐
                            },
                        },
                    ],
                    series: [
                        {
                            name: "花费", // 提示图的文字
                            data: cost,
                            type: "line",
                            smooth: true,
                            color: "#36d",
                        },
                        {
                            name: "不归因成交",
                            data: non_attribution_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,202,128)",
                        },
                        {
                            name: "1日归因成交",
                            data: convert_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,98,98)",
                            // color: "rgb(187,107,228)",
                        },
                        {
                            name: "不归因ROI",
                            data: non_attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "rgb(67,185,132)",
                            // color: "rgb(255,202,128)",
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                        {
                            name: "1日归因ROI",
                            data: attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "rgb(187,107,228)",
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                    ],
                    legend: {
                        icon: "rect",
                    },
                });
            });
        },

        // 历史天 趋势图
        drawday() {
            let params = {
                    advertiser_id_one:this.checkedForm.org_names1,
                    advertiser_id_two:this.checkedForm.org_names2,
                    advertiser_id_three:this.checkedForm.org_names3,
                    plan_ids:this.checkedForm.org_names4,
                    plan_status:this.checkedForm.org_names5,
                    start_time:this.value2[0],
                    end_time:this.value2[1]
                }
            hchart(params).then((r) => {
                console.log(r)
                let day = this.$echarts.init(document.getElementById("day"));
                let date = r.data.chart.date;
                let cost = r.data.chart.cost;
                let non_attribution_amount = r.data.chart.non_attribution_amount;
                let convert_amount = r.data.chart.convert_amount;
                let non_attribution_roi = r.data.chart.non_attribution_roi;
                let attribution_roi = r.data.chart.attribution_roi;
                day.setOption({
                    tooltip: {
                        trigger: "axis",
                        axisPointer: { type: "cross" },
                    },
                    xAxis: {
                        type: "category",
                        data: date,
                        axisLine: {
                            // lineStyle: {
                            //     type: 'dashed'   // x轴变为虚线
                            // }
                        },
                        axisTick: {
                            length: 6, // x轴向下的长度
                        },
                        axisLabel: {
                            formatter: "{value}", // x轴的每一个数值的单位
                            align: "center",
                        },
                    },
                    yAxis: [
                        //  左边y轴
                        {
                            type: "value",
                            name: "金额(元)", // 左上角单位指标
                            position: "left",
                            min: 0,
                            max: 800, // y轴坐标的值
                            // interval: 150, // y轴刻度的间隔
                            axisLabel: {
                                formatter: "{value}元", // y轴的每一个数值的单位
                                align: "right",
                            },
                        },
                        // 右边y轴
                        {
                            type: "value",
                            name: "成交ROI", // 右上角单位指标
                            position: "right",
                            min:0,
                            max:1.2,
                            // offset:35,  // 偏移量
                            interval: 0.3, // y轴刻度的间隔
                            axisLabel: {
                                align: "left", // 右对齐
                            },
                        },
                    ],
                    series: [
                        {
                            name: "花费", // 提示图的文字 
                            data: cost,
                            type: "line",
                            smooth: true,
                            color: "#36d",
                        },
                        {
                            name: "不归因成交",
                            data: non_attribution_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,202,128)",
                        },
                        {
                            name: "1日归因成交",
                            data: convert_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,98,98)",
                            // color: "rgb(187,107,228)",
                        },
                        {
                            name: "不归因ROI",
                            data: non_attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "rgb(67,185,132)",
                            // color: "rgb(255,202,128)",
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                        {
                            name: "1日归因ROI",
                            data: attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "rgb(187,107,228)",
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                    ],
                    legend: {
                        icon: "rect",
                    },
                });
            });
        },

        // 时间趋势图
        drawline() {
                console.log(this.checkedForm.org_names1)
                // this.timedata = r.data.chart
                let params = {
                    advertiser_id_one:this.checkedForm.org_names1,
                    advertiser_id_two:this.checkedForm.org_names2,
                    advertiser_id_three:this.checkedForm.org_names3,
                    plan_ids:this.checkedForm.org_names4,
                    plan_status:this.checkedForm.org_names5,
                }
                timechart(params).then(r=>{
                let trend = echarts.init(document.getElementById("trend"));
                let hours = r.data.chart.hours;
                let cost = r.data.chart.cost;
                let non_attribution_amount = r.data.chart.non_attribution_amount;
                let convert_amount = r.data.chart.convert_amount;
                let non_attribution_roi = r.data.chart.non_attribution_roi;
                let attribution_roi = r.data.chart.attribution_roi;
                // console.log(time6)
                trend.setOption({
                    tooltip: {
                        trigger: "axis",
                        axisPointer: { type: "cross" },
                    },
                    xAxis: {
                        type: "category",
                        data:hours,
                        
                        axisLine: {
                            lineStyle: {
                                type: "dashed", // x轴变为虚线
                            },
                        },
                        axisTick: {
                            length: 6, // x轴向下的长度
                        },
                        axisLabel: {
                            formatter: "{value}", // x轴的每一个数值的单位
                            align: "center",
                        },
                    },
                    yAxis: [
                        //  左边y轴
                        {
                            type: "value",
                            name: "金额(元)", // 左上角单位指标
                            position: "left",
                            min: 0,
                            max: 2000, // y轴坐标的值
                            // axisTick:{
                            //     length:0,
                            // }
                        },
                        // 右边y轴
                        {
                            type: "value",
                            name: "成交ROI", // 右上角单位指标
                            position: "right",
                            min: 0,
                            max: 4,
                            // offset:35,
                            interval: 1,
                            axisLabel: {
                                align: "right", // 右对齐
                            },
                        },
                    ],
                    series: [
                        {
                            name: "花费", // 提示图的文字
                            data: cost,
                            type: "line",
                            smooth: true,
                            color: "#36d",
                        },
                        {
                            name: "不归因成交",
                            data:non_attribution_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,202,128)",
                        },
                        {
                            name: "1日归因成交",
                            data:convert_amount,
                            type: "line",
                            smooth: true,
                            color: "rgb(255,98,98)",
                        },
                        {
                            name: "不归因ROI",
                            data: non_attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "rgb(67,185,132)",
                            //   type:'dashed',
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                        {
                            name: "1日归因ROI",
                            data:attribution_roi,
                            type: "line",
                            smooth: true,
                            color: "purple",
                            //   type:'dashed',
                            yAxisIndex: 1, // 让数据以y轴右侧为准
                            // lineStyle: {
                            //     type: "dotted", // 让折现变为虚线
                            // },
                        },
                    ],
                    legend: {   
                        icon: "rect",
                    },
                });
            })
        },
        

        // 选择框1
        // 筛选1号
        cardChange1(event) {
            const currentSelected = event.map((items) => items[0]);
            const differenceMakers = [
                ...currentSelected.filter((item) => !this.earlySelected.includes(item)),
                ...this.earlySelected.filter((item) => !currentSelected.includes(item)),
            ];
            if (differenceMakers.includes("")) {
                if (currentSelected.includes("")) {
                    // 全选
                    this.checkedForm.org_names1 = this.org_options1.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else {
                    // 全选取消
                    this.checkedForm.org_names1 = [];
                    this.flag = false;
                }
            } else {
                const options = this.org_options1
                    .map((item) => item["value"])
                    .filter((item) => item);
                const optionsSelectedCurrent = currentSelected.filter((item) => item);
                if (options.length === optionsSelectedCurrent.length) {
                    this.checkedForm.org_names1 = this.org_options1.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else if (options.length > optionsSelectedCurrent.length) {
                    this.checkedForm.org_names1 = this.flag
                        ? this.org_options1
                            .filter((item) => item["value"])
                            .filter((item) => item["value"] !== differenceMakers[0])
                            .map((item) => item["value"])
                        : event.map((items) => items[0]);
                    this.flag = false;
                } else {
                    this.checkedForm.org_names1 = [];
                }
            }
            this.earlySelected = this.checkedForm.org_names1;
        },

        // 选择框2
        // 筛选2号
        cardChange2(event) {
            const currentSelected = event.map((items) => items[0]);
            const differenceMakers = [
                ...currentSelected.filter((item) => !this.earlySelected.includes(item)),
                ...this.earlySelected.filter((item) => !currentSelected.includes(item)),
            ];
            if (differenceMakers.includes("")) {
                if (currentSelected.includes("")) {
                    // 全选
                    this.checkedForm.org_names2 = this.org_options2.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else {
                    // 全选取消
                    this.checkedForm.org_names2 = [];
                    this.flag = false;
                }
            } else {
                const options = this.org_options2
                    .map((item) => item["value"])
                    .filter((item) => item);
                const optionsSelectedCurrent = currentSelected.filter((item) => item);
                if (options.length === optionsSelectedCurrent.length) {
                    this.checkedForm.org_names2 = this.org_options2.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else if (options.length > optionsSelectedCurrent.length) {
                    this.checkedForm.org_names2 = this.flag
                        ? this.org_options2
                            .filter((item) => item["value"])
                            .filter((item) => item["value"] !== differenceMakers[0])
                            .map((item) => item["value"])
                        : event.map((items) => items[0]);
                    this.flag = false;
                } else {
                    this.checkedForm.org_names2 = [];
                }
            }
            this.earlySelected = this.checkedForm.org_names2;
        },
        // 筛选3号
        cardChange3(event) {
            const currentSelected = event.map((items) => items[0]);
            const differenceMakers = [
                ...currentSelected.filter((item) => !this.earlySelected.includes(item)),
                ...this.earlySelected.filter((item) => !currentSelected.includes(item)),
            ];
            if (differenceMakers.includes("")) {
                if (currentSelected.includes("")) {
                    // 全选
                    this.checkedForm.org_names3 = this.org_options3.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else {
                    // 全选取消
                    this.checkedForm.org_names3 = [];
                    this.flag = false;
                }
            } else {
                const options = this.org_options3
                    .map((item) => item["value"])
                    .filter((item) => item);
                const optionsSelectedCurrent = currentSelected.filter((item) => item);
                if (options.length === optionsSelectedCurrent.length) {
                    this.checkedForm.org_names3 = this.org_options3.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else if (options.length > optionsSelectedCurrent.length) {
                    this.checkedForm.org_names3 = this.flag
                        ? this.org_options3
                            .filter((item) => item["value"])
                            .filter((item) => item["value"] !== differenceMakers[0])
                            .map((item) => item["value"])
                        : event.map((items) => items[0]);
                    this.flag = false;
                } else {
                    this.checkedForm.org_names3 = [];
                }
            }
            this.earlySelected = this.checkedForm.org_names3;
        },
        // 筛选4号
        cardChange4(event) {
            const currentSelected = event.map((items) => items[0]);
            const differenceMakers = [
                ...currentSelected.filter((item) => !this.earlySelected.includes(item)),
                ...this.earlySelected.filter((item) => !currentSelected.includes(item)),
            ];
            if (differenceMakers.includes("")) {
                if (currentSelected.includes("")) {
                    // 全选
                    this.checkedForm.org_names4 = this.org_options4.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else {
                    // 全选取消
                    this.checkedForm.org_names4 = [];
                    this.flag = false;
                }
            } else {
                const options = this.org_options4
                    .map((item) => item["value"])
                    .filter((item) => item);
                const optionsSelectedCurrent = currentSelected.filter((item) => item);
                if (options.length === optionsSelectedCurrent.length) {
                    this.checkedForm.org_names4 = this.org_options4.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else if (options.length > optionsSelectedCurrent.length) {
                    this.checkedForm.org_names4 = this.flag
                        ? this.org_options4
                            .filter((item) => item["value"])
                            .filter((item) => item["value"] !== differenceMakers[0])
                            .map((item) => item["value"])
                        : event.map((items) => items[0]);
                    this.flag = false;
                } else {
                    this.checkedForm.org_names4 = [];
                }
            }
            this.earlySelected = this.checkedForm.org_names4;
        },
        // 筛选5号
        cardChange5(event) {
            const currentSelected = event.map((items) => items[0]);
            const differenceMakers = [
                ...currentSelected.filter((item) => !this.earlySelected.includes(item)),
                ...this.earlySelected.filter((item) => !currentSelected.includes(item)),
            ];
            if (differenceMakers.includes("")) {
                if (currentSelected.includes("")) {
                    // 全选
                    this.checkedForm.org_names5 = this.org_options5.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else {
                    // 全选取消
                    this.checkedForm.org_names5 = [];
                    this.flag = false;
                }
            } else {
                const options = this.org_options5
                    .map((item) => item["value"])
                    .filter((item) => item);
                const optionsSelectedCurrent = currentSelected.filter((item) => item);
                if (options.length === optionsSelectedCurrent.length) {
                    this.checkedForm.org_names5 = this.org_options5.map(
                        (item) => item["value"]
                    );
                    this.flag = true;
                } else if (options.length > optionsSelectedCurrent.length) {
                    this.checkedForm.org_names5 = this.flag
                        ? this.org_options5
                            .filter((item) => item["value"])
                            .filter((item) => item["value"] !== differenceMakers[0])
                            .map((item) => item["value"])
                        : event.map((items) => items[0]);
                    this.flag = false;
                } else {
                    this.checkedForm.org_names5 = [];
                }
            }
            this.earlySelected = this.checkedForm.org_names5;
        },

        // 实时小时报表 总计的方法
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "总价";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += " 元";
                } else {
                    sums[index] = "N/A";
                }
            });

            return sums;
        },

         // 页码的方法 (历史报表)
        handleSizeChange2(val) {
            this.pageSize2 = val
            this.initTable1()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange2(val) {
            this.currentPage2 = val
            this.initTable1()
            console.log(`当前页: ${val}`);
        },
         // 页码的方法 (实时报表)
        handleSizeChange1(val) {
            this.pageSize = val
            this.initTable()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange1(val) {
            this.currentPage = val
            this.initTable()
            console.log(`当前页: ${val}`);
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
    }, // 方法结束
};
</script>

<style scoped>
/* // 主体内容图标 */
.analyse .nav-icon {
    display: flex;
    align-items: center;
}
.analyse .el-icon-s-fold {
    font-size: 3vh;
}
.analyse .nav-icon span {
    font-size: 2vh;
    padding-left: 1vh;
}
/* // 整体背景 */
.analyse .bg {
    background-color: #fff;
}

/* // 导出按钮 */
.analyse .excel-btn {
    position: absolute;
    right: 0;
    top: 50%;
    overflow: hidden;
}

.analyse .search-part {
    padding-top: 2vh;
    margin-bottom: 1vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.analyse .form {
    padding-top: 2vh;
    padding-left: 1vh;
    border: 6px solid rgb(240, 242, 245);
}
.analyse .f1 {
    background-color: #fff;
    margin-top: 2vh;
}

.analyse .form {
    margin-top: 2vh;
}
.analyse .history1 {
    text-align: left;
}

.analyse .form .form-img {
    border: 1px solid rgb(220, 221, 223);
    background-color: #fff;
    width: 156.5vh;
    height: 50vh;
    margin: 1vh 2vh 0 2vh;
}
.analyse .form-list{
    margin-bottom: 2vh;
}
.analyse .form .form-list1 {
    border: 1px solid rgb(220, 221, 223);
    background-color: #fff;
    width: 156.5vh;
    height: 70vh;
    margin: 0 2vh 2vh 2vh;
}

.analyse .form .day-form {
    border: 1px solid rgb(220, 221, 223);
    background-color: #fff;
    width: 156.5vh;
    height: 78vh;
    margin: 1vh 2vh 2vh 2vh;
}

.analyse .dayform-title {
    display: flex;
    align-items: center;
}
.analyse .form .text {
    padding-bottom: 2vh;
    flex: 1;
}

.analyse .form .text {
    text-align: left;
    margin: 2vh 0 0 2vh;
}

.analyse .day-form .pages {
    text-align: right;
    margin-top: 1vh;
}

.analyse .day-plan {
    border: 1px solid rgb(220, 221, 223);
    background-color: #fff;
    width: 70vh;
    /* overflow: hidden;
    flex-wrap: nowrap;
    text-overflow: ellipsis; */
    height: 5vh;
    margin-left: 2vh;
    /* margin-top: 5vh; */
    display: flex;
    align-items: center;
}
.analyse .day-plan .plan-text {
    display: flex;
    align-items: center;
    /* flex: 1; */
}
.analyse .day-title {
    display: flex;
}

.analyse .day-img .day-title .text {
    flex: 1;
}

.analyse .day-img .title2 {
    display: flex;
}
.analyse .day-img .title2 .title2-1 {
    flex: 1;
}

.analyse .day-img {
    border: 1px solid rgb(220, 221, 223);
    background-color: #fff;
    height: 50vh;
    width: 156.5vh;
    margin: 2vh;
}
.analyse #day2 {
    padding: 4vh;
}

.analyse .t-title {
    display: flex;
}

.analyse .time-title {
    display: flex;
    align-items: center;
}

.analyse .page2 {
    text-align: right;
    margin-top: 2vh;
}

.analyse .el-dialog_body {
    background-color: rgb(240, 242, 250);
}

/* /deep/.el-tag.el-tag {
    margin: 2px 0 0 13px;
} */
.analyse .sx3,
.sx4,
.sx5 {
    width: 250px;
}
.analyse .sx2 {
    width: 250px;
}


/* 选择框内的样式 */
/deep/.el-select__tags {
   flex-wrap: nowrap;
}
/deep/.f1 .el-input--suffix .el-input__inner{
    width: 250px;
}
/deep/.el-tag.el-tag{
    /* margin: auto; */
    margin: 0 5px;
}
/* /deep/.search-part>.el-select .el-tag{
    max-width: auto;
    display: inline-block;
} */
/* /deep/.el-select__tags-text{
    overflow: visible;
    text-overflow: ellipsis;
} */

</style>