<template>
  <el-container class="index-con" >
      <el-aside :class="showclass" >
        <common-aside></common-aside>
      </el-aside>
    <el-container class="main-con">
      <el-header class="index-header">
        <nav-common></nav-common>
      </el-header>
      <el-main class="index-main pandding">
        <router-view :key="$route.fullPath"></router-view> 
      </el-main>
    </el-container>
  </el-container>


<!-- <el-container class="index-con">
  <el-header class="index-header">
    <nav-common></nav-common>
  </el-header>
  <el-container>
    <el-aside :class="showclass" >
      <common-aside></common-aside>
    </el-aside>
    <el-container>
      <el-main class="index-main pandding">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</el-container> -->




</template>
<script>
// 导入组件
import CommonAside from '@/components/CommonAside.vue'
import NavCommon from '@/components/NavCommon.vue'
import {changeUser} from '@/request/api'
export default {
  name: 'index',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false,
      fullscreenLoading:false
    }
  },
  // 注册组件
  components: {
    CommonAside,
    NavCommon
  },

  created() {
    
    if (!sessionStorage.getItem('Authorization') && !localStorage.getItem('Authorization')) {
      this.$router.replace({path: "/login"})
      return;
    }
    
    // 监听
    this.$root.$on('toggle', value => {
      if (value) {
        setTimeout( ()=>{
          this.showclass = 'asideshow'
        })
      } else {
        setTimeout(() => {
          this.showclass = 'aside'
        }, 280)
      }
    })
  },
  watch:{
    $route(to,from){
      if(to.path == '/'){
        // console.log(to.path,'to.path')
        let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
        if(userinfo.is_admin == 1){
          // console.log(userinfo.is_admin,'userinfo.is_admin')
          this.$router.push({path:'DataKanban/datakanban'})
        }
      }
    }
  },

}
</script>
<style >
.showclass{
  z-index: 999;
}
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #334157;
  margin: 0px;
  transition: 0.4s;
  /* transform: translate(100px,200px);
  z-index: 9999; */
}
.asideshow {
  width: 200px !important;
  /* height: 100%; */
  /* background-color: #334157; */
  margin: 0px;
  transition: 0.4s;
  /* transform: rotate(720deg); */
}
.index-header{
  /* background-color: rgb(245,246,250); */
  padding-left: 0px ;
  padding-right: 0px;
}
.index-main {
  padding: 18px;
  background-color: rgb(245,246,250);
  /* border-left: 2px solid #333; */
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-corner {
    background: #fff;
    border-radius: 4px;
}
::-webkit-scrollbar-thumb {
    background: #e0e5f6;
    border-radius: 4px;
}
::-webkit-scrollbar-track {
    background: #fff;
}
@media screen and (max-width: 1200px) {
  .index-main {
    padding: 1px;
  }
  .pandding{
    padding: 2px;
  }
}
</style>
