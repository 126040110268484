<template>
    <div class="company" v-loading="loading">
      <!-- 搜索部分 -->
      <div class="head_search_box">
        <div class="head_search">
          <el-input
            style="width: 300px"
            placeholder="请输入账号"
            v-model="keyword"
            clearable
            @keydown.enter.native="search"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
        <div class="addgs">
          <el-button type="primary" @click="openDialog">添加账号</el-button>
          <el-dialog
            title="添加账号"
            :close-on-click-modal="close_model"
            :visible.sync="drawer"
            center
            append-to-body
            width="30%"
          >
            <el-form :model="accountList" :label-width="addLabelWidth">
              <el-form-item style="margin-bottom: 10px; width: 25vw" label="账号">
                <el-input
                  style="width: 90%"
                  v-model="accountList.name"
                  autocomplete="off"
                  placeholder="请输入账号"
                ></el-input>
              </el-form-item>
              <el-form-item style="margin-bottom: 10px; width: 25vw" label="密码">
                <el-input
                  style="width: 90%"
                  type="password"
                  v-model="accountList.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item
              style="margin-bottom: 10px; width: 25vw"
              label="所属代理商"
            >
              <el-select
                v-model="accountValue"
                placeholder="请选择所属代理商"
                style="width: 90%"
              >
                <el-option
                  v-for="item in accountOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
              <el-form-item label="状态" :label-width="addLabelWidth">
                <el-radio v-model="accountList.status" label="1">启用</el-radio>
                <el-radio v-model="accountList.status" label="0">禁用</el-radio>
              </el-form-item>
              <el-form-item style="width: 25vw" label="备注">
                <el-input
                  type="textarea"
                  v-model="accountList.memo"
                  autocomplete="off"
                  placeholder="备注"
                  style="width: 90%"
                ></el-input>
              </el-form-item>
            </el-form>
  
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="add">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="f2">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="account" label="账号"> </el-table-column>
          <el-table-column prop="factor_name" label="所属代理商"> </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            :formatter="statusFormatter1"
            :filters="[
              { text: '启用', value: 1 },
              { text: '禁用', value: 0 },
            ]"
            :filter-method="filterTag"
          >
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.status == 1 ? 'success' : 'danger'"
                disable-transitions
                >{{ getstatus(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="add_time" label="添加时间"> </el-table-column>
          <el-table-column prop="update_time" label="修改时间"> </el-table-column>
          <el-table-column prop="memo" label="备注"> </el-table-column>
          <el-table-column prop="operation" label="操作" fixed="right">
            <template slot-scope="scope" style="display: flex">
         
              <el-button type="text" size="small" @click="openUpdate(scope.row)"
                >修改</el-button
              >
              <el-dialog
                title="修改信息"
                :close-on-click-modal="close_model"
                :visible.sync="dialogFormVisible"
                center
                append-to-body
                width="30%"
              >
                <el-form :model="updateform" :label-width="formLabelWidth">
                  <el-form-item
                    style="margin-bottom: 10px; width: 25vw"
                    label="密码"
                  >
                    <el-input
                      v-model="updateform.password"
                      type="password"
                      autocomplete="off"
                      placeholder="请输入更改的密码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="状态"
                    style="margin-bottom: 10px; width: 25vw"
                  >
                    <el-radio v-model="updateform.status" label="1">启用</el-radio>
                    <el-radio v-model="updateform.status" label="0">禁用</el-radio>
                  </el-form-item>
                  <el-form-item style="width: 25vw" label="备注">
                    <el-input
                      type="textarea"
                      v-model="updateform.memo"
                      autocomplete="off"
                      placeholder="备注"
                      style="width: 90%"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="updatePassword"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
              <el-button style="margin-left:10px" @click="hand1(scope.row)" type="text" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(totalNum) ? Number(totalNum) : 0"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    addAgentAccount,
    delAgentAccount,
    editAgentAccount, 
    agentAccountList,
    getFactor
  } from "@/request/api";
  import $utils from "@/js/utils";
  export default {
    data() {
      return {
        loading: false,
        updateform:{
           password:"",
           status: "1",
           memo:'',
           tb_jump_type:"app",
        },
        dialogFormVisible: false,
        accountOptions: [],
        accountValue: "",
        tableData: [
          {
            account_name: "王小虎",
            status: "启用",
          },
        ],
        drawer: false,
        close_model: false,
        curPage: 1,
        totalNum: 0,
        pageSize: 10,
        keyword: "",
        accountList: {
          name: "",
          status: "1",
          memo: "",
          
        },
        addLabelWidth: "120px",
        formLabelWidth: "45px",
        form: {
          name: "",
          status: "1",
          memo: "",
        },
        advertisingId: "",
        searchname: "",
        userid:"",
        accountId:'',
      };
    },
    watch: {},
    computed: {},
    created() {},
  
    mounted() {
      this.getAllAccountmanage();
      // this.getAdvertisingList();
    },
  
    methods: {
      //获取代理商账户列表
      getAllAccountmanage() {
        this.loading = true;
        // console.log(this.$route.query.name, "this.$route.query.name");
        if (this.$route.query.name) {
          this.keyword = this.$route.query.name;
          this.searchname = this.$route.query.name;
          this.accountId = this.$route.query.id;
        }
        agentAccountList({
          page: this.curPage,
          pageSize: this.pageSize,
          name: this.searchname,
          id:Number(this.accountId)
        }).then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.totalNum = res.total;
            this.tableData = res.data;
            this.tableData.forEach((ele) => {
              if(ele.add_time == ''){
                ele.add_time = ''
              }else{
                ele.add_time = $utils.format(new Date(ele.add_time), "yyyy-MM-dd hh:mm:ss"); 
              }
              
              if(ele.update_time == ''){
                ele.update_time = ''
              }else{
                ele.update_time = $utils.format(new Date(ele.update_time), "yyyy-MM-dd hh:mm:ss"); 
              } 
            })
            console.log(res, "res");
          } else {
            this.$message.error(res.msg);
          }
          // console.log(res,'res')
  
          // console.log(this.tableData,'this.tableData.update_time')
        });
      },
      //搜索代理商账户
      search() {
        this.loading = true;
        this.curPage = 1;
        console.log(this.accountId,'this.accountId')
        agentAccountList({
          page: this.curPage,
          pageSize: this.pageSize,
          name: this.keyword,
        }).then((res) => {
          this.loading = false;
          this.tableData = res.data;
        });
      },
      //打开添加弹窗
      openDialog() {
          this.drawer = true;
          getFactor({}).then(r=>{
            // console.log(r,'r')
            this.accountOptions = r.data;
          })
      },
      //添加代理商
      add() {
        if (!this.accountList.name || !this.accountList.password) {
          this.$message({
            message: "请输入名称或密码",
            type: "worning",
          });
          return;
        }
        if (!this.accountValue) {
        this.$message({
          message: "请选择广告代理商",
          type: "worning",
        });
        return;
      }
        addAgentAccount({
          username: this.accountList.name,
          password: this.accountList.password,
          id_factor:this.accountValue,
          memo: this.accountList.memo,
          status: this.accountList.status,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.getAllAccountmanage();
            this.drawer = false;
          } else {
            this.$message({
              message: "添加失败",
              type: "error",
            });
          }
          // console.log(res,'res')
        });
      },
  
      
      //点击删除，删除数据
      hand1(val) {
        
        this.$confirm("您确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          delAgentAccount({
            id: val.id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getAllAccountmanage();
            } else {
              this.$message({
                message: "删除失败",
                type: "error",
              });
            }
          });
        });
        // console.log(val,'获取到的数据')
      },
      //打开修改界面
      openUpdate(row){
        // console.log(row,'122121')
        this.userid = row.id
        this.dialogFormVisible = true;
     
      },
      //修改密码
      updatePassword(){
        // console.log(this.updateform,'this.updateform')
        editAgentAccount({
          id:this.userid,
          password:this.updateform.password,
          status:this.updateform.status,
          memo:this.updateform.memo
        }).then(res=>{
          if (res.code == 1) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.getAllAccountmanage()
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
              });
              this.dialogFormVisible = false;
            }
        })
      },
      //跳转看板页面
      godatakanban(row) {
        // console.log(row);
        if(row.status == "停用"){
          this.$message({
                message: "该账号已停用",
                type: "error",
              });
              return
        }
        // 暂无接口
        let info = localStorage.getItem('corpid');
        if(info){
          this.$router.replace({path: "/report/Report",query: { s_uid: row.id, s_account: row.account },})
          window.location.reload();
        }else{
          const newrouter = this.$router.resolve({
          path: "/report/Report",
          query: { s_uid: row.id, s_account: row.account },
        });
        window.open(newrouter.href, "_blank");
        // console.log(newrouter.href)
        }
        
      },
      //改变每条页数
      handleSizeChange(val) {
        this.pageSize = val;
        // console.log('this.pageSize', this.pageSize)
        this.getAllAccountmanage();
      },
      //改变页码
      handleCurrentChange(val) {
        // console.log('current page', val);
        this.curPage = val;
        this.getAllAccountmanage();
      },
      filterTag(value, row) {
        return row.status === value;
      },
      statusFormatter1(row, column) {
        const status = row.status;
        if (status == 1) {
          return "启用";
        } else {
          return "禁用";
        }
      },
      getstatus(status) {
        // console.log(status, "status");
        if (status == 1) {
          return "启用";
        }else{
          return "禁用";
        }
        // return status;
      },
        isSwitch(row){
          var isswitch = row.is_switch == true ? "1" : "0"
            switchUser({
                user_id:row.id,
                is_switch:isswitch
            }).then(res=>{
                if (res.code == 1) {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "修改失败",
                        type: "error",
                    });
                }
            })
        }
    },
  };
  </script>
   <style scoped>
  .company .f2 {
    padding: 15px;
    background-color: #fff;
  }
  .company .page {
    text-align: right;
    margin: 15px 2px 0 0;
  }
  @media screen and (max-width: 1200px) {
    .head_search_box{
      flex-direction: column;
    }
    .addgs{
      margin-top: 10px;
    }
  }
  
  </style>