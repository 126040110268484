 <!-- 活动监控实时趋势 --> 
<template>
  <div>
    <div class="pandding flex_between">
      <div class="flex">实时趋势</div>
      <div class="flex">
        <el-select @change="hamndlevalue1" v-model="defaultvalue1" placeholder="请选择">
          <el-option
            v-for="item in options_trend1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>

        <div class="change">对比</div>
        <el-select @change="hamndlevalue1" v-model="defaultvalue2" placeholder="请选择">
          <el-option
            v-for="item in options_trend2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="echart" :id="'mychart_' + aa" :style="myChartStyle0"></div>
  </div>
</template>
<script>
import { realtimetrend, timetrend } from "@/request/api.js"; // 营销活动监控实时趋势
import * as echarts from "echarts";
export default {
  props: {
    aa: {
      type: String,
      default: () => {}
    }
  },

  data() {
    return {
      chart: null,
      valueactive: true,
      options_trend: [
        {
          value: "cost",
          label: "花费"
        },
        {
          value: "showNum",
          label: "展现量"
        },
        {
          value: "click",
          label: "点击量"
        },
        {
          value: "ctr",
          label: "点击率"
        },
        {
          value: "convertNum",
          label: "转化数"
        },
        {
          value: "convertRate",
          label: "转化率"
        },
        {
          value: "convertCost",
          label: "转化成本"
        },
        {
          value: "convertAmount",
          label: "转化金额"
        },
        {
          value: "payAmountRoi",
          label: "成交ROI"
        },
        {
          value: "payAmount",
          label: "成交金额"
        }
      ],
      defaultvalue1: "cost", //默认第一个
      defaultvalue2: "showNum", //
      ymax0: 2000,
      taskmax0: 1000,

      myChart0: null,
      xData0: [], //横坐标
      yData0: [], //花费数据
      legenddata0: ["花费", "展现量"],
      taskData0: [], //展现量数据
      myChartStyle0: { float: "left", width: "100%", height: "400px" }, //图表样式
      reqParam0: {
        keyType: "account", //account : 账户 , planGroup:广告组 , plan:计划
        keyId: this.aa, //账户ID
        costMin: "",
        costMax: ""
      } //请求参数
    };
  },
  created() {},
  mounted() {
    this.Realtimetrend();
  },
  computed: {
    options_trend1() {
      return this.options_trend.filter(ele => ele.value != this.defaultvalue2);
    },
    options_trend2() {
      return this.options_trend.filter(ele => ele.value != this.defaultvalue1);
    }
  },
  methods: {
    handleSetnum(data) {
      let obj = Math.max(...data);
      obj = Math.ceil(obj);
      let str = obj.toString();
      str = str.charAt(str.length - 1);
      const str2 = 10 - str;
      obj = obj + Number(str2);
      return obj;
    },
    // ctr
    handleSetdata(data) {
      this.xData0 = [];
      this.yData0 = [];
      this.taskData0 = [];
      if (data) {
        data.forEach(item => {
          this.xData0.push(item.time); //横坐标
          if (this.defaultvalue1 == "cost" && this.defaultvalue2 == "showNum") {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.showNum); //展现量数据
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.click); //点击量数据
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.ctr); //点击率数据
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "cost" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.cost); //花费数据
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "showNum" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.showNum); //展现量
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "click" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.click); //点击量
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "ctr" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.ctr); //点击率
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.convertNum); //转化数
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "convertNum" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "convertRate" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.convertRate); //转化率
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "convertCost" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.convertCost); //转化成本
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.convertCost); //转化金额
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.payAmountRoi); //成交ROI
          } else if (
            this.defaultvalue1 == "convertAmount" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.convertAmount); //转化金额
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "payAmountRoi" &&
            this.defaultvalue2 == "payAmount"
          ) {
            this.yData0.push(item.payAmountRoi); //成交ROI
            this.taskData0.push(item.payAmount); //成交金额
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "cost"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.cost); //花费
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "showNum"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.showNum); //展现量
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "click"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.click); //点击量
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "ctr"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.ctr); //点击率
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "convertNum"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.convertNum); //转化数
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "convertRate"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.convertRate); //转化率
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "convertCost"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.convertCost); //转化成本
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "convertAmount"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.convertAmount); //转化金额
          } else if (
            this.defaultvalue1 == "payAmount" &&
            this.defaultvalue2 == "payAmountRoi"
          ) {
            this.yData0.push(item.payAmount); //成交金额
            this.taskData0.push(item.payAmountRoi); //成交ROI
          }
        });
      }

      this.ymax0 = this.handleSetnum(this.yData0);
      this.taskmax0 = this.handleSetnum(this.taskData0);
      // console.log(this.ymax, this.taskmax);
      this.initEcharts();
    },
    
    async getData() {
      const result = await timetrend(this.reqParam0);
      if (!result) return;
      const { data } = result;

      this.handleSetdata(data);
      // console.log(data);
    },
    // 默认渲染
    Realtimetrend() {
      this.getData();
    },
    //   "#025DF4", "#E695DD",
    initEcharts() {
      const option = {
        color: [
          {
            type: "linear",
            // x2=1,y=0,柱子的颜色在水平方向渐变
            x2: 0,
            y2: 1,
            colorStops: [
              // 0%处的颜色
              {
                offset: 0,
                color: "#357CF0"
              },
              // 50%处的颜色
              {
                offset: 0.5,
                color: "#BCD4FC"
              },
              // 100%处的颜色
              {
                offset: 1,
                color: "#BCD4FC"
              }
            ],
            global: false // 缺省为 false
          },
          {
            type: "linear",
            // x2=1,y=0,柱子的颜色在水平方向渐变
            x2: 0,
            y2: 1,
            colorStops: [
              // 0%处的颜色
              {
                offset: 0,
                color: "#E593DC"
              },
              // 50%处的颜色
              {
                offset: 0.5,
                color: "#F6DCF3"
              },
              // 100%处的颜色
              {
                offset: 1,
                color: "#F6DCF3"
              }
            ],
            global: false // 缺省为 false
          }
        ], //更改填充的颜色
        tooltip: {
          show: true,
          trigger: "axis"
        },
        xAxis: {
          data: this.xData0
        },
        legend: {
          // 图例
          data: this.legenddata0,
          bottom: "0%"
        },
        yAxis: [
          {
            name: this.legenddata0[0],
            min: 0,
            max: this.ymax0,
            // interval: 3000
            position: "left"
          },
          {
            name: this.legenddata0[1],
            min: 0,
            max: this.taskmax0,
            // interval: 30,
            position: "right"
          }
        ],
        series: [
          {
            areaStyle: {}, //填充
            name: this.legenddata0[0],
            data: this.yData0,
            type: "line", // 类型设置为折线图
            smooth: true, //是否平滑曲线
            label: {
              show: false,
              position: "top",
              textStyle: {
                fontSize: 16
              }
            }
          },
          {
            areaStyle: {}, //填充
            name: this.legenddata0[1],
            data: this.taskData0,
            type: "line", // 类型设置为折线图
            smooth: true,
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            },
            yAxisIndex: 1
          }
        ]
      };
      if (!this.myChart0) {
        //   // debugger;
        this.myChart0 = echarts.init(
          document.getElementById("mychart_" + this.aa)
        );
      }
      // this.myChart0.clear();
      this.myChart0.setOption(option);

      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart0.resize();
      });
    },
    // 点击下拉框
    hamndlevalue1() {
      if (this.defaultvalue1 == this.defaultvalue2) return;
      const obj1 = this.options_trend1.filter(
        item => item.value == this.defaultvalue1
      );
      const obj2 = this.options_trend2.filter(
        item => item.value == this.defaultvalue2
      );

      this.legenddata0 = [obj1[0].label, obj2[0].label];
      this.reqParam0.contrastA = this.defaultvalue1;
      // console.log(this.reqParam0.contrastA);
      this.reqParam0.contrastB = this.defaultvalue2;
      this.getData();
    }
  }
};
</script>

<style lang="scss"  >
</style>