<template>
    <div class="tb-goods">

        <!-- 一楼：搜索栏 -->
        <div class="f1">
            <!-- 搜索框 -->
            <div class="f1-search">
                <el-select
                 @change="getGoodsList"
                 v-model="platform" 
                 filterable 
                 placeholder="请选择">
                     <el-option
                        key=""
                        label="全部平台"
                        value=""
                    >
                    </el-option>
                    <el-option
                        v-for="item in option2"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-select
                 @change="getGoodsList"
                 v-model="val" 
                 filterable 
                 placeholder="请选择">
                    <el-option
                        key=""
                        label="全部"
                        value="">
                    </el-option>
                    <el-option
                        v-for="item in option"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-input
                    placeholder="请输入商品名称"
                    v-model="keyword"
                    clearable
                    @keydown.enter.native="searchGoods"
                >
                    <template slot="append"><el-button type="primary" @click="searchGoods">搜索</el-button></template>
                </el-input>
            </div>
            <!-- 按钮：添加商品 -->
            <div>
                <el-button type="primary" @click="addGoods" style="margin-left: 16px;">添加商品</el-button>
                <el-dialog
                    :title = "drawer_title"
                    show-close
                    width='30%'
                    :visible.sync="drawer"
                    :modal="true"
                    :close-on-click-modal="false"
                    >
                    <!-- 输入框 -->
                    <div class="drawer-main">
                        <el-form ref="form" :model="saveData" :rules="rules" label-position="right" label-width="88px">
                            <el-form-item label="平台" style="width: 25vw">
                                <div style="text-align:left;">
                                    <el-radio-group v-model="saveData.platform">
                                        <el-radio label="1">巨量引擎</el-radio>
                                        <el-radio label="2">腾讯广告</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                            <el-form-item label="渠道" style="width: 25vw">
                                <div style="text-align:left;">
                                    <el-radio-group v-model="saveData.channel">
                                        <el-radio label="1">淘宝</el-radio>
                                        <el-radio label="2">京东</el-radio>
                                        <el-radio label="3">拼多多</el-radio>
                                    </el-radio-group>
                                </div>
                            </el-form-item>
                            <el-form-item label="活动" style="width: 25vw">
                                <el-select v-model="saveData.activity_id" placeholder="请选择活动" style="width: 100%;">
                                    <el-option
                                        v-for="item in activitylist"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <!-- </div> -->
                            <el-form-item label="公司" style="width: 25vw">
                                <el-select v-model="saveData.company_id" placeholder="请选择公司" style="width: 100%;">
                                    <el-option
                                        v-for="item in companylist"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="商品ID" prop="item_id" style="width: 25vw">
                                <el-input v-model="saveData.item_id"><el-button type="primary" slot="append" @click="getGoodsInfo">查询</el-button></el-input>
                            </el-form-item>
                            <el-form-item label="商品名称" prop="item_name" style="width: 25vw">
                                <el-input 
                                type="textarea"
                                v-model="saveData.item_name" 
                                aria-required="required"
                                :readonly="item_name_readonley"></el-input>
                            </el-form-item>
                            <el-form-item label="旺旺昵称" prop="wangwang_name" style="width: 25vw" v-if="saveData.channel==1">
                                <el-input v-model="saveData.wangwang_name"></el-input>
                            </el-form-item>
                            <el-form-item label="sessionkey" prop="sessionkey" style="width: 25vw" v-if="saveData.channel==1">
                                <el-input v-model="saveData.sessionkey"></el-input>
                            </el-form-item>
                            <el-form-item label="通天塔链接" prop="babel_url" style="width: 25vw" v-if="saveData.channel==2">
                                <el-input v-model="saveData.babel_url"></el-input>
                            </el-form-item>
                            <el-form-item label="商品报备到期时间" style="width: 25vw" v-if="saveData.channel==3">
                                <el-date-picker
                                        v-model="saveData.report_etime"
                                        type="datetime"
                                        align="left"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="备注" style="width: 25vw">
                                <el-input 
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 3}"
                                v-model="saveData.remarks"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" style="width: 25vw;">
                                <div style="text-align:left;">
                                    <el-radio v-model="saveData.status" label="1">启用</el-radio>
                                    <el-radio v-model="saveData.status" label="0">禁用</el-radio>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="empty"></div>
                    <div style="text-align:center;margin: 2vh 0 7vh 0">
                        <!-- <el-button @click="cannelForm()">取 消</el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
                        <el-button type="primary" @click="saveGoods" :disabled="save_button" style="width:150px">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>

        <!-- 二楼：表格内容 -->
        <div class="f2" v-loading="loading">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                    prop="activity_name"
                    label="活动名"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="company_name"
                    label="公司"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="渠道"
                    align="center"
                    header-align="left"
                >
                    <template slot-scope="scope">
                        {{getChannel(scope.row.channel)}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="platform"
                    label="平台"
                    width="100"
                    :formatter="statusFormatter1"
                    >
                </el-table-column>
                <el-table-column
                    prop="item_id"
                    label="商品ID"
                    width="150">
                </el-table-column>
                <el-table-column
                v-if="id_role != 3"
                        label="返利比例"
                        width="80" >
                    <template slot-scope="scope">
                        <span>{{scope.row.commission_rate}}%</span>
                    </template>
                </el-table-column>
                <el-table-column
                v-if="id_role != 3"
                    prop="item_name"
                    label="商品名字"
                    width="310"
                    show-overflow-tooltip
                  >
                </el-table-column>
                <el-table-column
                    label="落地页"
                    width="80">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="primary" size="mini" >查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    label="1跳地址"
                    width="150">
                    <template slot-scope="scope">
                        <el-button @click="copyOneJumpButton(scope.row)" type="primary" size="mini">详情</el-button>
                        <el-button @click="createOneUrl(scope.row)" type="primary" size="mini" v-if="scope.row.one_jump_url!=''">重置</el-button>
                    </template>
                </el-table-column>
                <el-table-column
                        label="拼多多商品报备到期时间"
                        width="150">
                    <template slot-scope="scope">
                        <el-text :style="scope.row.report_etime_warning == true ? 'color:red' : ''">{{scope.row.report_etime}}</el-text>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品详情"
                    width="80">
                    <template slot-scope="scope">
                        <a style="margin-left: 10px;" :href="scope.row.preview_url" target="_blank">预览</a>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="80"
                    :filters="[{ text: '启用', value: 1 }, { text: '禁用', value: 0 }]"
                    :filter-method="filterTag"
                    filter-placement="bottom-end"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.status == 1 ? 'success' : 'danger'"
                            disable-transitions>{{getStatus(scope.row.status)}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    width="100"
                    >
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    width="115"
                    >
                    <template slot-scope="scope" style="display:flex;justify-content:space-between;">
                        <el-button @click="editGoods(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="deleteGoods(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
            <!-- 版本选择弹框-->
            <el-dialog
                title="详情"
                :visible.sync="dialogVisible"
                width="50%"
                center
                :before-close="handleClose">
                <span>
                    <template>
                      <table class="notesList_table">
            <thead>
                <tr>
                    <th style="width: 20%">名称</th>
                    <th>链接</th>
                    <th style="width: 10%">操作</th>
                </tr>
            </thead>
            <tbody>
                <!--<tr>
                    <td>小程序一跳链接</td>
                    <td><span>{{copyRow.one_jump_url}}</span></td>
                    <td>
                        <el-button @click="copyOneJump(copyRow.one_jump_url)" v-if="copyRow.one_jump_url!=''" type="primary" size="mini">复制</el-button>
                        <el-button  @click="createOneUrl(copyRow,1)" type="primary" size="mini" v-else>生成</el-button>
                    </td>
                </tr>-->
                <tr>
                    <td>小程序一跳2.0链接</td>
                    <td><span>{{copyRow.one_jump_url_v2}}</span></td>
                    <td>
                        <el-button @click="copyOneJump(copyRow.one_jump_url_v2)"  v-if="copyRow.one_jump_url_v2!=''" type="primary" size="mini">复制</el-button>
                        <el-button  @click="createOneUrl(copyRow,1)" type="primary" size="mini" v-else>生成</el-button>
                    </td>
                </tr>
                <tr>
                    <td>星量一跳链接</td>
                    <td><span>{{copyRow.one_jump_url_v3}}</span></td>
                    <td>
                        <el-button @click="copyOneJump(copyRow.one_jump_url_v3)" type="primary" size="mini"  v-if="copyRow.one_jump_url_v3!=''">复制</el-button>
                        <el-button  @click="createOneUrl(copyRow,3)" type="primary" size="mini" v-else>生成</el-button>
                    </td>
                </tr>
                <tr>
                    <td>星量2.0监测链接</td>
                    <td><span>{{copyRow.track_url_v2}}</span></td>
                    <td v-if="copyRow.track_url_v2 != null && copyRow.track_url_v2!= '' && copyRow.track_url_v2!='null'"><el-button @click="copyOneJump(copyRow.track_url_v2)" type="primary" size="mini">复制</el-button></td>
                    <td v-else><el-button @click="createTrackUrl()" type="primary" size="mini" >生成</el-button></td>
                </tr>
                <tr>
                        <td>详情页</td>
                        <td><span>{{copyRow.one_landing_url}}</span></td>
                        <td><el-button @click="copyOneJump(copyRow.one_landing_url)" type="primary" size="mini">复制</el-button></td>
                </tr>
                <!--<tr v-if="copyRow.babel_url != ''">
                    <td>通天塔一跳链接</td>
                    <td><span>{{copyRow.one_jump_babel_url}}</span></td>
                    <td>
                        <el-button @click="copyOneJump(copyRow.one_jump_babel_url)" v-if="copyRow.one_jump_babel_url!=''" type="primary" size="mini">复制</el-button>
                        <el-button  @click="createOneUrl(copyRow,4)" type="primary" size="mini" v-else>生成</el-button>
                    </td>
                </tr>-->
                <tr v-if="copyRow.babel_url != ''">
                    <td>通天塔一跳2.0链接</td>
                    <td><span>{{copyRow.one_jump_babel_url_v2}}</span></td>
                    <td>
                        <el-button @click="copyOneJump(copyRow.one_jump_babel_url_v2)"  v-if="copyRow.one_jump_babel_url_v2!=''" type="primary" size="mini">复制</el-button>
                        <el-button  @click="createOneUrl(copyRow,4)" type="primary" size="mini" v-else>生成</el-button>
                    </td>
                </tr>
                <tr v-if="copyRow.babel_url != ''">
                    <td>详情页</td>
                    <td><span>{{copyRow.one_landing_babel_url}}</span></td>
                    <td><el-button @click="copyOneJump(copyRow.one_landing_babel_url)" type="primary" size="mini">复制</el-button></td>
                </tr>
                <!--<tr>
                    <td>星量监测链接</td>
                    <td><span>{{copyRow.track_url}}</span></td>
                    <td v-if="copyRow.track_url != null && copyRow.track_url!= '' && copyRow.track_url!='null'"><el-button @click="copyOneJump(copyRow.track_url)" type="primary" size="mini">复制</el-button></td>
                    <td v-else><el-button @click="createTrackUrl()" type="primary" size="mini" >生成</el-button></td>
                </tr>-->

            </tbody>
          </table>
<!--  <el-radio v-model="radio" label="1">老版本</el-radio>-->
<!--  <el-radio v-model="radio" label="2">新版本</el-radio>-->
<!--<el-button @click="copyOneJump(copyRow.one_jump_url)" type="primary" size="mini" v-if="copyRow.one_jump_url!=''">复制v1版本</el-button>-->
<!--<el-button @click="copyOneJump(copyRow.one_jump_url_v2)" type="primary" size="mini" v-if="copyRow.one_jump_url_v2!=''">复制v2版本</el-button>-->
<!--<el-button @click="copyOneJump(copyRow.one_jump_url_v3)" type="primary" size="mini" v-if="copyRow.one_jump_url_v3!=''">复制监测链接版本</el-button><el-button @click="copyOneJump(copyRow.one_landing_url)" type="primary" size="mini" v-if="copyRow.one_landing_url!='' && copyRow.platform == 1">详情页</el-button>-->
</template>
                </span>
                <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
<!--    <el-button type="primary" @click="dialogVisible = false;copyOneJump()">确 定</el-button>-->
  </span>
            </el-dialog>

        </div>
    </div>
</template>

<script>

import {
    platForm,
    addTbMerchandise,
    addTbMerchandiseList,
    companylist,
    goods_delete,
    goods_detail,
    activitylist,
    createoneurl,
    createtrack
} from "@/request/api";
import $utils from "@/js/utils";

export default {

    data() {
        return {
            platform: '',
            loading: false,
             option2:[
                {
                value: 1,
                name: '巨量引擎'
            },
                {
                value: 2,
                name: '腾讯广告'
            }],
            option:[
                {
                value: 1,
                name: '淘宝'
            },
                {
                value: 2,
                name: '京东'
            },{
                value: 3,
                name: '拼多多'
            }
            ],
            value:'',
            val: '',

            totalNum: 0,
            pageSize: 10,
            curPage: 1,
            activitylist: [],
            companylist: [],
            agentId: '',
            // 嵌套添加商品  
            drawer: false,
            // 搜索框
            keyword: '',
            // 表格内容  
            tableData: [],
            // 添加列表
            saveData: {
                channel: "1",
                activity_id: "",
                company_id: "",
                item_name: "",
                wangwang_name: "",
                sessionkey: "",
                babel_url: "",
                item_id: "",
                remarks: "",
                status: "1",
                platform: '1',
                report_etime:0
            },
            //表单验证
            rules: {
                activity_id: [
                    { required: true, message: '请选择活动', trigger: 'blur' },
                ],
                company_id: [
                    { required: true, message: '请选择公司', trigger: 'blur' },
                ],
                item_name: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' },
                ],
                item_id: [
                    { required: true, message: '请输入商品ID', trigger: 'blur' },
                ],
                wangwang_name: [
                    { required: true, message: '请输入旺旺昵称', trigger: 'blur' },
                ]
            },
            drawer_title : "添加商品",
            save_button: true,
            item_name_readonley: true,
            dialogVisible: false,
            radio: 1,
            copyRow : {},
            id_role:1
        }
    },
    mounted(){
        this.getGoodsList()
        this.getActivityList()
        this.getCompanyList()
        this.getPlatform()
        if(sessionStorage.getItem('userinfo')){
            this.id_role = JSON.parse(sessionStorage.getItem('userinfo')).id_role
        }
        
    },
    methods: {
         /**
         * 获取平台列表
         */
        getPlatform(){
            this.loading = true;
            platForm().then(res => {
                if(res.code == 1){
                    console.log(res,'平台列表');
                    this.option2 = res.data
                    this.loading = false
                }
            })
        },
        // 平台
        statusFormatter1(row, column) {
            const platform = row.platform;
            if (platform == 1) {
                return "巨量引擎";
            } else {
                return "腾讯广告";
            }
        },
        handleClick(row) {
            // console.log(row);
            row.channel = this.getChannel(row.channel)
            sessionStorage.setItem("good_" + row.id, JSON.stringify(row));
            // this.$router.push('/taobaoGoods/landingpage?id=' + row.id)
            this.$router.push({path: '/taobaoGoods/landingpage',query:{id:row.id}})
        },
        handleSizeChange(val) {
            this.pageSize = val
            // console.log('this.pageSize', this.pageSize)
            this.getGoodsList(this.keyword)
        },
        handleCurrentChange(val) {
            // console.log('current page', val);
            this.curPage = val;
            this.getGoodsList(this.keyword)
        },
        handleClose(done) {
            done();
        },
        //获取商品列表
        getGoodsList() {
            this.loading = true;
            addTbMerchandiseList({page: this.curPage, pageSize: this.pageSize , channel: this.val ,platform: this.platform}).then(res => {
                // console.log(res)
                this.loading = false;
                if (res.code == 1) {
                    this.totalNum = res.total
                    this.tableData = res.data
                    this.getCommissionInfo()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //获取返利信息
        getCommissionInfo(){
            this.tableData.forEach((item,index) => {
                goods_detail({channel:item.channel,item_id:item.item_id}).then(res => {
                    if(res.code == 1){
                        this.tableData[index].commission_rate = res.data.commission_rate
                    }
                })
            })
        },
        //搜索
        searchGoods() {
            this.curPage = 1;
            this.loading = true;
            addTbMerchandiseList({page: this.curPage, pageSize: this.pageSize, keyword: this.keyword}).then(res => {
                // console.log(res)
                this.loading = false;
                if (res.code == 1) {
                    this.totalNum = res.total
                    this.tableData = res.data
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        filterTag(value, row) {
            return row.status === value;
        },
        /**
         * 获取活动列表
         */
        getActivityList() {
            if (this.activitylist.length <= 0) {
                activitylist().then(res => {
                    if (res.code == 1) {
                        this.activitylist = res.data[0].campaignList;
                        // this.activitylist = res.data.campaignList;
                    }
                })
            }
        },
        /**
         * 获取公司列表
         */
        getCompanyList(){
            companylist().then(res => {
                if(res.code == 1){
                    this.companylist = res.data
                }
            })
        },
        //保存商品
        saveGoods() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    addTbMerchandise(this.saveData).then(res => {
                        if (res.code == 1) {
                            this.$message.success("保存成功")
                            setTimeout(() => {
                                this.drawer = false
                                this.$refs["form"].resetFields();
                                this.getActivityList();
                                this.getGoodsList();
                            }, 1000)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        cannelForm() {
            this.drawer = false
            this.$refs["form"].resetFields();
            console.log(this.saveData);
            // return
            addTbMerchandise(this.saveData).then(res => {
                console.log(res)
            })
        },

        /**
         * 编辑商品
         * @param item
         */    
        editGoods(item){
            console.log(item,'itme')
            this.drawer_title = "编辑商品"
            item.platform = item.platform +""
            item.status = item.status+""
            item.channel = item.channel+""
            // item.channel = item.channel+""
            console.log(item.platform,'bbbbbbbbb');
            console.log(item.channel,'aaaaaaaaaa');
            this.saveData = Object.assign({},item)
            this.drawer = true
            this.save_button = false
        },
        /**
         * 删除商品
         */
        deleteGoods(item){
            this.$confirm("确认删除商品吗？").then(res => {
                if(res == "confirm"){
                    goods_delete({id:item.id}).then(res => {
                        if(res.code == 1){
                            this.getGoodsList();
                            this.$message.success(res.msg)
                        }
                    })
                }
            })
        },
        getStatus(status){
            return  status == 1 ? "启用" : "禁用"
        },
        getChannel(channel){
            var channelArr = ['淘宝','京东','拼多多']
            return channelArr[Number(channel)-1]
        },
        addGoods(){
            this.saveData = {
                channel: '1',
                activity_id: "",
                company_id: "",
                item_name: "",
                wangwang_name:"",
                babel_url:"",
                item_id: "",
                remarks: "",
                status: "1",
                platform: '1',
                report_etime: 0
            }
            this.drawer_title = "添加商品"
            this.drawer = true;
            this.save_button = true
        },
        // 获取商品信息
        getGoodsInfo(){
            this.save_button = true
            goods_detail({channel:this.saveData.channel,item_id:this.saveData.item_id}).then(res => {
                this.save_button = false
                if(res.data.sessionkey !=""){
                    this.saveData.sessionkey = res.data.sessionkey
                }
                if(res.code == 1 && res.data.item_name != ""){
                    this.saveData.item_name = res.data.item_name
                    this.saveData.wangwang_name = res.data.wangwang_name
                    if(res.data.img_url != ""){
                        this.saveData.img_url = res.data.img_url
                    }
                    if(res.data.product_id != ""){
                        this.saveData.product_id = res.data.product_id
                    }
                }else {
                    this.saveData.item_name = "";
                    // this.save_button = true;
                    this.item_name_readonley = false;
                    this.$message.error("商品信息不存在,请手动填写")
                }
            })
        },
        copyOneJump(url){
            if(this.radio == 2 && this.copyRow.one_jump_url_v2 == ""){
                this.$message.error("暂无新版本一跳链接，请点击重置生成")
                return
            }
            $utils.copyClipboard(url);
            this.$message({
                message: "复制成功",
                type: "success",
            });
        },
        copyOneJumpButton(row){
            this.dialogVisible = true
            this.copyRow = row
        },
        //生成一跳链接
        createOneUrl(row,type = 1){
            createoneurl({id:row.id,type:type}).then(res => {
                if(res.code == "1"){
                    if(type == 4){
                        this.copyRow.one_jump_babel_url = res.data.one_jump_babel_url
                        this.copyRow.one_jump_babel_url_v2 = res.data.one_jump_babel_url_v2
                        this.copyRow.one_landing_babel_url = res.data.one_landing_babel_url
                    }else {
                        this.copyRow.one_jump_url = res.data.one_jump_url
                        this.copyRow.one_jump_url_v2 = res.data.one_jump_url_v2
                        this.copyRow.one_landing_url = res.data.one_landing_url
                        this.copyRow.one_jump_url_v3 = res.data.xingliang_url
                    }
                    this.$message.success(res.msg)
                    // setTimeout(() => {
                    //     this.getGoodsList()
                    // })
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        //生成给监测链接
        createTrackUrl(){
            createtrack().then(res => {
                if(res.code == "1"){
                    this.copyRow.track_url = res.data.url;
                    this.copyRow.track_url_v2 = res.data.url_v2
                    this.$message({
                        message: res.msg,
                        type: "success",
                    });
                }else{
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            })
        }
    }
}
</script>


<style scoped>
.tb-goods .f1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 0 0 20px 0;
    background-color: #fff;
    flex-wrap: wrap;
}

.tb-goods .f1-search {
    display: flex;
    align-items: center;
}

.tb-goods .f2 {
    padding: 20px;
    background-color: #fff;
}

.tb-goods .page {
    text-align: right;
    margin: 15px 2px 0 0;
    /* padding-bottom: 50px; */
}

.tb-goods .drawer-main {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.tb-goods .empty {
    height: 68%;
}
a{
    text-decoration: none;
    color: #0a63fc;
}

.el-form-item{
    margin-bottom: 22px;
}

/* 解决编辑弹窗上移问题 */
/* /deep/.el-dialog__header{
    padding: 20px 20px 10px !important;
} */



.notesList_table {
    border-top: 1px solid #dddddd;
    width: 100%;
    border-collapse: collapse;
}

.notesList_table td,
.notesList_table th {
    border-bottom: 1px solid #dddddd;
    color: #333333;
    height: 40px;
    line-height: 40px;
    text-align: left;
}

.notesList_table td span{
    word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
}
</style>