<template>
    <div class="dplan">

        <!-- 嵌套内容 -->
        <div class="floor">
                        <!-- 1.计划分时趋势 -->
                        <div class="f1">
                          <div class="f1-1">
                            <div>计划分时趋势</div>
                            <div>...</div>
                          </div>
                        <!-- 趋势图 -->
                        <div>

                        </div>
                        </div>
                        <!-- 2.时间范围选择器 -->
                        <div class="f2">
                          <div>时间范围</div>
                          <el-date-picker
                            v-model="value2"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                          </el-date-picker>
                        </div>
                        <!-- 3.计划分天趋势 -->
                        <div class="f3">
                          <div class="f3-1">
                            <div>计划分天趋势</div>
                            <div>...</div>
                          </div>
                          <!-- 趋势图 -->
                          <div>

                          </div>
                        </div>
                        <!-- 4.计划分天报表 -->
                        <div class="f4">
                          <div class="f4-1">
                            <div>计划分天报表</div>
                            <div>...</div>
                          </div>
                          <!-- 报表内容 -->
                          <div>

                          </div>
                        </div>


                      </div>
                      <!-- 内容结束 -->


    </div>
</template>

<script>
    export default {
        data() {
            return {
        // 时间选择器的方法
        value2: "",
        pickerOptions: {
            shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              end.setDate(end.getDate() - 1);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "前天",
            onClick(picker) {
              const end = new Date();
              end.setDate(end.getDate() - 2);
              const start = new Date();
              start.setDate(start.getDate() - 2);
              // start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近15天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
            }
        },
    }
</script>

<style lang="scss" scoped>
// 嵌套背景
.dplan .el-dialog__body{
    background-color: rgb(240, 242, 250);
}
.dplan .f1{
    // border: 2px solid palevioletred;
    background-color: rgb(240, 242, 250);
}
.dplan .f1-1,.f3-1,.f4-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1vh;
}

.dplan .f2,.f3,.f4{
    background-color: rgb(240,242,250);
}
.dplan .f2{
    padding: 1vh;
}
</style>