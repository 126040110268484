<template>
  <div>
    <div v-loading="loading">
      <!-- 筛选 -->
      <div class="Marketing_activity_monitoring_1">
        <div class="flex_around pandding" style=" margin: 0px 0px 0px 510px;width:188px">
          <el-select style="width: 110px;" v-model="options1">
            <el-option :label="options1.toString()" :value="options1"></el-option>
          </el-select>
          <shaixuan @handlechange1="handlechange1" @update:updateMultipleSelect="val => mulSelecteds = val" :mulSelecteds="mulSelecteds"></shaixuan>
          <sx2 :acid="acid" @handlechange2="handlechange2"></sx2>
          <order @orderchange="orderchange"></order>
          <sx3 :acid="acid" @handlechange3="handlechange3"></sx3>
        </div>
        <div class="flex pandding">
          <div class="flex relative">
            <i @click="handleclear1" class="el-icon-circle-close absolute1 cursor"></i>
          </div>
          <div class="flex relative">
            <i @click="handleclear2" class="el-icon-circle-close absolute2 cursor"></i>
          </div>
          <el-popover
            style="margin: -35px 0 0 15px"
            class
            placement="bottom"
            width="450"
            trigger="click"
            @show="showROI"
            v-model="visibleROI"
          >
            <el-input
              clearable
              slot="reference"
              style="width: 490px"
              :readonly="true"
              v-model="input"
              placeholder="请输入ROI"
            ></el-input>
            <div class="flex">
              <div class="dayu" ref="dayu">大于</div>
              <el-input class="margin_right4" v-model="input1"></el-input>
            </div>
            <div class="flex">
              <div class="dayu" ref="xiaoyu">小于</div>
              <el-input class="margin_right4" v-model="input2"></el-input>
            </div>
            <div class="float_right">
              <el-button class type="primary" @click="handleapplication">应用</el-button>
            </div>
          </el-popover>
          <el-popover
            style="margin: -35px 0 0 15px"
            placement="bottom"
            width="450"
            trigger="click"
            @show="showCOST"
            v-model="visibleCOST"
          >
            <el-input
              class="margin_right20"
              slot="reference"
              style="width: 490px"
              :readonly="true"
              v-model="input3"
              placeholder="请输入花费"
            ></el-input>
            <div class="flex">
              <div class="dayu">大于</div>
              <el-input class="margin_right4" v-model="input4"></el-input>
            </div>
            <div class="flex">
              <div class="dayu">小于</div>
              <el-input class="margin_right4" v-model="input5"></el-input>
            </div>
            <div class="float_right">
              <el-button class type="primary" @click="handlemoney">应用</el-button>
            </div>
          </el-popover>
        </div>
      </div>
      <!-- 实时数据概览 -->
      <div class="Marketing_activity_monitoring_2">
        <div class="pandding">
          <div class="flex">
            实时数据概览
            <div class="font14 Marketing_activity_monitoring_2_text">
              数据统计截止至
              <div id="time" class="Marketing_activity_monitoring_2_text">{{ updateTime }}</div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="Marketing_activity_monitoring_2_box">
            <div class="pandding">
              <div class="flex">
                <div class="font14">花费</div>
                <div>
                  <el-tooltip class="item" effect="dark" content="当日从0点起截止更新时间累计花费" placement="top">
                    <i class="el-icon-warning font14"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="font28 flex" v-if="currentCost == ''">--</div>
              <div class="flex font26" v-else>{{ currentCost }}</div>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_2_box">
            <div class="pandding">
              <div class="flex">
                <div class="font14">展现量</div>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="当日从0点起截止更新时间累计展现量"
                    placement="top"
                  >
                    <i class="el-icon-warning font14"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="font28 flex" v-if="currentShow == ''">--</div>
              <div class="flex font26" v-else>{{ currentShow }}</div>
            </div>
            <div class="pandding" style="margin: 5px 0 0 0">
              <div class="flex_between">
                <div>
                  <div class="flex">
                    <div class="font14">点击量</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="当日从0点起截止更新时间累计点击量"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="currentClick == ''">--</div>
                  <div class="flex font26" v-else>{{ currentClick }}</div>
                </div>
                <div>
                  <div class="flex">
                    <div class="font14">点击率</div>
                    <div>
                      <el-tooltip class="item" effect="dark" content="点击量/展现量" placement="top">
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="currentCtr == ''">--</div>
                  <div class="flex font26" v-else>{{ currentCtr }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_2_box">
            <div class="pandding">
              <div class="flex">
                <div class="font14">转化数</div>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="当日从0点起截止更新时间累计转化数"
                    placement="top"
                  >
                    <i class="el-icon-warning font14"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="font28 flex" v-if="currentConvert == ''">--</div>
              <div class="flex font26" v-else>{{ currentConvert }}</div>
            </div>
            <div class="pandding">
              <div class="flex_between">
                <div>
                  <div class="flex">
                    <div class="font14">转化率</div>
                    <div>
                      <el-tooltip class="item" effect="dark" content="转化数/点击量" placement="top">
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="currentConvertRate == ''">--</div>
                  <div class="flex font26" v-else>{{ currentConvertRate }}%</div>
                </div>
                <div>
                  <div class="flex">
                    <div class="font14">转化成本</div>
                    <div>
                      <el-tooltip class="item" effect="dark" content="花费/转化数" placement="top">
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="currentConvertCost == ''">--</div>
                  <div class="flex font26" v-else>{{ currentConvertCost }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_2_box">
            <div class="flex_between">
              <div class="pandding">
                <div class="flex">
                  <div class="font14">成交金额</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="当日付款不限点击时间的成交金额"
                      placement="top"
                    >
                      <i class="el-icon-warning font14"></i>
                    </el-tooltip>
                  </div>
                </div>
                <div class="font28 flex" v-if="currentPayAmount == ''">--</div>
                <div class="flex font26" v-else>{{ currentPayAmount }}</div>
              </div>
              <div class="pandding" style="width: 120px;">
                <div class="flex">
                  <div class="font14">成交订单数</div>
                  <div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="当日付款不限点击时间的成交订单数"
                      placement="top"
                    >
                      <i class="el-icon-warning font14"></i>
                    </el-tooltip>
                  </div>
                </div>
                <div class="font28 flex" v-if="currentPayCount == ''">--</div>
                <div class="flex font26" v-else>{{ currentPayCount }}</div>
              </div>
            </div>
            <div class="flex_between">
              <div class="pandding">
                <div class="flex_between">
                  <div>
                    <div class="flex">
                      <div class="font14">当日转化金额</div>
                      <div>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="当日点击且当日付款的成交金额"
                          placement="top"
                        >
                          <i class="el-icon-warning font14"></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="font28 flex" v-if="currentConvertAmount == ''">--</div>
                    <div class="flex font26" v-else>{{ currentConvertAmount }}</div>
                  </div>
                </div>
              </div>
              <div class="pandding">
                <div class="flex_between">
                  <div>
                    <div class="flex">
                      <div class="font14">当日成交订单数</div>
                      <div>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="当日点击且当日付款的当日成交订单数"
                          placement="top"
                        >
                          <i class="el-icon-warning font14"></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="font28 flex" v-if="currentConvertCount == ''">--</div>
                    <div class="flex font26" v-else>{{ currentConvertCount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_2_box">
            <div class="pandding">
              <div class="flex">
                <div class="font14">成交ROI</div>
                <div>
                  <el-tooltip class="item" effect="dark" content="成交金额/花费" placement="top">
                    <i class="el-icon-warning font14"></i>
                  </el-tooltip>
                </div>
              </div>
              <div class="font28 flex" v-if="currentRoi == ''">--</div>
              <div class="flex font26" v-else>{{ currentRoi }}</div>
            </div>
            <div class="pandding">
              <div class="flex_between">
                <div>
                  <div class="flex">
                    <div class="font14">当日转化ROI</div>
                    <div>
                      <el-tooltip class="item" effect="dark" content="当日转化金额/花费" placement="top">
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="currentConvertRoi == ''">--</div>
                  <div class="flex font26" v-else>{{ currentConvertRoi }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 实时趋势 -->
      <div class="Marketing_activity_monitoring_3">
        <trend :hw="this.acid" ref="effectObjreport" @gogoodspare="gogoodspare" ></trend>
      </div>
      <!-- 商品投放效果 -->
      <div class="Marketing_activity_monitoring_3">
        <div class="flex pandding">商品投放效果</div>
        <effect ref="effectObj" :hw="this.acid"></effect>
      </div>
      <!-- 账户详细数据 -->
      <div class="Marketing_activity_monitoring_5">
        <GoodTabs :filterData="goodTabsFilterData" :orderchange2="orderchange2"></GoodTabs>
      </div>
       <!-- <textbox></textbox> -->
 
    </div>
  </div>
</template>
<script src="//unpkg.com/vue/dist/vue.js"></script>
<script src="//unpkg.com/element-ui@2.15.7/lib/index.js"></script>
<script>
import trend from "../../echarts/goods_trend";
import effect from "../../echarts/goods_effect";
import drawer from "../../echarts/goods_drawer";
import shaixuan from "../../echarts/goods_shaixuan";
import sx2 from "../../echarts/goods_sx2";
import sx3 from "../../echarts/goods_sx3";
import GoodTabs from "../../echarts/goods_tabs";
import Task from "../../echarts/goods_Task";
import advertisement from "../../echarts/goods_advertisement";
import order from "../../echarts/goods_order";
import textbox from "../../echarts/goods_text";

import {
  activitylist,
  monitoringdata,
  dataoverview,
  realtimetrend,
  productlaunch,
} from "@/request/api.js"; // 营销监控筛选接口和首页页签获取账户、广告组、计划接口
import { number } from "echarts";
export default {
  components: {
    trend,
    effect,
    drawer,
    shaixuan,
    sx2,
    sx3,
    GoodTabs,
    Task,
    advertisement,
    order,
    textbox
  },
  data() {
    return {
      mulSelecteds:[],
      loading: false,
      visibleROI: false,
      visibleCOST: false,
      zhnum: "",
      filterAccountData: null,
      updateTime: "",
      drawdata: "",
      hw: [],
      childVisible: false, //是否展示抽屉
      currentCost: "", //实数数据概览花费
      currentShow: "", //实数数据概览展现量
      currentClick: "", //实数数据概览点击量
      currentConvert: "", //实数数据概览转化数
      currentPayAmount: "", //实数数据概览成交金额
      currentConvertAmount: "", //实数数据概览当日转化金额
      currentRoi: "", //实数数据概览成交roi
      currentConvertRoi: "", //实数数据概览当日转化roi
      currentCtr: "", //实数数据概览点击率
      currentConvertRate: "", //实数数据概览转化率
      currentConvertCost: "", //实数数据概览转化成本
      currentPayCount: "", //实数数据概览成交订单数
      currentConvertCount: "", //实数数据概览当日成交订单数
      trend: "",
      zhdata: {}, //第一个
      handid: {}, //第三个
      zhdata1: {}, //第二个
      cost: "",
      value2: true,
      value: "",
      checkList: [],
      input1: "",
      input2: "",
      input4: "",
      input5: "",

      earlySelected: [],
      checkedForm: {
        org_names1: [],
        org_names2: [],
        org_names3: []
      },
      // 对于半全选，判断全选功能的进入或是退出
      flag: false,

      props: { multiple: true },
      options1: [],
      idx: [],
      newlabei: "",
      visible: false,
      currentPage4: 1,
      tabType: "",
      accountId: "", //账户id
      adGroupId: "", //广告组id
      planId: "", //计划id
      accountName: "", //账户名称
      cost: "", //花费
      showNum: "", //展现量
      click: "", //点击量
      convertNum: "", //转化数
      payAmount: "", //	成交金额
      convertAmount: "", //	当日转化金额
      payCount: "", //	成交订单数
      convertCount: "", //当日转化订单数
      cpm: "", //千次展现费用
      cpc: "", //平均点击单价
      ctr: "", //点击率
      convertCost: "", //转化成本
      convertRate: "", //转化率
      avgAmountVar: "", //成交订单均价
      payRoi: "", //成交roi
      avgConvertAmount: "", //	当日转化订单均价
      convertRoi: "", //当日转化roi
      payConvertRate: "", //支付转化率
      goodIds: [],
      acid: [],
      roiMax: "",
      roiMin: "",
      costMax: "",
      costMin: "",
      // 选项卡
      activeName: "account",
      totalnum: {
        type: Number,
        default: 1
      },
      // accountTableData: [],
      // adgroupTableData: [],
      // planTableData: [],
      tsnum: "10",
      selectAccountId: -1,
      // selectAccountId1: 0,
      targetType: "",
      targetIds: [],
      effect: [],
      orderchange2: "tb"
    };
  },
  created() {
    // console.log(sessionStorage.getItem("key"));//动态token
  },
  computed: {
    input() {
      if (this.roiMax == "" || this.roiMin == "") {
        return "";
      }
      return `大于:${this.roiMin}` + `:` + `小于:${this.roiMax}`;
    },
    input3() {
      if (this.costMax == "" || this.costMin == "") {
        return "";
      }
      return `大于:${this.costMin}` + `:` + `小于:${this.costMax}`;
    },
    goodTabsFilterData() {
      return {
        goodIds: this.goodIds,
        acticityId: this.acid,
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        orderFrom:this.orderchange2,
         
      };
    }
  },
  mounted() {
    this.Activitylist();
    // this.handleClick()
  },
  methods: {
    orderchange(value) {
      console.log(value, "order子组件传过来拉");
      this.orderchange2 = value;
      console.log(this.orderchange2);
      this.search();
    },
    handlechange2(obj) {
      this.targetType = obj.targetType;
      this.targetIds = obj.targetIds;
      this.search();
    },
    handlechange3(obj) {
      // this.targetType = obj.targetType;
      this.goodIds = obj.targetIds;
      -+this.search();
    },
    handlechange1(ids) {
      //
      this.acid = ids;
      this.search();
    },
    showROI() {
      this.input1 = this.roiMax;
      this.input2 = this.roiMin;
    },
    showCOST() {
      this.input4 = this.costMax;
      this.input5 = this.costMin;
    },
    //筛选大小于 花费清空
    handleclear2() {
      console.log("花费清空");
      (this.costMin = ""), (this.costMax = "");
      this.search();
    },

    //筛选大小于 ROI清空
    handleclear1() {
      console.log("ROI清空");
      (this.roiMin = ""), (this.roiMax = "");
      this.search();
    },
    //  筛选大小于 ROI
    handleapplication() {
      this.roiMax = this.input1;
      this.roiMin = this.input2;
      this.search();
      this.visibleROI = false;
    },
    //  筛选大小于 花费
    handlemoney() {
      this.costMax = this.input4;
      this.costMin = this.input5;
      this.search();
    },
    gogoodspare(val){
     console.log(val);
   
    },
    search() {
      // console.log("this.acid", this.acid);
      this.loading = true;
      let promiseArr = [];
      let param = {
        // Authorization: sessionStorage.getItem("key"),
        goodIds: this.goodIds,
        acticityId: this.acid, //活动id
        tabType: "account", //页签类别
        page: '', //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        orderFrom: this.orderchange2, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        compare:0,
        contrastA:'cost',
        contrastB:"showNum",
        
      };
      // 监控实时数据概览
      this.loading = true;
      dataoverview({
        acticityId: this.acid, //活动id
        targetIds: this.targetIds, //	选择的类型的数据id，数组格式
        targetType: this.targetType, //类型，代理(AGENT)、账户(ACCOUNT)、广告组(AD_GROUP)、计划(PLAN)，默认空
        goodIds: [], //商品id
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        orderFrom: this.orderchange2 //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
      }).then(res => {
        this.loading = false;
        // console.log(res.data);
        if (res.data) {
          this.currentCost = res.data.currentCost;
          this.currentShow = res.data.currentShow;
          this.currentClick = res.data.currentClick;
          this.currentConvert = res.data.currentConvert;
          this.currentPayAmount = res.data.currentPayAmount;
          this.currentConvertAmount = res.data.currentConvertAmount;
          this.currentRoi = res.data.currentRoi;
          this.currentConvertRoi = res.data.currentConvertRoi;
          this.currentCtr = res.data.currentCtr;
          this.currentConvertRate = res.data.currentConvertRate;
          this.currentConvertCost = res.data.currentConvertCost;
          this.updateTime = res.data.updateTime;
          this.currentPayCount = res.data.currentPayCount;
          this.currentConvertCount = res.data.currentConvertCount;
        } else {
          this.currentCost = "";
          this.currentShow = "";
          this.currentClick = "";
          this.currentConvert = "";
          this.currentPayAmount = "";
          this.currentConvertAmount = "";
          this.currentRoi = "";
          this.currentConvertRoi = "";
          this.currentCtr = "";
          this.currentConvertRate = "";
          this.currentConvertCost = "";
          this.updateTime = "";
          this.currentPayCount = "";
          this.currentConvertCount = "";
        }
      });
      // 趋势
      this.$refs.effectObjreport.search(param);
      // effect图表
      this.$refs.effectObj.search(param);
    },
    Activitylist() {
      activitylist({}).then(res => {
        this.options1 = res.data[0].platformName;
      });
    }
  }
};
</script>

<style lang="scss">
.tableScopeSwitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}
/*打开时文字位置设置*/
.tableScopeSwitch .el-switch__label--right {
  // z-index: 1;
  right: -70px; /*不同场景下可能不同，自行调整*/
  color: #409eff;
}
/*关闭时文字位置设置*/
.tableScopeSwitch .el-switch__label--left {
  right: -80px; /*不同场景下可能不同，自行调整*/
  color: #dcdfe6;
}
/*显示文字*/
.tableScopeSwitch .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 75px !important; /*开关按钮的宽度大小*/
}
// 主体内容图标
.nav-icon {
  display: flex;
  align-items: center;
}
.el-icon-s-fold {
  font-size: 3vh;
}
.nav-icon span {
  font-size: 2vh;
  padding-left: 1vh;
}
.Marketing_activity_monitoring_1 {
  width: 100%;
  height: 140px;
  background: #ffffff;
  // margin: 10px 0 0 0;
}
.Marketing_activity_monitoring_2 {
  width: 100%;
  height: 260px;
  background: #ffffff;

}
.Marketing_activity_monitoring_3 {
  width: 100%;
  height: 470px;
  background: #ffffff;
  margin: 10px 0 0 0;
}
.Marketing_activity_monitoring_4 {
  width: 100%;
  height: 350px;
  background: #ffffff;
  margin: 20px 0 0 0;
}
.Marketing_activity_monitoring_5 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  margin: 10px 0 0 0;
}
.Marketing_activity_monitoring_2_text {
  line-height: 25px;
  margin: 0 0 0 10px;
  display: flex;
}
.Marketing_activity_monitoring_2_box {
  width: 19%;
  height: 190px;
  background: #f5f6fa;
  margin: 0 0 0 10px;
}
.el-icon-warning {
  margin: 0 0 0 5px;
}
.dayu {
  width: 30px;
  text-align: center;
  line-height: 45px;
  margin: 0 2px 0 -4px;
}
.change {
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  width: 70px;
  text-align: center;
}
// .el-select__tags {
//   margin: 0px 0 0 0;
//   max-width: 241.031px !important;
// }
// .el-input--suffix {
//   width: 190px;
// }
// .el-select__tags-text {
//   width: 50px;
// }



.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>