<template>
  <div class="container">
    <!-- 多选择框-->
    <div v-for="nData in navData">
      <el-checkbox-group v-model="checkedKeys" @change="handleCheckedCitiesChange(1,checkedKeys,nData)">
        <!-- 子菜单-->
        <el-checkbox
            :label="nData.id" :key="nData.id"
        ><b>{{nData.label}}-1级</b>
        </el-checkbox>
      </el-checkbox-group>
      <div style="display: flex">
        <div style="padding:  5px 15px;" v-for="nD in nData.children">
          <!--菜单栏-->
          <el-checkbox-group v-model="checkedKeys" @change="handleCheckedCitiesChange
        (2,checkedKeys,navData,nData,nD.id)">
            <el-checkbox :label="nD.id" :key="nD.id">{{nD.label}}-2级</el-checkbox>
          </el-checkbox-group>
          <div style="padding:  5px 15px;">
            <el-checkbox-group
              v-model="checkedKeys">
              <!--路由导航 -->
              <el-checkbox
                @change="handleCheckedCitiesChange(3,checkedKeys,nData.children,nD.children,nD.id,nData.id,n.id)"
                v-for="n in nD.children" :label="n.id" :key="n.id">
                <i>H3 {{n.label}}</i>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <el-button class="query_btn" type="primary" @click="goSubmit" :disabled="disabled">提交</el-button>
  </div>
</template>


<script>
export default {
  name: '',
  data() {
    return {
      navData: [], //获取的数据
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      expandedKeys: [], //默认打开的项
      checkedKeys: [2,3,4], //默认选中的项
      submitData: [], //需要提交的数据
      disabled: true,//初始修改状态
      // 多选择框
      checkAll: false,
      isIndeterminate: true,
      datalist: [
        {
          id:1,
          pid:0,
          name:'数据报表'
        },
        {
          id:2,
          pid:0,
          name:'订单报表'
        },
        {
          id:3,
          pid:0,
          name:'投放配置'
        },
        {
          id:4,
          pid:3,
          name:'商品管理'
        },
        {
          id:5,
          pid:3,
          name:'落地页转链'
        },
        {
          id:6,
          pid:0,
          name:'业务管理'
        },
        {
          id:7,
          pid:6,
          name:'公司管理'
        },
        {
          id:8,
          pid:6,
          name:'主账户管理'
        },
        {
          id:9,
          pid:6,
          name:'活动配置'
        },
      ]
    }
  },
  mounted() {
    let navData = this.datalist;
    //处理数据格式
    let arr = []; //子系统 级的数据
    for (let i = 0; i < navData.length; i++) {
      if (navData[i].pid == 0) {
        arr.push({
          id: navData[i].id,
          label: navData[i].name,
          children: []
        })
      }
      this.expandedKeys.push(navData[i].id);
    }
    for (let j = 0; j < navData.length; j++) {
      for (let a = 0; a < arr.length; a++) {
        // 判断 功能id==等于菜单id时
        if (arr[a].id === navData[j].pid) {
          // 为每一个 子系统添加 菜单
          arr[a].children.push({
            id: navData[j].id,
            label: navData[j].name,
            children: []
          })
        }
      }
    }
    for (let b = 0; b < navData.length; b++) {
      for (let c = 0; c < arr.length; c++) {
        for (let d = 0; d < arr[c].children.length; d++) {
          // 为每一个 菜单添加 路由导航
          if (arr[c].children[d].id === navData[b].pid) {
            arr[c].children[d].children.push({
              id: navData[b].id,
              label: navData[b].name,
            })
          }
        }
      }
    }
    this.navData = arr;
    this.submitData = this.checkedKeys;
    console.log("处理完成的数据", this.navData);
    console.log("选中的数据", this.checkedKeys);
  },
  methods: {
    // 多选框进行改变时
    handleCheckedCitiesChange(type, checkedKeys, data, childrenData, nDid, Did, nid) {
      this.submitData = [];
      if (type === 3) {
        this.handleNavigation(checkedKeys, data, childrenData, nDid, Did, nid)
      } else if (type === 2) {
        this.handleNavigationTwo(checkedKeys, childrenData, nDid)
      } else if (type === 1) {
        this.handleNavigationOne(checkedKeys, data)
      }
      this.submitData = this.checkedKeys;
      this.disabled = checkedKeys.length <= 0;
    },
    //选中子系统时
    handleNavigationOne(checkedKeys, data) {
      let result = checkedKeys.includes(data.id);
      if (result) {
        data.children.forEach(d => {
          let results = checkedKeys.includes(d.id);
          if (!results) {
            this.checkedKeys.push(d.id);
          }
          d.children.forEach(da => {
            let resultNavigation = checkedKeys.includes(da.id);
            if (!resultNavigation) {
              this.checkedKeys.push(da.id);
            }
          });
        });
      } else {
        for (let i = 0, len = data.children.length; i < len; i++) {
          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === (data.children[i].id);
            if (results) {
              this.checkedKeys.splice(j, 1)
            }
          }
        }
        for (let i = 0, len = data.children.length; i < len; i++) {
          for (let k = 0, len = data.children[i].children.length; k < len; k++) {
            for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
              let results = this.checkedKeys[j] === (data.children[i].children[k].id);
              if (results) {
                this.checkedKeys.splice(j, 1)
              }
            }
          }
        }
      }
    },
    // 选中菜单时
    handleNavigationTwo(checkedKeys, childrenData, nDid) {
      let result = checkedKeys.includes(nDid);
      if (result) {
        let results = checkedKeys.includes(childrenData.id);
        if (!results) {
          this.checkedKeys.push(childrenData.id);
        }
        childrenData.children.forEach(da => {
          if (da.id === nDid) {
            let result = checkedKeys.includes(da.id);
            if (result) {
              da.children.forEach(d => {
                let resultNavigation = checkedKeys.includes(d.id);
                if (!resultNavigation) {
                  this.checkedKeys.push(d.id);
                }
              });
            }
          }
        });
      } else {
        for (let i = 0, len = childrenData.children.length; i < len; i++) {
          if (childrenData.children[i].id === nDid) {
            for (let k = 0, len = childrenData.children[i].children.length; k < len; k++) {
              for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                let results = this.checkedKeys[j] === (childrenData.children[i].children[k].id);
                if (results) {
                  this.checkedKeys.splice(j, 1)
                }
              }
            }
          }
        }
        let r = 0;
        for (let i = 0, len = childrenData.children.length; i < len; i++) {
          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === (childrenData.children[i].id);
            if (results) {
              r++;
            }
          }
        }
        if (r === 0) {
          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === childrenData.id;
            if (results) {
              this.checkedKeys.splice(j, 1)
            }
          }
        }
      }
    },
    handleNavigation(checkedKeys, data, childrenData, nDid, Did, nid) {
      let re = checkedKeys.includes(nid);
      if (re) {

        let result = checkedKeys.includes(nDid);
        let results = checkedKeys.includes(Did);
        if (!result) {
          this.checkedKeys.push(nDid);
        }
        if (!results) {
          this.checkedKeys.push(Did);
        }
      } else {
        let number = 0;
        for (let i = 0, len = childrenData.length; i < len; i++) {
          let results = this.checkedKeys.includes(childrenData[i].id);
          if (results) {
            number++;
          }
        }
        if (number === 0) {
          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === nDid;
            if (results) {
              this.checkedKeys.splice(j, 1)
            }
          }
        }
        let numbers = 0;
        for (let i = 0, len = data.length; i < len; i++) {

          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === (data[i].id);
            if (results) {
              numbers++;
            }
          }
        }
        if (numbers === 0) {
          for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
            let results = this.checkedKeys[j] === Did;
            if (results) {
              this.checkedKeys.splice(j, 1)
            }
          }
        }
      }
    },

    //数据提交
    goSubmit() {
      console.log(this.submitData);
    }
  },
}
</script>



<style scoped>
.container{
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
</style>
