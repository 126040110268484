<template>
    <div class="info-content">
        <el-form label-width="80px">
            <el-form-item label="区域高度">
                <el-slider
                v-model="height"
                :min="50"
                :max="500"
                @change="change"
                >
                </el-slider>
            </el-form-item>
            <!-- <el-form-item label="链接备注">
                <el-input type="textarea" :rows="4" v-model="list.remarks"></el-input>
            </el-form-item> -->
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'Link',
    props: ['data', 'className'],
    data() {
        return {
            list: {},
            height: 300,
        }
    },
    mounted() {
        this.height = this.data.data.height
        console.log('link height', this.data.data.height)
    },
    methods: {
        change() {
            this.data.data.height = this.height;
            this.$emit("change", this.data)
        }
    }
}
</script>

<style lang="scss" scoped>
.info-content{
    padding: 20px;
}
</style>