
const cfg = {
    'info': {
        tit: '页面信息',
        com: 'Info'
    },
    'images': {
        tit: '图片',
        icon: 'el-icon-picture',
        com: 'Images'
    },
    // 'banner': {
    //     tit: '轮播图',
    //     com: 'Images'
    // },
    // 'product': {
    //     tit: '商品',
    //     com: 'Product'
    // },
    'link': {
        tit: '链接',
        icon: 'el-icon-link',
        com: 'Link',
        data: {
            height: 200,
            top: 350,
        }
    },
}
export default cfg;