<template>
  <div>
    
    <el-tabs v-model="activeName1" @tab-click="handleClick1" style="margin:0">
      <el-tab-pane label="修改密码" name="first">
        <div class="person1 pandding" style="margin: 1vh 0 0 0vh">
          <!-- <div class="flex font14">账号</div>
          <div class="flex font14 margin_top">{{userinfo.name}}</div> -->
          <div class="flex" style>
            <el-form
              :label-position="labelPosition"
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="密码" prop="pass" >
                <el-input
                  placeholder="请输入原密码"
                  type="password"
                  v-model="ruleForm.pass"
                  autocomplete="off"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="checkPass" style="margin-top: 20px;">
                <el-input
                  placeholder="请输入新密码"
                  type="password"
                  v-model="ruleForm.checkPass"
                  autocomplete="off"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass1">
                <el-input
                  placeholder="请再次输入新密码"
                  type="password"
                  v-model="ruleForm.checkPass2"
                  autocomplete="off"
                  show-password
                ></el-input>
              </el-form-item>
              <el-form-item >
                <el-button
                  style="float:right;margin:20px 0 0 0"
                  type="primary"
                  @click="submitForm('ruleForm')"
                >确认修改</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="淘宝小程序" name="second" v-if="userinfo.is_admin != 1 && userinfo.is_admin != 2">
        <div class="person1 pandding" style="margin: 1vh 0 0 0vh" >
          <div v-for="(item,index) in tbdata" style="margin-bottom: 20px">
            <div class="flex font14">小程序名称：{{item.mininame}}</div>
            <div class="flex font14 margin_top">卖家号：{{item.nick}}</div>
            <div class="flex font14 margin_top">小程序到期时间：{{item.expiresTime}}</div>
            <div class="flex font14 margin_top">首次授权时间：{{item.firstAuthorizeTime}}</div>
            <div class="flex font14 margin_top">状态：{{item.msn}}</div>
            <div class="flex font14 margin_top">版本：{{item.versionName}}</div>
          </div>

          </div>
      </el-tab-pane> 
    </el-tabs>
  </div>
</template>
<script>
import { editPwd, userinfo,miniappuserlist } from "../../request/api.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入新密码"));
    //   } else if (value !== this.ruleForm.checkPass || this.ruleForm.checkPass) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      tbdata:[],
      userinfo:[],
      tapli: ["微信", "钉钉", "飞书"],
      idxon: 0,
      bomtxt: [
        {
          txt: "微信扫码关注绑定",
          id: 0
        },
        {
          txt: "钉钉扫码关注绑定",
          id: 1
        },
        {
          txt: "飞书扫码关注绑定",
          id: 2
        }
      ],
      radio: 3,
      labelPosition: "left",
      activeName1: "first",
      ruleForm: {
        pass: "",
        checkPass: ""
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        // checkPass: [{ validator: validatePass2, trigger: "blur" }]
      },
      token:''
    };
    
  },

  mounted() {
   this.userinfo =  JSON.parse(sessionStorage.getItem("userinfo"))
    console.log(this.userinfo)
    //  console.log(this.userinfo);
  // console.log(this.ruleForm.checkPass1);
    if(this.userinfo.is_admin != 1 && this.userinfo.is_admin != 2){
      this.getminiappuserlist()
    }
  },

  methods: {

    tapqie(idx) {
      this.idxon = idx;
    },
    getminiappuserlist(){
      miniappuserlist().then(res => {
        if(res.code == 1){
          this.tbdata = res.data
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let info = sessionStorage.getItem("Authorization");
          let bossinfo = localStorage.getItem("Authorization")
          if(info && !bossinfo){
            this.token = info;
          }else if(info && bossinfo){
            this.token = info;
          }else if(!info && bossinfo){
            this.token = bossinfo;
          }
          editPwd({
            Authorization: this.token,
            oldpass:this.ruleForm.pass,
            newpass:this.ruleForm.checkPass
          }).then(r=>{
            // console.log(r);
            if(r.code == 1){
              this.$message({
              message:'修改成功',
              type:'success',
            });
            }else{
              this.$message({
              message:'修改失败',
              type:'danger',
            });
            }
          })
          // alert("submit!");
        } else {
          console.log("密码错误");
          return false;
        }
      });
    },
    handleClick1(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style  scoped>
/deep/.el-tabs__header{
  margin: 0;
}
.nav-icon {
  display: flex;
  align-items: center;
}

.nav-icon span {
  font-size: 2vh;
  padding-left: 1vh;
}
.person1 {
  width: 100%;
  height: 700px;
  background-color: #fff;
}
.el-input {
  width: 560px;
}
.el-form--label-top .el-form-item__label {
  float: left;
  margin: 50px 0 0 0;
  line-height: 0;
}
.el-form-item {
  margin-bottom: 10px;
}
.box {
  width: 280px;
  height: 200px;
  border: 1px solid #aeb3cd;
}
</style>