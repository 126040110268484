<template>
  <div>
    <el-tabs v-loading="loading" v-model="activeName" @tab-click="tabClick">
      <!-- 一楼：搜索栏 -->
      <div class="flex" style="padding: 10px;">
        <columnclick
          style="margin: 0 0 0px 10px;"
          :param="'100001'"
          @accept="accountname"
          :columnsInfo="accountColumns"
          @confirm="toConfirm('account', $event)"
        ></columnclick>
        <el-button
          @click="handleAccount"
          type="primary"
          style="margin-left: 16px;width:120px;height:40px"
        >导出报表</el-button>
      </div>
      <el-tab-pane label name="account">
        <el-table border :data="array" style="width: 100%">
          <el-table-column fixed prop="accountName" label="账户" width="260"></el-table-column>
          <el-table-column fixed prop="operation" label="操作" width="180">
              <div
              slot-scope="zhnum"
              class="flex"
              style="display: flex;justify-content: space-around;"
              v-if="zhnum.row.accountName != '汇总'"
            >
              <div
                style="color: #0a63fc"
                class="cursor"
                @click="openAnalyse('account', array[zhnum.$index],zhnum)"
                type="primary"
              >详细分析</div>

              <div
                style="color: #0a63fc"
                class="cursor"
                @click="openTabs('account', array[zhnum.$index],zhnum,plantime)"
              >计划</div>
            </div>
          </el-table-column>
          <el-table-column
            width="150"
            v-for="(item, index) in filterAccountColumns"
            :prop="item.val"
            :label="item.name"
            sortable
            :key="index"
            v-if=" item.name != '操作'"
          ></el-table-column>
        </el-table>
        <div class="block flex_between margin_top">
          <span class="demonstration"></span>
          <!-- size-change 每页条数 -->
          <!-- current-change 当前页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 15, 50]"
            :page-size="1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(arrayAccount) ? Number(arrayAccount) : 0"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <drawer
      :orderFrom="orderfromAccount"
      :filterDateStart="filterDateStartAccount"
      :filterDateEnd="filterDateEndAccount"
      :right_paramsPlan="right_params"
      ref="analyseView"
      :costaccount="costaccount"
      :clickaccount="clickaccount"
      :convertNumaccount="convertNumaccount"
      :payAmountaccount="payAmountaccount"
      :roiaccount="roiaccount"
      :showNumaccount="showNumaccount"
    ></drawer>
  </div>
</template>

<script>
import {
  subaccountdata,
  accountlis,
  commodity,
  downloadChildAccountData,
  cornMetricsByKeyId,
  columndefault
} from "@/request/api.js"; //
import columnclick from "./goods_columnclick";
import drawer from "./goods_drawer";
import advertisement from "./goods_advertisement";
import $utils from "@/js/utils";
const CFG_NAME = "good_tab_config_account";
export default {
  components: {
    columnclick,
    drawer,
    advertisement
  },
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          goodIds: [],
          acticityId: "",
          targetIds: [],
          targetType: "",
          roiMax: "",
          roiMin: "",
          costMax: "",
          costMin: "",
          orderFrom: ""
        };
      }
    },
    orderchange2: {
      type: String,
      default: ""
    },
    parmval: {
      type: String,
      default: ""
    },
    // 子账户账户筛选数据包传递
    accountarrayAccount: {
      type: Array,
      default: ""
    },
    totalnumAccount: {
      type: null,
      default: ""
    },
    orderfromAccount: {
      type: String,
      default: ""
    },
    valgoodsAccount: {
      type: Array,
      default: ""
    },
    keywordAccount: {
      type: String,
      default: ""
    },
    filterDateStartAccount: {
      //从父组件传递开始时间
      type: String,
      default: ""
    },
    filterDateEndAccount: {
      //从父组件传递结束时间
      type: String,
      default: ""
    },
    val: {
      type: Array,
      default: ""
    },
    right_params: {
      type: String,
      default: ""
    },
    valone1: {
      type: Number,
      default: ""
    }
  },
  data() {
    return {
      costaccount:"",
      clickaccount:"",
      convertNumaccount:"",
      payAmountaccount:"",
      roiaccount:"",
      showNumaccount:"",
      orderFrom: "",
      filterDateEnd: "",
      filterDateStart: "",
      right_paramsAccount: "",
      checked: "false",
      tab: "",
      loading: false,
      activeName: "account",
      filterAccountData: null,
      page: 1,
      pageSize: 10,
      totalnum: 0,
      filterType: "",
      accountColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "展现及花费",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "展现及花费",
          name: "订单成本",
          val: "orderCost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "转化及成交",
          name: "平均点击单价",
          val: "cpc"
        }
      ],
      adgroupColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        },
        {
          type: "展现及花费",
          name: "当日转化ROI",
          val: "convertRoi"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        }
      ],
      // 子账户数据表格表头数据列
      planColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "展现及花费",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "展现及花费",
          name: "订单成本",
          val: "orderCost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "转化及成交",
          name: "平均点击单价",
          val: "cpc"
        }
      ],
      array: [],
      arrayAccount: {
        type: Number,
        default: 1
      },
      midell: this.valone1,
      plantime: true
    };
  },
  watch: {
    // filterData(newVal) {
    //   this.handlerFilterAccount();
    // }
    filterData: {
      handler(newVal) {
        console.log("goods_tabs filter data handler", newVal);
        // this.handlerFilterAccount();
      },
      deep: true
    },
    parmval(e) {
      this.handlerFilterAccount(e);
    },
    accountarrayAccount(accountarrayAccount) {
      this.array = this.accountarrayAccount;
    },
    totalnumAccount(totalnumAccount) {
      this.arrayAccount = this.totalnumAccount;
    },
    parmval(e) {
      this.handlerFilterAccount(e);
    },
    valone1(valone1) {
      console.log(valone1, "console.log(this.valone1);");
      this.page = this.valone1;
    },
    page(page, midell) {
      console.log(this.page, "page");
      this.midell = this.page;
    }
  },
  computed: {
    filterAccountColumns() {
      return this.accountColumns.filter(ele => ele.checked);
    },
    filterAdgroupColumns() {
      return this.adgroupColumns.filter(ele => ele.checked);
    },
    filterPlanColumns() {
      return this.planColumns.filter(ele => ele.checked);
    }
  },
  // orderchange2动态订单来源下标
  mounted() {
    console.log();
    // this.handlerFilterAccount();
    if (this.valone1) {
      this.page = this.valone1;
    }
    columndefault({
        btnCode: "100001",
      }).then((res) => {
        
        let list = res.data.sort((a, b) => {
          return a.colTypeCode - b.colTypeCode;
        });
        let data1 = list[0].colTypeCode;
        let typeList = ["属性指标", "展现及花费", "转化及成交"];
        let index = 0;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          list[i].name = item.colName;
          list[i].val = item.colField;
          if (data1 == item.colTypeCode) {
            list[i].type = typeList[index];
          } else {
            data1 = item.colTypeCode;
            index++;
            list[i].type = typeList[index];
          }
        }
        this.accountColumns = list;
        console.log(this.planColumns,'this.planColumns')
        this.accountColumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.accountColumns.forEach((ele, idx) => {
          // ele.checked = true;
          
          this.$set(this.accountColumns[idx], "checked", true);
        });
      });
  },
  created() {
    let cfg = sessionStorage.getItem(CFG_NAME);
    if (cfg) {
      cfg = JSON.parse(cfg);
      if (cfg.account) {
        this.accountColumns = cfg.account;
      }
      if (cfg.adgroup) {
        this.adgroupColumns = cfg.adgroup;
      }
      if (cfg.plan) {
        this.planColumns = cfg.plan;
      }
    }
    this.accountColumns.forEach((ele, idx) => {
      if (
        this.accountColumns[idx].checked == undefined ||
        this.accountColumns[idx].checked == null
      ) {
        this.$set(this.accountColumns[idx], "checked", true);
      }
    });
    this.adgroupColumns.forEach((ele, idx) => {
      if (
        this.adgroupColumns[idx].checked == undefined ||
        this.adgroupColumns[idx].checked == null
      ) {
        this.$set(this.adgroupColumns[idx], "checked", true);
      }
    });
    this.planColumns.forEach((ele, idx) => {
      if (
        this.planColumns[idx].checked == undefined ||
        this.planColumns[idx].checked == null
      ) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
  },
  methods: {
    // // 账户下载表格
    handleAccount() {
      downloadChildAccountData({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartAccount,
        filterDateEnd: this.filterDateEndAccount,
        orderFrom: this.orderfromAccount,
        accountIds: this.val,
        search: this.keywordAccount,
        btnCode: "100001",
        goodIds: this.valgoodsAccount
      }).then(res => {
        console.log(res);
        $utils.exportRaw(res.data.filename, res.data.file);
      });
    },
    accountname(accountname) {
      console.log(accountname);
      this.name = accountname.colName;
    },
    openAnalyse(type, data, tabId, plantime) {
      this.$refs.analyseView.show(type, data, tabId, plantime);
      console.log(type, "type", data, "data", tabId, "tabbbbbb");
         this.chooseKeyIdaccount=data.accountId
      cornMetricsByKeyId({
           filterDateStart: this.filterDateStartAccount,
           filterDateEnd: this.filterDateEndAccount,
            chooseKeyType:"account",
            chooseKeyId: this.chooseKeyIdaccount,
            orderFrom:this.orderchange2,
      }).then(res=>{
            console.log(res.data);
            this.costaccount=res.data.cost
            this.clickaccount=res.data.click
            this.convertNumaccount=res.data.convertNum
            this.payAmountaccount=res.data.payAmount
            this.roiaccount=res.data.roi
            this.showNumaccount=res.data.showNum
      })
    },

    toConfirm(type, data) {
      console.log("toConfirm", type, data);
      if (type == "account") {
        this.accountColumns = data;
      } else if (type == "adgroup") {
        this.adgroupColumns = data;
      } else {
        this.planColumns = data;
      }
      // 缓存选中列
      let cfg = sessionStorage.getItem(CFG_NAME);
      if (cfg) {
        cfg = JSON.parse(cfg);
      } else {
        cfg = {};
      }
      cfg[type] = data;
      sessionStorage.setItem(CFG_NAME, JSON.stringify(cfg));
    },
    // tabs切换事件
    tabClick(tab, page) {
      tab = tab.name;
      console.log(tab);
      this.filterType = "";
      this.filterAccount = "";
      this.handleClick(tab, 1);
    },
    handleClick(tab, page) {
      this.page = page;
      // console.log(this.page, "this.page11111111111111111111111111");
      this.handleFilterPlan();
    },
    openTabs(tab, item) {
      console.log(item.accountId);
      this.$emit("showPlan", item.accountId);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleClick(this.activeName, 1);
    },
    //*************计划分页点击发起请求
    handleCurrentChange(val) {
      console.log(val, "123");
      this.handleClick(this.activeName, val);
    },
    // 点击tabs请求
    handlerFilterAccount(type) {
      if (
        !this.filterData.acticityId ||
        this.filterData.acticityId.length == 0
      ) {
        return;
      }
      let param = {
        tabType: type, //页签类别
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      param = Object.assign(param, this.filterData);
      this.subaccountdata(param).then(res => {
        this.array = res.data;
        res.sum.accountName = '汇总';
        this.array.unshift(res.sum);
        // console.log( this.array,' this.array')
        this.array.forEach(showNum => {
          showNum.showNum = parseInt(showNum.showNum);
          // console.log(showNum.showNum);
        });
        this.array.forEach(click => {
          click.click = parseInt(click.click);
        });
        this.array.forEach(convertNum => {
          convertNum.convertNum = parseInt(convertNum.convertNum);
        });
        this.arrayAccount = res.total;
        // console.log(this.arrayAccount, "this.arrayAccount");
      });
    },
    handleFilterPlan(type, account) {
      this.activeName = "account";
      // console.log(type, account,'123456')
      
      let param = {
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartAccount,
        filterDateEnd: this.filterDateEndAccount,
        orderFrom: this.orderfromAccount,
        accountIds: this.val,
        search: this.keywordAccount,
        goodIds: this.valgoodsAccount,
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      console.log(param,'param')
      this.loading = true;
      this.subaccountdata(param).then(res => {
        this.loading = false;
        // console.log("子账户", res.data);
        this.array = res.data;
        
        // console.log(this.array,'aaaaaaa')
        this.arrayAccount = res.total;
        // console.log(this.array);
      });
    },

    subaccountdata(param) {
      return new Promise((resovle, reject) => {
        this.loading = true;
        if (param["filterDateStart"]) {
          // console.log("报表tab页接口", param);
          console.log(JSON.stringify(param.acticityId),'获取到的');
          this.acid = JSON.stringify(param.acticityId);
          subaccountdata(param)
            .then(res => {
              this.loading = false;
              resovle(res);
            })
            .catch(e => {
              reject(e);
            });
        }
      });
    }
  }
};
</script>
 <style scoped>
.el-table th.el-table__cell > .cell {
  width: 400px;
}

/deep/.el-select__tags-text:first-child {
  width: 30px;
}
.el-table th.el-table__cell > .cell {
  width: 400px;
}
/deep/.el-tabs__active-bar {
  display: none;
}
/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/#tab-account {
  position: absolute;
}

#pane-account {
  padding: 18px;
}
</style>