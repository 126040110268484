<template>
  <div class="login-container">
    <!-- 授权页面 -->
    <div class="box" v-if="showAuth == 0">
      <h2 class="wz">星川数据淘宝联盟授权</h2>
      <!-- 输入框 -->
      <div class="input-user"></div>
      <div class="input-pwd"></div>

      <!-- 登录按钮 -->
      <el-button
        @click="show(1)"
        type="primary"
        :disabled="!checked"
        @keyup.enter="keyDown(e,1)"
        >去授权</el-button
      >
    </div>

    <!-- 授权信息页面 -->
    <div class="box" v-if="showAuth == 1">
      <h3 class="wz" style="margin-bottom:0% !important;">授权成功</h3>
      <h4 class="wz" style="margin-bottom:2vh !important;">请完善以下信息</h4>
      <!-- 输入框 -->
      <div class="input-user">
        <el-input ref="input1" placeholder="请输入姓名" v-model="input1">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </div>
      <div class="input-pwd">
        <el-input
          placeholder="请输入手机号"
          v-model="input2"
          ref="telnumber"
        >
          <i slot="prefix" class="el-input__icon el-icon-phone"></i>
        </el-input>
      </div>
      <div class="input-sel">
        <el-select v-model="fzvalue" placeholder="请选择分组" style="width: 100%;" @change="changeFzValue">
          <el-option
            v-for="item in fzoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="input-sel">
        <el-select v-model="lxvalue" placeholder="请选择类型" style="width: 100%;" @change="changeLxValue"> 
          <el-option
            v-for="item in lxoptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 登录按钮 -->
      <el-button
        style="margin-top:2vh;"
        @click="show(2)"
        type="primary"
        :disabled="!checked"
        @keyup.enter="keyDown(e,2)"
        >完成</el-button
      >
    </div>

    <!-- 授权成功页面 -->
    <div class="box" v-if="showAuth == 2">
      <h4 class="wz">信息填写完成,谢谢支持</h4>
      <el-button
        style="margin-top:2vh;"
        @click="opentb"
        type="primary"
        :disabled="!checked"
        >打开淘宝联盟网站</el-button
      >
    </div>
  </div>
</template>

<script>
import { getAccountTypeList,authorizationInfo } from "@/request/api";

export default {
  data() {
    return {
        fzoptions: [
        {
          value: "1",
          label: "凤梨",
        },
        {
          value: "2",
          label: "元英",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
      fzvalue: "1",
      lxoptions: [
        {
          value: "0",
          label: "个人",
        },
        {
          value: "1",
          label: "公司",
        },
      ],
      lxvalue: 0,
      showAuth: "0",
      contain: "contain",
      yzmimg: "",
      yzmtoken: "",
      yzm: "",
      loginTitle: "",
      loginBa: "",
      input1: "",
      input2: "",
      checked: true,
      alimama_id:null,
    };
  },
  mounted() {

    this.getAccountTypeList();
    window.addEventListener("keydown", this.keyDown);
    // console.log(this.$route,'this/')
    if(this.$route.query.alimama_id){
      this.showAuth = 1;
      this.alimama_id = this.$route.query.alimama_id;
    }else{
      this.showAuth = 0
    }
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {

    opentb(){
      window.location.href= 'https://pub.alimama.com/'
    },
    changeFzValue(e){
      this.fzvalue = e;
    },
    changeLxValue(e){
      this.lxvalue = e;
    },
    //获取个人下拉
    getAccountTypeList(){
      getAccountTypeList({}).then(r=>{
        this.lxoptions = r.data;
        this.lxvalue = r.data[0].id
        // console.log(r,'r')
      })
    },
    // 点击回车键登录
    keyDown(e,number) {
        
      if (e.keyCode == 13) {
        this.showAuth = number;
        this.show(number);
      }
    },
    show(e) {
      // console.log(this.$route.query.alimama_id,'this/')
        if(e == 1){
          this.showAuth = e
          window.location.href= 'https://oauth.taobao.com/authorize?response_type=code&client_id=25016850&redirect_uri=http%3a%2f%2fcqapi.xiaojiazhuli.com%2ftb%2fcallback.php&state=qupeng5@eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1hbmFnZXIiLCJhdWQiOiIzYTkyMDQzMTk5YTFlNDhkMWNlOSIsImlhdCI6MTY2Njc0ODQ2MCwibmJmIjoxNjY2NzQ4NDYwLCJleHAiOjE2NjY5MjEyNjAsImlwIjoiMTgyLjE0OS4xNjEuNTYifQ.EOHmkBN_peQzgrfQKpkpGQhA1KAaEDYLyh3XEu3HFUE&view=web'
            return
        }else{
          authorizationInfo({
            name:this.input1,
            mobile:this.input2,
            grouping:this.fzvalue,
            account_type:this.lxvalue,
            alimama_id:this.alimama_id
          }).then(r=>{
            if(r.code == 1){
              this.showAuth = 2
            }
            // console.log(r,'r')
          })
        }
    },
  },
};
</script>

<style lang="scss" scoped>
// 背景最大容器
.login-container {
  height: 100%;
  width: 100%;
  background-color: rgb(45, 58, 75);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 盒子容器：登录界面
// .login-container .box{
//     border: 1px solid black;
// }
// 登录标题
.login-container .wz {
  color: white;
  margin-bottom: 7vh;
}

// 登录输入框
.login-container .input-user {
  width: 55vh;
  // padding-bottom: 6vh;
}

.login-container .input-pwd {
  width: 55vh;
  padding-top: 4vh;
}
.login-container .input-sel{
    width: 55vh;
  padding-top: 4vh;
}
.input_yz {
  width: 55vh;
  padding-bottom: 3vh;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-input-group__append {
  width: 30% !important;
}
// 隐私政策
.login-container .checkbox {
  padding-bottom: 3vh;
}

.login-container .agree {
  color: white;
}

// 登录按钮
.login-container .el-button {
  width: 30vh;
}
.footer {
  position: absolute;
  bottom: 10px;
  color: white;
  text-align: center;
  font-size: 14px;
}
@media screen and (max-width: 1200px) {
  .box {
    width: 100% !important;
  }
  .input-user {
    width: auto !important;
    // padding-bottom: 6vh;
  }

  .input-pwd {
    width: auto !important;
    padding-top: 4vh;
  }
.input-sel{
    width: auto !important;
    padding-top: 4vh;
}
}
</style>