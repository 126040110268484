<template>
  <div class="detail">
    <!-- 标题栏 -->
    <div class="f3">
      <div class="f3-1">
        <span>
          <el-input
            style="width:31vh;"
            type="text"
            placeholder="请输入活动名称"
            v-model="activityInfo.name"
            maxlength="20"
            show-word-limit
            clearable
          >
          <template slot="append"><el-button type="primary" @click="save" >保存</el-button></template>
          </el-input>
        </span>
      </div>
      <div class="f3-2">
        <el-popconfirm
          v-if="id"
          title="是否保存修改内容"
          @confirm="save"
          @cancel="left"
        >
        <el-button slot="reference" class="title2" type="primary">
          返回
        </el-button>
        </el-popconfirm>
        <el-button v-else slot="reference" class="title2" type="primary" @click="left">
          返回
        </el-button>
      </div>
    </div>
    <!-- 基本信息内容 -->
    <div class="f1" v-loading="loading">
      <div class="c2">
        <el-date-picker
          :disabled="activityInfo.activity_type == 1"
          v-model="dates"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-radio-group v-model="activityInfo.activity_type">
            <el-radio-button label="2" icon="share"
              >短期投放
              <el-tooltip
                class="item"
                effect="dark"
                content="短期投放适用于阶段性，非持续性的营销活动"
                placement="top"
              >
                <i class="el-icon-warning"></i> </el-tooltip
            ></el-radio-button>
            <el-radio-button label="1"
              >长期投放
              <el-tooltip
                class="item"
                effect="dark"
                content="长期投放适用于持续性的营销活动"
                placement="top"
              >
                <i class="el-icon-warning"></i> </el-tooltip
            ></el-radio-button>
          </el-radio-group>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-radio-group v-model="activityInfo.platform" @change="changeplatform">
            <el-radio-button label="1" >巨量引擎</el-radio-button>
            <el-radio-button label="2" >腾讯广告</el-radio-button>
          </el-radio-group>
      </div>
    </div>
    <!-- 关联内容 -->
    <div class="f2" v-loading="loading">
      <div class="a">
        <div class="a1">
          <el-input placeholder="请输入账户名称和ID" v-model="filterInput" @input='filters(newArr,filterInput)' clearable>
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="a2">
          <el-popconfirm
            title="确定删除吗？"
            @confirm="delrelate"
          >
            <el-button slot="reference" type="danger" icon="el-icon-delete">删除关联</el-button>
          </el-popconfirm>&nbsp;
          <el-button
            type="primary"
            icon="el-icon-document-add"
            @click="dialogTableVisible = true, selectPage(tableDataShowArr)"
            >关联账户</el-button
          >
          <el-dialog 
            title="关联账户" 
            :visible.sync="dialogTableVisible" 
            center
            :close-on-click-modal="false"
            @open="Relation"
          >
            <div class="search">
              <el-input placeholder="请输入账户名称和ID" v-model="filterIdAndName" style="width:25vh" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
              <el-checkbox v-model="checked"  @change="showAdd">只显示新增账户</el-checkbox>
            </div>
            <el-table
              :data="gridDataFilter"
              height="550"
              v-loading="tableloading"
              style="width: 100%"
              row-key="code"
              ref="multipleTable"
              default-expand-all
              @selection-change="handleSelectionChange"
              :indent="50"
              :select-on-indeterminate="false"
              @select="select"
              @select-all="selectAll"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column
                type="selection"
                prop="checkbox"
                label="全选"
              ></el-table-column>
              <el-table-column
                property="name"
                label="账户名称"
                width="330"
              ></el-table-column>
              <el-table-column property="remark" label=""></el-table-column>
              <el-table-column property="code" label="账户ID"></el-table-column>
            </el-table>
            <!-- 关联用户按钮 -->
            <div class="btn">
              <el-button type="primary" @click="relate">确定关联</el-button>
            </div>
          </el-dialog>
          <!-- 嵌套结束 -->
        </div>
      </div>
      <div class="a3">
        <el-table
          :data="tableDataShowArr"
          style="width: 100%; max-height: 250"
          row-key="code"
          default-expand-all
          ref="multipleTable2"
          @selection-change="handleSelectionChange"
          :indent="50"
          :select-on-indeterminate="false"
          @select="select"
          @select-all="selectAll"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            type="selection"
            prop="checkbox"
            label="全选"
            width="150"
          ></el-table-column>
          <el-table-column property="name" label="账户名称"></el-table-column>
          <el-table-column property="code" label="账户ID"></el-table-column>
        </el-table>
      </div>
    </div>
 <!-- 关联投放表格结束 -->
    <!-- 监测链接标题 -->
    <!-- <div class="title5" v-if="id">监测链接</div> -->
    <!-- 监测链接 内容 -->
    <div class="f4" v-if="id">
      <div class="f4-1">
        <div class="f4-11">已过审且在投的计划需要手动添加监测链接</div>
        <div class="f4-12">
          <el-button
            v-if="tabData.length > 0"
            type="primary"
            @click="exportFile"
            >导出全部监测链接</el-button
          >
        </div>
      </div>
      <!-- 表格内容 -->
      <div class="a4" v-loading="loading">
        <el-table :data="tabData" stripe style="width: 100%; padding-left: 3vh">
          <el-table-column prop="name" label="名称" width="400">
          </el-table-column>
          <el-table-column prop="url" label="链接" width="1200">
          </el-table-column>
        </el-table>
      </div>
    </div>
    
  </div>
</template>

<script>
import { saveedit, relation, edit } from "../../request/api";
import $utils from "@/js/utils.js";
import { createLogger } from 'vuex';

export default {
  data() {
    return {
      checked:false,
      saveType:'save',
      tableloading:true,
      loading:true,
      // 链接的表格
      tabData:[],
      filterIdAndName:'',
      filterInput:'',
      activityInfo: {
        name: "",
        activity_type: 1,
        platform: 1,
        start_time: "",
        end_time: "",
      },
      id: null,
      selectArr: [],
      checkedKeys: true,
      multipleTable: [], //存放选中值的数组

      text: "",
      input4: "",
      tabPosition: "top",
      activeName: "first",
      dates: "",
      radio1: "1",
      radio2: "单品投放",
      input1: "",
      // 关联账户的数据
      // 嵌套的
      gridData: [],
      // 外层的
      tableData: [],
      dialogTableVisible: false,
      newArr:[],
      tableDataShowArr:[],
      copyplatform: null,
    };
  },//开始

  computed: {
    // 搜索过滤里层
    gridDataFilter() {
      console.log('grid', this.gridData)
      if (this.filterIdAndName == "") {
        return this.gridData;
      }
      let ret = [];
      this.gridData.forEach(parent => {
        let cloneParent = JSON.parse(JSON.stringify(parent));
        parent.children.forEach(ele => {
           if (ele.name.indexOf(this.filterIdAndName) != -1  || ele.code.indexOf(this.filterIdAndName) != -1 ) {
             let findParent = ret.find(e => e.code == parent.code)
             if (findParent) {
               findParent.children.push(ele)
             } else {
               cloneParent.children = [ele];
               ret.push(cloneParent)
             }
          }
        })
      })
      return ret;//this.gridData.filter(ele => ele.name.indexOf(this.filterIdAndName) != -1)
    },
  },
  mounted() {
    if (this.$route.query.data) {
      let param = JSON.parse(this.$route.query.data);
      console.log(param,'param');
      this.activityInfo.platform = param.platform
      // console.log(this.activityInfo.platform,'this.activityInfo.platform');
      this.saveType = "edit";
      this.id = param.id
      this.copyplatform = param.platform
    }
    this.Relation();
    this.initDate();

  },
  methods: {
    changeplatform(e){
      console.log(e,'eeeeeeeeeeeeee')
      this.activityInfo.platform = e;
      this.copyplatform = e;
      this.Relation();
    },
    filters(arr, val) {
        let newArr = JSON.parse(JSON.stringify(arr))
        let abc = this.filterTree(newArr, val)
        // console.log(abc)
        this.tableDataShowArr = abc
    },
    filterTree(nodes, query) {
      // 条件就是节点的title过滤关键字
      let predicate = function(node) {
        if (node.name.includes(query) || node.name.includes(query)) {
          return true;
        } else {
          return false;
        }
      };
      let predicate2 = function(node) {
        if (node.code.includes(query) || node.code.includes(query)) {
          return true;
        } else {
          return false;
        }
      };
      // 结束递归的条件
      if (!(nodes && nodes.length)) {
        return [];
      }
      let newChildren = [];
      for (let node of nodes) {
        let subs = this.filterTree(node.children, query);
        if ( predicate(node) || predicate2(node) ) {
          newChildren.push(node);
        } else if (subs && subs.length) {
          node.children = subs;
          newChildren.push(node);
        }
      }
      return newChildren.length ? newChildren : [];
    },
    tableDataShow() {
      let data = [];
      this.tableData.forEach((ele) => {
        let parent = null;
        this.gridData.forEach((grid) => {
          let idx = grid.children.findIndex((e) => e.code == ele.code);
          if (idx != -1) {
            parent = grid;
          }
        });
        if (!parent) {
          return
        }
        // ele.code
        let has = data.find((e) => e.code == parent.code);
        if (has) {
          has.children.push(ele);
        } else {
          parent = JSON.parse(JSON.stringify(parent));
          parent["children"] = [ele];
          data.push(parent);
        }
      });
      console.log('dddd', data)
      // return data;
      this.tableDataShowArr = data
      this.newArr = JSON.parse(JSON.stringify(data))
      console.log(this.tableDataShowArr)
      this.$forceUpdate()
      this.$nextTick(()=>{
        this.selectPage(this.tableDataShowArr)
      })  
    },
    
    // 默认关联
    selectPage(data){
      data.forEach(e=>{
        if(e.children){
          e.children.map((j) => {
          this.toggleSelection(j, true);
        });
        }
      })
    },

    // 新增显示
    showAdd(e) {
      this.tableloading = true
      this.checked = e
      if(e){
        let data =  this.setdatalist(this.gridData)
        this.gridData = data
        this.tableloading =false
      }else{
        this.Relation()
      }
    },
    // 导出
    exportFile() {
      let name =
        "活动" +
        this.activityInfo.name +
        "_" +
        $utils.format(new Date(), "yyyy-mm-dd") +
        ".txt";
      let data = "活动" + this.activityInfo.name + "\r\n";
      this.tabData.forEach((ele) => {
        data += ele.name + ":" + ele.url + "\r\n";
      });
      $utils.exportRaw(name, data);
    },
    // 全选方法
    select(selection, row) {
      console.log('select', selection, row)
      if (
        selection.some((el) => {
          return row.id === el.id;
        })
      ) {
        if (row.children) {
          row.children.map((j) => {
            this.toggleSelection(j, true);
          });
        }
      } else {
        if (row.children) {
          row.children.map((j) => {
            this.toggleSelection(j, false);
          });
        }
      }
    },
    selectAll(selection) {
      // console.log('selectAll')
      // tabledata第一层只要有在selection里面就是全选
      const isSelect = selection.some((el) => {
        const gridDataIds = this.gridData.map((j) => j.id);
        return gridDataIds.includes(el.id);
      });
      // tableDate第一层只要有不在selection里面就是全不选
      const isCancel = !this.gridData.every((el) => {
        const selectIds = selection.map((j) => j.id);
        return selectIds.includes(el.id);
      });
      if (isSelect) {
        selection.map((el) => {
          if (el.children) {
            el.children.map((j) => {
              this.toggleSelection(j, true);
            });
          }
        });
      }
      if (isCancel) {
        this.gridData.map((el) => {
          if (el.children) {
            el.children.map((j) => {
              this.toggleSelection(j, false);
            });
          }
        });
      }
    },
    handleSelectionChange(selection) {
      this.selectArr = selection;
      // console.log(this.selectArr,123456)
    },
    toggleSelection(row, select) {
      if (row) {
        this.$nextTick(() => {
          this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection(row, select);
          this.$refs.multipleTable2 && this.$refs.multipleTable2.toggleRowSelection(row, select);
        });
      }
    },
    cancelAll() {
      this.$refs.multipleTable.clearSelection();
    },

    // 全选方法 2
    select2(selection, row) {},
    selectAll2(selection) {},
    handleSelectionChange2(selection) {},
    cancelAll() {},


    handleSelectionChange(val) {
      this.multipleTable = val; //  this.multipleTable 选中的值
      // console.log(this.multipleTable,654);
    },
    relate() {
      console.log(this.multipleTable)
      this.tableData = this.multipleTable.filter(ele => !ele.children);
      console.log("this.tableData", this.tableData);
      this.dialogTableVisible = false
      this.tableDataShow()
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    left() {
      this.$router.push("/manage/Manage");
    },
    // 保存
    save() {
      let arr = this.multipleTable.map((item) => {
        return item.id;
      });

      let param = Object.assign(this.activityInfo, {
        platform: this.activityInfo.platform,
        status: "1",
        type: this.saveType,
        activityId: this.id,
        connected_account: arr,
        activityType: this.activityInfo.activity_type,
      });
      if (this.id) {
        param["activityId"] = this.id;
      }
      console.log("param", param);
      param["start_time"] = this.dates[0]; //parseInt(new Date( this.dates[0]).getTime() / 1000);
      param["end_time"] = this.dates[1]; //parseInt(new Date( this.dates[1]).getTime() / 1000);
      
      saveedit(param).then((r) => {
        console.log(r);
        if (r.code == 1) {
          this.$message({
            message:'保存成功',
            type:'success',
          });
          setTimeout( ()=>{
            this.$router.push("/manage/Manage");
          },1200)
        }else{
          this.$message({
            message:'保存失败',
            type:'error',
          })
        }
      });
    },

    // 删除关联
    delrelate(index) {
      this.tableDataShowArr.splice(index);
    },
    // 活动详情
    detail() {
      edit({
        activityId: this.id,
        type: "list",
        // platform: this.activityInfo.platform
      }).then((res) => {
        this.loading = false;
        console.log("detail", res);
        //   this.radio1 = this.listData.activityType;
        this.activityInfo = res.data.activityInfo;
        this.activityInfo.platform = this.copyplatform
        console.log(this.activityInfo,'this.activityInfo')
        let sdate = new Date(this.activityInfo.start_time);
        let edate = new Date(this.activityInfo.end_time);
        if (
          isNaN(sdate) ||
          isNaN(edate) ||
          sdate.getTime() == 0 ||
          edate.getTime() == 0
        ) {
          this.initDate();
        }
        //回显数据
        let lists = [];
        res.data.children.forEach(ele => {
          let obj = null;
          console.log(this.gridData,'this.gridData')
          this.gridData.forEach(e => {
            // console.log(e,'e')
            if (e.children) {
              let o = e.children.find(account => {
                // console.log("CCCCC", account.code , ele.account_id)
                return account.code == ele.account_id
              })
              if (o) {
                obj = o
              }
            }        
          });
          console.log(obj,'boj')
          if (obj) {
            lists.push(obj);
          } else {
            console.error("没有找到对应帐户！", ele);
          }
        })
        // console.log("edit detail ", lists);
        this.tableData = lists;
        this.tabData = res.data.platform;
        this.tableDataShow()
        // this.gridData.forEach((item) => {
        //   this.tableData.forEach((i) => {
        //     if (item.code == i.account_id) {
        //       this.multipleTable.push(item);
        //     }
        //   });
        // });
      });
    },
    // 关联列表
    Relation() {
      console.log(this.activityInfo.platform,'activityInfo.platform')
      this.tableloading = true
      relation({
        platform: this.activityInfo.platform
      }).then((r) => {
        console.log(r,'123333' );
        this.gridData = r.data;
        this.tableloading = false
        if (this.id) {
          this.detail();
        }else{
          this.loading = false
        }
      });
    },
    // 新增显示
    setdatalist(data){
      let newdata =[...data]
      let setdata = []
      for(let i=0;i<data.length;i++){
        newdata[i].children = newdata[i].children.filter( item => item.remark == "")  
      }
      setdata = newdata.filter(item=>item.children.length>=1)
      return setdata
    },

    initDate() {
      let arr = [
        new Date(),
        new Date(new Date().getTime() + 3600 * 24 * 1000 * 30),
      ];
      this.activityInfo.start_time = $utils.format(arr[0], "yyyy-MM-dd");
      this.activityInfo.end_time = $utils.format(arr[1], "yyyy-MM-dd");
      this.dates = [this.activityInfo.start_time, this.activityInfo.end_time];
    },

  },

  
};
</script>

<style scoped>

/* 子级图标隐藏 */
.detail /deep/.el-table [class*=el-table__row--level-1] .el-table__expand-icon {
  opacity: 0;
  cursor: auto;
  margin-right: 0;
}

/* // 基本信息内容 */
.detail .f1 {
  margin-top: 2vh;
  background-color: #fff;
}
.detail .f1 .c1 {
  display: flex;
  align-items: center;
  padding: 2vh;
}
.detail .f1 .c2 {
  text-align: left;
  margin-left: 2vh;
  padding: 2vh 0 2vh 0;
}
.detail .f1 .c3 {
  display: flex;
  align-items: center;
  /* padding: 2vh; */
}
/* // 关联投放标题 */
.detail .title4 {
  margin: 2vh 0 0 4vh;
  text-align: left;
}
/* // 关联内容 */
.detail .f2 {
  /* margin-top: 2vh; */
  background-color: #fff;
}
.detail .f2 .a {
  display: flex;
  justify-content: space-between;
}
.detail .f2 .a1,
.a2 {
  display: inline-block;
  padding: 2vh;
}
.detail .f2 .a2 .search {
  /* width: 95vh; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail .f2 .a3 {
  text-align: center;
}
.detail .f2 .a2 .btn {
  text-align: center;
  margin-top: 1.5vh;
}
/* // 底部样式 */
.detail .f3 {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* margin: 20px 0; */
  /* box-shadow: 0 2px 4px rgba(187, 55, 55, 0.12), 0 0 6px rgba(31, 29, 29, 0.04); */
}

.detail .f3-1,.f3-2 {
  display: flex;
  align-items: center;
}
/* // 按钮提示文字样式 */
.detail .el-icon-warning {
  color: rgb(214, 214, 214);
}
.detail .el-icon-warning:hover {
  color: rgb(15, 111, 207);
}

.detail .title5 {
  text-align: left;
  margin: 2vh 0 2vh 4vh;
}
.detail .f3 .a1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail .f3 .a1-1 {
  padding-left: 2vh;
}
.detail .f3 .a1-2 {
  padding: 1vh 2vh 0 0;
}
.detail .f4{
  background-color: #fff;
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.detail .f4-1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 3.5vh;
}

.el-dialog__header{
    padding: 20px 20px 10px !important;
}


</style>