import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import * as echarts from "echarts";
import '@/permission'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
export const eventBus = new Vue()
Vue.prototype.$echarts = echarts;
// Vue.config.productionTip = false
Vue.use(ElementUI);



// 引入axios模块
import axios from 'axios'
// 将axios挂载到vue的原型上
Vue.prototype.axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 我们在main.js中用常量创建一个bus，然后将它放入Vue实例的原型对象中。
const bus = new Vue()
Vue.prototype.$bus = bus;