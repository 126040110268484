 <!-- 活动监控账户详细数据中详细分析第一个账户弹窗抽屉 --> 
<template>
  <div v-loading="isloading">
    <div v-if="drawdata">
      <el-drawer
        :append-to-body="true"
        title
        :visible.sync="isShow"
        :with-header="false"
        size="45%"
      >
        <div v-if="this.right_paramsPlan == 'plan'" class="pandding drawer_head">计划详情</div>
        <div v-if="this.right_paramsPlan == 'account'" class="pandding drawer_head">子账户详情</div>
        <div v-if="this.right_paramsPlan == 'mainAccount'" class="pandding drawer_head">主账户详情</div>
        <div v-if="this.right_paramsPlan == 'company'" class="pandding drawer_head">公司数据详情</div>

        <div class="pandding flex drawer_id">
          <div v-if="this.right_paramsPlan == 'plan'">计划:</div>
          <div v-if="this.right_paramsPlan == 'account'">子账户:</div>
          <div v-if="this.right_paramsPlan == 'mainAccount'">主账户:</div>
          <div v-if="this.right_paramsPlan == 'company'">公司:</div>

          <div v-if="this.right_paramsPlan == 'plan'">{{ planName}}</div>
          <div v-if="this.right_paramsPlan == 'account'">{{ accountName }}</div>
          <div v-if="this.right_paramsPlan == 'mainAccount'">{{ mainAccountName }}</div>
          <div v-if="this.right_paramsPlan == 'company'">{{ companyName }}</div>

          <div v-if="this.right_paramsPlan == 'plan'">（计划ID：</div>
          <div v-if="this.right_paramsPlan == 'account'">（子账户ID：</div>
          <div v-if="this.right_paramsPlan == 'mainAccount'">（主账户ID：</div>
          <div v-if="this.right_paramsPlan == 'company'">（公司ID：</div>

          <div v-if="this.right_paramsPlan == 'plan'">{{ planId }}</div>
          <div v-if="this.right_paramsPlan == 'account'">{{ accountId }}</div>
          <div v-if="this.right_paramsPlan == 'mainAccount'">{{ mainAccountId }}</div>
          <div v-if="this.right_paramsPlan == 'company'">{{ companyId }}</div>）
        </div>
        <div class="indicators">
          <div class="pandding" style="color: #a1a1a1">核心指标</div>
          <div class="flex_around line-height40">
            <div class="indicators_box">
              <div class="indicators_box_money">花费（元）</div>
              <div class="indicators_box_num">{{ costplan }}</div>
              <div class="indicators_box_num">{{ costaccount}}</div>
              <div class="indicators_box_num">{{ costparaccount}}</div>
              <div class="indicators_box_num">{{ costCompanydata}}</div>

            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">点击量</div>
              <div class="indicators_box_num">{{ clickplan }}</div>
              <div class="indicators_box_num">{{ clickaccount}}</div>
              <div class="indicators_box_num">{{ clickparaccount}}</div>
              <div class="indicators_box_num">{{ clickCompanydata }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化数</div>
              <div class="indicators_box_num">{{ convertNumplan }}</div>
              <div class="indicators_box_num">{{ convertNumaccount }}</div>
              <div class="indicators_box_num">{{ convertNumparaccount }}</div>
              <div class="indicators_box_num">{{ convertNumCompanydata }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">曝光量</div>
              <div class="indicators_box_num">{{ showNumplan }}</div>
              <div class="indicators_box_num">{{ showNumaccount}}</div>
              <div class="indicators_box_num">{{ showNumparaccount }}</div>
              <div class="indicators_box_num">{{ showNumCompanydata }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化成交金额</div>
              <div class="indicators_box_num">{{ payAmountplan }}</div>
              <div class="indicators_box_num">{{ payAmountaccount }}</div>
              <div class="indicators_box_num">{{ payAmountparaccount }}</div>
              <div class="indicators_box_num">{{ payAmountCompanydata }}</div>
            </div>
            <div class="indicators_box">
              <div class="indicators_box_money">转化ROI</div>
              <div class="indicators_box_num">{{ roiplan }}</div>
              <div class="indicators_box_num">{{ roiaccount }}</div>
              <div class="indicators_box_num">{{ roiparaccount }}</div>
              <div class="indicators_box_num">{{ roiCompanydata }}</div>
            </div>
          </div>
        </div>

        <div class="indicators1">
          <div class="bigbox">
            <div class="title">
              <!-- <div  @click="tab(1)" :class="this.tabshow == 1 ? 'active' : ''">趋势图</div>
              <div  @click="tab(2)" :class="this.tabshow == 2 ? 'active' : ''">表格</div> -->
              <el-button  @click="tab(1)" :type="btn1" style="margin-right:5px">趋势图</el-button>
              <el-button  @click="tab(2)" :type="btn2">表格</el-button>
            </div>
            <div class="containerbox">
              <div v-show="tabshow == 1">
                <!-- <goods :drawdata="drawdata.accountId" :aa="drawdata.accountId"></goods> -->

                <div>
                  <div class="pandding flex_between">
                    <div class="flex titlename" style="line-height: 46px;">实时趋势</div>
                    <div class="flex">
                      <el-select @change="changeFilter1" v-model="filterValue1" placeholder="请选择">
                        <el-option
                          v-for="item in filterOption1"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>

                      <div class="change">对比</div>
                      <el-select @change="changeFilter2" v-model="filterValue2" placeholder="请选择">
                        <el-option
                          v-for="item in filterOption2"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div
                    class="echart"
                    :class="'mychart_drawer' + this.right_paramsPlan"
                    :id="'mychart_drawer' + this.right_paramsPlan"
                    ref="mychart"
                    :style="myChartStyle0"
                  ></div>
                </div>
              </div>
              <div v-show="tabshow == 2">
                <div class="pandding flex_between">
                  <div class="flex" style="color: #a1a1a1; font-size: 15px; line-height: 40px">时间报表</div>
                  <div class="flex">
                    <!-- 自定义组件 -->
                    <columnclick
                      :columnsInfo="accountColumns"
                      @confirm="toConfirm('account', $event)"
                    ></columnclick>
                    <!-- 导出按钮 -->
                    <el-button
                      @click="handleAccount"
                      type="primary"
                      style="margin-left: 16px;width:120px;height:40px"
                    >导出报表</el-button>
                  </div>
                </div>

                <el-table
                  :data="data"
                  style="width: 100%"
                  height="400"
                  :default-sort="{prop: 'date', order: 'descending'}"
                >
                  <el-table-column width="170" fixed prop="dateTime" label="时间"></el-table-column>
                  <el-table-column
                    v-for="item  in filterAccountColumns"
                    width="100"
                    :key="item.val"
                    :prop="item.val"
                    :label="item.name"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
 
<script>
import goods from "../echarts/goods";
import columnclick from "../echarts/goods_columnclick";
import $utils from "@/js/utils.js";

// import trtd from "../echarts/goods_trtd";
import * as echarts from "echarts";
import { metricsTrendReport,downloadDetailData } from "@/request/api.js"; //营销活动监控首页-(账户 广告组 计划 [ 时间趋势 ])
import pubsub from 'pubsub-js'
const options_trend = [
  {
    value: "cost",
    label: "花费"
  },
  {
    value: "showNum",
    label: "展现量"
  },
  {
    value: "click",
    label: "点击量"
  },
  {
    value: "ctr",
    label: "点击率"
  },
  {
    value: "convertNum",
    label: "转化数"
  },
  {
    value: "convertRate",
    label: "转化率"
  },
  {
    value: "convertCost",
    label: "转化成本"
  },
  {
    value: "convertAmount",
    label: "转化金额"
  },
  {
    value: "roi",
    label: "成交ROI"
  },
  {
    value: "payAmount",
    label: "成交金额"
  }
];

export default {
  components: {
    goods,
    columnclick
  },
  props: {
    right_paramsPlan: {
      type: null,
      default: ""
    },
    filterDateStart: {
      type: String,
      default: ""
    },
    filterDateEnd: {
      type: String,
      default: ""
    },
    orderFrom: {
      type: String,
      default: ""
    },
    costplan: {
      type: null,
      default: ""
    },
    clickplan: {
      type: null,
      default: ""
    },
    convertNumplan: {
      type: null,
      default: ""
    },
    payAmountplan: {
      type: null,
      default: ""
    },
    roiplan: {
      type: null,
      default: ""
    },
    showNumplan: {
      type: null,
      default: ""
    },
    // 
    costCompanydata: {
      type: null,
      default: ""
    },
    clickCompanydata: {
      type: null,
      default: ""
    },
    convertNumCompanydata: {
      type: null,
      default: ""
    },
    payAmountCompanydata: {
      type: null,
      default: ""
    },
    roiCompanydata: {
      type: null,
      default: ""
    },
    showNumCompanydata: {
      type: null,
      default: ""
    },
    // 
      costaccount: {
      type: null,
      default: ""
    },
    clickaccount: {
      type: null,
      default: ""
    },
    convertNumaccount: {
      type: null,
      default: ""
    },
    payAmountaccount: {
      type: null,
      default: ""
    },
    roiaccount: {
      type: null,
      default: ""
    },
    showNumaccount: {
      type: null,
      default: ""
    },
    // 
      costparaccount: {
      type: null,
      default: ""
    },
    clickparaccount: {
      type: null,
      default: ""
    },
    convertNumparaccount: {
      type: null,
      default: ""
    },
    payAmountparaccount: {
      type: null,
      default: ""
    },
    roiparaccount: {
      type: null,
      default: ""
    },
    showNumparaccount: {
      type: null,
      default: ""
    },
  },
  computed: {
    filterOption1() {
      return options_trend.filter(ele => ele.value != this.filterValue2);
    },
    filterOption2() {
      return options_trend.filter(ele => ele.value != this.filterValue1);
    },
    filterAccountColumns() {
      return this.accountColumns.filter(ele => ele.checked);
    }
  },
  watch: {},
  data() {
    return {
      btn1:'primary',
      btn2:'',
      click: "",
      convertNum: "",
      showNum: "",
      payAmount: "",
      roi: "",
      cost: "",
      planId: "",
      accountId: "",
      mainAccountId: "",
      companyId: "",
      planName: "",
      accountName: "",
      mainAccountName: "",
      companyName: "",
      legenddata0: "",
      keyIds: "",
      right_params: "",
      keyType: "account",
      keyType1: "1",
      activeName: "first",
      isloading: false,
      drawdata: {},
      drawdata1: {},

      aa: "",
      goods: "",
      value1: "",
      value2: "",
      isShow: false,
      tabshow: 1,
      filterValue1: "cost",
      filterValue2: "showNum",
      myChart0: null,
      myChartStyle0: { float: "left", width: "100%", height: "400px" }, //图表样式
      searchForm: {
        chooseKeyType: this.right_paramsPlan,
        chooseKeyId: this.keyIds,
        filterDateStart: this.filterDateStart,
        filterDateEnd: this.filterDateEnd,
        cycle: "0",
        orderFrom: this.orderFrom
      },
      data: [],
      accountColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "展现及花费",
          name: "平均点击单价",
          val: "cpc"
        },
        {
          type: "展现及花费",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "转化及成交",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "转化及成交",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "支付转化率",
          val: "payConvertRate"
        }
      ],
      plantime: false
    };
  },
  mounted() {
    // this.getData();
    this.pid = pubsub.subscribe('echartData',(msgName, data)=>{
        console.log('---------------->>>>>>>>>>>>',data)
        this.data = data.data
        
        // this.initEcharts(this.data);
    })
  },
  destroyed() {
    // console.error('dddd')
  },
  created() {
    this.accountColumns.forEach((ele, idx) => {
      // ele.checked = true;
      this.$set(this.accountColumns[idx], "checked", true);
    });
  },
  methods: {
    handleAccount() {
      downloadDetailData({
        filterDateStart: this.filterDateStart,
        filterDateEnd: this.filterDateEnd,
        chooseKeyType: this.right_paramsPlan,
        chooseKeyId: this.drawdata.row.accountId,
        orderFrom: this.orderFrom,
        btnCode: "100002",
      }).then(res => {
        console.log(res);
        $utils.exportRaw(res.data.filename, res.data.file);
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    changeFilter1(v) {
      this.filterValue1 = v;
      console.log(v, "v");
      const obj1 = options_trend.filter(ele => ele.value == this.filterValue1);
      const obj2 = options_trend.filter(ele => ele.value == this.filterValue2);
      this.legenddata0 = [obj1[0].label, obj2[0].label];
      console.log(obj1, obj2, "123456");
      console.log(this.legenddata0, "this.legenddata0");
      this.initEcharts(this.data);
    },
    changeFilter2(v) {
      this.filterValue2 = v;
      this.initEcharts(this.data);
    },
    show(type, data, tabId, plantime) {
      if (type != "plan") {
        this.activeName = "ssss";
      }
      this.keyType = type;
      // console.log(this.keyType, "this.keyType");
      this.drawdata = tabId;
      // console.log(this.drawdata, "this.drawdata", tabId, "tabId");
      this.isShow = true;
      this.getData();
    },
    getData() {
      this.searchForm.keyType = this.keyType;
      this.searchForm.orderFrom = this.orderFrom;
      if (this.keyType == "account") {
        this.searchForm.chooseKeyId = this.drawdata.row.accountId;
      } else if (this.keyType == "plan") {
        this.searchForm.chooseKeyId = this.drawdata.row.planId;
      } else if (this.keyType == "mainAccount") {
        this.searchForm.chooseKeyId = this.drawdata.row.mainAccountId;
      } else if (this.keyType == "company") {
        this.searchForm.chooseKeyId = this.drawdata.row.companyId;
      }
      // console.log(this.drawdata.row.planName, "123123");
      this.planName = this.drawdata.row.planName;
      this.accountName = this.drawdata.row.accountName;
      this.mainAccountName = this.drawdata.row.mainAccountName;
      this.companyName = this.drawdata.row.companyName;

      this.planId = this.drawdata.row.planId;
      this.accountId = this.drawdata.row.accountId;
      this.mainAccountId = this.drawdata.row.mainAccountId;
      this.companyId = this.drawdata.row.companyId;
      console.log(this.filterDateStart,'filterDateStart')
      this.searchForm.filterDateStart = this.filterDateStart;
      this.searchForm.filterDateEnd = this.filterDateEnd;
      this.isloading = true;
      metricsTrendReport(this.searchForm).then(res => {
        this.isloading = false;
        // console.log("cornMetricsByKeyId res", res);
        if (res.code == 1) {
          this.data = res.data;
          // console.log(this.data,'this.data')
          this.initEcharts(this.data);
        }
      });
    },
    toConfirm(type, data) {
      if (type == "account") {
        this.accountColumns = data;
        console.log(this.accountColumns, "123123123");
      } else if (type == "adgroup") {
        this.adgroupColumns = data;
      } else {
        this.planColumns = data;
      }
    },
    drawers() {
      this.drawer = true;
      this.getData();
      this.$emit("drawerss");
    },
    tab(val) {
      console.log(val,'1231');
      this.tabshow = val;
      if (val == 1) {
        // this.$refs.btn1.type = 'primary'
        // this.$refs.btn2.type = ''
        this.btn1 = "primary"
        this.btn2 = ''
      } else if (val == 2) {
        // this.$refs.btn2.type = 'primary'
        // this.$refs.btn1.type = ''
        this.btn2 = "primary"
        this.btn1 = ''
      }
    },
    // buttonType(val) {
    //   console.log(val,'222222');
    // },
    initEcharts(data) {
      // console.log(this.legenddata0, "123456132");
      let filter1 = options_trend.find(ele => ele.value == this.filterValue1);
      // console.log(filter1.label, "filter1");
      let filter2 = options_trend.find(ele => ele.value == this.filterValue2);
      // console.log(filter2.label, "filter2");
      this.legenddata0 = [filter1.label, filter2.label];

      // 查询最大值
      let max = this.getMaxObject(data, this.filterValue1);
      // console.log("max1", this.filterValue1, max);
      let max1 =
        max[Number(this.filterValue1)] <= 0
          ? 10
          : max[Number(this.filterValue1)];
      max = this.getMaxObject(data, this.filterValue2);
      // console.log("max2", this.filterValue2, max);
      let max2 =
        max[Number(this.filterValue2)] <= 0
          ? 10
          : max[Number(this.filterValue2)];
      let xAxis = data.map(ele => {
        return ele.dateTime;
      });
      console.log('xAxis', xAxis);
      let yAxis = [
        {
          name: filter1.label,
          min: 0,
          max: max1,
          splitLine: { show: false },
          // interval: 3000
          position: "left"
        },
        {
          name: this.legenddata0[1],
          min: 0,
          max: max2,
          // interval: 30,
          position: "right"
        }
      ];
      let series1 = data.map(ele => {
        return ele[this.filterValue1];
      });
      // console.log(series1, "series1");
      let series2 = data.map(ele => {
        return ele[this.filterValue2];
      });
      // console.log(series2, "series2");
      let series = [
        {
          areaStyle: {}, //填充
          name: filter1.label,
          data: series1,
          type: "line", // 类型设置为折线图
          smooth: true, //是否平滑曲线
          label: {
            show: false,
            position: "top",
            textStyle: {
              fontSize: 16
            }
          }
        },
        {
          areaStyle: {}, //填充
          name: filter2.label,
          data: series2,
          type: "line", // 类型设置为折线图
          smooth: true,
          label: {
            show: false, //是否显示坐标轴上面的数值
            position: "bottom",
            textStyle: {
              fontSize: 16
            }
          },
          yAxisIndex: 1
        }
      ];

      const option = {
        color: [
          {
            type: "linear",
            // x2=1,y=0,柱子的颜色在水平方向渐变
            x2: 0,
            y2: 1,
            colorStops: [
              // 0%处的颜色
              {
                offset: 0,
                color: "#357CF0"
              },
              // 50%处的颜色
              {
                offset: 0.5,
                color: "#BCD4FC"
              },
              // 100%处的颜色
              {
                offset: 1,
                color: "#BCD4FC"
              }
            ],
            global: false // 缺省为 false
          },
          {
            type: "linear",
            // x2=1,y=0,柱子的颜色在水平方向渐变
            x2: 0,
            y2: 1,
            colorStops: [
              // 0%处的颜色
              {
                offset: 0,
                color: "#E593DC"
              },
              // 50%处的颜色
              {
                offset: 0.5,
                color: "#F6DCF3"
              },
              // 100%处的颜色
              {
                offset: 1,
                color: "#F6DCF3"
              }
            ],
            global: false // 缺省为 false
          }
        ], //更改填充的颜色
        tooltip: {
          show: true,
          trigger: "axis"
        },
        xAxis: {
          data: xAxis
        },
        // legend: {
        //   // 图例
        //   data: this.legenddata0,
        //   bottom: "0%"
        // },
        yAxis: yAxis,
        series: series
      };
      // console.log('dddd', "mychart_drawer" + this.right_paramsPlan)
      let el = document.getElementsByClassName(
        "mychart_drawer" + this.right_paramsPlan
      )[0];
      console.log("mychart_drawer" + this.right_paramsPlan,'.......',this.$refs)
      // let el = document.getElementById("mychart_drawer" + this.right_paramsPlan)
      if (!this.myChart0) {
        // console.log(this.legenddata0, "1234561322222222222222222");
        this.myChart0 = echarts.init(el);
        this.myChart0.setOption(option);

        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
          this.myChart0.resize();
        });
      } else {
        // console.log(this.legenddata0, "1234561321111111111");
        // 刷新
        // console.log("series刷新", series[0].name);
        let chartOption = this.myChart0.getOption();
        // console.log(chartOption);
        chartOption.series = series;
        chartOption.yAxis = yAxis;
        chartOption.xAxis = {
          data: xAxis
        };
        
        this.myChart0.dispose()
        this.myChart0 = echarts.init(this.$refs.mychart)
        this.myChart0.setOption(chartOption);
      }
      // this.myChart0.clear();
    },
    getMaxObject(data, field) {
      const max = data.reduce(function(prev, current) {
        return prev[field] > current[field] ? prev : current;
      });
      return max;
    }
  }
};
</script>

<style>
.el-drawer.rtl {
  background: #f5f6fa;
}
.drawer_head {
  border-bottom: 1px solid darkgray;
  color: #a1a1a1;
}

.indicators {
  width: 93%;
  height: 150px;
  background: #fff;
  margin: 0 auto;
}
.indicators1 {
  width: 93%;
  height: 500px;
  background: #fff;
  margin: 20px auto;
}
.indicators_box {
  border-left: 1px solid #e5e5e5;
  height: 75px;
  padding: 0 0 0 20px;
}

.indicators_box_money {
  color: #a1a1a1;
  font-size: 14px;
}
.indicators_box_num {
  color: #a1a1a1;
  font-size: 18px;
}
.drawer_id {
  font-size: 14px;
  color: #a1a1a1;
}
.bigbox .title {
  display: flex;
  float: right;
  margin: 15px 2px 0 35px;
}
/* .bigbox .title div {
  border: 1px solid #c5c8dc;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 9px;
} */

.active {
  background-color: #1966ff;
  color: #ffff;
}
html,
body {
  scroll-behavior: smooth;
}
.el-tabs__header {
  margin: 0 0 0 20px;
}

@media screen and (max-width: 1800px) {
  .titlename {
    /* margin: 0 0 0 10px; */
    width: 170px;
  }
}
</style>