<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleTabClick" style="margin:-20px">
            <!-- <el-tab-pane label="转链历史" name="list">
                <el-table :data="tableHistoryData" style="width: 100%;margin: 15px 21px">
                    <el-table-column prop="name" label="链接类型" width="180">
                    </el-table-column>
                    <el-table-column prop="url" label="链接地址" width=""></el-table-column>
                    <el-table-column prop="" width="180" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="handleCopy(scope.$index, scope.row)"
                            >复制链接
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane> -->
            <el-tab-pane label="开始转链" name="create">
                <el-form ref="form" style="margin: 15px 21px; background-color:#fff; padding: 18px 2px "
                         label-width="100px" v-loading="loading">

                    <el-form-item label="商品" style="text-align: left;margin:0 0 20px 0; ">
                        <el-select v-model="itemName" filterable placeholder="请选择商品" @change="changeGoods($event)"
                                   :filter-method="selectGoods">
                            <el-option
                                v-for="(item,index) of goods"
                                :key="index"
                                :label="item.item_name"
                                :value="index"
                            >
                            </el-option>
                        </el-select>

                    </el-form-item>
                    <!--
                    <el-form-item label="代理商账户" style="text-align: left;margin:0 0 20px 0; ">
                        <el-select v-model="agent" placeholder="请选择代理商账户" @change="changeAgent($event)">
                            <el-option
                                v-for="(item,index) of options"
                                :key="item.id"
                                :label="item.name"
                                :value="index"
                            >
                            </el-option>
                        </el-select>

                    </el-form-item>
                    -->
                    <el-form-item label="链接" style="margin:0 0 20px 0;padding:0 2vh 0 0 ">
                        <el-input
                            v-model="input"
                            placeholder="请粘贴链接"
                            type="textarea"
                        ></el-input>
                    </el-form-item>

                    <el-form-item style="text-align: left">
                        <el-button type="primary" icon="el-icon-search" @click="toRescue"
                        >立即创建
                        </el-button
                        >
                    </el-form-item>
                </el-form>
                <div v-if="tableData.length > 0">
                    <el-row type="flex" justify="end" style="margin-bottom: 30px">
                        <el-col :span="3">
                            <el-button
                                type="primary"
                                size="small"
                                icon="el-icon-download"
                                @click="toExport"
                            >导出转链结果
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </div>
                <el-table :data="tableData" v-if="tableData.length > 0" style="width: 100%">
                    <el-table-column prop="name" label="链接类型" width="180">
                    </el-table-column>
                    <el-table-column prop="url" label="链接地址" width=""></el-table-column>
                    <el-table-column prop="" width="180" label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="handleCopy(scope.$index, scope.row)"
                            >复制链接
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div>
                    <!-- <div style="width: 400px; margin: 16px 0 0 0">请复制链接</div> -->
                    <div>
                        {{ url }}
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import {convertlinks, agentlist,addTbMerchandiseList} from "../../request/api.js";
import $utils from "@/js/utils";

export default {
    data() {
        return {
            loading: false,
            activeName: 'create',
            input: "",
            url: "",
            tableData: [],
            tableHistoryData: [],
            options: [],
            agent: "",
            agentId: -1,
            itemName:"",
            goods: [],
            goodsId:""
        };
    },
    mounted() {
        this.getAgentList();
        this.getGoodsList();
    },
    methods: {
        handleTabClick() {

        },
        toExport() {
            let txt = "原链接:" + this.input + "\r\n=====================\r\n";
            this.tableData.forEach((ele) => {
                txt += ele.name + "\r\n" + ele.url + "\r\n\r\n";
            });
            $utils.exportRaw("导出转链结果.txt", txt);
        },
        handleCopy(idx, row) {
            $utils.copyClipboard(row.url);
            this.$message({
                message: "复制成功",
                type: "success",
            });
        },
        toRescue() {
            this.url = "";
            if (!this.input) {
                this.$message({
                    message: "请填写链接",
                    type: "error",
                });
                return;
            }
            //判断链接类型
            if (
                this.input.indexOf("tmall") === -1 &&
                this.input.indexOf("taobao") === -1 &&
                this.input.indexOf("chengzijianzhan") === -1
            ) {
                this.$message.error("请填写正确的淘宝天猫链接");
                return;
            }
            /*
            if (this.agentId < 0) {
                this.$message.error("请选择代理商账号");
                return;
            }
            if (this.options[this.agentId].domain == "") {
                this.$message.error("请先绑定域名后在转链");
                return;
            }
             */
            if(!this.goodsId){
                this.$message.error("请先选择商品");
                return;
            }
            this.loading = true;
            this.tableData = [];
            convertlinks({url: this.input,goods_id:this.goodsId}).then((res) => {
                this.loading = false;
                // this.tableData = [
                //   {
                //     name: "直跳商品",
                //     url: "https://open.taobao.com/doc.htm?docId=28625&docType=2",
                //   },
                //   {
                //     name: "直接优惠券",
                //     url: "https://open.taobao.com/doc.htm?docId=28625&docType=2",
                //   },
                // ];
                if (res.code == 1) {
                    if (res.data.type == "first") {
                        this.tableData = [
                            {
                                name: "直跳商品",
                                url: res.data.url,
                            },
                            {
                                name: "直跳优惠券",
                                url: res.data.url_coupon,
                            },
                            {
                                name: "直跳种草页",
                                url: res.data.url_zhongcao,
                            },
                        ]
                    } else {
                        this.tableData = [
                            {
                                name: "二跳商品",
                                url: res.data.url,
                            },
                            {
                                name: "二跳优惠券",
                                url: res.data.url_coupon,
                            },
                            {
                                name: "二跳种草页",
                                url: res.data.url_zhongcao,
                            },
                        ]
                    }
                    this.tableData = this.tableData.filter(item => item.url!="");
                    console.log(this.tableData)
                    // this.url = res.data.url;
                    this.$message({
                        message: "转链成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },
        getAgentList() {
            agentlist().then(res => {
                if (res.code == 1) {
                    this.options = res.data.list;
                }
            })
        },
        changeAgent(e) {
            this.agentId = e
        },
        changeGoods(e){
            this.goodsId = this.goods[e].id
            console.log(this.goodsId)
        },
        getGoodsList() {
            addTbMerchandiseList({page:1,pageSize:1000}).then(res => {
                if(res.code == 1){
                    let goods = res.data
                    var goods_type = ["淘宝","京东","拼多多"]
                    goods.forEach((item,index) => {
                        item.item_name = goods_type[parseInt(item.channel)-1]+"："+item.item_name
                        this.goods.push(item)
                    })
                    this.goods = res.data

                }
            })
        },
        selectGoods(e){
            try {
                this.goods.forEach((item,index)=> {
                    if(item.item_name.indexOf(e) != -1 || item.item_id == e){
                        this.itemName = item.item_name
                        throw new Error("查询到数据了，终止循环")
                    }
                })
            }catch (err){
                console.log(err)
            }

        }
    },
};
</script>

<style>

</style>