<template>
  <el-select
    class="elinput"
    @change="orderchange"
    v-model="valuegoods"
    placeholder="订单来源"
    style="width:150px;margin:0 0 20px 0;"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  props: ["orderfrom"],
  data() {
    return {
      options: [
        {
          value: "",
          label: "全部订单渠道"
        },
        {
          value: "tb",
          label: "淘宝联盟"
        },
        {
          value: "jd",
          label: "京东联盟"
        },
        {
          value: "pdd",
          label: "拼多多"
        }
      ],
      valuegoods: ""
    };
  },
  watch: {
    orderfrom: {
      handler(newVal, oldVal) {
        this.value = newVal;
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    orderchange(valuegoods) {
      this.$emit("orderchange", this.valuegoods);
      this.$emit("orderchangeplan", this.valuegoods);
         console.log(this.valuegoods);
      this.$emit("orderchangeAccount", this.valuegoods);
      this.$emit("orderchangeparAccount", this.valuegoods);
      this.$emit("orderchangeCompanydata", this.valuegoods);
    }
  }
};
</script>

<style scoped>
.elinput /deep/ .el-input__inner {
  /* border-right: none !important; */
  border-radius: 5px 0px 0px 5px;
}
@media screen and (max-width: 1500px) {
  .elinput /deep/ .el-input__inner {
    border-right: 1px solid #dcdfe6;
  }
}
</style>