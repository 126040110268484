 <!-- 活动监控实时趋势 --> 
<template>
  <div>
    <div class="pandding flex_between">
      <div class="flex">
        <div>时间趋势</div>
      </div>
      <div class="flex">
        <el-select @change="hamndlevalue1" v-model="defaultvalue1" placeholder="请选择">
          <el-option
            v-for="item in options_trend1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select @change="hamndlevalue1" v-model="defaultvalue2" placeholder="请选择">
          <el-option
            v-for="item in options_trend2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="echart" id="mychart" :style="myChartStyle"></div>
  </div>
</template>
<script>
import { realtimetrend, reporttimetrend } from "@/request/api.js"; // 营销活动监控实时趋势丶报表实时趋势
import * as echarts from "echarts";
export default {
  props: {
    hw: {
      // 指定属性名, 属性值的类型, 必要性，这里写的hour 就是父组件 :hour 的定义，需要保持一致
      type: Array,
      default: [1]
    },
    name: {
      name
    },
    orderchange2: {
      type: String,
      default: ""
    },
    filterData: {
      type: Object,
      default: () => {
        return {
          goodIds: [],
          acticityId: "",
          targetIds: [],
          targetType: "",
          roiMax: "",
          roiMin: "",
          costMax: "",
          costMin: "",
          contrastStatus: 0,
          orderFrom: "tb",
          compare: "",
          filterDateStart: "", //开始日期
          filterDateEnd: "", //结束日期
          filterContrastDateStart: "", //勾选对比后的开始日期，默认空
          filterContrastDateEnd: "", //	勾选对比后的结束日期，默认空
          filterDateType: "2", //	时间条件类型，1-分时，2-分日，默认2
          cycle: "", //转化效果评估，0-不归因 1-1日 3-3日 7-7日 15-15日 30-30日，默认0
          contrastStatus: "" //勾选对比，0-未勾选，1-勾选，默认不勾选
        };
      }
    }
  },
  data() {
    return {
      valueactive: true,
      options_trend: [
        {
          value: "cost",
          label: "花费"
        },
        {
          value: "showNum",
          label: "展现量"
        },
        {
          value: "click",
          label: "点击量"
        },
        {
          value: "ctr",
          label: "点击率"
        },
        {
          value: "convertNum",
          label: "转化数"
        },
        {
          value: "convertRate",
          label: "转化率"
        },
        {
          value: "convertCost",
          label: "转化成本"
        },
        {
          value: "convertAmount",
          label: "转化金额"
        },
        {
          value: "payAmountRoi",
          label: "成交ROI"
        },
        {
          value: "payAmount",
          label: "成交金额"
        }
      ],
      options_trendreport: [
        {
          value: "cost",
          label: "花费"
        },
        {
          value: "showNum",
          label: "展现量"
        },
        {
          value: "cpm",
          label: "千次展现费用"
        },
        {
          value: "click",
          label: "点击量"
        },
        {
          value: "ctr",
          label: "点击率"
        },
        {
          value: "cpc",
          label: "平均点击单价"
        },
        {
          value: "convertNum",
          label: "转化数"
        },
        {
          value: "convertRate",
          label: "转化率"
        },
        {
          value: "payConvertRate",
          label: "支付转化率"
        },
        {
          value: "convertCost",
          label: "转化成本"
        },
        {
          value: "pagePv",
          label: "页面PV"
        },
        {
          value: "pageUv",
          label: "页面UV"
        },
        {
          value: "pageUvCost",
          label: "UV成本"
        },
        {
          value: "collectUv",
          label: "收藏UV"
        },
        {
          value: "collectCost",
          label: "收藏成本"
        },
        {
          value: "addShopCartUv",
          label: "加入购物车UV"
        },
        {
          value: "addShopCartCost",
          label: "加购成本"
        },
        {
          value: "addShopCartConvert",
          label: "加购转化"
        },
        {
          value: "payAmount",
          label: "成交金额"
        },
        {
          value: "payCount",
          label: "成交订单数"
        },
        {
          value: "avgAmountVar",
          label: "成交订单均价"
        },
        {
          value: "totalPlay",
          label: "播放数"
        },
        {
          value: "play25FeedBreak",
          label: "25%进度播放数"
        },
        {
          value: "playDurationSum",
          label: "播放时长"
        },
        {
          value: "share",
          label: "分享数"
        },
        {
          value: "validPlay",
          label: "有效播放数"
        },
        {
          value: "play50FeedBreak",
          label: "50%进度播放数"
        },
        {
          value: "averagePlayTimePerPlay",
          label: "平均单次播放时长"
        },
        {
          value: "comment",
          label: "评论数"
        },
        {
          value: "validPlayCost",
          label: "有效播放成本"
        },
        {
          value: "play75FeedBreak",
          label: "75%进度播放数"
        },
        {
          value: "playOverRate",
          label: "完播率"
        },
        {
          value: "likeNum",
          label: "点赞数"
        },
        {
          value: "validPlayRate",
          label: "有效播放率"
        },
        {
          value: "play100FeedBreak",
          label: "99%进度播放数"
        },
        {
          value: "roi",
          label: "成交ROI"
        }
      ],
      defaultvalue1: "cost", //默认第一个
      defaultvalue2: "showNum", //
      ymax: 2000,
      taskmax: 1000,
      myChart: null,
      xData: [], //横坐标
      xData0: [],
      yData0B: [],
      taskData0B: [],
      currentDataA: [], //花费数据
      legenddata: ["花费", "展现量"],
      legenddataLast: ["昨日花费", "昨日展现量"],
      currentDataB: [], //展现量数据
      lastDataA: [], //花费数据
      lastDataB: [], //花费数据
      myChartStyle: { float: "left", width: "100%", height: "400px" }, //图表样式
      reqParam: {
        acticityId: this.hw,
        compare: "", //对比
        contrastA: "cost",
        contrastB: "showNum",
        targetIds: [],
        targetType: "",
        goodIds: ""
      }, //请求参数监控实时趋势
      reqParamreport: {
        acticityId: this.hw,
        targetIds: "",
        targetType: "",
        goodIds: [],
        compare: "",
        filterDateStart: this.filterData.filterDateStart, //开始日期
        filterDateEnd: this.filterData.filterDateEnd, //结束日期
        filterContrastDateStart: this.filterData.filterContrastDateStart, //勾选对比后的开始日期，默认空
        filterContrastDateEnd: this.filterData.filterContrastDateEnd, //	勾选对比后的结束日期，默认空
        filterDateType: this.filterData.filterDateType, //	时间条件类型，1-分时，2-分日，默认2
        cycle: this.filterData.cycle, //转化效果评估，0-不归因 1-1日 3-3日 7-7日 15-15日 30-30日，默认0
        contrastStatus: this.filterData.contrastStatus //勾选对比，0-未勾选，1-勾选，默认不勾选
      } //请求参数报表时间趋势
    };
  },
  computed: {
    options_trend1() {
      if (this.name == "0") {
        return this.options_trendreport.filter(
          ele => ele.value != this.defaultvalue2
        );
      } else {
        return this.options_trend.filter(
          ele => ele.value != this.defaultvalue2
        );
      }
    },
    options_trend2() {
      if (this.name == "0") {
        return this.options_trendreport.filter(
          ele => ele.value != this.defaultvalue1
        );
      } else {
        return this.options_trend.filter(
          ele => ele.value != this.defaultvalue1
        );
      }
    }
  },
  // watch: {
  // },

  mounted() {
    // console.log(this.filterData);
  },
  methods: {
    search(param) {
      if (this.name == "0") {
        this.reqParamreport = param;
        // console.log(this.reqParamreport);
        this.reqParamreport.filterContrastDateStart = this.filterData.filterContrastDateStart;
        this.getData();
      } else {
        this.reqParam = param;
        // console.log(this.reqParam);
        this.getData();
      }
    },
    //是否开启昨日数据对比
    handleStatusChange(val) {
      console.log(val);
      this.reqParam.compare = val;
      console.log(this.reqParam.compare);

      this.$emit("gogoodspare", val);
      this.Realtimetrend();
    },
    // 最大值
    handleSetnum(data) {
      if (data.length > 0 && typeof data[0] == "object") {
        data = data.map(x => x.value);
      }
      let obj = Math.max(...data);
      obj = Math.ceil(obj);
      let str = obj.toString();
      str = str.charAt(str.length - 1);
      const str2 = 10 - str;
      obj = obj + Number(str2);
      return obj;
    },
    handleSetdata(data) {
      if (this.name == "0") {
        // console.log("时间趋势");
        this.xData0 = []; ///横坐标
        this.yData0 = [];
        this.taskData0 = [];
        let label1 = this.options_trendreport.find(
          ele => ele.value == this.defaultvalue1
        ).label;
        let label2 = this.options_trendreport.find(
          ele => ele.value == this.defaultvalue2
        ).label;
        this.legenddata = [label1, label2];
        // console.log("this.legenddata", this.legenddata);
        if (data) {
          var dotHtml1 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#0090ff";></span>';
          var dotHtml2 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:red"></span>';
          var dotHtml3 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:red"></span>';
          var dotHtml4 =
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:green"></span>';
          data.forEach(item => {
            let name =
              dotHtml1 +
              item.dateTime +
              " " +
              label1 +
              ": " +
              item[this.defaultvalue1] +
              "<br/>" +
              dotHtml2 +
              item.dateTime +
              " " +
              label2 +
              ": " +
              item[this.defaultvalue2];
            if (this.reqParamreport.contrastStatus == 1) {
              name +=
                "<br/>" +
                dotHtml3 +
                item.dateTimeB +
                " " +
                label1 +
                ": " +
                item[this.defaultvalue1 + "B"] +
                "<br/>" +
                dotHtml4 +
                item.dateTimeB +
                " " +
                label2 +
                ": " +
                item[this.defaultvalue2 + "B"];
            }
            this.xData0.push(item.dateTime); //横坐标
            this.yData0.push({
              value: item[this.defaultvalue1],
              name: name,
              tooltip: {
                formatter: (params, ticket, callback) => {
                  // callback("AAAA")
                  // callback(ticket, params.name)
                  // console.log("================", params);
                  return params.name;
                }
              }
            });
            this.taskData0.push({
              value: item[this.defaultvalue2],
              name: name,
              tooltip: {
                formatter: (params, ticket, callback) => {
                  return params.name;
                }
              }
            });
            if (this.reqParamreport.contrastStatus == 1) {
              this.yData0B.push({
                value: item[this.defaultvalue1 + "B"],
                name: name,
                tooltip: {
                  formatter: (params, ticket, callback) => {
                    return params.name;
                  }
                }
              });
              this.taskData0B.push({
                value: item[this.defaultvalue2 + "B"],
                name: name,
                tooltip: {
                  formatter: (params, ticket, callback) => {
                    return params.name;
                  }
                }
              });
            }
          });
        }
        this.ymax0 = this.handleSetnum(this.yData0);
        this.taskmax0 = this.handleSetnum(this.taskData0);
        this.initEchartsreport();
      } else {
        console.log("实时趋势");
        this.xData = [];
        this.currentDataA = [];
        this.currentDataB = [];
        this.lastDataA = [];
        this.lastDataB = [];
        if (data) {
          data.forEach(item => {
            this.xData.push(item.hour);
            this.currentDataA.push(item.contrastACurrent);
            this.currentDataB.push(item.contrastBCurrent);
            this.lastDataA.push(item.contrastALast);
            this.lastDataB.push(item.contrastBLast);
          });
        }
        console.log( this.xData,' this.xData')
        this.ymax = this.handleSetnum(this.currentDataA.concat(this.lastDataA));
        this.taskmax = this.handleSetnum(
          this.currentDataB.concat(this.lastDataB)
        );
        // console.log(this.ymax, this.taskmax);
        this.initEcharts();
      }
    },

    async getData() {
      if (this.name == "0") {
        console.log("report"); //报表时间趋势
        const result = await reporttimetrend(this.reqParamreport);
        // console.log(result,'result1111111111111111111111222')
        if (!result) return;
        const { data } = result;
        this.responseData = data;
        // console.log( data);
        this.handleSetdata(data);
        // console.log(data);
      } else {
        console.log("goods"); //监控实时趋势
        const result = await realtimetrend(this.reqParam);
        if (!result) return;
        const { data } = result;
        this.responseData = data;
        this.handleSetdata(data);
        console.log(data);
      }
    },
    // 默认渲染
    Realtimetrend() {
      this.getData();
    },

    //   "#025DF4", "#E695DD",
    initEcharts() {
      let legenddata = this.legenddata;
      let series = [
        {
          areaStyle: {
            opacity: 0.1,
            color: "#357CF0",
            origin: "start"
          }, //填充
          name: this.legenddata[0],
          data: this.currentDataA,
          type: "line", // 类型设置为折线图
          smooth: true, //是否平滑曲线
          label: {
            show: false,
            position: "top",
            textStyle: {
              fontSize: 16
            }
          }
        },
        {
          areaStyle: {
            opacity: 0.1,
            color: "#E593DC",
            origin: "start"
          }, //填充
          name: this.legenddata[1],
          data: this.currentDataB,
          type: "line", // 类型设置为折线图
          smooth: true,
          label: {
            show: false, //是否显示坐标轴上面的数值
            position: "bottom",
            textStyle: {
              fontSize: 16
            }
          },
          yAxisIndex: 1
        }
      ];
      let yAxis = [
        {
          name: this.legenddata[0],
          min: 0,
          max: this.ymax,
          // interval: 3000
          splitLine: { show: false },

          position: "left"
        },
        {
          name: this.legenddata[1],
          min: 0,
          max: this.taskmax,
          // interval: 30,
          position: "right"
        }
      ];
      if (this.reqParam.compare == 1) {
        console.log("显示昨日");
        legenddata = this.legenddata.concat(this.legenddataLast);
        series = series.concat([
          {
            // areaStyle: {}, //填充
            name: legenddata[2],
            data: this.lastDataA,
            type: "line", // 类型设置为折线图
            smooth: true,
            lineStyle: {
              type: "dashed"
              //  color: '#ff0000'
            },
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            }
          },
          {
            // areaStyle: {}, //填充
            name: legenddata[3],
            data: this.lastDataB,
            type: "line", // 类型设置为折线图
            smooth: true,
            lineStyle: {
              type: "dashed"
              //  color: '#00FF00'
            },
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            },
            yAxisIndex: 1
          }
        ]);
        // console.log(series,'this.xData.push(res.data[i].dateTimeA);')
      } else {
        // console.log("显示昨日 false");
      }
      console.log(yAxis, series);
      const option = {
        color: ["#357CF0", "#E593DC", "#FF0000", "#00FF00"], //更改填充的颜色
        tooltip: {
          show: true,
          trigger: "axis"
        },
        xAxis: {
          data: this.xData
        },
        legend: {
          // 图例
          data: legenddata,
          bottom: "0%"
        },
        yAxis: yAxis,
        series: series
      };
      // console.log(this.xData, "option");
      if (!this.myChart) {
        this.myChart = echarts.init(document.getElementById("mychart"));
      }
      this.myChart.clear();
      this.myChart.setOption(option);
      // echarts.init(document.getElementById(mychart)).dispose(); // 销毁实例
      // echarts.init(document.getElementById(mychart)); // 再次创建实例
      // this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    initEchartsreport() {
      let legenddata = this.legenddata;
      // console.log("legenddata", legenddata);
      let series = [
        {
          areaStyle: {
            opacity: 0.1,
            color: "#357CF0",
            origin: "start"
          }, //填充
          name: this.legenddata[0],
          data: this.yData0,
          type: "line", // 类型设置为折线图
          smooth: true, //是否平滑曲线
          tooltip: {
            axisPointer: {
              label: {}
            },
            formatter: (params, ticket, callback) => {}
          },
          label: {
            show: false,
            position: "top",
            textStyle: {
              fontSize: 16
            }
          }
        },
        {
          areaStyle: {
            opacity: 0.1,
            color: "#E593DC",
            origin: "start"
          }, //填充
          name: this.legenddata[1],
          data: this.taskData0,
          type: "line", // 类型设置为折线图
          smooth: true,
          label: {
            show: false, //是否显示坐标轴上面的数值
            position: "bottom",
            textStyle: {
              fontSize: 16
            }
          },
          yAxisIndex: 1
        }
      ];
      if (this.reqParamreport.contrastStatus == 1) {
        series.push(
          {
            areaStyle: {
              opacity: 0.1,
              color: "#1E1E1E",
              origin: "start"
            }, //填充
            lineStyle: { type: "dashed", color: "#ff0000" },
            name: this.legenddata[0],
            data: this.taskData0B,
            type: "line", // 类型设置为折线图
            smooth: true,
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            },
            yAxisIndex: 1
          },
          {
            areaStyle: {
              opacity: 0.1,
              color: "#1E1E1E",
              origin: "start"
            }, //填充
            lineStyle: { type: "dashed", color: "green" },
            name: this.legenddata[1],
            data: this.taskData0B,
            type: "line", // 类型设置为折线图
            smooth: true,
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            },
            yAxisIndex: 1
          }
        );
      }
      let yAxis = [
        {
          name: this.legenddata[0],
          min: 0,
          max: this.ymax0,
          // interval: 3000

          position: "left"
        },
        {
          splitLine: { show: false },
          name: this.legenddata[1],
          min: 0,
          max: this.taskmax0,
          // interval: 30,
          position: "right"
        }
      ];
      if (this.reqParamreport.compare == 1) {
        console.log("显示昨日");
        legenddata = this.legenddata.concat(this.legenddataLast);
        series = series.concat([
          {
            // areaStyle: {}, //填充
            name: legenddata[2],
            data: this.taskData0,
            type: "line", // 类型设置为折线图
            smooth: true,
            lineStyle: {
              type: "dashed"
              //  color: '#ff0000'
            },
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            }
          },
          {
            // areaStyle: {}, //填充
            name: this.legenddata0[1],
            data: this.taskData0,
            type: "line", // 类型设置为折线图
            smooth: true,
            lineStyle: {
              type: "dashed"
              //  color: '#00FF00'
            },
            label: {
              show: false, //是否显示坐标轴上面的数值
              position: "bottom",
              textStyle: {
                fontSize: 16
              }
            },
            yAxisIndex: 1
          }
        ]);
      } else {
      }
      // console.log(yAxis, series);
      const option = {
        color: ["#357CF0", "#E593DC", "#FF0000", "#00FF00"], //更改填充的颜色
        tooltip: {
          show: true,
          trigger: "item"
        },
        xAxis: {
          data: this.xData0
        },
        legend: {
          // 图例
          data: legenddata,
          bottom: "0%"
        },
        yAxis: yAxis,
        series: series
      };
      // console.log("option",option)
      if (!this.myChart) {
        console.log(this.legenddata,'this.legenddata',this.xData0,'this.xData0',legenddata,'legenddata,',)
        //   // debugger;
        this.myChart = echarts.init(document.getElementById("mychart"));
        this.myChart.setOption(option);
      } else {
        // 刷新
        // let chartOption = this.myChart.getOption();
        // chartOption.series = series;
        this.myChart.setOption(option);
      }
      // echarts.init(document.getElementById(mychart)).dispose(); // 销毁实例
      // echarts.init(document.getElementById(mychart)); // 再次创建实例
      // this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    // 点击下拉框
    hamndlevalue1() {
      if (this.name == "0") {
        // 历史
        if (this.defaultvalue1 == this.defaultvalue2) return;
        const obj1 = this.options_trend1.filter(
          item => item.value == this.defaultvalue1
        );
        const obj2 = this.options_trend2.filter(
          item => item.value == this.defaultvalue2
        );
        this.legenddata0 = [obj1[0].label, obj2[0].label];
        this.reqParam.contrastA = this.defaultvalue1;
        console.log(this.reqParam.contrastA);
        this.reqParam.contrastB = this.defaultvalue2;
        // this.getData();

        this.handleSetdata(this.responseData);
      } else {
        // 实时
        if (this.defaultvalue1 == this.defaultvalue2) return;
        const obj1 = this.options_trend1.filter(
          item => item.value == this.defaultvalue1
        );
        const obj2 = this.options_trend2.filter(
          item => item.value == this.defaultvalue2
        );
        this.legenddata = [
          obj1[0].label,
          obj2[0].label,
          "昨日" + obj1[0].label,
          "昨日" + obj2[0].label
        ];
        this.reqParam.contrastA = this.defaultvalue1;
        this.reqParam.contrastB = this.defaultvalue2;
        this.getData();
      }
    }
  }
};
</script>

<style lang="scss"  >
</style>