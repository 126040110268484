<template>
    <div>
<el-row type="flex" justify="space-between" style="align-items: flex-start" class="nav-icon">
        <el-col :span="10" class="nav2" align="left" >
        <el-button size="small" type="primary" @click="check">检测有效</el-button>
            <span v-if="taobaoTableData.length>0">总计：{{taobaoTableData.length}}</span>
            <span v-if="can_use>0">可用：{{can_use}}</span>
            <span v-if="not_use>0">失效：{{not_use}}</span>
        </el-col>
        <el-col :span="10" align="right" >
            <el-button size="small" type="primary" @click="AlimamaToOauth">添加账户</el-button>
        </el-col>
</el-row>
        <!-- <el-tabs v-model="activeName1" @tab-click="handleClick1"> -->
            <!-- <el-tab-pane label="授权设置" name="first"><br> -->
                <!-- <el-tabs v-model="activeName2" @tab-click="handleClick2" v-loading="loading"> -->
                    <!-- <el-tab-pane label="广告平台授权" name="first">
                        <div class="authorization" style="margin-left: 2vh">
                            <div class="flex pandding">
                                <img class="flex" src="../../assets/xiazai.png" alt/>
                                <div>
                                    <div class="flex">
                                        <div class="font18">巨量引擎</div>
                                        <div>状态:已授权</div>
                                    </div>
                                    <div class="flex font12 margin_top">(目前仅支持管家和广告主账号授权)</div>
                                </div>
                            </div>
                            <div>
                                <el-table
                                    :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                                    :data="tableData"
                                    style="width: 100%"
                                    min-height="250"
                                >
                                    <el-table-column fixed prop="name" label="名称" width="300"></el-table-column>
                                    <el-table-column prop="oauth_time" label="授权时间" width="100"></el-table-column>
                                    <el-table-column prop="status" label="授权状态" width="170"></el-table-column>
                                    <el-table-column prop="city" label="历史数据回溯" width="420">
                                        <div>
                                            <div
                                                slot="reference"
                                                style="color: #0a63fc"
                                                class="margin_right cursor"
                                                @click="centerDialogVisible = true"
                                            >回溯数据
                                            </div>
                                            <el-dialog
                                                :append-to-body="true"
                                                style="z-index: 100"
                                                :visible.sync="centerDialogVisible"
                                                width="22%"
                                                :before-close="handleClose"
                                            >
                                                <div class="flex font24">
                                                    <div>
                                                        <i class="el-icon-warning-outline" style="color: red"></i>
                                                    </div>
                                                    <div class="font15" style="line-height: 34px; margin: 0 0 0 5px">
                                                        提示
                                                    </div>
                                                </div>
                                                <span
                                                    class="margin_top"
                                                >系统将为您回溯该账号(2022/03/08-2022/06/06)的报表及素材数据，预估用时5天完成。系统默认回溯近90天数据，如需回溯更多历史数据，请联系产品运营人员。</span>
                                                <br/>
                                                <br/>

                                                <div>回溯一旦开始无法终止，请确认是否回溯？</div>
                                                <span slot="footer" class="dialog-footer">
                          <el-button @click="centerDialogVisible = false">取 消</el-button>
                          <el-button type="primary" @click="recallData()">确 定</el-button>
                        </span>
                                            </el-dialog>
                                        </div>
                                    </el-table-column>
                                    <el-table-column fixed="right" label="账号授权" width="120">
                                        <el-popover
                                            placement="top-start"
                                            width="200"
                                            trigger="hover"
                                            slot-scope="scope"
                                        >
                                            <div class="text_center">
                                                <div
                                                    class="cursor"
                                                    onmouseover="this.style.color='#0A63FC'"
                                                    onmouseout="this.style.color=''"
                                                >敏感素材授权
                                                </div>

                                                <div
                                                    @click="relieveOauth('1',scope.row.id)"
                                                    class="cursor"
                                                    onmouseover="this.style.color='#0A63FC'"
                                                    onmouseout="this.style.color=''"
                                                >解除授权
                                                </div>
                                            </div>
                                            <i slot="reference" class="el-icon-more cursor"></i>
                                        </el-popover>
                                    </el-table-column>
                                </el-table>
                                <div class="flex cursor" style="margin: 30px 0 0 10px">
                                    <i class="el-icon-circle-plus-outline font24" style="color: #6f99ed"></i>
                                    <div class="font16" style="color: #6f99ed" @click="dialogVisiblee = true">添加账户</div>
                                </div>
                                <el-dialog title="提示" :visible.sync="dialogVisiblee" width="50%">
                                    <span>即将前往巨量引擎授权，请勾选“允许服务商管理敏感物料”，否则可能影响产品的素材分析功能准确性！</span>
                                    <img
                                        style="width:70%;height:40%"
                                        src="../../assets/auth_tooltip.9b69e4a0.png"
                                        alt
                                    />
                                    <div>
                                        <el-button type="primary" round @click="toRescue">我已知晓 立即授权</el-button>
                                    </div>
                                </el-dialog>
                            </div>
                        </div>
                    </el-tab-pane> -->
                    <!-- <el-tab-pane label="电商平台授权" name="second"> -->
                        <el-table
                            :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                            :data="taobaoTableData"
                            style="width: 100%; margin: 2vh 0 0 0"
                            min-height="250"
                        >
                            <el-table-column fixed prop="name" label="名称"></el-table-column>
                            <el-table-column fixed prop="adzone_num" label="推广位数量" width="90"></el-table-column>
                            <el-table-column fixed sortable prop="canuse" width="90" label="可用状态">
                                <div slot-scope="scope">
                                    <i v-if="scope.row.canuse == ''">-</i>
                                    <i v-else-if="scope.row.canuse == 'loading'" class="el-icon-loading"></i>
                                    <i v-else v-html="scope.row.canuse">{{scope.row.canuse}}</i>
                                </div>
                            </el-table-column>
<!--                            <el-table-column prop="oauth_time" label="授权时间" width="150"></el-table-column>-->
                            <el-table-column prop="expire_time" sortable label="授权到期时间" width="120"></el-table-column>
                            <el-table-column prop="use_order" label="使用" width="150">
                                <div slot-scope="scope">
                                   
                                    <el-button slot="reference" type="info" plain size="mini" @click="change_use_order(scope.row, 0)">修改 {{scope.row.use_order > 0 ? scope.row.use_order:''}}</el-button>

                                </div>
                            </el-table-column>
                            <el-table-column prop="status" label="状态" width="120">
                                <div slot-scope="scope">
                                    <!-- <span v-html="statusTitle[scope.row.status]"></span> -->
                                    <el-button v-if="scope.row.status==1" slot="reference" type="info" plain size="mini" v-html="statusTitle[scope.row.status]" @click="status(scope.row, 0)">关闭报表</el-button>
                                    <el-button v-else-if="scope.row.status==2" slot="reference" type="danger" plain size="mini" v-html="statusTitle[scope.row.status]" @click="AlimamaToOauth"></el-button>
                                    <el-button v-else slot="reference" type="primary" plain size="mini" v-html="statusTitle[scope.row.status]" @click="status(scope.row, 1)">开启报表</el-button>
                                </div>
                            </el-table-column>
                            <!-- <el-table-column prop="operation" label="操作" width="180">
                                <div v-if="scope.row.zl_status == 0" slot-scope="scope" class="flex">
                                    <el-popconfirm
                                    title="确认要启用此帐户吗？"
                                    @confirm="zl_status(scope.row)"
                                    >
                                    <el-button slot="reference" type="primary" plain size="mini">启用</el-button>
                                    </el-popconfirm>
                                    
                                </div>
                                <div v-else>
                                    启用中
                                </div>
                            </el-table-column> -->
                        </el-table>
                        <!-- <div class="flex cursor" style="margin: 30px 0 0 10px">
                            <i class="el-icon-circle-plus-outline font24" style="color: #6f99ed"></i>
                            <div class="font16" style="color: #6f99ed" @click="AlimamaToOauth">添加账户</div>
                        </div> -->
                    <!-- </el-tab-pane> -->
                <!-- </el-tabs> -->
            <!-- </el-tab-pane> -->
            <!-- <el-tab-pane label="域名绑定" name="second">
                <div class="agent" style="padding: 20px; margin: 2vh 0 0 2vh">
                    <div class="demo-input-suffix flex">

                        <el-select v-model="agent" placeholder="请选择代理商账户" @change="changeAgent($event)">
                            <el-option
                                v-for="(item,index) of options"
                                :key="item.id"
                                :label="item.name"
                                :value="index"
                            >
                            </el-option>
                        </el-select>
                        <el-input
                            style="width: 320px;margin: 0 12px "
                            placeholder="请输入域名"
                            prefix-icon="el-icon-search"
                            v-model="input2"
                        ></el-input>
                        <el-button  type="primary" icon="el-icon-search" @click="bindDomain">确认</el-button>
                    </div>
                    <div class="flex " style="margin:20px 20px 0 20px">请将域名解析到IP:121.5.51.95、{{domain == "" ? "暂未绑定域名" : "当前绑定的域名"+domain}}
                    </div>
                </div>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="代理商设置" name="second">
              <div class="agent">
                <div class="demo-input-suffix flex_between" style="margin: 0px 20px 0 20px">
                  <el-input
                    class="margin_top"
                    style="width: 320px"
                    placeholder="请输入代理商"
                    prefix-icon="el-icon-search"
                    v-model="input2"
                  ></el-input>
                  <el-button class="margin_top" type="primary" icon="el-icon-search">添加代理</el-button>
                </div>
                <div style="margin: 100px 0 0 0">
                  <img src="../../assets/noData.26c11572.svg" alt />
                  <div>您还未创建任何账号</div>
                </div>
              </div>
            </el-tab-pane>-->
        <!-- </el-tabs> -->
    </div>
</template>

<script>
import {
    getooauth,
    toauth,
    toutiaolist,
    taobaolist,
    orelieveoauth,
    trelieveoauth,
    recalldata,
    domain_get,
    domain_add,
    agentlist,
    alimama_multi,
    alimama_status,
    checktaobaostatus, change_use_order
} from "../../request/api.js";

export default {
    name: "XXXSetup",

    data() {
        return {
            loading: false,
            url: "",
            taobaoUrl: "https://xingchuan360.com/#/tbauthorize",
            dialogVisiblee: false,
            visible: false,
            centerDialogVisible: false,
            handleClick1: "",
            handleClick2: "",
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            activeName1: "first",
            activeName2: "second",
            tableData: [],
            taobaoTableData: [],
            domain: "",
            options: [],
            agent:"",
            agentId:-1,
            statusTitle:[
                "未获取",
                "获取中",
                "授权过期"
            ],
            can_use:0,
            not_use:0,
            checkFlag: true
        };
    },

    mounted() {
        var query = this.$route.query;
        if (query.type != undefined) {
            this.activeName2 = query.type;
        }
        this.getData();
    },

    methods: {
        change_use_order(item, idx ) {
            // console.log(item)
            let id = item.id
            this.$prompt('输入使用级别(数值越大越优先)', '输入使用级别', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item.use_order,
                closeOnClickModal: false
            }).then(async ({ value }) => {
                let num = parseInt(value)
                if (isNaN(num)) {
                    this.$message.error('请输入数字')
                    return;
                }

                //修改数据库
                change_use_order({id:item.id,use_order:num}).then(res => {
                    if(res.code == 1){
                        // send
                        let idx = this.taobaoTableData.findIndex(ele => ele.id == id)
                        this.taobaoTableData[idx].use_order = num
                        let status = num > 0 ? 1 : 0
                        this.taobaoTableData[idx].status = status
                        this.$message.success(res.msg)
                    }
                })
                
            })
        },
        check() {
            if(this.checkFlag == false){
                this.$message.error("检测中，请稍后再试！")
                return;
            }
            this.checkFlag = false;
            this.taobaoTableData.forEach(ele => {
                this.$set(ele, "canuse", 'loading');
            })
            let ids = this.taobaoTableData.map(item => {
                return item.id
            })
            const size = 50
            var num = Math.ceil(ids.length/size)
            this.not_use = 0;
            this.can_use = 0;
            var j = 0;
            for (let i = 0; i < num; i++) {
                var ids_temp = ids.slice(i*size,size*(i+1))
                checktaobaostatus({ids: ids_temp}).then(res => {
                    console.log("checktaobaostatus", res);
                    j++;
                    if (res.status == 1) {
                        res.data.forEach(d => {
                            let obj = this.taobaoTableData.find(e => e.id == d.id)
                            if (obj) {
                                d.status == '失效' ? this.not_use++ : this.can_use++;
                                obj.canuse = d.status == '失效' ? '<span style="color:red;">' + d.status + '</span>' : d.status;
                            }
                        })
                    }
                })
            }
            console.log(j)
            var cc = setInterval(() =>{
                if (j == num){
                    this.checkFlag = true
                    clearInterval(cc)
                }
            },500)
            //console.log(num)
            return


        },
        async getData() {
            this.loading = true;
            //await this.$getooauth();
            await this.getTOauth();
            //await this.getTouTiaoList();
            await this.getTaobaoList();
            //this.getDomain();
            //await this.getAgentList();
            this.loading = false;
        },
        async status(row, newStatus) {
            this.loading = true;
            console.log(row);
            if(newStatus == 1){
                checktaobaostatus({ids: [row.id]}).then(res => {
                    console.log("checktaobaostatus", res);
                    if (res.status == 1 && res.data[0].status == "可用") {
                        alimama_status({alimamaid:row.id,status:newStatus}).then(res => {
                            if(res.code == 1){
                                this.$message.success(res.msg)
                                this.getTaobaoList();
                            }
                        })
                        // TODO: 网络请求，成功后执行下面处理刷新画面
                        this.loading = false;
                    }else{
                        this.$message.error("联盟号不可用")
                        this.loading = false;
                    }
                })
            }else{
                alimama_status({alimamaid:row.id,status:newStatus}).then(res => {
                    if(res.code == 1){
                        this.$message.success(res.msg)
                        this.getTaobaoList();
                    }
                })
                // TODO: 网络请求，成功后执行下面处理刷新画面
                this.loading = false;
            }

        },
        zl_status(row) {
            this.loading = true;
            console.log(row);
            alimama_multi({alimamaid:row.id}).then(res => {
                if(res.code == 1){
                    // TODO: 网络请求，成功后执行下面处理刷新画面
                    this.taobaoTableData.forEach(ele => {
                        if (ele.id == row.id) {
                            ele.zl_status = 1
                        } else if (ele.status == 1) {
                            ele.zl_status = 0
                        }
                    })
                    this.$message.success(res.msg)
                }
            })
            this.loading = false;
        },
        toRescue() {
            window.open(this.url, "_blank");
        },
        AlimamaToOauth() {
            window.open(this.taobaoUrl, "_blank");
        },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then(_ => {
                    done();
                })
                .catch(_ => {
                });
        },
        deleteRow(index, rows) {
            rows.splice(index, 1);
        },

        //获取巨量授权链接地址
        $getooauth() {
            return getooauth()
                .then(res => {
                    if (res.code == 1) {
                        this.url = res.data.url;
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        //获取头条授权列表
        getTouTiaoList() {
            return toutiaolist().then(res => {
                if (res.code == 1) {
                    this.tableData = res.data.list;
                }
            });
        },
        getTOauth() {
            // return toauth().then(res => {
            //     if (res.code == 1) {
            //         this.taobaoUrl = res.data.url;
            //     }
            // });
        },
        getTaobaoList() {
            return taobaolist().then(res => {
                if (res.code == 1) {
                    res.data.list.forEach(ele => {
                        this.$set(ele, "canuse", '');
                    })
                    res.data.list = res.data.list.sort((a, b)=> {
                        // if (b.use_order == a.use_order) {
                        //     return 
                        // }
                        return b.use_order - a.use_order;
                    })
                    this.taobaoTableData = res.data.list;
                }
            });
        },
        /**
         * 解除授权
         * @param type
         * @param id
         */
        relieveOauth(type, id) {
            if (type == 1) {
                orelieveoauth({agent_id: id}).then(res => {
                    if (res.code == 1) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                    this.$message({
                        message: res.msg,
                        type: Number(res.code) == 1 ? "success" : "error"
                    });
                });
            } else if (type == 2) {
                orelieveoauth({alimama_id: id}).then(res => {
                    if (res.code == 1) {
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }
                    this.$message({
                        message: res.msg,
                        type: Number(res.code) == 1 ? "success" : "error"
                    });
                });
            }
        },
        recallData() {
            this.centerDialogVisible = false;
            recalldata().then(res => {
                this.$message.success(res.msg);
            });
        },
        /**
         * 获取绑定的域名
         */
        getDomain(){
            domain_get().then(res => {
                if(res.code == 1){
                    this.domain = res.data.domain;
                }
            })
        },
        /**
         * 绑定域名
         */
        bindDomain(){
            if(this.input2 == ""){
                this.$message.error("请先填写要绑定的域名");
                return
            }
            if(this.agentId < 0){
                this.$message.error("请选择代理商账号");
                return
            }
            var reg=/^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)?(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
            if(!reg.test(this.input2)){
                this.$message.error("请输入正确的域名格式")
                return;
            }
            if(this.options[this.agentId].domain != ""){
                this.$confirm("您已绑定域名，确认替换吗？","提示",).then(res => {
                    if(res === "confirm"){
                        this.doBindDomain();
                    }
                }).catch()
            }else{
                this.doBindDomain()
            }
        },
        doBindDomain(){
            domain_add({domain:this.input2,agent_id:this.options[this.agentId].id}).then(res => {
                if(res.code == 1){
                    this.$message.success("绑定成功");
                    this.options[this.agentId].domain = this.input2
                    this.domain = this.input2
                }
            })
        },
        getAgentList(){
            agentlist().then(res => {
                if(res.code == 1){
                    this.options = res.data.list;
                }
            })
        },
        changeAgent(e){
            this.agentId = e
            this.domain = this.options[e].domain
        }
    }
};
</script>

<style scoped>
.nav-icon {
    display: flex;
    align-items: center;
}

.nav-icon span {
    font-size: 2vh;
    padding-left: 1vh;
}

.agent {
    width: 100%;
    height: 400px;
    background: #fff;
}

.authorization {
    width: 100%;
    /* height: 400px; */
    background: #fff;
    margin: 1vh 0;
}

</style>