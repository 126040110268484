<template>
    <div class="child-manage">
        
        <!-- 搜索部分 -->
        <div class="head_search_box">
            <div class="head_search">
                <el-input
                    style="width:300px"
                    placeholder="请输入账号名称"
                    v-model="searchData.keyword"
                    clearable
                    >
                    <template slot="append"><el-button type="primary" @click="search">搜索</el-button></template>
                </el-input>
            </div>
            <div>
                <el-button type="primary" @click="add">添加子账号</el-button>
                <el-dialog
                    title = "添加子账号"
                    show-close
                    width='600px'
                    :visible.sync="drawer"
                    :modal="true"
                    :close-on-click-modal="false"
                    >
                    <!-- 输入框 -->
                    <div class="drawer-main">
                        <el-form ref="form" :model="saveData" :rules="rules" label-position="right" label-width="110px">
                            <!-- <div style="text-align:center"> -->
                            <el-form-item label="账号名称" prop="username" >
                                <el-input 
                                    maxlength="10"
                                    placeholder="请输入名称( 10位以内 )"
                                    v-model="saveData.username">
                                        <template slot="prepend">{{user}}_</template>
                                </el-input>
                            </el-form-item>
                            <br><br>
                            <el-form-item label="输入密码" prop="password" >
                                <el-input 
                                type="password"
                                show-password
                                v-model="saveData.password" 
                                aria-required="required" 
                                ></el-input>
                            </el-form-item><br><br>
                            <el-form-item label="再次输入密码" prop="repassword">
                                <el-input 
                                type="password"
                                show-password
                                v-model="saveData.repassword" 
                                aria-required="required" 
                                ></el-input>
                            </el-form-item><br><br>
                            <el-form-item label="备注" prop="memo">
                                <el-input 
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 3}"
                                v-model="saveData.memo" 
                                ></el-input>
                            </el-form-item><br>
                            <el-form-item label="状态" style="width: 25vw;" prop="status">
                                <div style="text-align:left;">
                                    <el-radio v-model="saveData.status" label="1">启用</el-radio>
                                    <el-radio v-model="saveData.status" label="0">禁用</el-radio>
                                </div>
                            </el-form-item>
                            <el-form-item label="主账户" >
                                <div style="text-align:left;">

                                    <el-checkbox-group v-model="saveData.agent_ids"  @change="powerCheck($event)">
                                        <!-- 子菜单-->
                                        <el-checkbox v-for="(nData,i) in accountList" :key="i"
                                            :label="nData.id"
                                        ><b>{{nData.name}}</b>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </el-form-item>
                            <el-form-item label="权限" prop="permissions">
                                <div style="text-align:left;height:260px;">
                                    <el-checkbox-group v-model="saveData.permissions"> 
                                        <div v-for="(nData,i) in navData" :key="i">
                                            <el-checkbox-group v-model="checkedKeys1" @change="handleCheckedCitiesChange1(1,checkedKeys1,nData)">
                                                <!-- 子菜单-->
                                                <el-checkbox
                                                    :label="nData.id" :key="nData.id"
                                                ><b>{{nData.label}}</b>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                            <div style="display: flex">
                                                <div style="padding: 0px 15px 0 0;" v-for="(nD,i) in nData.children" :key="i">
                                                <!--菜单栏-->
                                                <el-checkbox-group v-model="checkedKeys1" @change="handleCheckedCitiesChange1
                                                (2,checkedKeys1,navData,nData,nD.id)">
                                                    <el-checkbox :label="nD.id" :key="nD.id">{{nD.label}}</el-checkbox>
                                                </el-checkbox-group>
                                                <div style="padding:  0px 15px 0 0;">
                                                    <el-checkbox-group
                                                    v-model="checkedKeys1">
                                                    <!--路由导航 -->
                                                    <el-checkbox
                                                        @change="handleCheckedCitiesChange1(3,checkedKeys1,nData.children,nD.children,nD.id,nData.id,n.id)"
                                                        v-for="n in nD.children" :label="n.id" :key="n.id">
                                                        <i>H3 {{n.label}}</i>
                                                    </el-checkbox>
                                                    </el-checkbox-group>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-checkbox-group>
                                </div>
                            </el-form-item>
                           
                        </el-form>
                    </div>
                    <div class="empty"></div>
                    <div style="text-align:center;margin: 3vh 0 2vh 0">
                        <el-button type="primary" @click="saveGoods" :disabled="save_button" style="width:150px">确 定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <!-- 表格部分 -->
        <div class="f2" v-loading="loading">
            <el-table
                :data="tableData"
                border
                style="width: 100%">
                <!-- <el-table-column
                    prop="id"
                    label="ID"
                    v-if="id == 1"
                >
                </el-table-column> -->
                <el-table-column
                    prop="account"
                    label="账号名称"
                    width="270"
                    >
                </el-table-column>
                <el-table-column
                    prop="menu_name"
                    label="页面"
                    width="600"
                >
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="100"
                    :formatter="statusFormatter1"
                    :filters="[{ text: '启用', value: 1 }, { text: '禁用', value: 0 }]"
                    :filter-method="filterTag"
                    filter-placement="bottom-end"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.status == 1 ? 'success' : 'danger'"
                            disable-transitions>{{getstatus(scope.row.status)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="memo"
                    label="备注"
                    width="350"
                    >
                </el-table-column>
                <el-table-column
                    prop="add_time"
                    label="添加时间"
                    width="200"
                    >
                </el-table-column>
                <el-table-column
                    prop="operation"
                    label="操作"
                    fixed="right"
                    width="120"
                    >
                    <template slot-scope="scope" style="display:flex;">
                        <el-button @click="companyEdit(scope.row)" type="text" size="small">修改</el-button>&nbsp;
                        <el-popconfirm
                            title="确定删除吗？"
                            @confirm="deleteGoods(scope.row)">
                            <el-button slot="reference" type="text" size="small">删除</el-button>
                        </el-popconfirm>
                            <el-dialog 
                                width="550px"
                                title="修改信息" 
                                :visible.sync="dialogFormVisible" 
                                center 
                                append-to-body
                                :close-on-click-modal="closeModal">
                                <div class="drawer-main" style="">
                                  <el-form :model="form" :rules="edit_rules" label-position="right" label-width="70px">
                                    <!-- <div style="display:flex; align-items:center; justify-content:center;"> -->
                                    <el-form-item label="修改密码" prop="password">
                                        <el-input 
                                            type="password"
                                            show-password
                                            v-model="form.password"  
                                            aria-required="required"
                                        ></el-input>
                                    </el-form-item><br><br>
                                    <!-- </div> -->
                                    <el-form-item label="备注" prop="memo">
                                        <el-input 
                                            type="textarea"
                                            :autosize="{ minRows: 2, maxRows: 3}"
                                            v-model="form.memo"  
                                        ></el-input>
                                    </el-form-item><br>
                                    <el-form-item label="状态" style="width: 25vw;">
                                        <div style="text-align:left;">
                                            <el-radio-group v-model="form.status">
                                                <el-radio :label="1">启用</el-radio>
                                                <el-radio :label="0">禁用</el-radio>
                                            </el-radio-group>
                                        </div>
                                    </el-form-item>
                                      <el-form-item label="主账户" >
                                          <div style="text-align:left;">

                                              <el-checkbox-group v-model="form.agent_ids"  @change="powerCheck($event)">
                                                          <!-- 子菜单-->
                                                          <el-checkbox v-for="(nData,i) in accountList"
                                                              :label="nData.id" :key="nData.id" :checked="agentCheck(scope.row.agent_ids,nData.id)"
                                                          ><b>{{nData.name}}</b>
                                                          </el-checkbox>
                                              </el-checkbox-group>
                                          </div>
                                      </el-form-item>
                                    <el-form-item label="权限" >
                                        <div style="text-align:left;">
                                            
                                            <el-checkbox-group v-model="form.permissions"> 
                                                <div v-for="(nData,i) in navData" :key="i">
                                                    <el-checkbox-group v-model="checkedKeys" @change="handleCheckedCitiesChange(1,checkedKeys,nData)">
                                                        <!-- 子菜单-->
                                                        <el-checkbox
                                                            :label="nData.id" :key="nData.id"
                                                        ><b>{{nData.label}}</b>
                                                        </el-checkbox>
                                                    </el-checkbox-group>
                                                    <div style="display: flex">
                                                        <div style="padding: 0px 15px 0 0;" v-for="(nD,i) in nData.children" :key="i">
                                                        <!--菜单栏-->
                                                        <el-checkbox-group v-model="checkedKeys" @change="handleCheckedCitiesChange
                                                        (2,checkedKeys,navData,nData,nD.id)">
                                                            <el-checkbox :label="nD.id" :key="nD.id">{{nD.label}}</el-checkbox>
                                                        </el-checkbox-group>
                                                        <div style="padding:  0px 15px 0 0;">
                                                            <el-checkbox-group
                                                            v-model="checkedKeys">
                                                            <!--路由导航 -->
                                                            <el-checkbox
                                                                @change="handleCheckedCitiesChange(3,checkedKeys,nData.children,nD.children,nD.id,nData.id,n.id)"
                                                                v-for="n in nD.children" :label="n.id" :key="n.id">
                                                                <i>H3 {{n.label}}</i>
                                                            </el-checkbox>
                                                            </el-checkbox-group>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-checkbox-group>
                                        </div>
                                    </el-form-item>
                                  </el-form>
                                </div>
                                <div slot="footer" style="text-align:center;margin: 1vh 0 6vh 0">
                                    <el-button type="primary" @click="edit" style="width:150px">确 定</el-button>
                                </div>
                            </el-dialog>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 页码 -->
            <div class="page">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(totalNum) ? Number(totalNum) : 0"
                >
                </el-pagination>
            </div>
        </div>


    </div>
</template>

<script>
import {
    addChildAccount,
    childAccount,
    delAccount,
    editAccount,
    menulist,
    userinfo,
    accountlist
} from '@/request/api';


    export default {
        data() {
                var validatePass = (rule, value, callback) => {
                    if (value === '') {
                        callback(new Error('请输入密码'));
                    } else {
                        if (this.saveData.repassword !== '') {
                            this.$refs.saveData.validateField('repassword');
                        }
                        callback();
                    }
                };
                var validatePass2 = (rule, value, callback) => {
                    if (value === '') {
                        callback(new Error('请再次输入密码'));
                    } else if (value !== this.saveData.password) {
                        callback(new Error('两次输入密码不一致!'));
                    } else {
                        callback();
                    }
                };
            return {
                loading: true,

                navData: [], //获取的数据
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                expandedKeys: [], //默认打开的项
                checkedKeys1:[], // 添加选中的项
                checkedKeys: [], // 修改默认选中的项 
                submitData: [], //需要提交的数据
                disabled: true,//初始修改状态
                // 多选择框
                checkAll: false,
                isIndeterminate: true,
                datalist: [
                    {
                    id:1,
                    pid:0,
                    name:'数据报表'
                    },
                    {
                    id:2,
                    pid:0,
                    name:'订单报表'
                    },
                    {
                    id:3,
                    pid:0,
                    name:'投放配置'
                    },
                    {
                    id:4,
                    pid:3,
                    name:'商品管理'
                    },
                    {
                    id:5,
                    pid:3,
                    name:'落地页转链'
                    },
                    {
                    id:6,
                    pid:0,
                    name:'业务管理'
                    },
                    {
                    id:7,
                    pid:6,
                    name:'公司管理'
                    },
                    {
                    id:8,
                    pid:6,
                    name:'主账户管理'
                    },
                    {
                    id:9,
                    pid:6,
                    name:'活动配置'
                    },
                ],
                
                user:'',
                input1:'',
                checkList: [],
                save_button:false,
                closeModal:false,
                searchData: {
                    keyword:'',
                },
                saveData: {
                    username:'',
                    password:'',
                    repassword:'',
                    memo:'',
                    status: '1',
                    permissions:[],
                    agent_ids: []
                },
                curPage:1,
                totalNum:0,
                pageSize:10,
                drawer: false,
                keyword: '',
                tableData:[{
                    id:'',
                    account:'',
                    status: '1',
                    add_time:'',
                    memo:'',
                    menu_name:'',
                }],
                // 表格的id
                edit_id:'',
                edit_status:'',
                menu_id:'',
                id:'',
                dialogFormVisible: false,
                form: {
                    memo:'',
                    password:'',
                    status: null,
                    permissions:[],
                    agent_ids: []
                },
                agent_ids:[],
                // 添加表单验证
                rules: {
                    username: [
                        // { required: true, message: '请输入账号名称', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 15 位以内', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { validator: this.checkData, trigger: 'blur' },
                        { min: 6, max: 12, message: '长度在 6 到 12 位', trigger: 'blur' },
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    repassword: [
                        { required: true, message: '请再次输入密码', trigger: 'blur'},
                        { validator: this.checkData, trigger: 'blur' },
                        { min: 6, max: 12, message: '长度在 6 到 12 位', trigger: 'blur' },
                        { validator: validatePass2, trigger: 'blur', required: true }
                    ],
                },
                // 修改表单验证
                edit_rules: {
                    password : [
                        { min: 6, max: 12, message: '长度在 6 到 12 位', trigger: 'blur' },
                        { validator: this.checkData, trigger: 'blur' },
                    ]
                },
                accountList: []

            }
        },
        mounted () {
            this.getlist();
            this.getAccountlis()
            let navData = this.datalist;
            //处理数据格式
            let arr = []; //子系统 级的数据
            for (let i = 0; i < navData.length; i++) {
            if (navData[i].pid == 0) {
                arr.push({
                id: navData[i].id,
                label: navData[i].name,
                children: []
                })
            }
            this.expandedKeys.push(navData[i].id);
            }
            for (let j = 0; j < navData.length; j++) {
            for (let a = 0; a < arr.length; a++) {
                // 判断 功能id==等于菜单id时
                if (arr[a].id === navData[j].pid) {
                // 为每一个 子系统添加 菜单
                arr[a].children.push({
                    id: navData[j].id,
                    label: navData[j].name,
                    children: []
                })
                }
            }
            }
            for (let b = 0; b < navData.length; b++) {
            for (let c = 0; c < arr.length; c++) {
                for (let d = 0; d < arr[c].children.length; d++) {
                // 为每一个 菜单添加 路由导航
                if (arr[c].children[d].id === navData[b].pid) {
                    arr[c].children[d].children.push({
                    id: navData[b].id,
                    label: navData[b].name,
                    })
                }
                }
            }
            }
            this.navData = arr;
            this.submitData = this.checkedKeys; // 修改
            this.submitData = this.checkedKeys1; // 删除

            // console.log("处理完成的数据", this.navData);
            // console.log("选中的数据", this.checkedKeys);

        },
        watch: {
            saveGoods() {
                if(this.drawer == false){
                    this.saveData = []
                }
            },
        },
        methods: {
            // ****  添加 *****
            // 多选框进行改变时
            handleCheckedCitiesChange1(type, checkedKeys1, data, childrenData, nDid, Did, nid) {
            this.submitData = [];
            if (type === 3) {
                this.handleNavigation1(checkedKeys1, data, childrenData, nDid, Did, nid)
            } else if (type === 2) {
                this.handleNavigationTwo1(checkedKeys1, childrenData, nDid)
            } else if (type === 1) {
                this.handleNavigationOne1(checkedKeys1, data)
            }
            this.submitData = this.checkedKeys1;
            this.disabled = checkedKeys1.length <= 0;
            },
            //选中子系统时
            handleNavigationOne1(checkedKeys1, data) {
            let result = checkedKeys1.includes(data.id);
            if (result) {
                data.children.forEach(d => {
                let results = checkedKeys1.includes(d.id);
                if (!results) {
                    this.checkedKeys1.push(d.id);
                }
                d.children.forEach(da => {
                    let resultNavigation = checkedKeys1.includes(da.id);
                    if (!resultNavigation) {
                    this.checkedKeys1.push(da.id);
                    }
                });
                });
            } else {
                for (let i = 0, len = data.children.length; i < len; i++) {
                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === (data.children[i].id);
                    if (results) {
                    this.checkedKeys1.splice(j, 1)
                    }
                }
                }
                for (let i = 0, len = data.children.length; i < len; i++) {
                for (let k = 0, len = data.children[i].children.length; k < len; k++) {
                    for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === (data.children[i].children[k].id);
                    if (results) {
                        this.checkedKeys1.splice(j, 1)
                    }
                    }
                }
                }
            }
            },
            // 选中菜单时
            handleNavigationTwo1(checkedKeys1, childrenData, nDid) {
            let result = checkedKeys1.includes(nDid);
            if (result) {
                let results = checkedKeys1.includes(childrenData.id);
                if (!results) {
                this.checkedKeys1.push(childrenData.id);
                }
                childrenData.children.forEach(da => {
                if (da.id === nDid) {
                    let result = checkedKeys1.includes(da.id);
                    if (result) {
                    da.children.forEach(d => {
                        let resultNavigation = checkedKeys1.includes(d.id);
                        if (!resultNavigation) {
                        this.checkedKeys1.push(d.id);
                        }
                    });
                    }
                }
                });
            } else {
                for (let i = 0, len = childrenData.children.length; i < len; i++) {
                if (childrenData.children[i].id === nDid) {
                    for (let k = 0, len = childrenData.children[i].children.length; k < len; k++) {
                    for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                        let results = this.checkedKeys1[j] === (childrenData.children[i].children[k].id);
                        if (results) {
                        this.checkedKeys1.splice(j, 1)
                        }
                    }
                    }
                }
                }
                let r = 0;
                for (let i = 0, len = childrenData.children.length; i < len; i++) {
                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === (childrenData.children[i].id);
                    if (results) {
                    r++;
                    }
                }
                }
                if (r === 0) {
                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === childrenData.id;
                    if (results) {
                    this.checkedKeys1.splice(j, 1)
                    }
                }
                }
            }
            },
            handleNavigation1(checkedKeys1, data, childrenData, nDid, Did, nid) {
            let re = checkedKeys1.includes(nid);
            if (re) {

                let result = checkedKeys1.includes(nDid);
                let results = checkedKeys1.includes(Did);
                if (!result) {
                this.checkedKeys1.push(nDid);
                }
                if (!results) {
                this.checkedKeys1.push(Did);
                }
            } else {
                let number = 0;
                for (let i = 0, len = childrenData.length; i < len; i++) {
                let results = this.checkedKeys1.includes(childrenData[i].id);
                if (results) {
                    number++;
                }
                }
                if (number === 0) {
                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === nDid;
                    if (results) {
                    this.checkedKeys1.splice(j, 1)
                    }
                }
                }
                let numbers = 0;
                for (let i = 0, len = data.length; i < len; i++) {

                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === (data[i].id);
                    if (results) {
                    numbers++;
                    }
                }
                }
                if (numbers === 0) {
                for (let j = 0, lens = this.checkedKeys1.length; j < lens; j++) {
                    let results = this.checkedKeys1[j] === Did;
                    if (results) {
                    this.checkedKeys1.splice(j, 1)
                    }
                }
                }
            }
            },

            // **** 修改 ****
            // 多选框进行改变时
            handleCheckedCitiesChange(type, checkedKeys, data, childrenData, nDid, Did, nid) {
            this.submitData = [];
            if (type === 3) {
                this.handleNavigation(checkedKeys, data, childrenData, nDid, Did, nid)
            } else if (type === 2) {
                this.handleNavigationTwo(checkedKeys, childrenData, nDid)
            } else if (type === 1) {
                this.handleNavigationOne(checkedKeys, data)
            }
            this.submitData = this.checkedKeys;
            this.disabled = checkedKeys.length <= 0;
            },
            //选中子系统时
            handleNavigationOne(checkedKeys, data) {
            let result = checkedKeys.includes(data.id);
            if (result) {
                data.children.forEach(d => {
                let results = checkedKeys.includes(d.id);
                if (!results) {
                    this.checkedKeys.push(d.id);
                }
                d.children.forEach(da => {
                    let resultNavigation = checkedKeys.includes(da.id);
                    if (!resultNavigation) {
                    this.checkedKeys.push(da.id);
                    }
                });
                });
            } else {
                for (let i = 0, len = data.children.length; i < len; i++) {
                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === (data.children[i].id);
                    if (results) {
                    this.checkedKeys.splice(j, 1)
                    }
                }
                }
                for (let i = 0, len = data.children.length; i < len; i++) {
                for (let k = 0, len = data.children[i].children.length; k < len; k++) {
                    for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === (data.children[i].children[k].id);
                    if (results) {
                        this.checkedKeys.splice(j, 1)
                    }
                    }
                }
                }
            }
            },
            // 选中菜单时
            handleNavigationTwo(checkedKeys, childrenData, nDid) {
            let result = checkedKeys.includes(nDid);
            if (result) {
                let results = checkedKeys.includes(childrenData.id);
                if (!results) {
                this.checkedKeys.push(childrenData.id);
                }
                childrenData.children.forEach(da => {
                if (da.id === nDid) {
                    let result = checkedKeys.includes(da.id);
                    if (result) {
                    da.children.forEach(d => {
                        let resultNavigation = checkedKeys.includes(d.id);
                        if (!resultNavigation) {
                        this.checkedKeys.push(d.id);
                        }
                    });
                    }
                }
                });
            } else {
                for (let i = 0, len = childrenData.children.length; i < len; i++) {
                if (childrenData.children[i].id === nDid) {
                    for (let k = 0, len = childrenData.children[i].children.length; k < len; k++) {
                    for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                        let results = this.checkedKeys[j] === (childrenData.children[i].children[k].id);
                        if (results) {
                        this.checkedKeys.splice(j, 1)
                        }
                    }
                    }
                }
                }
                let r = 0;
                for (let i = 0, len = childrenData.children.length; i < len; i++) {
                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === (childrenData.children[i].id);
                    if (results) {
                    r++;
                    }
                }
                }
                if (r === 0) {
                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === childrenData.id;
                    if (results) {
                    this.checkedKeys.splice(j, 1)
                    }
                }
                }
            }
            },
            handleNavigation(checkedKeys, data, childrenData, nDid, Did, nid) {
            let re = checkedKeys.includes(nid);
            if (re) {

                let result = checkedKeys.includes(nDid);
                let results = checkedKeys.includes(Did);
                if (!result) {
                this.checkedKeys.push(nDid);
                }
                if (!results) {
                this.checkedKeys.push(Did);
                }
            } else {
                let number = 0;
                for (let i = 0, len = childrenData.length; i < len; i++) {
                let results = this.checkedKeys.includes(childrenData[i].id);
                if (results) {
                    number++;
                }
                }
                if (number === 0) {
                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === nDid;
                    if (results) {
                    this.checkedKeys.splice(j, 1)
                    }
                }
                }
                let numbers = 0;
                for (let i = 0, len = data.length; i < len; i++) {

                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === (data[i].id);
                    if (results) {
                    numbers++;
                    }
                }
                }
                if (numbers === 0) {
                for (let j = 0, lens = this.checkedKeys.length; j < lens; j++) {
                    let results = this.checkedKeys[j] === Did;
                    if (results) {
                    this.checkedKeys.splice(j, 1)
                    }
                }
                }
            }
            },


            // 密码验证不包括中文
            checkData(rule, value, callback) {
                if (value) {
                    if (/[\u4E00-\u9FA5]/g.test(value)) {
                        callback(new Error('不能包含中文!'))
                        this.$message.error('不能包含中文');
                    } else {
                        callback()
                    }
                }
                callback()
            },

            // 搜索
            search() {
                this.curPage = 1
                childAccount({
                    name:this.searchData.keyword,
                    page:this.curPage,
                    pageSize: this.pageSize,
                }).then(r=>{
                    // console.log(r);
                    this.tableData = r.data
                    for(let i=0; i<this.tableData.length;i++){
                        this.tableData[i].add_time = new Date(parseInt(this.tableData[i].add_time) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
                    }
                    this.totalNum = r.total
                })
            },
            // 新增账号
            add() {
                userinfo({}).then(r=>{
                    // console.log(r);
                    // console.log(r.data.account.split('.')[0])
                    this.user = r.data.account.split('(')[0]
                    // this.user = r.data.account
                })
                this.drawer = true
            },
            // 保存确定
            saveGoods() {
                menulist({
                    id: this.submitData
                }).then(r=>{
                    // console.log(r);
                })
                addChildAccount({
                    username: this.user + '_' + this.saveData.username,
                    password: this.saveData.password,
                    repassword: this.saveData.repassword,
                    memo: this.saveData.memo,
                    status: this.saveData.status,
                    permissions: this.submitData,
                    agent_ids: this.saveData.agent_ids
                }).then(r=>{
                    // console.log(r);
                    if(r.code == 1){
                        this.$message({
                            message:'保存成功',
                            type:'success'
                        })
                    }
                    this.getlist();
                    this.drawer = false
                    // this.$refs[e].resetFields();
                })
            },
            // 编辑信息
            companyEdit(item) {
                console.log(item);
                this.menu_id = item.menu_id
                let arr = item.menu_id.split(',')
                this.checkedKeys = arr.map(Number)
                this.submitData = this.checkedKeys
                this.agent_ids = item.agent_ids
                // console.log( this.checkedKeys,'aaaaaaaaaa')

                this.form.memo = item.memo 
                // console.log(this.form.memo,'备注');

                this.form.status = item.status
                this.form.agent_ids = Object.values(Object.assign({},item.agent_ids))
                // console.log(this.form.status,'状态');

                this.edit_id = item.id
                this.dialogFormVisible = true
            },
            // 修改确定 
            edit() {
                editAccount({
                    id: this.edit_id,
                    password: this.form.password,
                    status: this.form.status,
                    memo: this.form.memo,
                    permissions: this.submitData,
                    agent_ids: this.form.agent_ids
                }).then(r=>{
                    // console.log(r);
                    if(r.code == 1){
                        this.$message({
                            message:'修改成功',
                            type:'success'
                        })
                    }
                    this.getlist();
                    this.dialogFormVisible = false
                })
            },
            // 删除
            deleteGoods(row) {
                // console.log(row.id);
                delAccount({
                    ids:row.id,
                }).then(r=>{
                    // console.log(r);
                    if(r.code == 1){
                        this.$message({
                            message:'删除成功',
                            type:'success'
                        })
                    }
                    this.getlist();
                })
            },
            // 显示列表
            getlist() {
                childAccount({
                    page:this.curPage,
                    pageSize: this.pageSize,
                }).then(r=>{
                    // console.log(r,'');
                    this.loading = false
                    this.tableData = r.data
                    for(let i=0; i<this.tableData.length;i++){
                        // this.tableData[i].add_time = new Date(parseInt(this.tableData[i].add_time) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');
                        this.tableData[i].add_time = new Date(parseInt(this.tableData[i].add_time) * 1000).toLocaleString().replace(/\//g, '-');
                    }    
                    // console.log(this.tableData[0].add_time);
                    this.totalNum = r.total
                })
            },

            getstatus(status) {
                return status == 1 ? '启用' : '禁用';
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.getlist()
            },
            handleCurrentChange(val) {
                // console.log('current page', val);
                this.curPage = val;
                this.getlist()
            },
            filterTag(value, row) {
                return row.status === value;
            },
            statusFormatter1(row, column) {
                const status = row.status;
                if (status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            },
            getAccountlis(){
                accountlist({page:1,pageSize:100}).then(res => {
                    if(res.code == 1){
                        this.accountList = res.data
                    }
                })
            },
            powerCheck(e){
                this.saveData.agent_ids = e
            },
            agentCheck(id){
                if((this.form.agent_ids instanceof Array) == false){
                    return false
                }
                return this.form.agent_ids.includes(id)

            }


        },
    }
</script>

<style scoped>
.child-manage .f2 {
    padding: 20px;
    background-color: #fff;
}
.child-manage .page {
    text-align: right;
    margin: 15px 2px 0 0;
    /* padding-bottom: 50px; */
}
.child-manage .drawer-main {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
/* .el-dialog__body{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
} */
/deep/.el-input-group__prepend{
    background-color: #fff;
    padding: 7px 0 10px 15px;
}
/deep/.el-input-group--prepend .el-input__inner, .el-input-group__append{
    border-left: 0;
    border-color: rgb(220,223,230);
    padding: 0;
}

</style>