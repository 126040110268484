 <!-- 活动监控账户详细数据中详细分析弹窗的折线图 --> 
<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    height="400"
    :default-sort="{prop: 'date', order: 'descending'}"
  >
    <el-table-column fixed prop="time" label="时间" width="120"></el-table-column>
    <el-table-column prop="cost" label="花费" sortable width="180"></el-table-column>
    <el-table-column prop="showNum" label="展现量" sortable width="180"></el-table-column>
    <el-table-column prop="ShowCost" label="千次展现费用" sortable width="180"></el-table-column>
    <el-table-column prop="click" label="点击量" sortable width="180"></el-table-column>
    <el-table-column prop="ClickCost" label="平均点击单价" sortable width="180"></el-table-column>
    <el-table-column prop="rate" label="点击率" sortable width="180"></el-table-column>
    <el-table-column prop="convertNum" label="转化数" sortable width="180"></el-table-column>
    <el-table-column prop="ctr" label="转化成本" sortable width="180"></el-table-column>
    <el-table-column prop="convertRate" label="转化率" sortable width="180"></el-table-column>
    <el-table-column prop="payAmount" label="成交金额" sortable width="180"></el-table-column>
    <el-table-column prop="payCount" label="成交订单数" sortable width="180"></el-table-column>
    <el-table-column prop="AmountVar" label="成交订单均价" sortable width="180"></el-table-column>
    <el-table-column prop="payRoi" label="成交ROI" sortable width="180"></el-table-column>
    <el-table-column prop="convertAmount" label="当日转化金额" sortable width="180"></el-table-column>
    <el-table-column prop="convertCount" label="当日转化订单数" sortable width="180"></el-table-column>
    <el-table-column prop="ConvertAmount" label="当日转化订单均价" sortable width="180"></el-table-column>
    <el-table-column prop="convertRoi" label="当日转化ROI" sortable width="180"></el-table-column>
    <el-table-column prop="payConvertRate" label="支付转化率" sortable width="180"></el-table-column>
  </el-table>
</template>

<script>
import { timetrend } from "@/request/api.js"; // 营销活动监控实时趋势
export default {
  props: {
    aa: {
      type: String,
      default: [0]
    }
  },
  data() {
    return {
      tableData: [],
      tablelist: []
    };
  },
  mounted() {
    console.log(this.aa);
  },
  created() {
    this.Timetrend();
  },
  methods: {
    Timetrend() {
      timetrend({
        Authorization: sessionStorage.getItem("key"),
        keyType: "account", //account : 账户 , planGroup:广告组 , plan:计划
        keyId: this.aa, //账户ID
        roiMax: "", //成交roi最大值，默认传空即可
        roiMin: "", //	成交roi最小值，默认传空即可
        goodIds: [], //商品ID []
        costMin: "", //最小花费
        costMax: "" //最大花费
        //请求参数
      }).then(res => {
        // console.log(res.data);
        this.tableData = res.data;
      });
    },
    formatter(row, column) {
      return row.address;
    }
  }
};
</script>
 <style>
</style>