import { render, staticRenderFns } from "./NavCommon.vue?vue&type=template&id=0c622a1c&scoped=true&"
import script from "./NavCommon.vue?vue&type=script&lang=js&"
export * from "./NavCommon.vue?vue&type=script&lang=js&"
import style0 from "./NavCommon.vue?vue&type=style&index=0&id=0c622a1c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c622a1c",
  null
  
)

export default component.exports