<template>
  <div>
    <div>
      <el-tabs
        style="background-color: #fff"
        v-model="activeTabName"
        @tab-click="tabclick"
      >
        <!-- 汇总 -->
        <el-tab-pane name="1" v-loading="loading">
          <span slot="label">
            <span style="padding-left: 8px">汇总</span>
          </span>
          <!-- 筛选 -->
          <div class="Marketing_activity_monitoring_1" style="margin: 0; padding: 0">
            <div class="pandding" style="display: flex; flex-wrap: wrap">
              <!-- 活动选择 -->
              <shaixuan :activitys="activitys" :trendDisabled ="trendDisabled" @handlechange1="handlechange1"></shaixuan>
              <!-- 帐户/广告组/计划 选择 -->
              <sx2
                :acid="acid"
                :targetType="targetType"
                @handlechange2="handlechange2"
              ></sx2>
              <!-- 淘宝/京东/拼多多 -->
              <order @orderchange="orderchange"></order>
              <!-- 商品搜索 -->
              <sx3 :acid="acid" @handlechange3="handlechange3"></sx3>
              <!-- 分日/分时 -->
              <repsx2
                style="width: 100%"
                :torepsx2="torepsx2"
                :isShowSelect="true"
                :isShowCompare="true"
                :timeCycle="[
                  this.filterDateStart,
                  this.filterDateEnd,
                  this.filterContrastDateStart,
                  this.filterContrastDateEnd,
                ]"
                :defaultselect="filterDateType"
                @getweek="getweek($event)"
                @getweekevent="getweekevent($event)"
              ></repsx2>

              <!-- 归因/不归因 -->
              <repsx4 @change="changeCycle"></repsx4>

              <div class="flex pandding" style="margin: 0; display: contents">
                <el-popover
                  style=""
                  class
                  placement="bottom"
                  width="230"
                  trigger="click"
                  @show="showROI"
                  v-model="visibleROI"
                >
                  <el-input
                    class="elinput ROIbox"
                    clearable
                    slot="reference"
                    style="width: 200px; margin: 0 0 20px 0"
                    :readonly="true"
                    v-model="input"
                    placeholder="请输入ROI"
                  >
                    <!-- <el-button slot="append" icon="el-icon-search" @click="carTypeVisible = true" /> -->
                    <span slot="suffix" v-show="input" @click.stop.prevent="handleclear1">
                      <i
                        class="el-icon-close"
                        style="margin-left: 5px; cursor: pointer; margin-top: 12px"
                      ></i>
                    </span>
                  </el-input>
                  <div class="flex">
                    <div class="dayu" ref="dayu">大于</div>
                    <el-input
                      class="margin_right4"
                      ref="roi_input1"
                      style="width: 100%"
                      :maxlength="3"
                      v-model="input1"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <div class="dayu" ref="xiaoyu">小于</div>
                    <el-input
                      class="margin_right4"
                      style="width: 100%"
                      :maxlength="3"
                      v-model="input2"
                    ></el-input>
                  </div>
                  <div class="float_right">
                    <el-button class type="primary" @click="handleapplication"
                      >应用</el-button
                    >
                  </div>
                </el-popover>
                <el-popover
                  style=""
                  placement="bottom"
                  width="250"
                  trigger="click"
                  @show="showCOST"
                  v-model="visibleCOST"
                >
                  <el-input
                    class="elinput_right"
                    slot="reference"
                    style="width: 200px"
                    :readonly="true"
                    clearable
                    v-model="input3"
                    placeholder="请输入花费"
                  >
                    <span
                      slot="suffix"
                      v-show="input3"
                      @click.stop.prevent="handleclear2"
                    >
                      <i
                        class="el-icon-close"
                        style="margin-left: 5px; cursor: pointer; margin-top: 12px"
                      ></i>
                    </span>
                  </el-input>
                  <div class="flex">
                    <div class="dayu">大于</div>
                    <el-input
                      class="margin_right4"
                      style="width: 100%"
                      ref="cast_input4"
                      v-model="input4"
                    ></el-input>
                  </div>
                  <div class="flex">
                    <div class="dayu">小于</div>
                    <el-input
                      class="margin_right4"
                      style="width: 100%"
                      v-model="input5"
                    ></el-input>
                  </div>
                  <div class="float_right">
                    <el-button class type="primary" @click="handlemoney">应用</el-button>
                  </div>
                </el-popover>
              </div>
            </div>

            <!-- <div class="flex" style="text-align:left;padding-left:40px;">
              <el-link type="primary" @click="isShowFilterMore = !isShowFilterMore">显示更多筛选条件</el-link>
            </div>-->
          </div>
          <!-- 实时数据概览 -->
          <div class="Marketing_activity_monitoring_2">
            <div class="pandding" style="padding-bottom: 5px">
              <div class="flex" style="margin-bottom: 0">
                数据概览
                <div class="font14 Marketing_activity_monitoring_2_text">
                  数据统计截止至
                  <div id="time" class="Marketing_activity_monitoring_2_text">
                    {{ overViewData.updateTime }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex box" style="flex-wrap: wrap">
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">花费</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="选定时间周期内，总的花费"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.cost == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.cost }}</div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">展现量</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="选定时间周期内，总的展现量"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.show == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.show }}</div>
                </div>
                <div class="pandding">
                  <div class="flex_between">
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">千次展现费用(元)</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="花费/展现量*1000"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.cpm == null">--</div>
                      <div class="flex font22" v-else>{{ overViewData.cpm }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">点击量</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="选定时间周期内，总的点击量"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.click == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.click }}</div>
                </div>
                <div class="pandding">
                  <div class="flex_between">
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">平均点击单价</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="花费/点击量"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.cpc == null">--</div>
                      <div class="flex font22" v-else>{{ overViewData.cpc }}</div>
                    </div>
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">点击率</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="点击量/展现量"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.clickRate == null">
                        --
                      </div>
                      <div class="flex font22" v-else>{{ overViewData.clickRate }}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">转化数</div>
                    <div>
                      <el-tooltip placement="top">
                        <div slot="content">
                          <span v-if="overViewData.specifyNum == null">选定周期内，指定商品ID上报数;<br>非指定商品ID上报数</span>
                          <span v-else>选定周期内，指定商品ID上报数：{{overViewData.specifyNum}}，<br>非指定商品ID上报数：{{overViewData.nonSpecifyNum}}</span>
                        </div>
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.convert == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.convert }}</div>
                </div>
                <div class="pandding">
                  <div class="flex_between">
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">转化成本(元)</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="花费/转化数"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.convertCost == null">
                        --
                      </div>
                      <div class="flex font22" v-else>{{ overViewData.convertCost }}</div>
                    </div>
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">转化率</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="转化数/点击量"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.convertRate == null">
                        --
                      </div>
                      <div class="flex font22" v-else>
                        {{ overViewData.convertRate }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
              <!-- <div class="flex"> -->
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">成交金额</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="选定周期内，投放推广带来的期间累计成交金额"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.amountVar == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.amountVar }}</div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">成交订单数</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="选定周期内，投放推广带来的期间累计成交订单数"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.payCountVar == null">
                    --
                  </div>
                  <div class="flex font22" v-else>{{ overViewData.payCountVar }}</div>
                </div>
                <div class="pandding">
                  <div class="flex_between">
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">支付转化率</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="选定周期内，成交人数/累计页面UV*100%"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="font28 flex" v-if="overViewData.payConvertRate == null">
                        --
                      </div>
                      <div class="flex font22" v-else>
                        {{ overViewData.payConvertRate }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">成交订单均价</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="期间累计成交金额/期间累计成交订单数"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.avgAmountVar == null">
                    --
                  </div>
                  <div class="flex font22" v-else>{{ overViewData.avgAmountVar }}</div>
                </div>
                <div class="pandding">
                  <div class="flex_between">
                    <div>
                      <div class="flex" style="margin-bottom: 5px">
                        <div class="font14">客单价</div>
                        <div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="期间累计成交金额/期间累计成交人数"
                            placement="top"
                          >
                            <i class="el-icon-warning font14"></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div
                        class="font28 flex"
                        v-if="overViewData.perCustomerAmount == null"
                      >
                        --
                      </div>
                      <div class="flex font22" v-else>
                        {{ overViewData.perCustomerAmount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Marketing_activity_monitoring_2_box">
                <div class="pandding">
                  <div class="flex" style="margin-bottom: 5px">
                    <div class="font14">成交ROI</div>
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="期间累计成交总额/花费"
                        placement="top"
                      >
                        <i class="el-icon-warning font14"></i>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="font28 flex" v-if="overViewData.roiVar == null">--</div>
                  <div class="flex font22" v-else>{{ overViewData.roiVar }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 时间趋势 -->
          <div class="Marketing_activity_monitoring_3">
            <trend
              ref="effectObjreport"
              :hw="this.acid"
              :name="name"
              :filterData="goodTabsFilterData"
            ></trend>
          </div>
        </el-tab-pane>
        <!-- 计划数据 -->
        <el-tab-pane name="plan">
          <span slot="label">
            <span style="padding-left: 8px">计划数据</span>
          </span>
          <!-- 计划筛选层 -->
          <div class="head_search_box1">
            <!-- 计划搜索框 -->
            <div class="head_search1">
              <el-input
                @clear="getUserListPlan"
                placeholder="请输入计划名称"
                v-model="keywordPlan"
                clearable
                style="width: 350px; margin: 0px 20px 40px 0"
              >
                <template slot="append">
                  <el-button @click="getUserListPlan" type style="background: #f5f7fa"
                    >搜索</el-button
                  >
                </template>
              </el-input>

              <!-- 账户筛选 -->
              <el-select
                style="width: 300px; margin: 0 20px 40px 0px"
                @visible-change="handleaccount"
                v-model="valaccountplan"
                filterable
                placeholder="请选择查询账户"
                multiple
                collapse-tags
                @change="changeSelectaccountplan"
              >
                <el-option
                  v-for="(item, index) in options_account"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
              <!-- 渠道商品筛选 -->
              <orderplan @orderchangeplan="orderchangeplan"></orderplan>
              <!-- 商品筛选 -->
              <el-select
                class="elinput_right"
                style="margin: 0 20px 40px 0; width: 240px"
                @visible-change="handlegoods"
                v-model="valgoodsplan"
                filterable
                placeholder="请选择商品"
                multiple
                collapse-tags
                @change="changeSelectgoodsplan"
              >
                <el-option
                  v-for="(item, index) in options_goods"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.goodsId"
                  class="goodsname"
                >
                  <el-popover placement="right" width="100" trigger="hover" content>
                    <img
                      slot="reference"
                      @mouseover="mouseover"
                      onmouseover="this.style.border='1px solid #000'"
                      onmouseout="this.style.border='1px solid #fff'"
                      :src="item.goodsUrl"
                      alt
                      style="width: 50px; height: 50px"
                    />
                    <div style="width: 100px; height: 100px; background: #c9d4ec">
                      <img style="width: 100%; height: 100%" :src="item.goodsUrl" alt />
                    </div>
                  </el-popover>
                  <span style="padding: 10px; margin: 0px 0 0 28px; display: flex">{{
                    item.goodsName
                  }}</span>
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <repsx2plan
                :torepsx2plan="torepsx2plan"
                style="margin: 0px 0px 20px 0"
                :isShowSelect="true"
                :timeCycle="[
                  this.filterDateStartPlan,
                  this.filterDateEndPlan,
                  this.filterContrastDateStartPlan,
                  this.filterContrastDateEndPlan,
                ]"
                @getweekplan="getweekplan($event)"
              ></repsx2plan>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_5">
            <GoodTabs
              :valone1="valone1"
              right_params="plan"
              :keywordPlan="keywordPlan"
              :valgoodsplan="valgoodsplan"
              :filterDateStartPlan="filterDateStartPlan"
              :filterDateEndPlan="filterDateEndPlan"
              :val="val"
              :totalnumPlan="totalnumPlan"
              :accountarrayPlan="accountarrayPlan"
              :filterData="goodTabsFilterData"
              :orderfromplan="orderfromplan"
              :parmval="parmvalplan"
              :acid="acid"
            ></GoodTabs>
          </div>
        </el-tab-pane>
        <!-- 子账户数据 -->
        <el-tab-pane>
          <span slot="label">
            <span style="padding-left: 8px">子账户数据</span>
          </span>
          <div class="head_search_box1">
            <!-- 子账户搜索框 -->
            <div class="head_search1">
              <el-input
                @clear="getUserListAccount"
                placeholder="请输入子账户名称或ID"
                v-model="keywordAccount"
                clearable
                style="width: 350px; margin: 0px 20px 40px 0"
              >
                <template slot="append">
                  <el-button @click="getUserListAccount" type style="background: #f5f7fa"
                    >搜索</el-button
                  >
                </template>
              </el-input>
              <!-- 账户筛选 -->
              <el-select
                style="width: 300px; margin: 0 20px 40px 0px"
                @visible-change="handleaccount"
                v-model="valaccountAccount"
                filterable
                placeholder="请选择查询账户"
                multiple
                collapse-tags
                @change="changeSelectaccountAccount"
              >
                <el-option
                  v-for="(item, index) in options_account"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
              <!-- 渠道商品筛选 -->
              <orderplan @orderchangeAccount="orderchangeAccount"></orderplan>
              <!-- 商品筛选 -->
              <el-select
                class="elinput_right"
                style="margin: 0 20px 40px 0; width: 240px"
                @visible-change="handlegoods"
                v-model="valgoodsAccount"
                filterable
                placeholder="请选择商品"
                multiple
                collapse-tags
                @change="changeSelectgoodsAccount"
              >
                <el-option
                  v-for="(item, index) in options_goods"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.goodsId"
                  class="goodsname"
                >
                  <el-popover placement="right" width="100" trigger="hover" content>
                    <img
                      slot="reference"
                      @mouseover="mouseover"
                      onmouseover="this.style.border='1px solid #000'"
                      onmouseout="this.style.border='1px solid #fff'"
                      :src="item.goodsUrl"
                      alt
                      style="width: 50px; height: 50px"
                    />
                    <div style="width: 100px; height: 100px; background: #c9d4ec">
                      <img style="width: 100%; height: 100%" :src="item.goodsUrl" alt />
                    </div>
                  </el-popover>
                  <span style="padding: 10px; margin: 0px 0 0 28px; display: flex">{{
                    item.goodsName
                  }}</span>
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <repsx2Account
                :torepsx2Account="torepsx2Account"
                style="margin: 0px 0px 20px 0"
                :isShowSelect="true"
                :timeCycle="[
                  this.filterDateStartAccount,
                  this.filterDateEndAccount,
                  this.filterContrastDateStartAccount,
                  this.filterContrastDateEndAccount,
                ]"
                @getweekAccount="getweekAccount($event)"
              ></repsx2Account>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_5">
            <GoodTabsaccount
              :valone1="valone1"
              right_params="account"
              :keywordAccount="keywordAccount"
              :valgoodsAccount="valgoodsAccount"
              :filterDateStartAccount="filterDateStartAccount"
              :filterDateEndAccount="filterDateEndAccount"
              :val="val"
              :totalnumAccount="totalnumAccount"
              :accountarrayAccount="accountarrayAccount"
              :filterData="goodTabsFilterData"
              :orderfromAccount="orderfromAccount"
              :parmval="parmvalaccount"
              @showPlan="showPlan"
              :acid="acid"
            ></GoodTabsaccount>
          </div>
        </el-tab-pane>
        <!-- 主账户数据 -->
        <el-tab-pane>
          <span slot="label">
            <span style="padding-left: 8px">主账户数据</span>
          </span>
          <!-- 主账户筛选层 -->
          <div class="head_search_box1">
            <!-- 主账户搜索框 -->
            <div class="head_search1">
              <el-input
                @clear="getUserListparAccount"
                placeholder="请输入主账户名称"
                v-model="keywordparAccount"
                clearable
                style="width: 350px; margin: 0px 20px 40px 0"
              >
                <template slot="append">
                  <el-button
                    @click="getUserListparAccount"
                    type
                    style="background: #f5f7fa"
                    >搜索</el-button
                  >
                </template>
              </el-input>
              <!-- 账户筛选 -->
              <el-select
                style="width: 300px; margin: 0 20px 40px 0px"
                @visible-change="handleparaccount"
                v-model="valaccountparAccount"
                filterable
                placeholder="请选择查询主账户"
                multiple
                collapse-tags
                @change="changeSelectparAccount"
              >
                <el-option
                  v-for="(item, index) in options_account"
                  :key="index"
                  :label="item.mainAccountName"
                  :value="item.mainAccountId"
                ></el-option>
              </el-select>
              <!-- 渠道商品筛选 -->
              <orderplan @orderchangeparAccount="orderchangeparAccount"></orderplan>
              <!-- 商品筛选 -->
              <el-select
                class="elinput_right"
                style="margin: 0 20px 40px 0; width: 240px"
                @visible-change="handlegoods"
                v-model="valgoodsparAccount"
                filterable
                placeholder="请选择商品"
                multiple
                collapse-tags
                @change="changeSelectgoodsparAccount"
              >
                <el-option
                  v-for="(item, index) in options_goods"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.goodsId"
                  class="goodsname"
                >
                  <el-popover placement="right" width="100" trigger="hover" content>
                    <img
                      slot="reference"
                      @mouseover="mouseover"
                      onmouseover="this.style.border='1px solid #000'"
                      onmouseout="this.style.border='1px solid #fff'"
                      :src="item.goodsUrl"
                      alt
                      style="width: 50px; height: 50px"
                    />
                    <div style="width: 100px; height: 100px; background: #c9d4ec">
                      <img style="width: 100%; height: 100%" :src="item.goodsUrl" alt />
                    </div>
                  </el-popover>
                  <span style="padding: 10px; margin: 0px 0 0 28px; display: flex">{{
                    item.goodsName
                  }}</span>
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <repsx2parAccount
                :showparAccount="showparAccount"
                :torepsx2parAccount="torepsx2parAccount"
                style="margin: 0px 0px 20px 0"
                :isShowSelect="true"
                :timeCycle="[
                  this.filterDateStartparAccount,
                  this.filterDateEndparAccount,
                  this.filterContrastDateStartparAccount,
                  this.filterContrastDateEndparAccount,
                ]"
                @getweekparAccount="getweekparAccount($event)"
              ></repsx2parAccount>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_5">
            <GoodTabsparaccount
              :valone1="valone1"
              right_params="mainAccount"
              :keywordparAccount="keywordparAccount"
              :valgoodsparAccount="valgoodsparAccount"
              :filterDateStartparAccount="filterDateStartparAccount"
              :filterDateEndparAccount="filterDateEndparAccount"
              :val="val"
              :totalnumparAccount="totalnumparAccount"
              :accountarrayParaccount="accountarrayParaccount"
              :filterData="goodTabsFilterData"
              :orderfromparAccount="orderfromparAccount"
              :parmval="parmvalparaccount"
            ></GoodTabsparaccount>
          </div>
        </el-tab-pane>
        <!-- 公司数据 -->
        <el-tab-pane>
          <span slot="label">
            <span style="padding-left: 8px">公司数据</span>
          </span>
          <div class="head_search_box1">
            <!-- 公司搜索框 -->
            <div class="head_search1">
              <el-input
                @clear="getUserListCompanydata"
                placeholder="请输入公司名称"
                v-model="keywordCompanydata"
                clearable
                style="width: 350px; margin: 0px 20px 40px 0"
              >
                <template slot="append">
                  <el-button
                    @click="getUserListCompanydata"
                    type
                    style="background: #f5f7fa"
                    >搜索</el-button
                  >
                </template>
              </el-input>
              <!-- 账户筛选 -->
              <el-select
                style="width: 300px; margin: 0 20px 40px 0px"
                @visible-change="handleaccount"
                v-model="valaccountCompanydata"
                filterable
                placeholder="请选择查询账户"
                multiple
                collapse-tags
                @change="changeSelectCompanydata"
              >
                <el-option
                  v-for="(item, index) in options_account"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
              <!-- 渠道商品筛选 -->
              <orderplan @orderchangeCompanydata="orderchangeCompanydata"></orderplan>
              <!-- 商品筛选 -->
              <el-select
                class="elinput_right"
                style="margin: 0 20px 40px 0; width: 240px"
                @visible-change="handlegoods"
                v-model="valgoodsCompanydata"
                filterable
                placeholder="请选择商品"
                multiple
                collapse-tags
                @change="changeSelectgoodsCompanydata"
              >
                <el-option
                  v-for="(item, index) in options_goods"
                  :key="index"
                  :label="item.goodsName"
                  :value="item.goodsId"
                  class="goodsname"
                >
                  <el-popover placement="right" width="100" trigger="hover" content>
                    <img
                      slot="reference"
                      @mouseover="mouseover"
                      onmouseover="this.style.border='1px solid #000'"
                      onmouseout="this.style.border='1px solid #fff'"
                      :src="item.goodsUrl"
                      alt
                      style="width: 50px; height: 50px"
                    />
                    <div style="width: 100px; height: 100px; background: #c9d4ec">
                      <img style="width: 100%; height: 100%" :src="item.goodsUrl" alt />
                    </div>
                  </el-popover>
                  <span style="padding: 10px; margin: 0px 0 0 28px; display: flex">{{
                    item.goodsName
                  }}</span>
                </el-option>
              </el-select>
              <!-- 日期选择 -->
              <repsx2Companydata
                :torepsx2Companydata="torepsx2Companydata"
                style="margin: 0px 0px 20px 0"
                :isShowSelect="true"
                :timeCycle="[
                  this.filterDateStartCompanydata,
                  this.filterDateEndCompanydata,
                  this.filterContrastDateStartCompanydata,
                  this.filterContrastDateEndCompanydata,
                ]"
                @getweekCompanydata="getweekCompanydata($event)"
              ></repsx2Companydata>
            </div>
          </div>
          <div class="Marketing_activity_monitoring_5">
            <GoodTabscompanydata
              :valone1="valone1"
              right_params="company"
              :keywordCompanydata="keywordCompanydata"
              :valgoodsCompanydata="valgoodsCompanydata"
              :filterDateStartCompanydata="filterDateStartCompanydata"
              :filterDateEndCompanydata="filterDateEndCompanydata"
              :val="val"
              :totalnumCompanydata="totalnumCompanydata"
              :accountarrayCompanydata="accountarrayCompanydata"
              :filterData="goodTabsFilterData"
              :orderfromCompanydata="orderfromCompanydata"
              :parmval="parmvalcompanydata"
            ></GoodTabscompanydata>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script src="//unpkg.com/vue/dist/vue.js"></script>
<script src="//unpkg.com/element-ui@2.15.7/lib/index.js"></script>
<script>

import trend from "../../echarts/goods_trend";
import columnclick from "../../echarts/goods_columnclick";
import shaixuan from "../../echarts/goods_shaixuan";
// import sx2 from "../../echarts/goods_sx2";
// import sx2 from "../../echarts/goods_sx2";
const sx2 = () => import("@/echarts/goods_sx2");
const sx2plan = () => import("@/echarts/goods_sx2");
import sx3 from "../../echarts/goods_sx3";

import reporteffect from "../../components/report/report_effect";
// import repsx2 from "../../components/report/report_sx2";
// import repsx2plan from "../../components/report/report_sx2";
const repsx2 = () => import("@/components/report/report_sx2");
const repsx2plan = () => import("@/components/report/report_sx2");
const repsx2Account = () => import("@/components/report/report_sx2");
const repsx2parAccount = () => import("@/components/report/report_sx2");
const repsx2Companydata = () => import("@/components/report/report_sx2");

import repsx4 from "../../components/report/report_sx4";
import GoodTabs from "../../echarts/goods_tabs";
import GoodTabsaccount from "../../echarts/goods_tabs_account";
import GoodTabsparaccount from "../../echarts/goods_tabs_paraccount";
import GoodTabscompanydata from "../../echarts/goods_tabs_company";

// import order from "../../echarts/goods_order";
// import orderplan from "../../echarts/goods_order";
const order = () => import("@/echarts/goods_order");
const orderplan = () => import("@/echarts/goods_order");
const parms = ["", "plan", "account", "mainAccount", "company"];
import $utils from "@/js/utils";
import {
  activitylist,
  bbdataoverview,
  realtimetrend,
  planningdata,
  accountlis,
  commodity,
  subaccountdata,
  masteraccountdata,
  advertisercompany
} from "@/request/api.js"; // 营销监控筛选接口和首页页签获取账户、广告组、计划接口
import { number } from "echarts";
export default {
  components: {
    trend,
    reporteffect,
    columnclick,
    shaixuan,
    sx2,
    sx2plan,
    sx3,
    repsx2,
    repsx2plan,
    repsx2Account,
    repsx2parAccount,
    repsx2Companydata,
    repsx4,
    GoodTabs,
    order,
    orderplan,
    GoodTabsaccount,
    GoodTabsparaccount,
    GoodTabscompanydata
  },
  data() {
    return {
      trendDisabled:false,
      activeTabName: '1',
      valone1: null,
      overViewDataPlan: [],
      showparAccount: true, //主账户传递布尔值
      right_params: "",
      val: [],
      torepsx2: "1",
      torepsx2plan: "2",
      torepsx2Account: "3",
      torepsx2parAccount: "4",
      torepsx2Companydata: "5",
      accountarrayPlan: [], //计划表格数据包
      accountarrayAccount: [], //子账户表格数据包
      accountarrayParaccount: [], //主账户表格数据包
      accountarrayCompanydata: [], //公司表格数据包
      mouseover: "",
      options_account: [], //账户下拉框
      options_goods: [], //商品下拉框
      valaccountplan: "", //计划账户筛选
      valaccountAccount: "", //计划账户筛选
      valaccountparAccount: "", //主账户账户筛选
      valaccountCompanydata: "", //公司账户账户筛选

      valchannelgoods: "", //渠道商品筛选
      valgoodsplan: [], //计划商品筛选
      valgoodsAccount: [], //子账户商品筛选
      valgoodsparAccount: [], //主账户商品筛选
      valgoodsCompanydata: [], //公司商品筛选
      keywordPlan: "", //计划搜索
      keywordAccount: "",
      keywordparAccount: "",
      keywordCompanydata: "",
      loading: false,
      name: "0",
      visibleROI: false,
      visibleCOST: false,

      hw: [],
      isShowFilterMore: false,
      childVisible: false, //是否展示抽屉
      overViewData: {
        amountVar: "", //实数数据概览成交金额
        click: "", //实数数据概览点击量
        convert: "", //实数数据概览转化数
        payCountVar: "", //实数数据概览成交订单数
        show: "", //实数数据概览展现量
        avgAmountVar: "", //实数数据概览成交订单均价
        cpc: "", //实数数据概览平均点击单价
        cpm: "", //实数数据概览千次展现费用
        payConvertRate: "", //实数数据概览支付转化率
        perCustomerAmount: "", //实数数据概览客单价
        roiVar: "", //实数数据概览成交ROI
        cost: "", //实数数据概览花费
        convertRate: "", //实数数据概览转化率
        convertCost: "", //实数数据概览转化成本
        clickRate: "", //实数数据概览点击率
        updateTime: ""
      },
      trend: "",
      cost: "",
      value: "",
      input1: "",
      input2: "",
      input4: "",
      input5: "",
      // 对于半全选，判断全选功能的进入或是退出
      props: { multiple: true },
      activitys: [],
      visible: false,

      tabType: "",
      accountId: "", //账户id
      adGroupId: "", //广告组id
      planId: "", //计划id
      accountName: "", //账户名称
      cost: "", //花费
      showNum: "", //展现量
      click: "", //点击量
      convertNum: "", //转化数
      payAmount: "", //	成交金额
      convertAmount: "", //	当日转化金额
      payCount: "", //	成交订单数
      convertCount: "", //当日转化订单数
      cpm: "", //千次展现费用
      cpc: "", //平均点击单价
      ctr: "", //点击率
      convertCost: "", //转化成本
      convertRate: "", //转化率
      // avgAmountVar: "", //成交订单均价
      payRoi: "", //成交roi
      avgConvertAmount: "", //	当日转化订单均价
      convertRoi: "", //当日转化roi
      payConvertRate: "", //支付转化率
      goodIds: [],
      acid: [],
      roiMax: "",
      roiMin: "",
      costMax: "",
      costMin: "",
      // 选项卡
      activeName: "account",
      totalnumPlan: null,
      totalnumAccount: null,
      totalnumparAccount: null,
      totalnumCompanydata: null,

      tsnum: "10",
      targetType: "",
      targetIds: [],
      effect: [],
      filterDateStart: "",
      filterDateEnd: "",
      filterContrastDateStart: "",
      filterContrastDateEnd: "",
      filterDateType: "1",
      filterDateStartPlan: "",
      filterDateEndPlan: "",
      filterContrastDateStartPlan: "",
      filterContrastDateEndPlan: "",
      filterDateStartAccount: "",
      filterDateEndAccount: "",
      filterContrastDateStartAccount: "",
      filterContrastDateEndAccount: "",
      filterDateStartparAccount: "",
      filterDateEndparAccount: "",
      filterContrastDateStartparAccount: "",
      filterContrastDateEndparAccount: "",
      filterDateStartCompanydata: "",
      filterDateEndCompanydata: "",
      filterContrastDateStartCompanydata: "",
      filterContrastDateEndCompanydata: "",
      contrastStatus: 0,
      cycle: "0",
      orderfrom: "",
      orderfromplan: "",
      orderfromAccount: "",
      orderfromparAccount: "",
      orderfromCompanydata: "",
      tab: "",
      parmvalplan: "", // 请求参数计划(plan)
      parmvalaccount: "", //请求参数子账户(account)
      parmvalparaccount: "", //请求参数主账户(mainAccount)
      parmvalcompanydata: "" //请求参数公司(company)
    };
  },
  created() {
    // console.log(sessionStorage.getItem("key"));//动态token
    const end = new Date(); //获取当前的日期
    const start = new Date(); //获取当前的日期
    const endPlan = new Date(); //获取当前的日期
    const startPlan = new Date(); //获取当前的日期
    const endAccount = new Date(); //获取当前的日期
    const startAccount = new Date(); //获取当前的日期
    const endparAccount = new Date(); //获取当前的日期
    const startparAccount = new Date(); //获取当前的日期
    const endCompanydata = new Date(); //获取当前的日期
    const startCompanydata = new Date(); //获取当前的日期
    //end.setTime(end.getTime() );
    //start.setTime(end.getTime() - 3600 * 1000 * 24 * 6);
    //计算，将当期日期-1天
    //此时得到的是中国标准时间，格式不是yyyy-MM-dd，需要用dateFormat这个函数转换下
    this.filterDateStart = $utils.format(start, "yyyy-MM-dd");
    this.filterDateEnd = $utils.format(end, "yyyy-MM-dd");
    end.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
    start.setTime(end.getTime());

    this.filterContrastDateStart = $utils.format(start, "yyyy-MM-dd");
    this.filterContrastDateEnd = $utils.format(end, "yyyy-MM-dd");

    this.filterDateStartPlan = $utils.format(startPlan, "yyyy-MM-dd");
    this.filterDateEndPlan = $utils.format(endPlan, "yyyy-MM-dd");
    endPlan.setTime(startPlan.getTime() - 3600 * 1000 * 24 * 1);
    startPlan.setTime(endPlan.getTime() - 3600 * 1000 * 24 * 6);
    this.filterContrastDateStartPlan = $utils.format(startPlan, "yyyy-MM-dd");
    this.filterContrastDateEndPlan = $utils.format(endPlan, "yyyy-MM-dd");

    this.filterDateStartAccount = $utils.format(startAccount, "yyyy-MM-dd");
    this.filterDateEndAccount = $utils.format(endAccount, "yyyy-MM-dd");
    endAccount.setTime(startAccount.getTime() - 3600 * 1000 * 24 * 1);
    startAccount.setTime(endAccount.getTime() - 3600 * 1000 * 24 * 6);
    this.filterContrastDateStartAccount = $utils.format(
      startAccount,
      "yyyy-MM-dd"
    );
    this.filterContrastDateEndAccount = $utils.format(endAccount, "yyyy-MM-dd");

    this.filterDateStartparAccount = $utils.format(
      startparAccount,
      "yyyy-MM-dd"
    );
    this.filterDateEndparAccount = $utils.format(endparAccount, "yyyy-MM-dd");
    endparAccount.setTime(startparAccount.getTime() - 3600 * 1000 * 24 * 1);
    startparAccount.setTime(endparAccount.getTime() - 3600 * 1000 * 24 * 6);
    this.filterContrastDateStartparAccount = $utils.format(
      startparAccount,
      "yyyy-MM-dd"
    );
    this.filterContrastDateEndparAccount = $utils.format(
      endparAccount,
      "yyyy-MM-dd"
    );

    this.filterDateStartCompanydata = $utils.format(
      startCompanydata,
      "yyyy-MM-dd"
    );
    this.filterDateEndCompanydata = $utils.format(endCompanydata, "yyyy-MM-dd");
    endCompanydata.setTime(startCompanydata.getTime() - 3600 * 1000 * 24 * 1);
    startCompanydata.setTime(endCompanydata.getTime() - 3600 * 1000 * 24 * 6);
    this.filterContrastDateStartCompanydata = $utils.format(
      startCompanydata,
      "yyyy-MM-dd"
    );
    this.filterContrastDateEndCompanydata = $utils.format(
      endCompanydata,
      "yyyy-MM-dd"
    );
  },
  computed: {
    input() {
      if (this.roiMax == "" || this.roiMin == "") {
        return "";
      }
      return `大于:${this.roiMin}` + `:` + `小于:${this.roiMax}`;
    },
    input3() {
      if (this.costMax == "" || this.costMin == "") {
        return "";
      }
      return `大于:${this.costMin}` + `:` + `小于:${this.costMax}`;
    },
    goodTabsFilterData() {
      // return {}
      return {
        goodIds: this.goodIds,
        acticityId: this.acid,
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateStart: this.filterDateStart,
        filterDateEnd: this.filterDateEnd,
        contrastStatus: this.contrastStatus,
        filterContrastDateEnd: this.filterContrastDateEnd,
        filterContrastDateStart: this.filterContrastDateStart,
        filterDateType: this.filterDateType,
        cycle: this.cycle,
        // chooseKeyType: "account",
        chooseKeyId: "",
        accountIds: "",
        // chooseKeyId: this.chooseKeyId,
        orderFrom: this.orderfrom
      };
    }
  },
  mounted() {
    this.Activitylist();

    // this.pid = pubsub.subscribe('changeActivity',(msgName, data)=>{
    //   this.search()
    // })
  },
  methods: {
    // 计划时间筛选
    getweekplan(value) {
      this.valone1 = null;
      console.log("getweekplan", value.data);
      console.log("plan", this.filterDateStartPlan);
      let data = value.data;
      this.filterDateStartPlan = data[0];
      this.filterDateEndPlan = data[1];
      this.filterContrastDateStartPlan = data[2];
      this.filterContrastDateEndPlan = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = value.contrastStatus;
      setTimeout(() => {
        this.valone1 = 1;
        this.searchplan();
      }, 200);
    },
    // 计划搜索框
    getUserListPlan(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(this.val);
      planningdata({
        search: this.keywordPlan,
        acticityId: this.acid,
        filterDateEnd: this.filterDateEndPlan,
        filterDateStart: this.filterDateStartPlan,
        accountIds: this.val,
        goodIds: this.valgoodsplan,
        orderFrom: this.orderfromplan
      }).then(res => {
        this.totalnumPlan = res.total;
        this.accountarrayPlan = res.data;
      });
    },
    // 计划账号下拉planchange事件
    changeSelectaccountplan(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      // console.log(val);
      this.val = val;
      planningdata({
        platform:sessionStorage.getItem("cur_platform"),
        search: this.keywordPlan,
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndPlan,
        filterDateStart: this.filterDateStartPlan,
        filterDateType: "1",
        goodIds: this.valgoodsplan,
        orderFrom: this.orderfromplan,
        pageSize: "10"
      }).then(res => {
        console.log(res.data);
        this.accountarrayPlan = res.data;
        this.totalnumPlan = res.total;
        console.log(this.accountarrayPlan);
      });
    },
    // 计划商品下拉planchange事件
    changeSelectgoodsplan(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      this.valgoodsplan = val;
      console.log(this.valgoodsplan);
      planningdata({
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndPlan,
        filterDateStart: this.filterDateStartPlan,
        filterDateType: "1",
        goodIds: this.valgoodsplan,
        orderFrom: this.orderfromplan,
        pageSize: "10"
      }).then(res => {
        console.log(res);
        this.totalnumPlan = res.total;
        this.accountarrayPlan = res.data;
      });
    },
    // 计划订单来源
    orderchangeplan(value) {
      console.log(value, "order子组件传过来拉");
      this.orderfromplan = value;
      console.log(this.orderfromplan);
      this.searchplan();
    },
    // 子账户账号下拉planchange事件
    changeSelectaccountAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.val = val;
      subaccountdata({
        platform:sessionStorage.getItem("cur_platform"),
        search: this.keywordAccount,
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndAccount,
        filterDateStart: this.filterDateStartAccount,
        filterDateType: "1",
        goodIds: this.valgoodsAccount,
        orderFrom: this.orderfromAccount,
        pageSize: "10"
      }).then(res => {
        this.accountarrayAccount = res.data;
        this.totalnumAccount = res.total;
        console.log(this.accountarrayAccount);
      });
    },
    // 子账户商品下拉planchange事件
    changeSelectgoodsAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.valgoodsAccount = val;
      subaccountdata({
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndAccount,
        filterDateStart: this.filterDateStartAccount,
        filterDateType: "1",
        goodIds: this.valgoodsAccount,
        orderFrom: this.orderfromAccount,
        pageSize: "10"
      }).then(res => {
        console.log(res);
        this.accountarrayAccount = res.data;
        this.totalnumAccount = res.total;
      });
    },
    //  子账户搜索框
    getUserListAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(this.val);
      subaccountdata({
        search: this.keywordAccount,
        acticityId: this.acid,
        filterDateEnd: this.filterDateEndAccount,
        filterDateStart: this.filterDateStartAccount,
        accountIds: this.val,
        goodIds: this.valgoodsAccount,
        orderFrom: this.orderfromAccount
      }).then(res => {
        console.log(res);
        this.totalnumAccount = res.total;
        this.accountarrayAccount = res.data;
      });
    },
    // 子账户订单来源
    orderchangeAccount(value) {
      console.log(value, "order子组件传过来拉");
      this.orderfromAccount = value;
      console.log(this.orderfromAccount);
      this.searchAccount();
    },
    // 子账户时间筛选
    getweekAccount(value) {
      this.valone1 = null;
      console.log("getweekAccount", value.data);
      let data = value.data;
      this.filterDateStartAccount = data[0];
      this.filterDateEndAccount = data[1];
      this.filterContrastDateStartAccount = data[2];
      this.filterContrastDateEndAccount = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = value.contrastStatus;
      setTimeout(() => {
        this.valone1 = 1;
        this.searchAccount();
      }, 200);
    },
    // 主账户账号下拉planchange事件
    changeSelectparAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.val = val;
      masteraccountdata({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndparAccount,
        filterDateStart: this.filterDateStartparAccount,
        filterDateType: "1",
        goodIds: this.valgoodsparAccount,
        orderFrom: this.orderfromparAccount,
        pageSize: "10",
        search: this.keywordparAccount
      }).then(res => {
        console.log(res.data);
        this.accountarrayParaccount = res.data;
        console.log(this.accountarrayParaccount);
        this.totalnumparAccount = res.total;
      });
    },
    // 主账户商品下拉planchange事件
    changeSelectgoodsparAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.valgoodsparAccount = val;
      masteraccountdata({
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndparAccount,
        filterDateStart: this.filterDateStartparAccount,
        filterDateType: "1",
        goodIds: this.valgoodsparAccount,
        orderFrom: this.orderfromparAccount,
        pageSize: "10",
        search: this.keywordparAccount
      }).then(res => {
        console.log(res);
        this.accountarrayParaccount = res.data;
        this.totalnumparAccount = res.total;
      });
    },
    //  主账户搜索框
    getUserListparAccount(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(this.val);
      masteraccountdata({
        search: this.keywordparAccount,
        acticityId: this.acid,
        filterDateEnd: this.filterDateEndparAccount,
        filterDateStart: this.filterDateStartparAccount,
        accountIds: this.val,
        goodIds: this.valgoodsparAccount
      }).then(res => {
        console.log(res);
        this.totalnumparAccount = res.total;
        this.accountarrayParaccount = res.data;
      });
    },
    // 主账户订单来源
    orderchangeparAccount(value) {
      console.log(value, "order子组件传过来拉");
      this.orderfromparAccount = value;
      console.log(this.orderfromparAccount);
      this.searchparAccount();
    },
    // 主账户时间筛选
    getweekparAccount(value) {
      this.valone1 = null;
      console.log("getweekparAccount", value.data);
      let data = value.data;
      this.filterDateStartparAccount = data[0];
      this.filterDateEndparAccount = data[1];
      this.filterContrastDateStartparAccount = data[2];
      this.filterContrastDateEndparAccount = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = value.contrastStatus;
      setTimeout(() => {
        this.valone1 = 1;
        this.searchparAccount();
      }, 200);
    },
    // 唯一主账户账户筛选
    handleparaccount(val) {
      masteraccountdata({
        acticityId: JSON.stringify(this.acid),
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndparAccount,
        filterDateStart: this.filterDateStartparAccount,
        filterDateType: "1",
        goodIds: this.valgoodsparAccount,
        orderFrom: this.orderfromparAccount,
        pageSize: "10",
        search: this.keywordparAccount,
        platform:sessionStorage.getItem("cur_platform")
      }).then(res => {
        console.log(res.data);
        this.options_account = res.data;
        //  this.options_account.unshift({ accountName: "全部账户" }).splice(1);
      });
    },
    // 公司数据账号下拉planchange事件
    changeSelectCompanydata(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.val = val;
      advertisercompany({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndCompanydata,
        filterDateStart: this.filterDateStartCompanydata,
        filterDateType: "1",
        goodIds: this.valgoodsCompanydata,
        orderFrom: this.orderfromCompanydata,
        pageSize: "10"
      }).then(res => {
        console.log(res.data);
        this.accountarrayCompanydata = res.data;
        this.totalnumCompanydata = res.total;
        console.log(this.accountarrayCompanydata);
      });
    },
    // 公司数据商品下拉planchange事件
    changeSelectgoodsCompanydata(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(val);
      this.valgoodsCompanydata = val;
      advertisercompany({
        acticityId: this.acid,
        accountIds: this.val,
        contrastStatus: 0,
        filterDateEnd: this.filterDateEndCompanydata,
        filterDateStart: this.filterDateStartCompanydata,
        filterDateType: "1",
        goodIds: this.valgoodsCompanydata,
        orderFrom: this.orderfromCompanydata,
        pageSize: "10"
      }).then(res => {
        console.log(res);
        this.accountarrayCompanydata = res.data;
        this.totalnumCompanydata = res.total;
      });
    },
    //  公司数据搜索框
    getUserListCompanydata(val) {
      this.valone1 = null;
      setTimeout(() => {
        this.valone1 = 1;
      }, 200);
      console.log(this.val);
      advertisercompany({
        search: this.keywordCompanydata,
        acticityId: this.acid,
        filterDateEnd: this.filterDateEndCompanydata,
        filterDateStart: this.filterDateStartCompanydata,
        accountIds: this.val,
        goodIds: this.valgoodsCompanydata
      }).then(res => {
        console.log(res);
        this.totalnumCompanydata = res.total;
        this.accountarrayCompanydata = res.data;
      });
    },
    // 公司数据订单来源
    orderchangeCompanydata(value) {
      console.log(value, "order子组件传过来拉");
      this.orderfromCompanydata = value;
      console.log(this.orderfromCompanydata);
      this.searchCompanydata();
    },
    // 公司数据户时间筛选
    getweekCompanydata(value) {
      this.valone1 = null;
      console.log("getweekCompanydata", value.data);
      let data = value.data;
      this.filterDateStartCompanydata = data[0];
      this.filterDateEndCompanydata = data[1];
      this.filterContrastDateStartCompanydata = data[2];
      this.filterContrastDateEndCompanydata = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = value.contrastStatus;
      setTimeout(() => {
        this.valone1 = 1;
        this.searchCompanydata();
      }, 200);
    },
    // 商品筛选
    handlegoods() {
      commodity({
        acticityId: JSON.stringify(this.acid)
      }).then(res => {
        console.log(res.data);
        this.options_goods = res.data;
      });
    },
    // 账户筛选
    handleaccount() {
      return new Promise((resolve, reject) => {
        accountlis({
          acticityId: JSON.stringify(this.acid)
        }).then(res => {
          console.log(res.data,'11111111111111111');
          this.options_account = res.data;
          //  this.options_account.unshift({ accountName: "全部账户" }).splice(1);
          resolve(res)
        }).catch(e => {
          reject(e)
        });
      })

    },
    tabclick(tab, event) {
      this.tab = tab.index;
      console.log(parms[tab.index]);
      this.right_params = parms[tab.index]; //传递给子组件表头参数
      console.log(this.right_params, "right_paramsright_params");
      if (parms[tab.index] === "plan") this.parmvalplan = parms[tab.index];
      else if (parms[tab.index] === "account") {
        this.parmvalaccount = parms[tab.index];
      } else if (parms[tab.index] === "mainAccount") {
        this.parmvalparaccount = parms[tab.index];
      } else if (parms[tab.index] === "company") {
        this.parmvalcompanydata = parms[tab.index];
      }
    },
    showPlan(id) {
      // this.accountId = id;
      this.handleaccount().then(() => {
         this.valaccountplan = [id]
      this.changeSelectaccountplan([id])
      })

      this.activeTabName = "plan"
    },
    // 汇总订单来源
    orderchange(value) {
      // console.log(value, "order子组件传过来拉");
      this.orderfrom = value;
      console.log(this.orderfrom);
      this.search();
    },

    // 不归因
    changeCycle(val) {
      this.cycle = val;
      this.search();
    },
    // 汇总时间筛选
    getweek(value) {
      // console.log("getweek", value.data);
      let data = value.data;
      this.filterDateStart = data[0];
      this.filterDateEnd = data[1];
      this.filterContrastDateStart = data[2];
      this.filterContrastDateEnd = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = value.contrastStatus;
      this.search();
    },
    // 汇总对比为false
    getweekevent(value) {
      // console.log("getweekevent", value.data);
      let data = value.data;
      this.filterDateStart = data[0];
      this.filterDateEnd = data[1];
      this.filterContrastDateStart = data[2];
      this.filterContrastDateEnd = data[3];
      this.filterDateType = value.filterDateType;
      this.contrastStatus = 0;
      this.search();
    },
    //  <!-- 帐户/广告组/计划 选择 -->
    handlechange2(obj) {
      // console.log("handlechange2");
      this.targetType = obj.targetType;
      this.targetIds = obj.targetIds;
      this.search();
    },
    //  <!-- 商品搜索 -->
    handlechange3(obj) {
      this.goodIds = obj.targetIds;
      console.log(this.goodIds);

      this.search();
    },
    handlechange1(ids) {
      //
      this.acid = ids;
      this.search();
    },
    showROI() {
      this.input1 = this.roiMax;
      this.input2 = this.roiMin;
      this.$nextTick(() => {
        this.$refs.roi_input1.focus();
      });
    },
    showCOST() {
      this.input4 = this.costMax;
      this.input5 = this.costMin;
      this.$nextTick(() => {
        this.$refs.cast_input4.focus();
      });
    },
    //筛选大小于 花费清空
    handleclear2() {
      // console.log("花费清空");
      (this.costMin = ""), (this.costMax = "");
      this.search();
    },
    //筛选大小于 ROI清空
    handleclear1() {
      // console.log("ROI清空");
      (this.roiMin = ""), (this.roiMax = "");
      this.input1 = this.roiMin;
      this.input2 = this.roiMax;
      this.showROI = false;
      this.search();
    },
    //  筛选大小于 ROI
    handleapplication() {
      this.roiMax = this.input1;
      this.roiMin = this.input2;
      this.search();
      this.visibleROI = false;
    },
    //  筛选大小于 花费
    handlemoney() {
      this.costMax = this.input4;
      this.costMin = this.input5;
      this.search();
      this.visibleCOST = false;
    },
    async search() {
      this.loading = true;
      this.trendDisabled = true;
      let paramreport = {
        // Authorization: sessionStorage.getItem("key"),
        platform:sessionStorage.getItem("cur_platform"),
        goodIds: this.goodIds,
        acticityId: this.acid, //活动id
        tabType: "", //页签类别
        page: this.valone1, //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateType: this.filterDateType, //时间条件类型，1-分时，2-分日，默认2
        filterDateStart: this.filterDateStart, //开始日期
        filterDateEnd: this.filterDateEnd, //结束日期
        contrastStatus: this.contrastStatus,
        filterContrastDateStart:
          this.contrastStatus != 1 ? "" : this.filterContrastDateStart,
        filterContrastDateEnd: this.filterContrastDateEnd,
        cycle: this.cycle, //转化效果评估，0-不归因 1-1日 3-3日 7-7日 15-15日 30-30日，默认0
        orderFrom: this.orderfrom, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        version: "1",
        accountIds: []
      };
      // console.log("====report search====", paramreport.acticityId);
      // 监控实时数据概览
      
      let res = await bbdataoverview(paramreport);
      this.trendDisabled = false;
      console.log("===== report result ====", res);
      if (res.code == 1) {
        this.overViewData = res.data;
      }
      // 趋势
      delete paramreport.version;
      this.$refs.effectObjreport.search(paramreport);
      this.loading = false;
      // effect图表
      // this.$refs.effectObj.search(paramreport);
    },
    async searchplan() {
      this.loading = true;
      let paramreportplan = {
        // Authorization: sessionStorage.getItem("key"),
        goodIds: this.valgoodsplan,
        acticityId: this.acid, //活动id
        tabType: "plan", //页签类别
        page: this.valone1, //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateType: this.filterDateType, //时间条件类型，1-分时，2-分日，默认2
        filterDateStart: this.filterDateStartPlan, //开始日期
        filterDateEnd: this.filterDateEndPlan, //结束日期
        contrastStatus: this.contrastStatus,
        orderFrom: this.orderfromplan, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        accountIds: this.val,
        search: this.keywordPlan
      };
      let resplan = await planningdata(paramreportplan);
      console.log("===== report result ====", resplan);
      this.accountarrayPlan = resplan.data;
      this.totalnumPlan = resplan.total;
    },
    async searchAccount() {
      this.loading = true;
      let paramreportAccount = {
        // Authorization: sessionStorage.getItem("key"),
        goodIds: this.valgoodsAccount,
        acticityId: this.acid, //活动id
        tabType: "account", //页签类别
        page: this.valone1, //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateType: this.filterDateType, //时间条件类型，1-分时，2-分日，默认2
        filterDateStart: this.filterDateStartAccount, //开始日期
        filterDateEnd: this.filterDateEndAccount, //结束日期
        contrastStatus: this.contrastStatus,
        orderFrom: this.orderfromAccount, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        accountIds: this.val,
        search: this.keywordAccount
      };
      let resAccount = await subaccountdata(paramreportAccount);
      console.log("===== report result ====", resAccount);
      this.accountarrayAccount = resAccount.data;
      this.totalnumAccount = resAccount.total;
    },
    async searchparAccount() {
      this.loading = true;
      let paramreportparAccount = {
        // Authorization: sessionStorage.getItem("key"),
        goodIds: this.valgoodsparAccount,
        acticityId: this.acid, //活动id
        tabType: "mainAccount", //页签类别
        page: this.valone1, //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateType: this.filterDateType, //时间条件类型，1-分时，2-分日，默认2
        filterDateStart: this.filterDateStartparAccount, //开始日期
        filterDateEnd: this.filterDateEndparAccount, //结束日期
        contrastStatus: this.contrastStatus,
        orderFrom: this.orderfromparAccount, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        accountIds: this.val,
        search: this.keywordparAccount
      };
      let resparAccount = await masteraccountdata(paramreportparAccount);
      console.log("===== report result ====", resparAccount);
      this.accountarrayparAccount = resparAccount.data;
      this.totalnumparAccount = resparAccount.total;
    },
    async searchCompanydata() {
      this.loading = true;
      let paramreportCompanydata = {
        // Authorization: sessionStorage.getItem("key"),
        goodIds: this.valgoodsCompanydata,
        acticityId: this.acid, //活动id
        tabType: "company", //页签类别
        page: this.valone1, //页码
        pageSize: this.tsnum, //条数
        targetIds: this.targetIds,
        targetType: this.targetType,
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin,
        filterDateType: this.filterDateType, //时间条件类型，1-分时，2-分日，默认2
        filterDateStart: this.filterDateStartCompanydata, //开始日期
        filterDateEnd: this.filterDateEndCompanydata, //结束日期
        contrastStatus: this.contrastStatus,
        orderFrom: this.orderfromCompanydata, //订单来源，tb（淘宝）、jd（京东）、pdd(拼多多)，默认淘宝联盟
        accountIds: this.val,
        search: this.keywordCompanydata
      };
      let resCompanydata = await advertisercompany(paramreportCompanydata);
      console.log("===== report result ====", resCompanydata);
      this.accountarrayCompanydata = resCompanydata.data;
      this.totalnumCompanydata = resCompanydata.total;
    },
    Activitylist() {
      this.loading = true;
      activitylist({}).then(res => {
        // console.log("report activity", res);
        // this.activitys = res.data[0].platformName;
        this.activitys = res.data;
        this.loading = false;
        if(res.data.length>0){
          sessionStorage.setItem("cur_platform",res.data[0].platformType)
        }else{
          sessionStorage.setItem("cur_platform",'')
        }
      });
    }
  }
};
</script>
<style scoped>
.goodsname {
  width: 613px;
  height: 85px;
  white-space: normal;
  align-items: center;
  display: flex;
}
.elinput /deep/ .el-input__inner {
  border-right: none !important;
  border-radius: 5px 0px 0px 5px;
}
.elinput_right /deep/ .el-input__inner {
  border-radius: 0px 5px 5px 0px;
}

@media screen and (max-width: 1700px) {
  .elinput /deep/ .el-input__inner {
    border-right: 1px solid #dcdfe6 !important;
  }
}
/deep/.el-select .el-tag {
  max-width: 55% !important;
}
</style>
<style>
.el-tabs__nav {
  line-height: 70px;
}
.el-input__inner {
  /* border-radius: 0; */
  /* border-right: none; */
  /* border-right: 1px solid #DCDFE6; */
}
.el-popover__reference-wrapper {
}
</style>
<style lang="scss" scoped>
.tableScopeSwitch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff;
}

/*打开时文字位置设置*/
.tableScopeSwitch .el-switch__label--right {
  /* // z-index: 1; */
  right: -70px; /*不同场景下可能不同，自行调整*/
  color: #409eff;
}
/*关闭时文字位置设置*/
.tableScopeSwitch .el-switch__label--left {
  right: -80px; /*不同场景下可能不同，自行调整*/
  color: #dcdfe6;
}
/*显示文字*/
.tableScopeSwitch .el-switch__label.is-active {
  display: block;
}
.tableScopeSwitch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 75px !important; /*开关按钮的宽度大小*/
}
/* // 主体内容图标 */
.nav-icon {
  display: flex;
  align-items: center;
}
.el-icon-s-fold {
  font-size: 3vh;
}
.nav-icon span {
  font-size: 2vh;
  padding-left: 1vh;
}
.Marketing_activity_monitoring_1 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  // padding: 10px;
}
.el-tag.el-tag--info {
  width: 70px;
}
.Marketing_activity_monitoring_2 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-top: 8px solid #f5f6fa;
  // border-bottom: 8px solid #f5f6fa;
}
.box::after {
  content: "";
  width: 25%;
}

.Marketing_activity_monitoring_3 {
  width: 100%;
  height: 470px;
  background: #ffffff;
  margin: 10px 0 0 0;
}
.Marketing_activity_monitoring_4 {
  width: 100%;
  height: 350px;
  background: #ffffff;
  margin: 20px 0 0 0;
}
.Marketing_activity_monitoring_5 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  margin: 0;
}
.Marketing_activity_monitoring_2_text {
  line-height: 25px;
  margin: 0 0 0 10px;
  display: flex;
}
.Marketing_activity_monitoring_2_box {
  width: 24%;
  height: 190px;
  background: #f5f6fa;
  margin: 10px 0 0 12px;
}
.el-icon-warning {
  margin: 0 0 0 5px;
}
.dayu {
  width: 40px;
  text-align: center;
  line-height: 45px;
  margin: 0 2px 0 -4px;
}
.change {
  padding: 20px;
  font-size: 15px;
  line-height: 0px;
  width: 70px;
  text-align: center;
}

.el-icon-close:before {
  content: "\E78D";
}

.head_search1 {
  margin: 0px 0px -50px 0;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
v-deep.el-select__tags-text {
}

::v-deep.el-input__inner {
  height: 40px !important;
}
@media screen and (max-width: 1200px) {
  .Marketing_activity_monitoring_2_box {
    width: 48%;
    margin-left: 5px;
    height: 180px;
  }
  .pandding > div {
    // margin-bottom: 20px;
    margin-right: 0px;
  }

  .pandding {
    padding: 10px !important;
  }
  .ROIbox {
    // margin:0 0px 0 20px !important
  }
}
</style>
