 <!-- 活动监控账户详细数据中详细分析第三个计划弹窗抽屉 --> 

<template>
  <el-drawer
    :append-to-body="true"
    :visible="childVisible_"
    size="50%"
    @close="handleClose"
    :show-close="false"
  >
    <div class="pandding drawer_head">计划详情</div>
    <div class="pandding flex drawer_id">
      账户:
      <div>{{handid.planName}}</div>（账户ID：
      <div>{{handid.planId}}</div>）
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick" class="pandding">
      <div class="indicators">
        <div class="pandding" style="color:#a1a1a1">核心指标</div>
        <div class="flex_around line-height40">
          <div class="indicators_box">
            <div class="indicators_box_money">花费（元）</div>
            <div class="indicators_box_num">{{handid.cost}}</div>
          </div>
          <div class="indicators_box">
            <div class="indicators_box_money">点击量</div>
            <div class="indicators_box_num">{{handid.click}}</div>
          </div>
          <div class="indicators_box">
            <div class="indicators_box_money">转化数</div>
            <div class="indicators_box_num">{{handid.convertNum}}</div>
          </div>
          <div class="indicators_box">
            <div class="indicators_box_money">曝光量</div>
            <div class="indicators_box_num">{{handid.showNum}}</div>
          </div>
          <div class="indicators_box">
            <div class="indicators_box_money">转化成交金额</div>
            <div class="indicators_box_num">{{handid.payAmount}}</div>
          </div>
          <div class="indicators_box">
            <div class="indicators_box_money">转化ROI</div>
            <div class="indicators_box_num">{{handid.roi}}</div>
          </div>
        </div>
      </div>
      <el-tab-pane label="计划设置" name="first">
        <div class="price">
          <div class="pandding" style="color:#a1a1a1">预算与出价</div>
          <div class="pandding flex">
            预算类型
            <div style="margin:0 0 0 30px">日预算</div>
          </div>
          <div class="pandding flex">
            预算
            <div style="margin:0 0 0 60px">¥300,000.00</div>
          </div>
          <div class="pandding flex">
            投放时间
            <div style="margin:0 0 0 30px">长期投放</div>
          </div>
          <div class="pandding flex">
            投放时段
            <div style="margin:0 0 0 30px">星期一：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: -10px 0px 0px 110px;">星期二：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: 5px 0px 0px 110px;">星期三：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: 5px 0px 0px 110px;">星期四：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: 5px 0px 0px 110px;">星期五：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: 5px 0px 0px 110px;">星期六：00:00-24:00</div>
          </div>
          <div class="flex">
            <div style="margin: 5px 0px 0px 110px;">星期天：00:00-24:00</div>
          </div>
          <div class="pandding flex">
            投放场景
            <div style="margin:0 0 0 30px">常规投放</div>
          </div>
          <div class="pandding flex">
            竞价策略
            <div style="margin:0 0 0 30px">稳定成本-加速</div>
          </div>
          <div class="pandding flex">
            投放速度
            <div style="margin:0 0 0 30px">加速</div>
          </div>
          <div class="pandding flex">
            付费方式
            <div style="margin:0 0 0 30px">OCPM</div>
          </div>
          <div class="pandding flex">
            出价
            <div style="margin:0 0 0 30px">¥75.00</div>
          </div>
        </div>
        <div class="user">
          <div class="pandding" style="color:#a1a1a1">用户定向</div>
          <div class="pandding flex">
            性别
            <div style="margin:0 0 0 30px">不限</div>
          </div>
          <div class="pandding flex">
            地域
            <div
              style="margin:0 0 0 30px"
            >北京市,天津市,河北省,山西省,内蒙古自治区,辽宁省,吉林省,黑龙江省,上海市,江苏省,浙江省,安徽省,福建省,江西省,山东省,河南省,湖北省,湖南省,广东省,广西壮族自治区,海南省,重庆市,四川省,贵州省,云南省,西藏自治区,陕西省,甘肃省,青海省,宁夏回族自治区,新疆维吾尔自治区</div>
          </div>
          <div class="pandding flex">
            年龄
            <div style="margin:0 0 0 30px">大于等于50岁,24-30岁,31-40岁,41-49岁</div>
          </div>
          <div class="pandding flex">
            过滤已转化用户
            <div style="margin:0 0 0 30px">广告计划</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="推广分析" name="second">
        <div class="indicators1">
          <div class="bigbox">
            <div class="title">
              <div @click="tab(1)" :class="this.tabshow==1?'active':''">趋势图</div>
              <div @click="tab(2)" :class="this.tabshow==2?'active':''">表格</div>
            </div>
            <!-- <div class="containerbox">
              <div v-show="tabshow==1">
                <div class="pandding flex_between">
                  <div
                    class="flex"
                    style="color: rgb(161, 161, 161);font-size: 15px;line-height: 33px;width: 20%;"
                  >时间趋势</div>
                  <div class="flex">
                    <el-select v-model="value1" placeholder="请选择">
                      <el-option
                        v-for="item in options_trend1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                    <div class="change">对比</div>
                    <el-select v-model="value2" placeholder="请选择">
                      <el-option
                        v-for="item in options_trend2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div v-show="tabshow==2">
                <div class="pandding flex">
                  <div
                    class="flex"
                    style="color:#a1a1a1;
                      font-size: 15px;
                      line-height: 40px;"
                  >时间报表</div>
                </div>
                <div class="flex">
                  <el-table :data="tableDataaa" style="width: 100%">
                    <el-table-column prop="date" label="时间" width="180"></el-table-column>
                    <el-table-column prop="cost" label="花费" sortable width="180"></el-table-column>
                    <el-table-column prop="showNum" label="展现量" sortable width="180"></el-table-column>
                    <el-table-column prop="avgShowCost" label="千次展现费用" sortable width="180"></el-table-column>
                    <el-table-column prop="click" label="点击量" sortable width="180"></el-table-column>
                    <el-table-column prop="avgClickCost" label="平均点击单价" sortable width="180"></el-table-column>
                    <el-table-column prop="ctr" label="点击率" sortable width="180"></el-table-column>
                    <el-table-column prop="convertNum" label="转化数" sortable width="180"></el-table-column>
                    <el-table-column prop="convertCost" label="转化成本" sortable width="180"></el-table-column>
                    <el-table-column prop="convertRate" label="转化率" sortable width="180"></el-table-column>
                    <el-table-column prop="payAmount" label="成交金额" sortable width="180"></el-table-column>
                    <el-table-column prop="payCount" label="成交订单数" sortable width="180"></el-table-column>
                    <el-table-column prop="avgAmountVar" label="成交订单均价" sortable width="180"></el-table-column>
                    <el-table-column prop="payRoi" label="成交ROI" sortable width="180"></el-table-column>
                    <el-table-column prop="convertAmount" label="当日转化金额" sortable width="180"></el-table-column>
                    <el-table-column prop="convertCount" label="当日转化订单数" sortable width="180"></el-table-column>
                    <el-table-column prop="avgConvertAmount" label="当日转化订单均价" sortable width="180"></el-table-column>
                    <el-table-column prop="convertRoi" label="当日转化ROI" sortable width="180"></el-table-column>
                    <el-table-column prop="payConvertRate" label="支付转化率" sortable width="180"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>-->
            <div class="containerbox">
              <div v-show="tabshow==1">
                <goods :drawdata="drawdata.accountId" :aa="drawdata.accountId"></goods>
              </div>
              <div v-show="tabshow==2">
                <div class="pandding flex_between">
                  <div
                    class="flex"
                    style="color:#a1a1a1;
                      font-size: 15px;
                      line-height: 40px;"
                  >时间报表</div>
                  <div class="flex">
                    <columnclick></columnclick>
                  </div>
                </div>
                <trtd :aa="drawdata"></trtd>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<script>
import {} from "@/request/api.js"; //营销活动监控首页 - (账户、广告组、计划 -[核心指标])

import goods from "../echarts/goods";
import trtd from "../echarts/goods_trtd";

export default {
  components: {},
  //传值
  props: {
    //父组件传  childVisible值；
    handid: {
      type: Object,
      default: ""
    },

    childVisible: {
      type: Boolean,
      default: false //这里默认为false
    }
  },
  data() {
    return {
      activeName: "first",
      goods: "",
      value1: "",
      value2: "",
      drawer: false,
      tabshow: 1,
      options_trend1: [
        {
          value: "选项1",
          label: "花费"
        },
        {
          value: "选项2",
          label: "展现量"
        },
        {
          value: "选项3",
          label: "点击量"
        },
        {
          value: "选项4",
          label: "点击率"
        },
        {
          value: "选项5",
          label: "转化数"
        },
        {
          value: "选项6",
          label: "转化率"
        },
        {
          value: "选项7",
          label: "转化成本"
        },
        {
          value: "选项8",
          label: "转化金额"
        },
        {
          value: "选项9",
          label: "成交ROI"
        },
        {
          value: "选项10",
          label: "成交金额"
        }
      ],
      options_trend2: [
        {
          value: "选项1",
          label: "花费"
        },
        {
          value: "选项2",
          label: "展现量"
        },
        {
          value: "选项3",
          label: "点击量"
        },
        {
          value: "选项4",
          label: "点击率"
        },
        {
          value: "选项5",
          label: "转化数"
        },
        {
          value: "选项6",
          label: "转化率"
        },
        {
          value: "选项7",
          label: "转化成本"
        },
        {
          value: "选项8",
          label: "转化金额"
        },
        {
          value: "选项9",
          label: "成交ROI"
        },
        {
          value: "选项10",
          label: "成交金额"
        }
      ],
      tableDataaa: []
    };
  },
  //计算属性
  computed: {
    childVisible_: {
      get() {
        // console.log(this.childVisible, 'childVisible')
        return this.childVisible;
      },
      //值变化的时候会被调用
      set(v) {
        // console.log(v, 'v')
        this.$emit("changeDrawer", v);
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    tab(val) {
      this.tabshow = val;
    },
    //关闭当前抽屉；触发set方法（childVisible_值改变就会调用set）
    handleClose() {
      this.childVisible_ = false;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>
<style>
.el-drawer.rtl {
  background: #f5f6fa;
}
.drawer_head {
  border-bottom: 1px solid darkgray;
  color: #a1a1a1;
}

.indicators {
  width: 100%;
  height: 150px;
  background: #fff;
  margin: 0 auto;
}
.indicators1 {
  width: 100%;
  /* height: 00px; */
  background: #fff;
  margin: 20px auto;
}
.price {
  width: 100%;
  /* height: 500px; */
  background: #fff;
  margin: 20px auto;
}
.user {
  width: 100%;
  /* height: 250px; */
  background: #fff;
  margin: 20px auto;
}
.indicators_box {
  border-left: 1px solid #e5e5e5;
  height: 75px;
  padding: 0 0 0 20px;
}

.indicators_box_money {
  color: #a1a1a1;
  font-size: 14px;
}
.indicators_box_num {
  color: #a1a1a1;
  font-size: 18px;
}
.drawer_id {
  font-size: 14px;
  color: #a1a1a1;
}
.bigbox .title {
  display: flex;
  float: right;

}
.bigbox .title div {
  border: 1px solid #c5c8dc;
  cursor: pointer;
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-radius: 9px;
}
.containerbox {
}
.active {
  background-color: #1966ff;
  color: #ffff;
}
 
</style>