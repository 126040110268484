<template>
    <div>
        <div class="pandding pagehead margin_top">
            <div class="pandding flex_between">
                <el-button
                    type="primary"
                    @click="openOnepage"
                    style="margin-left: 16px"
                    >增加落地页</el-button
                >
                <el-button
                    type="primary"
                    @click="back"
                    style="float:right"
                    >返回</el-button
                >
                <el-drawer
                    title="增加落地页"
                    :visible.sync="drawer"
                    :direction="direction"
                    :show-close="true"
                    :modal="true"
                    :wrapperClosable="false"
                >
                    <div class="pandding" style="padding-top: 50px">
                        <el-form
                            ref="form"
                            :model="saveData"
                            label-width="80px"
                            :rules="rules"
                        >
                            <el-form-item label="方式" prop="name">
                                
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit"
                                    >导出</el-button
                                >
                                <el-button @click="handlebackgoods"
                                    >取消</el-button
                                >
                            </el-form-item>
                        </el-form>
                    </div>
                </el-drawer>
            </div>
        </div>
        <div class="flex pandding pagebody margin_top" v-loading="loading">
            <div
                class="pagebody_box"
                @mouseenter="enter(index)"
                v-for="(item, index) in todolist"
                :key="index"
            >
                <div class="pagebody_box_text">{{ item.title }}</div>
                <img class="pagebody_box_img" :src="item.images" alt />
                <div>
                    <div class="flex" style="margin: 0px 0px 15px 0px">
                        <div class="edit" @click="editOnepage(item)">编辑</div>
                        <div class="preview" @click="preview(item)">预览</div>
                    </div>
                    <div class="flex">
                        <div class="link" @click="copyLinkButton(item)">导出物料</div>
                        <div class="delete" @click="deleteOnepage(item)">
                            删除
                        </div>
                    </div>
                </div>
            </div>
            <div class="item h1"></div>
            <div class="item h1"></div>
            <div class="item h1"></div>
            <div class="item h1"></div>
            <div class="item h1"></div>
        </div>
        <!-- 版本选择弹框-->
        <el-dialog
            title="选择版本"
            :visible.sync="dialogVisible"
            width="15%"
            center
            :before-close="handleClose">
                <span>
                    <template>
  <el-radio v-model="radio" label="1">老版本</el-radio>
  <el-radio v-model="radio" label="2">新版本</el-radio>
</template>
                </span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false;copyLink()">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    addTbMerchandise,
    deployPage,
    loadingpage_list,
    loadingpage_delete,
} from "@/request/api";
import $utils from "@/js/utils";
export default {
    data() {
        return {
            loading: false,
            seen: false,
            drawer: false,
            direction: "rtl",
            imageUrl: "",
            todolist: [],
            goods_id: 0,
            saveData: {
                name: "",
                background_url: "",
                btn_click: "",
                btn_distance: "",
            },
            //表单验证
            rules: {
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                background_url: [
                    {
                        required: true,
                        message: "请上传背景图片",
                        trigger: "blur",
                    },
                ],
                btn_click: [
                    {
                        required: true,
                        message: "请上传按钮图片",
                        trigger: "blur",
                    },
                ],
                btn_distance: [
                    {
                        required: true,
                        message: "请输入按钮距离",
                        trigger: "blur",
                    },
                ],
            },
            headers: {
                "Content-Type": "multipart/form-data",
                authorization: sessionStorage.getItem("Authorization"),
            },
            dialogVisible: false,
            radio: "2",
            copyRow: {}
        };
    },
    watch: {
        $route(to, from) {
            //监听路由是否变化
            if (to.query.key != from.query.key) {
                this.key = to.query.key;
                this.getLandingPageList(); //重新加载数据
            }
        },
    },
    mounted() {
        if (this.$route.query.id != undefined) {
            this.goods_id = this.$route.query.id;
            this.getLandingPageList();
        }
    },
    created() {},
    methods: {
        back() {
            this.$router.back()
        },
        deleteOnepage(item) {
            this.$confirm("此操作将永久删除该页面, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.loading = true;
                loadingpage_delete({ page_id: item.id }).then((res) => {
                    this.loading = false;
                    if (res.code == 1) {
                        this.todolist = this.todolist.filter(
                            (ele) => ele.id != item.id
                        );
                    }
                });
            });
        },
        preview(item) {
            window.open(item.url, "_blank");
        },
        //导出物料按钮
        copyLinkButton(item){
            //this.dialogVisible = true
            this.copyRow = item;
            this.copyLink()
        },
        copyLink() {
            var item = this.copyRow
            console.log(item)
            if(this.radio == 2 && item.url_v2 == ""){
                this.$message.error("暂无新版落地页链接，请重新保存生成新版落地页链接")
                return;
            }
            let info = sessionStorage.getItem("good_" + this.goods_id)
            if (!info) {
                this.$router.back();
                return;
            }
            info = JSON.parse(info)
            // console.log("good info", info);
            var title = [];
            var url = [];
            title.push("商品名")
            url.push(info.item_name)
            title.push("代理商")
            url.push(info.agent_name)
            title.push("商品类型")
            url.push(info.channel)
            if(item.url != ""){
                let url_title;
                switch (item.channel){
                    case 1:
                        url_title = "淘联网址";
                        break;
                    case 2:
                        url_title = "京东链接"
                        break
                    case 3:
                        url_title = "拼多多链接"
                        break
                    default:
                        url_title = ""
                        break
                }
                title.push(url_title);
                if(this.radio == 1) {
                    url.push(item.url);
                }else if(this.radio == 2){
                    url.push(item.url_v2);
                }else{
                    this.$message.error("版本选择错误")
                    return;
                }
            }
            if(item.url_mini != ""){
                title.push("小程序网址");
                if(this.radio == 1) {
                    url.push(item.url_mini);
                }else if(this.radio == 2){
                    url.push(item.url_mini_v2);
                }
            }
            if(item.url_coupon){
                title.push("优惠券网址");
                if(this.radio == 1) {
                    url.push(item.url_coupon);
                }else if(this.radio == 2){
                    url.push(item.url_coupon_v2);
                }
            }
            if(item.url_zhongcao){
                title.push("种草页");
                if(this.radio == 1) {
                    url.push(item.url_zhongcao);
                }else if(this.radio == 2){
                    url.push(item.url_zhongcao_v2);
                }
            }
            if(item.url_one_jump){
                title.push("一跳链接")
                url.push(item.url_one_jump)
            }
            if(item.url_monitor){
                title.push("腾讯广告监测链接")
                url.push(item.url_monitor)
            }
            if(item.xingliang_url != ""){
                title.push("星量链接")
                url.push(item.xingliang_url)
            }
            $utils.exportRaw($utils.format(new Date(),"yyyyMMdd")+"-"+item.item_id+".csv",title.join(',')+"\r\n"+url.join(','))
            //this.drawer = true
            // $utils.copyClipboard(item.url);
            // this.$message({
            //     message: "复制成功",
            //     type: "success",
            // });
        },
        openOnepage() {
            this.$router.push("/taobaoGoods/onepage?id=" + this.goods_id);
        },
        editOnepage(item) {
            this.$router.push(
                "/taobaoGoods/onepage?id=" +
                    this.goods_id +
                    "&page_id=" +
                    item.id
            );
        },
        handleAvatarSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isJPG && isLt2M;
        },
        handlebackgoods() {
            this.drawer =false;
            // this.$router.replace("/taobaoGoods/taobaoGoods");
        },
        enter(index) {
            console.log(index);
            this.todolist.forEach((item) => {
                item.seen = false;
            });
            this.todolist[index].seen = true;
            // this.seen = true;
        },
        // leave(index) {
        //    this.todolist.forEach(item=>{
        //       item.seen=true
        //     })

        //     this.todolist[index].seen=false
        //   // this.seen = false;
        // },
        handleClose(done) {
            this.$confirm("确认关闭？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        getLandingPageList() {
            this.loading = true;
            loadingpage_list({ goods_id: this.goods_id }).then((res) => {
                console.log("loadingpage_list", res);
                this.loading = false;
                if (res.code == 1) {
                    // res.data.list.push(...res.data.list)
                    // res.data.list.push(...res.data.list)
                    res.data.list.forEach((ele) => {
                        let json = JSON.parse(ele.data);
                        let imgs = json.find((e) => e.type == "images");
                        ele.images = imgs.data[0].url;
                        let info = json.find((e) => e.type == "info");
                        ele.title = info.title;
                    });
                    this.todolist = res.data.list;
                }
            });
            // deployPage({tb_commodity_id:this.tb_commodity_id}).then(res => {
            //     if(res.code == 1){
            //         this.todolist = res.data
            //     }
            // })
        },
        //保存数据
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    addTbMerchandise(this.saveData).then((res) => {
                        if (res.code == 1) {
                            this.$message.success("保存成功");
                            setTimeout(() => {
                                this.drawer = false;
                                this.$refs["form"].resetFields();
                                this.getAgentList();
                            }, 1000);
                        } else {
                            this.$message.error("保存失败");
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        uploadFile(e) {
            console.log(e);
        },
    },
};
</script>

<style scoped>
.edit {
    width: 180px;
    height: 25px;
    background: rgb(22, 155, 213);
    border-radius: 6px;
    font-size: 11px;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
}

.preview {
    width: 180px;
    height: 25px;
    background: rgb(22, 155, 213);
    border-radius: 6px;
    font-size: 11px;
    line-height: 25px;
    color: #fff;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.link {
    width: 180px;
    height: 25px;
    background: rgb(22, 155, 213);
    border-radius: 6px;
    font-size: 11px;
    line-height: 25px;
    color: #fff;
    cursor: pointer;
}

.delete {
    width: 180px;
    height: 25px;
    background: #ff0000;
    border-radius: 6px;
    font-size: 11px;
    line-height: 25px;
    color: #fff;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 178px;
    text-align: center;
    border: 1px solid;
    margin: 18px 0 18px 0;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.pagehead {
    /* width: 100%; */
    background: #fff;
}

.pagebody {
    /* width: 100%; */
    background: #fff;
    min-height: 600px;
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: baseline;
}

.pagebody_box {
    width: 200px;
    padding: 20px;
    /* min-height: 580px; */
    /* background: #d7d7d7; */
}

.pagebody_box_img {
    width: 100%;
    height: 250px;
}

.pagebody_box_text {
    line-height: 40px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.flex_left {
    display: flex;
    justify-content: left;
}

.item.h1 {
      height: 1px;
      width: 200px;
      visibility: hidden
 }
</style>