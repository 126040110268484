// /**axios封装
//  * 请求拦截、相应拦截、错误统一处理
//  */
// import axios from 'axios';
// import QS from 'qs';
// import store from '../store/index'

// // 环境的切换
// if (process.env.NODE_ENV == 'development') {    
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'debug') {    
//     axios.defaults.baseURL = '/api';
// } else if (process.env.NODE_ENV == 'production') {    
//     axios.defaults.baseURL = '/api';
// }

// // 请求超时时间
// axios.defaults.timeout = 10000;

// // post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// // 请求拦截器
// axios.interceptors.request.use(    
//     config => {
//         // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
//         // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
//         const token = store.state.token;        
//         token && (config.headers.Authorization = token);        
//         return config;    
//     },    
//     error => {        
//         return Promise.error(error);    
//     })

// // 响应拦截器
// axios.interceptors.response.use(    
//     response => {        
//         if (response.status === 200) {            
//             return Promise.resolve(response);        
//         } else {            
//             return Promise.reject(response);        
//         }    
//     },
//     // 服务器状态码不是200的情况    
//     error => {        
//         if (error.response.status) {            
//             switch (error.response.status) {                
//                 // 401: 未登录                
//                 // 未登录则跳转登录页面，并携带当前页面的路径                
//                 // 在登录成功后返回当前页面，这一步需要在登录页操作。                
//                 case 401:                    
//                     router.replace({                        
//                         path: '/login',                        
//                         query: { redirect: router.currentRoute.fullPath } 
//                     });
//                     break;
//                 // 403 token过期                
//                 // 登录过期对用户进行提示                
//                 // 清除本地token和清空vuex中token对象                
//                 // 跳转登录页面                
//                 case 403:                     
//                     Toast({                        
//                         message: '登录过期，请重新登录',                        
//                         duration: 1000,                        
//                         forbidClick: true                    
//                     });                    
//                     // 清除token                    
//                     sessionStorage.removeItem('token');                    
//                     store.commit('loginSuccess', null);                    
//                     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
//                     setTimeout(() => {                        
//                         router.replace({                            
//                             path: '/login',                            
//                             query: { 
//                                 redirect: router.currentRoute.fullPath 
//                             }                        
//                         });                    
//                     }, 1000);                    
//                     break; 
//                 // 404请求不存在                
//                 case 404:                    
//                     Toast({                        
//                         message: '网络请求不存在',                        
//                         duration: 1500,                        
//                         forbidClick: true                    
//                     });                    
//                 break;                
//                 // 其他错误，直接抛出错误提示                
//                 default:                    
//                     Toast({                        
//                         message: error.response.data.message,                        
//                         duration: 1500,                        
//                         forbidClick: true                    
//                     });            
//             }            
//             return Promise.reject(error.response);        
//         }       
//     }
// );
// /** 
//  * get方法，对应get请求 
//  * @param {String} url [请求的url地址] 
//  * @param {Object} params [请求时携带的参数] 
//  */
// export function get(url, params){    
//     return new Promise((resolve, reject) =>{        
//         axios.get(url, {            
//             params: params        
//         })        
//         .then(res => {            
//             resolve(res.data);        
//         })        
//         .catch(err => {            
//             reject(err.data)        
//         })    
//     });
// }
// /** 
//  * post方法，对应post请求 
//  * @param {String} url [请求的url地址] 
//  * @param {Object} params [请求时携带的参数] 
//  */
// export function post(url, params) {    
//     return new Promise((resolve, reject) => {         
//         axios.post(url, QS.stringify(params))        
//         .then(res => {            
//             resolve(res.data);        
//         })        
//         .catch(err => {            
//             reject(err.data)        
//         })    
//     });
// }
import axios from 'axios' // 引入axios
import store from '../store/index' // 引入Vuex
import router from '../router' // 引入vue-router
import { Message } from 'element-ui' //局部引入UI框架组件
import { autoDingDingLogin } from '../js/auth'
// 环境的切换
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/api' // 开发环境
} else if (process.env.NODE_ENV === 'debug') {
    axios.defaults.baseURL = '/api' // 调试环境
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = '' // 生产环境
}
axios.defaults.timeout = 600000; //接口请求超时时间设置
// 请求拦截器
axios.interceptors.request.use(
    config => {
        if (sessionStorage.getItem('Authorization') ) {
            config.headers.Authorization = sessionStorage.getItem('Authorization'); //查看是否存在token
            //测试token过期验证 config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1hbmFnZXIiLCJhdWQiOiJkZTA4YTE2NzY1ZjM1YTQyYzNkMyIsImlhdCI6MTY1OTY2NTQxNiwibmJmIjoxNjU5NjY1NDE2LCJleHAiOjE2NTk4MzgyMTZ9.VtGTKs2RhdalaGvU-d6pS896IB3zUIZ-Uinix9nG9Bo'
            // config.headers.Authorization = `Bearer` + " " + sessionStorage.getItem('Authorization'); //查看是否存在token
            // console.log('config.headers.Authorization', config.headers.Authorization)
            return config;

        } else if(localStorage.getItem('Authorization')) {
            config.headers.Authorization = localStorage.getItem('Authorization'); //查看是否存在token
            //测试token过期验证 config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJhZG1hbmFnZXIiLCJhdWQiOiJkZTA4YTE2NzY1ZjM1YTQyYzNkMyIsImlhdCI6MTY1OTY2NTQxNiwibmJmIjoxNjU5NjY1NDE2LCJleHAiOjE2NTk4MzgyMTZ9.VtGTKs2RhdalaGvU-d6pS896IB3zUIZ-Uinix9nG9Bo'
            return config;
        }
        if (config.isUpload) {
            config.headers = { 'Content-Type': 'multipart/form-data'} // 根据参数是否启用form-data方式
            return config;
        } else {
            config.headers = { 'Content-Type': 'application/json' }
            return config;
        }
    },
    error => {
        return Promise.error(error)
    })



// 响应拦截器
axios.interceptors.response.use(
    // 服务码是200的情况
    response => {
        if (response.status === 200 && response.data.code === 1) {
            return Promise.resolve(response);
        } else if (response.status === 200) {
            var dingConfig = localStorage.getItem("dingConfig")
            if(dingConfig){
                autoDingDingLogin()
                return
            }
            switch (response.data.code) {
                // 未登录
                case 101:
                    Message({
                        message: '未登录',
                        type: 'error',
                        offset:380,
                        duration:1000
                    });
                    // 清除登录状态，返回首页
                    sessionStorage.removeItem('Authorization')
                    localStorage.removeItem('Authorization')
                    sessionStorage.removeItem('info')
                    localStorage.removeItem('info')
                    store.commit("changeNull")
                    if (router.app._route.name != 'home') {
                        setTimeout(() => {
                            router.push({
                                path: '/login',
                            })
                        }, 1000)
                    }
                    break;
                // 课程已添加
                case 100:
                    Message({
                        message: '课程已添加',
                        type: 'error',
                        offset:380,
                        duration:1000
                    });
                    break;
                // token过期
                case 201:
                    if(localStorage.getItem('loginout') == 'false' || localStorage.getItem('loginout') == undefined){
                        // console.log('我进来了',Promise.resolve(response))
                        window.localStorage.loginout = 'true';
                        Message({
                            message: '登录过期，请重新登录',
                            type: 'error',
                            offset:380,
                            duration:1000,

                        });
                        // 清除登录状态，返回首页
                        sessionStorage.removeItem('Authorization')
                        localStorage.removeItem('Authorization')
                        sessionStorage.removeItem('info')
                        localStorage.removeItem('info')
                        store.commit("changeNull")
                        if (router.app._route.name != 'home') {
                            setTimeout(() => {
                                router.push({
                                    path: '/login',
                                })
                                localStorage.removeItem('loginout')
                            }, 200)
                        }

                    }

                case 202:
                    if(localStorage.getItem('loginout') == 'false' || localStorage.getItem('loginout') == undefined){
                        // console.log('我进来了1',Promise.resolve(response))
                        window.localStorage.loginout = 'true';
                        Message({
                            message: '登录过期，请重新登录',
                            type: 'error',
                            offset:380,
                            duration:1000,

                        });
                        // 清除登录状态，返回首页
                        sessionStorage.removeItem('Authorization')
                        localStorage.removeItem('Authorization')
                        sessionStorage.removeItem('info')
                        localStorage.removeItem('info')
                        store.commit("changeNull")
                        if (router.app._route.name != 'home') {
                            setTimeout(() => {
                                router.push({
                                    path: '/login',
                                })
                                localStorage.removeItem('loginout')
                            }, 200)
                        }

                    }
                    break;
                case -1:
                    break;
                default:
                    Message({
                        message: response.data.msg ? response.data.msg : '未知错误',
                        type: 'warning',
                        offset: 380,
                        duration: 1000
                    });
            }
            return Promise.resolve(response);
        } else {
            Message({
                message: '服务器出错 code:' + response.status,
                type: 'warning',
                offset: 380,
                duration: 1000
            });
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        // console.log('error', error)
        if (error.response.status) {
            switch (error.response.status) {
                // 404请求不存在
                case 404:
                    Message({
                        message: '网络请求不存在',
                        type: 'error',
                        offset:380,
                        duration:1000
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    Message({
                        message: error.response.data.message ? error.response.data.message : '服务器出错:' + error.response.status + ' ' + error.response.statusText,
                        type: 'error',
                        offset:380,
                        duration:1000
                    });
            }
            return Promise.reject(error.response)
        }
    }
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [请求时url后直接加的参数，默认为空]
 */
export function get(url, params,config = {add: '' }) {
    return new Promise((resolve, reject) => {
        axios.get(url,{
            params: params
        },config).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} config [是否启用multipart/form-data格式请求，默认为false]
 */
export function post(url, params,config = {isUpload: false }) {
    return new Promise((resolve, reject) => {
        axios.post(url, params,config)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

