<template>
    <div class="plan">
        <!-- 主体内容图标 -->
        <div class="nav-icon">
        <i class="el-icon-s-fold"></i>
        <span>计划管理</span>
        </div>

        <!-- 标签页组件 -->
        <div class="bg">
            <template>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="计划" name="first">
                      <div class="flex">
                          <!-- 选择框1 -->
                            <el-select v-model="value1" placeholder="请选择平台">
                                <el-option
                                  v-for="item in select1"
                                  :key="item.value1"
                                  :label="item.label"
                                  :value="item.value1">
                                </el-option>
                            </el-select>&nbsp;&nbsp;&nbsp;                        
                            <!-- 选择框2 -->
                            <el-select v-model="value2" placeholder="请选择标签状态">
                                <el-option
                                  v-for="item in select2"
                                  :key="item.value2"
                                  :label="item.label"
                                  :value="item.value2">
                                </el-option>
                            </el-select>&nbsp;&nbsp;&nbsp;
                            <!-- 选择框3 -->
                            <el-select v-model="value3" placeholder="请选择标签">
                                <el-option
                                  v-for="item in select3"
                                  :key="item.value3"
                                  :label="item.label"
                                  :value="item.value3">
                                </el-option>
                            </el-select>&nbsp;&nbsp;&nbsp;
                            <!-- 选择框4 -->
                            <el-input
                                class="input1"
                                placeholder="输入关键词进行搜索"
                                v-model="input1">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                      </div>
                      <!-- 两个按钮 -->
                      <div class="btn">
                          <el-button type="primary">添加标签</el-button>
                          <el-button type="danger">删除标签</el-button>
                      </div>
                      <!-- 表格开始 -->
                      <div>
                          <el-table
                            :data="items"
                            stripe
                            style="width: 100%"
                          >
                            <el-table-column
                                type="selection"   
                                prop="checkbox"
                                label="全选">
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="计划名称"
                            > 
                            </el-table-column>
                            <el-table-column
                                prop="ad_id"
                                label="计划ID"
                            > 
                            </el-table-column>
                            <el-table-column
                                prop="platform"
                                label="平台"
                            > 
                            </el-table-column>
                          </el-table>
                      </div>
                      <!-- 页码 -->
                    <div class="pages"> 
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage1"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="1570">
                      </el-pagination>
                    </div>   
                  </el-tab-pane>
                  <!-- 计划栏结束 -->
                  <el-tab-pane label="标签管理" name="second">
                      <!-- 搜索栏 -->
                      <div class="search-btn">
                            <el-input
                                class="input2"
                                placeholder="输入关键词进行标签搜索"
                                v-model="input1">
                                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                            <el-button type="primary" @click="dialogFormVisible = true">新建标签分类</el-button>
                            <el-dialog title="新建标签分类" :visible.sync="dialogFormVisible">
                                <!-- 分割线 -->
                                <el-divider></el-divider>
                                <div class="tags-title">标签分类</div>
                                <!-- 输入框 -->
                                <div class="tags">
                                    <el-tag
                                        :key="tag"
                                        v-for="tag in dynamicTags"
                                        closable
                                        :disable-transitions="false"
                                        @close="handleClose(tag)">
                                        {{tag}}
                                    </el-tag>
                                    <el-input
                                      class="input-new-tag"
                                      v-if="inputVisible"
                                      v-model="inputValue"
                                      ref="saveTagInput"
                                      size="small"
                                      @keyup.enter.native="handleInputConfirm"
                                      @blur="handleInputConfirm"
                                    >
                                    </el-input>
                                    <el-button v-else class="button-new-tag" size="small" @click="showInput">添加标签后回车</el-button>                                      
                                </div>
                                <div slot="footer" class="dialog-footer">
                                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                                  <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                                </div>
                            </el-dialog>
                      </div>
                      <!-- 标签内容 -->
                      <div>  
                        <el-empty description="描述文字"></el-empty>
                      </div>  
                  </el-tab-pane>
                </el-tabs>
            </template>
        </div>
        <!-- 标签页结束 -->


    </div>
</template>

<script>
import { plan, planlist, planmanage, saveedit } from '@/request/api';


export default {
// 数据
    data() {
      return {
        //   标签内容
        dynamicTags: [],
        inputVisible: false,
        inputValue: '',
        //   嵌套
        dialogFormVisible: false,
        //   页码
        currentPage1: 1,
        // 表格内容
        items:[],  
        // 选择框2
        value2:'',
        select2:[{
            value2:'1',
            label:'已打标'
        },{
            value2:'2',
            label:'未打标'
        }],
        // 选择框3  
        value3:'',
        select3:[],
        // 输入框1
        input1:'',
        // 标签页的选项  
        activeName: 'first',
        // 选择框1
        value1:'',
        select1:[{
            value1:'1',
            label:'巨量引擎',
        },{
            value1:'2',
            label:'巨量千川',
        },{
            value1:'3',
            label:'Uni Desk',
        },{
            value1:'4',
            label:'腾讯广告',
        }],

      };
    },
mounted () {
//   saveedit({
//     agentId:1,
//     activityType:1,
//     name:'1',
//     platform:1,
//     status:'1',
//     connected_account:[1],
//     type:'save'
//   }).then(r=>{
//     console.log(r)
//   })
      this.getplan()
},
created(){
        console.log( sessionStorage.getItem('Authorization'));
},
// 方法
    methods: {
        getplan(){
          planmanage({
            Authorization: sessionStorage.getItem('Authorization'),
            pageSize:2,
            page:1,
          }).then(r=>{
            console.log(r);
            this.items = r.data.data
          })
        },

        // 标签
        handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.dynamicTags.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
    //  页签
      handleClick(tab, event) {
        console.log(tab, event);
      },
    //   页码
       handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },

    }
}
</script>

<style lang="scss" scoped>
// 背景色
.plan .bg{
    background-color: #fff;
    margin-top: 2vh;
    padding: 2vh;
}
// 主体内容图标
.plan .nav-icon{
    display: flex;
    align-items: center;
}
.plan .el-icon-s-fold{
  font-size: 3vh;
}
.plan .nav-icon span{
  font-size: 2vh;
  padding-left: 1vh;
}

// 计划
// 选择框4
.plan .input1{
    width: 30vh;
}
// 两个按钮
.plan .btn{
    text-align: right;
}
// 页码
.plan .pages{
    text-align: right;
    margin: 1vh 1vh 2vh 0;
}

// 标签管理
// 搜索栏
.plan .search-btn{
    display: flex;
    justify-content: space-between;
}
// 输入框
.plan .input2{
    width: 30vh;
}
// 标签分类标题
.plan .tags-title{
    text-align: left;
    margin: 1vh;
}
// 嵌套的标签
.plan .tags{
    border: 1px solid rgb(228,231,237);
    padding: 2vh;
}
</style>