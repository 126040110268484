<template>
  <div>
    <el-tabs v-loading="loading" v-model="activeName" @tab-click="tabClick">
      <!-- 一楼：搜索栏 -->
      <div class="flex" style="padding:10px">
        <columnclick
          style="margin: 0 0 0px 10px;"
          :param="'100006'"
          @accept="accountname"
          :columnsInfo="accountColumns"
          @confirm="toConfirm('company', $event)"
        ></columnclick>
        <el-button
          @click="handleCompanydata"
          type="primary"
          style="margin-left: 16px;width:120px;height:40px"
        >导出报表</el-button>
      </div>
      <el-tab-pane label name="company">
        <el-table border :data="array" style="width: 100%">
          <el-table-column fixed prop="companyName" label="所属公司" width="260"></el-table-column>
          <el-table-column fixed prop="operation" label="操作" width="180">
            <div slot-scope="zhnum" class="flex">
              <div
                style="color: #0a63fc"
                class="cursor"
                @click="openAnalyse('company', array[zhnum.$index],zhnum)"
                type="primary"
              >时段数据</div>
            </div>
          </el-table-column>
          <el-table-column
            width="150"
            v-for="(item, index) in filterAccountColumns"
            :prop="item.val"
            :label="item.name"
            sortable
            :key="index"
            v-if=" item.name != '操作'"
          ></el-table-column>
        </el-table>
        <div class="block flex_between margin_top">
          <span class="demonstration"></span>
          <!-- size-change 每页条数 -->
          <!-- current-change 当前页 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 15, 50]"
            :page-size="1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(arrayCompanydata) ? Number(arrayCompanydata) : 0"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <drawer
      :orderFrom="orderfromCompanydata"
      :filterDateStart="filterDateStartCompanydata"
      :filterDateEnd="filterDateEndCompanydata"
      :right_paramsPlan="right_params"
      ref="analyseView"
      :costCompanydata="costCompanydata"
      :clickCompanydata="clickCompanydata"
      :convertNumCompanydata="convertNumCompanydata"
      :payAmountCompanydata="payAmountCompanydata"
      :roiCompanydata="roiCompanydata"
      :showNumCompanydata="showNumCompanydata"
    ></drawer>
  </div>
</template>

<script>
import {
  advertisercompany,
  accountlis,
  commodity,
  downloadCompanyData,
  cornMetricsByKeyId,
  columndefault
} from "@/request/api.js"; //
import columnclick from "./goods_columnclick";
import drawer from "./goods_drawer";
import advertisement from "./goods_advertisement";
const CFG_NAME = "good_tab_config_company";
import $utils from "@/js/utils";
export default {
  components: {
    columnclick,
    drawer,
    advertisement
  },
  props: {
    filterData: {
      type: Object,
      default: () => {
        return {
          goodIds: [],
          acticityId: "",
          targetIds: [],
          targetType: "",
          roiMax: "",
          roiMin: "",
          costMax: "",
          costMin: "",
          orderFrom: ""
        };
      }
    },
    orderchange2: {
      type: String,
      default: ""
    },
    parmval: {
      type: String,
      default: ""
    },
    // 子账户账户筛选数据包传递
    accountarrayCompanydata: {
      type: Array,
      default: ""
    },
    totalnumCompanydata: {
      type: null,
      default: ""
    },
    orderfromCompanydata: {
      type: String,
      default: ""
    },
    valgoodsCompanydata: {
      type: Array,
      default: ""
    },
    keywordCompanydata: {
      type: String,
      default: ""
    },
    filterDateStartCompanydata: {
      //从父组件传递开始时间
      type: String,
      default: ""
    },
    filterDateEndCompanydata: {
      //从父组件传递结束时间
      type: String,
      default: ""
    },
    val: {
      type: Array,
      default: ""
    },
    right_params: {
      type: String,
      default: ""
    },
    valone1: {
      type: Number,
      default: ""
    }
  },
  data() {
    return {
      costCompanydata:"",
      clickCompanydata:"",
      convertNumCompanydata:"",
      payAmountCompanydata:"",
      roiCompanydata:"",
      showNumCompanydata:"",
      right_paramsCompanydata: "",
      checked: "false",
      tab: "",
      loading: false,
      activeName: "company",
      planTableData: [],
      filterAccountData: null,
      page: 1,
      pageSize: 10,
      totalnum: 0,
      filterType: "",
      // accountColumnsData: [],
      accountColumns: [
        {
          type: "属性指标",
          name: "所属公司ID",
          val: "companyId"
        },

        {
          type: "展现及花费",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "千次展现费用",
          val: "cpm"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "展现及花费",
          name: "平均点击单价",
          val: "cpc"
        },
        {
          type: "展现及花费",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "转化及成交",
          name: "成交订单数",
          val: "payCount"
        },
        {
          type: "转化及成交",
          name: "成交订单均价",
          val: "avgAmountVar"
        },
        {
          type: "转化及成交",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        }
      ],
      adgroupColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        },
        {
          type: "展现及花费",
          name: "当日转化ROI",
          val: "convertRoi"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        },
        {
          type: "转化及成交",
          name: "转化成本",
          val: "convertCost"
        }
      ],
      planColumns: [
        {
          type: "属性指标",
          name: "花费",
          val: "cost"
        },
        {
          type: "展现及花费",
          name: "成交金额",
          val: "payAmount"
        },
        {
          type: "展现及花费",
          name: "成交ROI",
          val: "roi"
        },
        {
          type: "展现及花费",
          name: "当日转化金额",
          val: "convertAmount"
        },
        {
          type: "展现及花费",
          name: "当日转化ROI",
          val: "convertRoi"
        },
        {
          type: "展现及花费",
          name: "展现量",
          val: "showNum"
        },
        {
          type: "展现及花费",
          name: "点击量",
          val: "click"
        },
        {
          type: "转化及成交",
          name: "点击率",
          val: "ctr"
        },
        {
          type: "转化及成交",
          name: "转化数",
          val: "convertNum"
        },
        {
          type: "转化及成交",
          name: "转化率",
          val: "convertRate"
        }
      ],
      array: [],
      arrayCompanydata: {
        type: Number,
        default: 1
      },
      midell: this.valone1
    };
  },
  watch: {
    filterData: {
      handler(newVal) {
        console.log("goods_tabs filter data handler", newVal);
        // this.handlerFilterAccount();
      },
      deep: true
    },
    parmval(e) {
      this.handlerFilterAccount(e);
    },
    accountarrayCompanydata(accountarrayCompanydata) {
      this.array = this.accountarrayCompanydata;
      console.log(this.array, "aaaa");
    },
    totalnumCompanydata(totalnumCompanydata) {
      this.arrayCompanydata = this.totalnumCompanydata;
      console.log(this.arrayCompanydata, "arrayCompanydata");
    },
    valone1(valone1) {
      console.log(valone1, "console.log(this.valone1);");
      this.page = this.valone1;
    },
    page(page, midell) {
      console.log(this.page, "page");
      this.midell = this.page;
    }
  },
  computed: {
    filterAccountColumns() {
      return this.accountColumns.filter(ele => ele.checked);
    },
    filterAdgroupColumns() {
      return this.adgroupColumns.filter(ele => ele.checked);
    },
    filterPlanColumns() {
      return this.planColumns.filter(ele => ele.checked);
    }
  },
  // orderchange2动态订单来源下标
  mounted() {
    console.log();
    // this.handlerFilterAccount();
    if (this.valone1) {
      this.page = this.valone1;
    }
    columndefault({
        btnCode: "100006",
      }).then((res) => {
        
        let list = res.data.sort((a, b) => {
          return a.colTypeCode - b.colTypeCode;
        });
        let data1 = list[0].colTypeCode;
        let typeList = ["属性指标", "展现及花费", "转化及成交"];
        let index = 0;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          list[i].name = item.colName;
          list[i].val = item.colField;
          if (data1 == item.colTypeCode) {
            list[i].type = typeList[index];
          } else {
            data1 = item.colTypeCode;
            index++;
            list[i].type = typeList[index];
          }
        }
        this.accountColumns = list;
        console.log(this.planColumns,'this.planColumns')
        this.accountColumns.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.accountColumns.forEach((ele, idx) => {
          // ele.checked = true;
          
          this.$set(this.accountColumns[idx], "checked", true);
        });
      });
  },
  created() {
    let cfg = sessionStorage.getItem(CFG_NAME);
    if (cfg) {
      cfg = JSON.parse(cfg);
      if (cfg.account) {
        this.accountColumns = cfg.account;
      }
      if (cfg.adgroup) {
        this.adgroupColumns = cfg.adgroup;
      }
      if (cfg.company) {
        this.accountColumns = cfg.company;
      }
    }
    this.accountColumns.forEach((ele, idx) => {
      if (
        this.accountColumns[idx].checked == undefined ||
        this.accountColumns[idx].checked == null
      ) {
        this.$set(this.accountColumns[idx], "checked", true);
      }
    });
    this.adgroupColumns.forEach((ele, idx) => {
      if (
        this.adgroupColumns[idx].checked == undefined ||
        this.adgroupColumns[idx].checked == null
      ) {
        this.$set(this.adgroupColumns[idx], "checked", true);
      }
    });
    this.planColumns.forEach((ele, idx) => {
      if (
        this.planColumns[idx].checked == undefined ||
        this.planColumns[idx].checked == null
      ) {
        this.$set(this.planColumns[idx], "checked", true);
      }
    });
  },
  methods: {
    // 主账户下载表格
    handleCompanydata() {
      downloadCompanyData({
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartCompanydata,
        filterDateEnd: this.filterDateEndCompanydata,
        orderFrom: this.orderfromCompanydata,
        accountIds: this.val,
        search: this.keywordCompanydata,
        btnCode: "100006",
        goodIds: this.valgoodsCompanydata
      }).then(res => {
        console.log(res.data);
        $utils.exportRaw(res.data.filename, res.data.file);
      });
    },
    // 按钮
    accountname(accountname) {
      console.log(accountname);
      this.name = accountname.colName;
    },
    // 推广分析
    openAnalyse(type, data, tabId) {
  console.log(data);
      this.$refs.analyseView.show(type, data, tabId);
 this.chooseKeyIdCompanydata=data.companyId
      cornMetricsByKeyId({
           filterDateStart: this.filterDateStartCompanydata,
           filterDateEnd: this.filterDateEndCompanydata,
            chooseKeyType:"company",
            chooseKeyId: this.chooseKeyIdCompanydata,
            orderFrom:this.orderchange2,
      }).then(res=>{
            console.log(res.data);
             this.costCompanydata=res.data.cost
            this.clickCompanydata=res.data.click
            this.convertNumCompanydata=res.data.convertNum
            this.payAmountCompanydata=res.data.payAmount
            this.roiCompanydata=res.data.roi
            this.showNumCompanydata=res.data.showNum
        
      })



    },
    toConfirm(type, data) {
      if (type == "company") {
        this.accountColumns = data;
      } else if (type == "adgroup") {
        this.adgroupColumns = data;
      } else {
        this.planColumns = data;
      }
      // 缓存选中列
      let cfg = sessionStorage.getItem(CFG_NAME);
      if (cfg) {
        cfg = JSON.parse(cfg);
      } else {
        cfg = {};
      }
      cfg[type] = data;
      sessionStorage.setItem(CFG_NAME, JSON.stringify(cfg));
    },
    tabClick(tab, page) {
      tab = tab.name;
      console.log(tab);
      this.filterType = "";
      this.filterAccount = "";
      this.handleClick(tab, 1);
    },
    handleClick(tab, page) {
      this.page = page;
      console.log("tab", tab, "page", this.page, this.filterType);
      if (tab == "account") {
        this.handlerFilterAccount();
      } else if (tab == "adGroup") {
        this.handleFilterAdgroup(null);
      } else {
        this.handleFilterPlan(null);
      }
    },
    handleSizeChange(val) {
      // console.log('handleSizeChange', val);
      this.pageSize = val;
      // this.page = 1;
      this.handleClick(this.activeName, 1);
    },
    handleCurrentChange(val) {
      this.handleClick(this.activeName, val);
    },
    // 点击tabs请求
    handlerFilterAccount(type) {
      console.log(type);
      if (
        !this.filterData.acticityId ||
        this.filterData.acticityId.length == 0
      ) {
        return;
      }
      let param = {
        tabType: type, //页签类别
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      param = Object.assign(param, this.filterData);
      this.advertisercompany(param).then(res => {
        console.log("公司数据", res.data);
        this.array = res.data;
        this.array.forEach(showNum => {
          showNum.showNum = parseInt(showNum.showNum);
          console.log(showNum.showNum);
        });
        this.array.forEach(click => {
          click.click = parseInt(click.click);
        });
        this.array.forEach(convertNum => {
          convertNum.convertNum = parseInt(convertNum.convertNum);
        });
        this.arrayCompanydata = res.total;
        console.log(this.arrayCompanydata);
      });
    },

    // 查看直接从账户跳转到计划
    handleFilterPlan(type, account) {
      this.activeName = "company";
      let param = {
        platform:sessionStorage.getItem("cur_platform"),
        acticityId: JSON.parse(this.acid),
        filterDateStart: this.filterDateStartCompanydata,
        filterDateEnd: this.filterDateEndCompanydata,
        orderFrom: this.orderfromCompanydata,
        accountIds: this.val,
        search: this.keywordCompanydata,
        goodIds: this.valgoodsCompanydata,
        page: this.page, //页码
        pageSize: this.pageSize, //条数
        orderFrom: this.orderchange2
      };
      this.loading = true;
      this.advertisercompany(param).then(res => {
        this.loading = false;
        console.log("公司数据", res);
        this.planTableData = res.data;
        this.arrayCompanydata = res.total;
        console.log(this.array);
      });
    },
    advertisercompany(param) {
      return new Promise((resovle, reject) => {
        this.loading = true;
        if (param["filterDateStart"]) {
          console.log("报表tab页接口", param);
          console.log(JSON.stringify(param.acticityId));
          this.acid = JSON.stringify(param.acticityId);
          advertisercompany(param)
            .then(res => {
              this.loading = false;
              resovle(res);
            })
            .catch(e => {
              reject(e);
            });
        }
      });
    }
  }
};
</script>
 <style>
.el-table th.el-table__cell > .cell {
  width: 400px;
}
.el-select__tags-text {
  /* width: 20px; */
}
.head_search_box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* margin: 20px 0; */
  background-color: #fff;
}
.head_search1 {
  /* display: flex; */
  /* align-items: center; */
}
#tab-company {
  position: absolute;
}
/deep/.el-tabs__active-bar {
  display: none;
}
/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/#tab-account {
  position: absolute;
}
.head_search1 {
  display: flex;
  flex-wrap: wrap;
}
.head_search_box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
#pane-company {
  padding: 18px;
}
</style>