import $ding from "@/js/dingtalk.open";
import {login} from "@/request/api";
import router from '../router' // 引入vue-router
const TokenKey = 'Authorization'

export function getToken() {
    if (sessionStorage.getItem(TokenKey)) {
        return sessionStorage.getItem(TokenKey)
    } else {
        return localStorage.getItem(TokenKey)
    }
}

export function autoDingDingLogin(){
    var dingConfig = localStorage.getItem("dingConfig")
    if(dingConfig){
        dingConfig = JSON.parse(dingConfig)
        $ding.ready(function () {
            $ding.device.notification.toast({
                icon: '', //icon样式，有success和error，默认为空
                text: '自动登录中....', //提示信息
                duration: 3, //显示持续时间，单位秒，默认按系统规范[android只有两种(<=2s >2s)]
                delay: 0, //延迟显示，单位秒，默认0
                onSuccess: function (result) {
                    /*{}*/
                },
                onFail: function (err) {
                }
            });
            // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
            $ding.runtime.permission.requestAuthCode({
                corpId: dingConfig.corpId,
                onSuccess: function (info) {
                    login({code: info.code, act: '3', corpid: dingConfig.corpId, dingding: 1}).then(
                        res => {
                            if (res.code == 1) {
                                let token = res.data.token;
                                if(res.data.is_admin == 1){
                                    localStorage.setItem("Authorization", token);
                                    localStorage.setItem("info", JSON.stringify(res.data));
                                }else{
                                    sessionStorage.setItem("Authorization", token);
                                    sessionStorage.setItem("info", JSON.stringify(res.data));
                                }
                                // console.log(token);
                                router.push({path: res.data.route});
                            } else {
                                this.$message.error(res.msg);
                            }
                        }
                    )
                },
                onFail: function (err) {
                    alert('dd error: ' + JSON.stringify(err));
                }
            });
        });
    }
}

