<template>
  <div>
    <input type="text" placeholder="请输入搜索内容" v-model="search" />
    <p v-for="(item, index) in searchMethod" :key="index">{{ item.name }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: [
        { name: "qq", baz: "1" },
        { name: "qq2", baz: "12" },
        { name: "q3q", baz: "13" },
        { name: "qq4", baz: "14" },
        { name: "qq5", baz: "15" },
        { name: "qq6", baz: "16" },
        { name: "qq7", baz: "17" },
        { name: "qq8", baz: "18" },
        { name: "qq9", baz: "19" }
      ],
      search: ""
    };
  },
  computed: {
    searchMethod() {
      return this.arr.filter(value => {
        return value.name.match(this.search);
      });
    }
  }
};
</script>

<style>
</style>