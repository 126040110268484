import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'

// 营销活动监控
import Goods from '@/views/goods/Goods';
// 营销活动管理
import Manage from '@/views/manage/Manage';
// 营销活动分析
import Analyse from '@/views/analyse/Analyse';
// 营销活动报表
import Report from '@/views/report/Report';
// 数据中心
import DataCenter from '@/views/DataCenter';
// 活动管理下的新建活动
import Active from '@/views/manage/Active';
// 新建活动详情
import ActiveDetail from '@/views/manage/ActiveDetail';

// 新建活动详情
import Setup from '@/views/setup/Setup';

import Auth from '@/views/setup/Auth';

// 编辑活动详情
import EditActive from '@/views/manage/EditActive';
// 活动管理的计划管理
import Plan from '@/views/manage/Plan';

// 活动管理的计划管理
import chain from '@/views/chain/chain';

// 活动管理的计划管理
import cersonalcenter from '@/views/user/cersonalcenter';

// 淘宝商品
import taobaoGoods from '@/views/taobaoGoods/taobaoGoods';

// 落地页配置
import landingpage from '@/views/taobaoGoods/landingpage';
import onepage from '@/views/onepage/index';

//广告代理商管理

import advertisingAgency from '@/views/SystemSetup/advertisingAgency';

//账号管理

import accountManagement from '@/views/SystemSetup/accountManagement';

//代理商列表

import dlslist from '@/views/SystemSetup/dlslist';

//代理商账号管理

import dlsManagement from '@/views/SystemSetup/dlsManagement';

//数据看板

import datakanban from '@/views/DataKanban/datakanban';

//账号授权页面
import authorization from '@/views/Account/authorization'

//联盟订单页面
import order from '@/views/Alliance/order'

//淘宝小程序页面
import tbxcx from '@/views/Account/tbxcx'

// 主账户管理
import MainAccount from '@/views/manage/MainAccount';

// 子账户
import ChildAccount from '@/views/manage/ChildAccount';

import ChildPlan from '@/views/manage/ChildPlan';

// 子账号管理
import ChildManage from '@/views/manage/ChildManage';


// 公司管理
import CompanyManage from '@/views/manage/CompanyManage';


// 测试
import testtree from '@/testing/TestTree'

import four from '@/views/four'

import tbauthorize from '@/views/tbauthorize'


Vue.use(VueRouter)

const routes = [
  {
    path: '/testtree',
    name: 'test',
    component: testtree
  },
  {
    path: '/four',
    name: 'four',
    component: four
  },
  {
    path: '/tbauthorize',
    name: 'tbauthorize',
    component: tbauthorize
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '',
    name: 'main',
    component: Main,
    children: [
      {
        path: '',
        alias: '/report/Report',
        name: '数据报表',
        component: Report,
      },
      // {
      //   path: '',
      //   alias: '/goods/ ',
      //   name: '营销活动监控',
      //   component: Goods,
      // },
      {
      path: '/goods/Goods',
      alias: '/goods/Goods',
      name: '营销活动监控',
      component: Goods,
    },
    {
      path: '/manage/Manage',
      name: '活动配置',
      component: Manage,
    }, {
      path: '/manage/Plan',
      name: '计划管理',
      component: Plan,
    }, {
      path: '/manage/Active',
      name: '新建活动',
      component: Active,
    }, {
      path: '/manage/ActiveDetail',
      name: '活动详情',
      component: ActiveDetail,
    }, {
      path: '/manage/EditActive',
      name: '编辑活动详情',
      component: EditActive,
    },{
      path: '/manage/MainAccount',
      name: '主账户管理',
      component: MainAccount,
    },{
      path: '/manage/ChildAccount',
      name: '子账户',
      component: ChildAccount,
    },{
      path: '/manage/ChildPlan',
      name: '子账户计划',
      component: ChildPlan,
    },{
      path: '/manage/ChildManage',
      name: '子账号管理',
      component: ChildManage,
    }, {
      path: '/manage/CompanyManage',
      name: '域名管理',
      component: CompanyManage,
    },{
      path: '/analyse/Analyse',
      name: '营销活动分析',
      component: Analyse,
    },  {
      path: '/DataCenter',
      name: '订单报表',
      component: DataCenter,
    }, {
      path: '/setup/Setup',
      name: '电商授权',
      component: Setup,
    }, {
      path: '/setup/Auth',
      name: '授权管理',
      component: Auth,
    },
    {
      path: '/user/cersonalcenter',
      name: '个人中心',
      component: cersonalcenter,
    },
    {
      path: '/chain/chain',
      name: '落地页转链',
      component: chain,
    },{
      path:'/taobaoGoods/taobaoGoods',
      name:'商品管理',
      component: taobaoGoods,
    },{
      path:'/taobaoGoods/landingpage',
      name:'配置落地页',
      component: landingpage,
      }, {
      path: '/taobaoGoods/onepage',
      name: '配置落地页',
      component: onepage,
      },
      {
        path: '/SystemSetup/advertisingAgency',
        name: '广告主管理',
        component: advertisingAgency,
      },
      {
        path: '/SystemSetup/accountManagement',
        name: '广告主账号管理',
        component: accountManagement,
      },
      {
        path: '/DataKanban/datakanban',
        name: '数据看板',
        component: datakanban,
      },{
        path: 'Account/authorization',
        name: '账号授权审核',
        component: authorization,
      },{
        path: 'Alliance/order',
        name: '联盟订单',
        component: order,
      },{
        path: 'Account/tbxcx',
        name: '淘宝小程序',
        component: tbxcx,
      },{
        path: 'SystemSetup/dlslist',
        name: '代理商列表',
        component: dlslist,
      },{
        path: 'SystemSetup/dlsManagement',
        name: '代理商账号管理',
        component: dlsManagement,
      },
  ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
