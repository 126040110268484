<template>
    <div class="active">
        <!-- 主体内容图标 -->
        <div class="nav-icon">
        <div class="nav1">
        <i class="el-icon-s-fold"></i>
        <span>活动配置</span>
        </div>
        <div class="nav2" @click="goback">
        <el-button type="primary">
        <i class="el-icon-back"></i>返回
        </el-button>
        </div>
        </div>
        <!-- 根据业务场景 -->
        <div class="f1">
            <span>根据您的业务场景</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>创建相应活动</span>
        </div>
        <!-- 选择业务 -->
        <div class="f2">
            <div class="c1">
                <div class="c1-1" @click="godetail">
                    <div class="title">
                    巨量引擎<br>
                    <div class="text">打通前链路营销数据和后链路成交数据、自动追踪全链路营销效果、实时回流沉淀及分析消费者资产</div></div>
                </div>
                <div class="c1-2">
                    <div class="title">
                    巨量千川<br>
                    <div class="text">直播和短视频投放效果和转化追踪、直播素材、商品、主播分析</div></div>
                </div>
                <div class="c1-3">
                    <div class="title">
                    Uni Desk<br>
                    <div class="text">多账户统筹管理、高效全链路营销效果追踪</div></div>
                </div>
            </div>
            <div class="c2">
                <div class="c2-1">
                    <div class="title">
                    腾讯广告<br>
                    <div class="text">多账户统筹管理、高效全链路营销效果追踪</div></div>
                </div>
                <div class="c2-2">
                    <div class="title">
                    淘宝客链接监测<br>
                    <div class="text">实时追踪全渠道淘宝客带货成交效果</div></div>
                </div>
                <div class="c2-3">
                    <div class="title">
                    秒针监测<br>
                    <div class="text">通过SFTP方式T+1回流、沉淀及分析秒针平台消费者资产</div></div>
                </div>
            </div>
            <div class="c3">
                <div class="c3-1">
                    <div class="title">
                    其他媒体平台监测<br>
                    <div class="text">支持部分媒体平台(如Soul、美柚、知乎等)实时回流、沉淀及分析消费者资产</div></div>
                </div>
                <div class="c3-2"></div>
                <div class="c3-3"></div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        methods: {
            godetail() {
                this.$router.push('/manage/ActiveDetail')
            },
            goback(){
                this.$router.go(-1)
            },
        },
    }
</script>

<style lang="scss" scoped>
// 主体内容图标
.active .nav-icon{
    display: flex;
    align-items: center;
}
.active .nav-icon .nav1{
    display: flex;
    align-items: center;
    flex: 1;
}
.active .el-icon-s-fold{
  font-size: 3vh;
}
.active .nav-icon span{
  font-size: 2vh;
  padding-left: 1vh;
}
// 返回按钮
// .active .nav2 .el-button{
//     width: 0.5vh;
// }
// 根据业务场景
.active .f1{
    margin-top: 2vh;
    text-align: left;
    // border: 1px solid purple;
    height: 15vh;
    background-color: #fff;
}
// 背景色
.active .f2{
    background-color: #fff;
    height: 50vh;
    margin-top: 1vh;
}

// 业务版块
// 第一行
.active .f2 .c1{
    display: flex;
    justify-content: space-evenly;
}
.active .f2 .c1 .c1-1,.c1-2,.c1-3{
    // border: 1px solid purple;
    height: 15vh;
    width: 40vh;
    margin-top: 1vh;
    background-color: rgb(245,246,250);
    cursor: pointer;
}
// 悬停变大
.active .f2 .c1 .c1-1:hover,.c1-2:hover,.c1-3:hover{
        // height: 15.2vh;
        // width: 40.5vh;
        box-shadow: 0 0 10px grey;
        transition: all 0.1s;
}
// 第二行
.active .f2 .c2{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1vh;
}
.active .f2 .c2 .c2-1,.c2-2,.c2-3{
    // border: 1px solid purple;
    height: 15vh;
    width: 40vh;
    background-color: rgb(245,246,250);
    cursor: pointer;
}
// 悬停变大
.active .f2 .c2 .c2-1:hover,.c2-2:hover{
    box-shadow: 0 0 10px grey;
    transition: all 0.1s;
}
// 第三行
.active .f2 .c3{
    display: flex;
    justify-content: space-evenly;
    margin-top: 1vh;
}
.active .f2 .c3 .c3-1{
    // border: 1px solid purple;
    height: 15vh;
    width: 40vh;
    background-color: rgb(245,246,250);
    cursor: pointer;
}
// 悬停变大
.active .f2 .c3 .c3-1:hover{
    box-shadow: 0 0 10px grey;
    transition: all 0.1s;
}
.active .f2 .c3 .c3-2,.c3-3{
    border: 1px solid #fff;
    height: 15vh;
    width: 40vh;
}
// 版块里面 字的样式
.active .f2 .text{
    font-size: 0.6rem;
    color: rgb(205,205,209);
    margin-top: 1vh;
}
.active .f2 .title{
    text-align: left;
    padding: 2vh;
}
// 全局
.active{
    background-color: rgb(245,246,250);
    color: black;
}
</style>