<template>
  <div class="manage">
    <!-- 标签页组件 -->
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick" v-loading="loading"> -->
          <!-- 活动栏 -->
          <!-- <el-tab-pane label="活动" name="first"> -->
            <!-- 选择框 1 -->
            <div class="box">
              <div class="box1">
                  <el-select v-model="searchForm.platform" placeholder="请选择" @change="searchPlatform">
                     <el-option
                        key=""
                        label="全部平台"
                        value=""
                    >
                    </el-option>
                    <el-option
                      v-for="item in option"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- 选择框 2 -->
                <el-date-picker
                  v-model="searchForm.time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker
                >&nbsp;&nbsp;&nbsp;&nbsp;
                <!-- 选择框 3 -->
                <div class="box3">
                  <el-input
                    style="width:30vh"
                    placeholder="请输入活动名称"
                    v-model="searchForm.activityName"
                    clearable
                    @keydown.enter.native="search"
                  >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    <template slot="append"><el-button @click="search" type="primary">搜索</el-button></template>
                  </el-input>
                </div>
              </div>

              <router-link to="/manage/ActiveDetail"
                ><el-button type="primary" icon="el-icon-document-add"
                  >新建活动</el-button
                ></router-link
              >
            </div>
            
            <!-- 表格内容 -->
            <div class="form">
              <el-table
                :data="items"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                border
              >
                <!-- <el-table-column prop="id" label="ID" /> -->
                <el-table-column prop="name" label="活动名称">
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="活动状态"
                  :formatter="statusFormatter1"
                >
                </el-table-column>
                <el-table-column
                  prop="platform"
                  label="投放平台"
                  :formatter="statusFormatter2"
                >
                </el-table-column>
                <el-table-column prop="castTime" label="活动时间">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间">
                </el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间">
                </el-table-column>
                <el-table-column prop="operation" label="操作" fixed="right">
                  <template slot-scope="scope">
                    <div class="operate">
                      <div class="cursor" @click="goEdit(scope.row)">
                        <el-button type="text">编辑</el-button>
                      </div>&nbsp;&nbsp;
                      <div class="cursor" @click="goSet(scope.row)" v-if="is_open == 1">
                        <el-button type="text">活动设置</el-button>
                      </div>&nbsp;&nbsp;
                        <el-dialog 
                            width="810px"
                            title="活动设置" 
                            :visible.sync="dialogFormVisible" 
                            center 
                            append-to-body
                            :close-on-click-modal="false"
                            @close="resetForm"
                            >
                            <el-form :model="order" ref="order" label-position="left" label-width="150px">
                                    <el-form-item label="推广商品设置" style="font-weight:bold;width: 100%;margin-left: 8%;margin-bottom:10px">
                                    </el-form-item>

                                    <el-form-item label="回传已付款订单" style="width: 100%;margin-left: 8%;margin-bottom:10px">
                                        <el-switch
                                          v-model="order.callback_paid"
                                          active-color="#ff4949"
                                          disabled
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.paid_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')" 
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.paid_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;margin-left:0px"
                                          v-model="order.callback_paid_ratio" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          >
                                        </el-input> 
                                    </el-form-item>

                                    <el-form-item label="回传未付款订单" style="width: 100%;margin-left: 8%;margin-bottom:10px">
                                        <el-switch
                                          v-model="order.callback_non_payment"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="noPay"
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.non_payment_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled"
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.non_payment_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.callback_non_payment_proportion" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          :disabled="disabled"
                                          >
                                        </el-input> 
                                    </el-form-item>

                                    <el-form-item label="回传失效订单" style="width: 100%;margin-left: 8%;">
                                        <el-switch
                                          v-model="order.callback_lose"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="orderLose"
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.lose_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled2"
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.lose_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled2"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.callback_lose_proportion" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          :disabled="disabled2"
                                          >
                                        </el-input> 
                                    </el-form-item>

                                    <el-form-item label="非推广商品设置" style="font-weight:bold;width: 100%;margin-left: 8%;">
                                    </el-form-item>

                                    <el-form-item label="回传全店订单" style="width: 100%;margin-left: 8%;">
                                        <el-switch
                                          v-model="order.callback_attribution_order"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="orderAll"
                                        >
                                        </el-switch> 
                                    </el-form-item>

                                    <el-form-item label="回传指定非推广商品" style="width: 100%;margin-left: 8%;margin-bottom:10px">
                                          <el-switch
                                            v-model="order.callback_all_item"
                                            active-value="1"
                                            inactive-value="0"
                                            @change="orderID"  
                                          >
                                          </el-switch>
                                          <span style="width: 85px;margin-right: 5px;margin-left:10px">输入商品ID</span>
                                          <el-input 
                                            style="width: 278px;margin-left: 0px"
                                            v-model="order.callback_all_item_id" 
                                            clearable
                                            @input.native="notChinese"
                                            :disabled="disabled3"
                                            >
                                          </el-input> 
                                          <el-checkbox 
                                            style="margin-left:15px"
                                            true-label="1"
                                            false-label="0"
                                            v-model="order.callback_all_platform"
                                            @change="checkPlatform"
                                            >
                                            淘宝
                                          </el-checkbox>
                                    </el-form-item>

                                    <!-- <el-form-item label="回传指定金额区间订单" style="width: 100%;margin-left: 10%;">
                                          <el-switch
                                          v-model="order.callback_section"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="clearMoney"
                                        >
                                        </el-switch>
                                          <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                          <el-input 
                                            style="width: 20%;margin-left:15px"
                                            v-model="order.callback_price_start" 
                                            oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                            :disabled="disabled4"
                                            >
                                          </el-input> 
                                          <span style="padding:0 10px;">-</span>
                                          <el-input 
                                            style="width: 20%;"
                                            v-model="order.callback_price_end" 
                                            oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                            :disabled="disabled4"
                                            >
                                          </el-input>
                                    </el-form-item> -->

                                    <el-form-item label="回传已付款订单" style="width: 100%;margin-left: 8%;margin-top: 10px;">
                                          <el-switch
                                          v-model="order.callback_kate_kao"
                                          active-color="#ff4949"
                                          disabled
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.kate_kao_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.kate_kao_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.callback_proportion" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          >
                                        </el-input> 
                                    </el-form-item>

                                    <el-form-item label="回传未付款订单" style="width: 100%;margin-left: 8%;margin-top: 10px;">
                                          <el-switch
                                          v-model="order.callback_arrearage"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="noOrder"
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.arrearage_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled5"
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.arrearage_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled5"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.callback_arrearage_proportion" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          :disabled="disabled5"
                                          >
                                        </el-input> 
                                    </el-form-item>
                            
                                    <el-form-item label="回传失效订单" style="width: 100%;margin-left: 8%;margin-top: 10px;">
                                          <el-switch
                                          v-model="order.callback_fail"
                                          active-value="1"
                                          inactive-value="0"
                                          @change="failOrder"
                                        >
                                        </el-switch>
                                        <span style="width: 85px;margin-right: 5px;margin-left:10px">金额区间</span>
                                        <el-input 
                                          style="width: 15%;margin-left:15px"
                                          v-model="order.fail_start" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled6"
                                          >
                                        </el-input> 
                                        <span style="padding:0 5px;">-</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.fail_end" 
                                          oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                          :disabled="disabled6"
                                          >
                                        </el-input>
                                        <span style="width: 66px;margin-right: 8px;margin-left: 15px">少上报比例</span>
                                        <el-input 
                                          style="width: 15%;"
                                          v-model="order.callback_fail_proportion" 
                                          :min="0"
                                          :max="100"
                                          @input.native="onInput0_100"
                                          :disabled="disabled6"
                                          >
                                        </el-input> 
                                    </el-form-item>
                            </el-form>
                            <div slot="footer" style="margin: 0 0 4vh 0">
                                <el-button type="primary" @click="set" style="width:150px">确 定</el-button>
                            </div>
                        </el-dialog>
                      <div class="cursor">
                          <el-popconfirm
                            title="确定删除吗？"
                            @confirm="del(scope.$index, scope.row)"
                          >
                            <el-button slot="reference" type="text">删除</el-button>
                          </el-popconfirm>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            <!-- 底部页码 -->
            <div class="page">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[10, 20, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Number(totalNum) ? Number(totalNum) : 0"
              >
              </el-pagination>
            </div>
          </div>
          <!-- </el-tab-pane> -->
          <!-- 活动栏结束 -->

          <!-- 计划栏 -->
          <!-- 第一行 -->
          <!-- <el-tab-pane label="计划" name="second"> 
          <div class="search-part">
                  <div class="row1">
                      <span>
                        <el-select v-model="options1" style="width: 216px">
                          <el-option :label="options1.toString()" :value="options1"></el-option>
                        </el-select>
                      </span> -->
              <!-- 多选1 -->
                      <!-- <span>  
                        <shaixuan @handlechange1="handlechange1" style="width:216px"></shaixuan>
                      </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                    -->
              <!-- 多选2 -->
                    <!-- <div class="row1-2">
                      <el-select 
                        v-model="message2"
                        collapse-tags
                        multiple
                        placeholder="请选择监测链接完成度"
                        >  
                        <el-option
                          v-for="item in select3"
                          :key="item.value3"
                          :label="item.label"
                          :value="item.value3">
                        </el-option>
                      </el-select>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
              <!-- 多选3 -->
                    <!-- <div class="row1-3">
                      <el-select
                        v-model="message3" 
                        collapse-tags 
                        multiple 
                        placeholder="请选择计划状态" 
                        >
                        <el-option
                          v-for="item in select4"
                          :key="item.value8"
                          :label="item.label"
                          :value="item.value8">
                        </el-option>
                      </el-select>
                    </div>
                  </div> -->
                <!-- 第二行 -->
                <!-- <div class="row2">
                      <div class="col1">
                           <sx2 :acid="acid" @handlechange2="handlechange2" ref="clearsx2"></sx2>
                    </div>&nbsp;&nbsp;&nbsp;
                      <div class="col2">
                        <el-input placeholder="请输入推广位名称" v-model="input1">
                          <template slot="prepend">推广位名称</template>
                        </el-input>
                      </div>    
                    </div> -->
                <!-- 第三行 -->
                <!-- <div class="row3">
                      <el-button type="text" @click="clear">恢复默认</el-button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <el-button type="primary" @click="planList">查询</el-button>
                    </div>
                    </div>  -->
                <!-- 计划列表 -->
                <!-- <div class="plan">
                      <div>计划列表</div>
                      <el-button type="primary" @click="download">下载表格</el-button>
                    </div> -->
                  <!-- 计划表内容 -->
                  <!-- <div class="plan-main"> -->
                    <!-- <el-empty :image-size="200"></el-empty> -->
                  <!-- <div class="plan-form">
                      <el-table
                      max-height="400px"
                      :data="tableData"
                      border
                      style="width: 100%">
                      <el-table-column
                        prop="adId"
                        label="计划ID"
                        width="270">
                      </el-table-column>
                      <el-table-column
                        prop="accountName"
                        label="计划"
                        width="240">
                      </el-table-column>
                      <el-table-column
                        prop="urlCompletion"
                        label="监测链接完成度"
                        width="222">
                      </el-table-column>
                      <el-table-column
                        prop="activityName"
                        label="活动"
                        width="190">
                      </el-table-column>
                      <el-table-column
                        prop="province"
                        label="代理"
                        width="120">
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        label="账户"
                        width="170">
                      </el-table-column>
                      <el-table-column
                        prop="campaignName"
                        label="广告组"
                        width="150">
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        label="计划状态"
                        width="200">
                      </el-table-column> 
                  </el-table>
                    </div>  -->
                    <!-- 页码 -->
                    <!-- <div class="page">
                      <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :page-sizes="[10, 20, 50]"
                          :page-size="pageSize"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="Number(totalNum) ? Number(totalNum) : 0"
                        >
                      </el-pagination>
                    </div> 
                    </div>
                </el-tab-pane>  -->
        <!-- </el-tabs>  -->

  </div>
</template>

<script>
import {
  activicySetting,
  platForm,
  activitylist,
  getactivicy,
  down,
  delactive,
  planlist,
  accountlis,
  advertisement,
} from "../../request/api.js";

import shaixuan from "../../echarts/goods_shaixuan";
import sx2 from "../../echarts/goods_sx2";

export default {
  components:{
    shaixuan,
    sx2
  },

  data() {
    return {
      disabled:true,
      disabled2:true,
      disabled3:true,
      // disabled4:true,
      disabled5:true,
      disabled6:true,
      is_open: null,
      setid:null,
      dialogFormVisible:false,
      order: {
        callback_paid: true,
        paid_start:'',
        paid_end:'',
        callback_paid_ratio:'',
        callback_non_payment:'',
        non_payment_start:'',
        non_payment_end:'',
        callback_non_payment_proportion:'',
        callback_lose:'',
        lose_start:'',
        lose_end:'',
        callback_lose_proportion:'',
        callback_attribution_order:'',
        callback_all_item:'',
        callback_all_item_id:null,
        callback_all_platform: '',
        // callback_section:'',
        // callback_price_start: '',
        // callback_price_end: '',
        callback_kate_kao: true,
        kate_kao_start:'',
        kate_kao_end:'',
        callback_proportion:'',
        callback_arrearage:'',
        arrearage_start:'',
        arrearage_end:'',
        callback_arrearage_proportion:'',
        callback_fail:'',
        fail_start:'',
        fail_end:'',
        callback_fail_proportion:'',

      },
      // 页码
      curPage: 1,
      pageSize: 10,
      totalNum: 0,
      newlabei: {},
      multipleTable: [],

      // 计划栏第一个筛选框
      options1:[],
      // 计划栏第二个筛选框
      acid:[],
      // 计划栏第二行筛选框
      brandOptions: [
        { name: "账户", val: "ACCOUNT" },
        { name: "广告组", val: "AD_GROUP" },
        { name: "计划", val: "PLAN" }
      ],
      brandObj: { },
      isSearchPlan: false,
      form: [],
      array: [],
      loading: false,
      typeOptions: [],
      form: {
        phoneBrand: "",
        phoneType: "",
        plan: [],
      },
      // acid: {
      //   type: Array,
      //   default: []
      // },

      message1: [],
      message2: [],
      message3: [],
      // 嵌套表格
      tabData: [
        {
          admin: "杨四郎",
          status: "可管理",
        },
      ],
      // 嵌套
      inVisible: false,
      outerVisible: false,
      innerVisible: false,
      // 嵌套的输入框
      input6: "",
      input5: "",
      // 计划第4个选择框
      select4: [
        {
          value8: "选项1",
          label: "投放中",
        },
        {
          value8: "选项2",
          label: "计划暂停",
        },
        {
          value8: "选项3",
          label: "新建审核中",
        },
        {
          value8: "选项4",
          label: "修改审核中",
        },
        {
          value8: "选项5",
          label: "已完成 (投放达到结束时间)",
        },
        {
          value8: "选项6",
          label: "计划新建",
        },
        {
          value8: "选项7",
          label: "审核不通过",
        },
        {
          value8: "选项8",
          label: "账户余额不足",
        },
        {
          value8: "选项9",
          label: "超出预算",
        },
        {
          value8: "选项10",
          label: "未到达投放时间",
        },
      ],
      value8: [],
      // 计划第6个选择框
      select6: [
        {
          value7: "选项1",
          label: "其他",
        },
      ],
      value7: [],
      // 计划第三个选择框
      select3: [{
        value3:'1',
        label:'完成'
      },{
        value3:'2',
        label:'部分添加'
      },{
        value3:'3',
        label:'待添加'
      }],
      // 计划第二个选择框
      select2: [],
      // 计划栏 选择框
      options: [],
      searchForm: {
        time: "",
        activityName: "",
        platform: '',
      },
      // 活动 日期选择
      value1: "",
      // 计划 推广位名称
      input1: "",
      //活动 第三个输入框
      input4: "",
      // 页签栏
      activeName: "first",
      // 计划表内容
      tableData: [],

      // 计划 第一个选择框
      select1: [
        {
          value4: "选项1",
          label: "巨量引擎",
        },
      ],
      value4: "选项1",
      // 活动 第一个选择框
      value2: "全部平台",
      option: [{
        value:1,
        name:'巨量引擎'
      },{
        value:2,
        name:'腾讯广告'
      }],

      // 计划 第四个选择框
      // proxy: ["代理", "账户", "广告组", "计划"],
      // 活动名称数据
      items: [],

      tableChecked: [],
      id: [],
    };
  },

  mounted() {
    // 显示活动列表
    this.getActivicy(); 
    this.getPlatform()
    // 显示计划列表
    // this.planList()

    // 计划栏第一个筛选框
    // this.Activitylist();
    // console.log(id,'idididi');

    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    console.log(this.userinfo,'this.userinfo')
    this.is_open = this.userinfo.is_open
    console.log(this.is_open,'this.isopen')
  },


  // 方法部分
  methods: {
    notChinese(e){
      // console.log(e,'zhognwen');
      let testChinese = e.target.value
      let reg = /.*[\u4e00-\u9fa5]+.*$/
      if(!reg.test(testChinese)){

      }else{
        this.$message.warning('不能含有中文')
      }
      e.target.value = e.target.value.replace(/[^\x00-\xff]/g,'')
    },
    onInput0_100(e) {
      this.$message.closeAll();
      // 验证是否是纯数字
      let isNumber = /^\d*$/.test(e.target.value);
      // 过滤非数字
      e.target.value = e.target.value.replace(/\D/g, "");
      if (!isNumber || e.target.value < 0 || e.target.value > 100) {
        this.$message.warning("只能输入[0,100]区间的整数");
      }
      e.target.value = (e.target.value >= 0 && e.target.value <= 100 && e.target.value.match(/^\d*/g)[0]) || null;
    },
    orderAll(e){
      if(e==1){
        this.order.callback_all_item = false
        this.order.callback_all_item_id = ''
        this.disabled3 = true
      }
    },
    orderID(e){
      if(e==0){
        this.order.callback_all_item_id = ''
        this.disabled3 = true
      }else{
        this.disabled3 = false
        this.order.callback_attribution_order = false
      }
    },
    noOrder(e){
      if(e==0) {
        this.order.arrearage_start = ''
        this.order.arrearage_end = ''
        this.order.callback_arrearage_proportion = ''
        this.disabled5 = true
      }else{
        this.disabled5 = false
      }
    },
    failOrder(e){
      if(e==0) {
        this.order.fail_start = ''
        this.order.fail_end = ''
        this.order.callback_fail_proportion = ''
        this.disabled6 = true
      }else{
        this.disabled6 = false
      }
    },
    // clearMoney(e){
    //   if(e==0) {
    //     this.order.callback_price_start = ''
    //     this.order.callback_price_end = ''
    //     this.disabled4 = true
    //   }else{
    //     this.disabled4 = false
    //   }
    // },
    checkPlatform(e){
      if(e==1){
        this.order.checkPlatform = 1
      }else{
        this.order.checkPlatform = 0
      }
    },
    noPay(e){
      if(e==0){
        this.order.non_payment_start = ''
        this.order.non_payment_end = ''
        this.order.callback_non_payment_proportion = ''
        this.disabled = true
      }else{
        this.disabled = false
      }
    },
    orderLose(e){
      if(e==0){
        this.order.lose_start = ''
        this.order.lose_end = ''
        this.order.callback_lose_proportion = ''
        this.disabled2 = true
      }else{
        this.disabled2 = false
      }
    },
    resetForm(){
      // this.order.callback_price_end = ''
      // this.$nextTick(function () {
      //   this.$refs.order.resetFields();
      // })
      // this.getActivicy()
    },
    set() {
      console.log('设置确定');
       console.log(this.setid,'activityId');

      activicySetting({
        callback_paid: 1,
        paid_start:this.order.paid_start,
        paid_end:this.order.paid_end,
        callback_paid_ratio:this.order.callback_paid_ratio,
        callback_non_payment:this.order.callback_non_payment,
        non_payment_start:this.order.non_payment_start,
        non_payment_end:this.order.non_payment_end,
        callback_non_payment_proportion:this.order.callback_non_payment_proportion,
        callback_lose:this.order.callback_lose,
        lose_start:this.order.lose_start,
        lose_end:this.order.lose_end,
        callback_lose_proportion:this.order.callback_lose_proportion,
        callback_attribution_order:Number(this.order.callback_attribution_order),
        callback_all_item:Number(this.order.callback_all_item),
        callback_all_item_id:this.order.callback_all_item_id,
        callback_all_platform:this.order.callback_all_platform,
        // callback_section:this.order.callback_section,
        // callback_price_start:this.order.callback_price_start,
        // callback_price_end:this.order.callback_price_end,
        callback_kate_kao: 1,
        kate_kao_start:this.order.kate_kao_start,
        kate_kao_end:this.order.kate_kao_end,
        callback_proportion:this.order.callback_proportion,
        callback_arrearage:this.order.callback_arrearage,
        arrearage_start:this.order.arrearage_start,
        arrearage_end:this.order.arrearage_end,
        callback_arrearage_proportion:this.order.callback_arrearage_proportion,
        callback_fail:this.order.callback_fail,
        fail_start:this.order.fail_start,
        fail_end:this.order.fail_end,
        callback_fail_proportion:this.order.callback_fail_proportion,
        activityId:this.setid,
        version:1

      }).then(r=>{
        console.log(r,123132);
        
        this.dialogFormVisible = false
        this.getActivicy()
        this.$message({
            message: "配置成功",
            type: "success",
        });
      })
    },
    goSet(e) {
      console.log(e,'eeeeeeeee');

      this.order.paid_start = e.paid_start.toString()
      this.order.paid_end = e.paid_end.toString()
      this.order.callback_paid_ratio = e.callback_paid_ratio
      this.order.callback_non_payment = e.callback_non_payment.toString()
      this.order.non_payment_start = e.non_payment_start.toString()
      this.order.non_payment_end = e.non_payment_end.toString()
      this.order.callback_non_payment_proportion = e.callback_non_payment_proportion.toString()
      if(this.order.callback_non_payment == 0){
        this.order.non_payment_start = ''
        this.order.non_payment_end = ''
        this.order.callback_non_payment_proportion = ''
        this.disabled = true
      }else{
        this.disabled = false
      }
      this.order.callback_lose = e.callback_lose.toString()
      this.order.lose_start = e.lose_start.toString()
      this.order.lose_end = e.lose_end.toString()
      this.order.callback_lose_proportion = e.callback_lose_proportion.toString()
      if(this.order.callback_lose == 0){
        this.order.lose_start = ''
        this.order.lose_end = ''
        this.order.callback_lose_proportion = ''
        this.disabled2 = true
      }else{
        this.disabled2 = false
      }
      // 回传全店订单
      this.order.callback_attribution_order = e.callback_attribution_order.toString()
      this.order.callback_all_item = e.callback_all_item.toString()
      this.order.callback_all_item_id = e.callback_all_item_id
      this.order.callback_all_platform = e.callback_all_platform.toString()
      if(this.order.callback_all_item == 0){
        this.disabled3 = true
      }else{
        this.disabled3 = false
      }
      // this.order.callback_section = e.callback_section.toString()
      // if(this.order.callback_section == 0){
      //   this.disabled4 = true
      // }else{
      //   this.disabled4 = false
      // }
      // this.order.callback_price_start = e.callback_price_start.toString()
      // this.order.callback_price_end = e.callback_price_end.toString()
      this.order.kate_kao_start = e.kate_kao_start.toString()
      this.order.kate_kao_end = e.kate_kao_end.toString()
      this.order.callback_proportion = e.callback_proportion.toString()
      // console.log(this.order.callback_proportion,'this.order.callback_proportion');
      this.order.callback_arrearage = e.callback_arrearage.toString()
      this.order.arrearage_start = e.arrearage_start.toString()
      this.order.arrearage_end = e.arrearage_end.toString()
      this.order.callback_arrearage_proportion = e.callback_arrearage_proportion.toString()
      if(this.order.callback_arrearage == 0){
        this.order.arrearage_start = ''
        this.order.arrearage_end = ''
        this.order.callback_arrearage_proportion = ''
        this.disabled5 = true
      }else{
        this.disabled5 = false
      }
      this.order.callback_fail = e.callback_fail.toString()
      this.order.fail_start = e.fail_start.toString()
      this.order.fail_end = e.fail_end.toString()
      this.order.callback_fail_proportion = e.callback_fail_proportion.toString()
      if(this.order.callback_fail == 0){
        this.order.fail_start = ''
        this.order.fail_end = ''
        this.order.callback_fail_proportion = ''
        this.disabled6 = true
      }else{
        this.disabled6 = false
      }

      this.setid = e.id
      this.dialogFormVisible = true
    },
    searchPlatform() {
      this.curPage = 1;
      this.getActivicy()
    },
    /**
         * 获取平台列表
         */
    getPlatform(){
        this.loading = true;
        platForm().then(res => {
            if(res.code == 1){
                // console.log(res,'平台列表');
                this.option = res.data;
                this.loading = false
            }
        })
    },
    search() {
      // this.searchForm
      this.curPage = 1; //重置页码
      this.getActivicy(this.searchForm);
    },
    // 返回活动列表 数据的方法
    // 状态
    statusFormatter1(row, column) {
      const status = row.status;
      if (status == 0) {
        return "停止";
      } else {
        return "进行中";
      }
    },
    // 平台
    statusFormatter2(row, column) {
      const platform = row.platform;
      if (platform == 1) {
        return "巨量引擎";
      } else {
        return "腾讯广告";
      }
    },

    // 计划栏第一个筛选框
    Activitylist() {
      activitylist({}).then(res => {
        this.options1 = res.data[0].platformName;
      });
    },
    // 计划栏第二个筛选框
    handlechange1(ids) {
      this.acid = ids;
      this.search();
    },
    handlechange2(obj) {
      this.targetType = obj.targetType;
      this.targetIds = obj.targetIds;
      this.search();
    },
    // 计划搜索  第二行  搜索框
    searchPlan(queryString, cb) {
      this.loading = true;
      plan({ 
        acticityId: this.acid, 
        searchName: queryString }).then(res => {
           this.loading = false;
          // console.log(res.data);
          res.data.forEach(ele => {
            ele["accountId"] = ele.planId;
            ele["accountName"] = ele.planName;
          });
          this.typeOptions = res.data;
          cb(res.data);
        }
      );
    },
    changeSelect1() {
      this.isSearchPlan = this.form.phoneBrand == "PLAN";
      this.typeOptions = [];
      // console.log(this.form.phoneBrand); //第一步获取第选中的值然后发出请求
      if (this.form.phoneBrand == "ACCOUNT") {
        accountlis({ acticityId: this.acid }).then(res => {
          // console.log(res.data);
          this.typeOptions = res.data;
        });
      } else if (this.form.phoneBrand == "AD_GROUP") {
        advertisement({ acticityId: this.acid }).then(res => {
          res.data.forEach(ele => {
            ele["accountId"] = ele.adGroupId;
            ele["accountName"] = ele.adGroupName;
          });
          this.typeOptions = res.data;
        });
      } else {
      }
      // 清空手机型号内容
      this.form.phoneType = "";
      // 遍历手机品牌的下拉选项数组
      for (const k in this.brandOptions) {
        // 手机品牌内容 是否等于 手机品牌的下拉选择数组中的某一项
        if (this.form.phoneBrand === this.brandOptions[k]) {
          this.typeOptions = this.brandObj[this.form.phoneBrand];
        }
      }
    },
    changeSelectPlan() {
      this.$emit("handlechange2", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.plan
      });
    },
    changeSelect2() {
      // console.log(this.form.phoneType);
      this.$emit("handlechange2", {
        targetType: this.form.phoneBrand,
        targetIds: this.form.phoneType
      });
    },

    // 清空 计划 选择框
    clear() {
      this.message1 = "";
      this.message2 = "";
      this.message3 = "";
      this.form.phoneType = "";
      // this.message4 = ''
      this.input1 = "";
      // 清除组件 的值
      Object.assign(this.$refs.clearsx2.$data,this.$refs.clearsx2.$options.data());
      this.planList();
    },
    // 显示计划列表
    // planList() {
    //   planlist({
    //     Authorization: sessionStorage.getItem("Authorization"), 
    //     activityId: [1,2],
    //     page: 1,
    //     pageSize: 2,
    //   }).then((r) => {
    //     console.log(r);
    //     // this.tableData = r.data.list;
    //   });
    // },
    // 显示活动列表
    getActivicy(param) {
      this.loading = true
      // debugger;
      if (!param) {
        param = {};
      }
      param = JSON.parse(JSON.stringify(param))
      if (param['time']) {
        param['start_time'] = param['time'][0];
        param['end_time'] = param['time'][1];
        delete(param['time'])
      }
      param = Object.assign(param, { page: this.curPage, number: this.pageSize ,platform: this.searchForm.platform });
      getactivicy(param).then((res) => {
        // console.log(res);
        this.loading = false
        this.items = res.data;
        // console.log(this.items,'新增的活动设置');
        this.totalNum = res.total;
        
      });
    },
    // 计划栏
    // 多选框1
    // SX1() {
    //   planlist({
    //     Authorization: sessionStorage.getItem("Authorization"), 
    //     activityId: "1",
    //     pageSize: 2,
    //     page: 1,
    //   }).then((r) => {
    //     console.log(r);
    //     this.select2 = r.data.list;
    //   });
    // },
    

    // 活动配置的计划栏 下载表格
    download() {
      down({
        activityId: "1",
        pageSize: "1",
        page: "1",
        urlCompletion: "empty",
        status: "1",
      }).then((res) => {
        // console.log(res);
      });
    },
    handleClick(row) {
      // console.log(row);
    },
    handleSizeChange(val) {
      this.pageSize = val
      // console.log('this.pageSize',this.pageSize)
      this.getActivicy(this.searchForm)
    },
    handleCurrentChange(val) {
      // console.log('current page', val);
      this.curPage = val;
      this.getActivicy(this.searchForm)
      // this.handleClick(this.newlabei);
    },
    hand(r) {
      // console.log(r);
    },
    // 点击编辑详情 跳转页面
    goEdit(row) {
      // let data = JSON.stringify(row);
      console.log(row,'row编辑');
      let data = JSON.stringify({ id: row.id ,platform: row.platform });
      console.log(data,'data');
      this.$router.push({ path: "/manage/ActiveDetail", query: { data } });
    },
    handleSelectionChange(val) {
      this.multipleTable = val; //  this.multipleTable 选中的值
      // console.log(this.multipleTable);
    },
    delAll() {
      // 这是选中的数组
      let idArr = this.multipleTable.map((item) => {
        return item.id;
      });
      if (idArr.length == 0) {
        this.$message({
          message: "请勾选后，再进行删除操作",
          type: "warning",
        });
        return;
      }
      console.log("delall", idArr);
      this.$confirm("此操作将永久删除选中活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delactive({
          ids: idArr,
        }).then((r) => {
          if (r.code == 1) {
            this.items = this.items.filter((ele) => idArr.indexOf(ele.id) == -1);
            // console.log(r);
            // this.getActivicy();
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      });

      
    },
    // 点击删除 删除活动
    del(index, row) {
      // console.log(this.getActivicy)
      // console.log(row.id)
      delactive({
        ids: [row.id],
      }).then((r) => {
        // console.log(r);
        if (r.code == 1) {
          this.items.splice(index, 1);
          // this.getActivicy();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
    },

  },

  
};
</script>

<style scoped>

/* 活动搜索框 */
.manage .box {
  display: flex;
  text-align: left;
  align-items: center;
  /* padding: 2vh 2vh; */
  background-color: #fff;
  padding: 20px;
  margin: 0 0 20px 0;
  flex-wrap: wrap;
}

.manage .box1 {
  flex: 1;
}
.manage .box .box3 {
  display: inline-block;
}
/* // 活动表内容 */
.manage .form {
  padding: 20px;
  background-color: #fff;
}
/* // 底部页码 */
.manage .page {
  text-align: right;
  margin: 20px 15px 0 0;
  /* padding-bottom: 20px; */
}
.manage .el-tabs .el-tabs--top {
  height: 80%;
}
.manage .el-main {
  height: 80%;
}

/* // 主体内容图标 */
.manage .nav-icon {
  display: flex;
  align-items: center;
}
.manage .el-icon-s-fold {
  font-size: 3vh;
}
.manage .nav-icon span {
  font-size: 2vh;
  padding-left: 2vh;
  padding-top: 2vh;
}

.manage .el-select .el-input {
  width: 130px;
}

/*  计划搜索栏  */
/*  搜索栏1 */
.manage .row1 {
  display: flex;
  align-items: center;
  height: 10vh;
  padding: 2vh 0 0 2vh;
}
/* .manage .el-tag.el-tag--info {
  margin: -20px 0 0 0;
} */
.manage .row1 .row1-11,.row1-12{
  width: 10vh;
}

/*  搜索栏2 */
.manage .row2 {
  display: flex;
  /* padding: 0 0 2vh 2vh; */
}

/*  解决搜索栏第二行冲突 */
.manage .el-form {
  margin: 0;
}
/*  搜索栏3 */
.manage .row3 {
  text-align: right;
  margin-right: 1vh;
}
/*  计划列表 */
.manage .plan {
  background-color: rgb(245, 246, 250);
  margin-top: 2vh;
  padding: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* // 计划表内容 */
.manage .plan-main {
  margin-top: 2vh;
}
/* // 计划表 */
.manage .plan-form {
  padding: 2vh;
}

/*  图标嵌套 */
/*  操作 */
.manage .operate {
  display: flex;
}

 /* 计划栏 第一行搜索框 */
/* /deep/.el-tag.el-tag--info{
   margin: 0 0 15px 10px; 
} */
</style>