<template>
  <div class="company" v-loading="loading">
    <!-- 搜索部分 -->
    <div class="head_search_box">
      <div class="head_search">
        <el-select
          v-model="ptListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-right: 10px"
          @change="changePt"
        >
        <el-option
            key=""
            label="全部平台"
            value=""
                    >
          </el-option>
          <el-option
            v-for="item in ptList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          style="width: 300px"
          placeholder="请输入主账户名称"
          v-model="searchData.keyword"
          clearable
          @keydown.enter.native="search"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          v-model="searchData.status"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeStatus"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
        v-if="this.userinfo.is_admin == 1"
          v-model="dlsListVal"
          placeholder="请选择"
          class="addgs"
          style="margin-left: 10px"
          @change="changeDls"
        >
          <el-option
            v-for="item in dlsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 表格部分 -->
    <div class="f2">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="授权账户名称"> </el-table-column>
        <el-table-column prop="code" label="账户ID"> </el-table-column>
        <el-table-column prop="account" label="公司账号"> </el-table-column>
        <el-table-column prop="company_name" label="关联公司">
        </el-table-column>
        <el-table-column prop="platform" label="平台" :formatter="statusFormatter1">
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          :filters="[
            { text: '禁用', value: 0 },
            { text: '已同意', value: 1 },
            { text: '待审核', value: 2 },
            { text: '已拒绝', value: 3 },
          ]"
          :filter-method="filterTag"
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status == '正常' ? 'success' : 'danger'"
              disable-transitions
              >{{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="申请授权时间">
        </el-table-column>
        <el-table-column prop="operation" label="操作" fixed="right">
          <template slot-scope="scope" style="display: flex">
            <el-button
              v-show="scope.row.status == '待审核'"
              @click="handleAgent(scope.row, 1)"
              type="text"
              size="small"
              >审核通过</el-button
            >
            <el-button
              v-show="scope.row.status == '待审核'"
              @click="handleAgent(scope.row, 3)"
              type="text"
              size="small"
              style="margin-left: 15px"
              >审核拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Number(totalNum) ? Number(totalNum) : 0"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { no_audit_agent, agent_audit,getFactor,platform  } from "@/request/api";

export default {
  data() {
    return {
      ptList:[],
      ptListVal:'',
      loading: false,
      statusList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "正常",
        },
        {
          value: "0",
          label: "禁用",
        },

        {
          value: "2",
          label: "待审核",
        },
        {
          value: "3",
          label: "审核拒绝",
        },
      ],
      statusvalue: "0",
      tableData: [
        {
          account_name: "王小虎",
          status: "启用",
        },
      ],
      drawer: false,
      curPage: 1,
      totalNum: 0,
      pageSize: 10,
      addAdvertising: {
        name: "",
        ratio: "",
        status: "1",
        memo: "",
      },
      addLabelWidth: "120px",
      formLabelWidth: "120px",
      userinfo: [],
      advertisingId: "",
      searchData: {
        keyword: "",
        status: "",
      },
      dlsListVal:0,
      dlsList:[],
    };
  },
  watch: {},
  computed: {},
  created() {},

  mounted() {
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    
      // this.getAdvertisingList();
      if(this.userinfo.is_admin == 1){
        this.getFactor();
      }else if(this.userinfo.is_admin == 2){
        this.dlsListVal = this.userinfo.id_factor;
        this.getAllAdvertising();
      }else{
        this.getAllAdvertising();
      }
      this.getplatform();
  },

  methods: {
    // 平台显示
    statusFormatter1(row, column) {
        const platform = row.platform;
        if (platform == 1) {
            return "巨量引擎";
        } else {
            return "腾讯广告";
        }
    },

    //切换平台
    changePt(e){
      this.ptListVal = e;
      this.getAllAdvertising();
    },
    //切换代理商
    changeDls(e){
      console.log(e,'e')
      this.dlsListVal = e;
      this.getAllAdvertising();
    },
    //获取代理商公司下拉框
    getFactor(){
        getFactor({}).then(r=>{
        // console.log(r,'r')
        this.dlsList = r.data;
        this.dlsListVal = this.dlsList[0].id;
        this.getAllAdvertising();
      })  
    },
    //切换状态显示
    changeStatus(e) {
      this.curPage = 1;
      this.getAllAdvertising();
    },
    //获取平台
    getplatform(){
      platform({}).then(res=>{
        // console.log(res,'res')
        this.ptList = res.data;
        // this.ptListVal = res.data[0].id;
      })
    },
    
    //获取代理商列表
    getAllAdvertising() {
      this.loading = true;
      no_audit_agent({
        ...this.searchData,
        page: this.curPage,
        page_size: this.pageSize,
        factorId:this.dlsListVal,
        platform:this.ptListVal
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.totalNum = res.data.total;
          this.tableData = res.data.list;
          // console.log(res, "res");
        } else {
          this.$message.error(res.msg);
        }
        // console.log(res, "res");

        // console.log(this.tableData,'this.tableData.update_time')
      });
    },
    //搜索代理商
    search() {
      this.curPage = 1;
      this.getAllAdvertising();
    },

    //改变每条页数
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log('this.pageSize', this.pageSize)
      this.curPage = 1;
      this.getAllAdvertising();
    },
    //改变页码
    handleCurrentChange(val) {
      // console.log('current page', val);
      this.curPage = val;
      this.getAllAdvertising();
    },
    //同意授权
    handleAgent(row, status) {
      let message = status == 1 ? "确认同意授权?" : "确认拒绝授权?";
      this.$confirm(message, "提示").then((result) => {
        if (result == "confirm") {
          agent_audit({ id: row.id, status: status, platform:  row.platform}).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getAllAdvertising();
            }
          });
        }
      });
    },

    filterTag(value, row) {
      return row.status === value;
    },
  },
};
</script>
 <style scoped>
.company .f2 {
  padding: 15px;
  background-color: #fff;
}
.company .page {
  text-align: right;
  margin: 15px 2px 0 0;
}
@media screen and (max-width: 1200px) {
  .head_search {
    flex-direction: column;
  }
  .addgs {
    margin-top: 10px;
  }
}
</style>