 <!-- 活动监控商品投放效果echarts 圆柱形 --> 

<template>
  <div class="echart" id="mychart1" :style="myChartStyle1"></div>
</template>

<script>
import * as echarts from "echarts";

import { productlauncheffect } from "@/request/api.js"; //营销活动监控商品投放效果

export default {
  props: {
    hw: {
      // 指定属性名, 属性值的类型, 必要性，这里写的hour 就是父组件 :hour 的定义，需要保持一致
      type: Array,
      default: [1]
    }
  },
  data() {
    return {
      xData1: [], //横坐标
      yData1: [], //花费
      taskDate1: [], //成交金额
      taskDate2: [], //ROI
      myChartStyle1: { float: "left", width: "100%", height: "400px" }, //图表样式
      reqParam: {
        acticityId: this.hw, //活动id
        targetType: [], //类型
        targetIds: [], //选择的类型的数据id
        userId: sessionStorage.getItem("key"), //当前登录账户id
        goodIds: [], //商品id
        costMax: "", //花费最大值
        costMin: "", //花费最小值
        roiMax: this.roiMax,
        roiMin: this.roiMin,
        costMax: this.costMax,
        costMin: this.costMin
      } //请求参数
    };
  },
  mounted() {
    this.initEcharts1();
  },
  watch: {
    hw(hw) {
      // console.log(this.hw);
      this.reqParam.acticityId = hw;
      this.getData();
    }
  },
  methods: {
    search(param) {
      this.reqParam = param;
      this.getData();
    },
    async getData() {
      const result = await productlauncheffect(this.reqParam);
      if (!result) return;
      const { data } = result;
      this.handleSetdata(data);
      // console.log(data);
    },
    handleSetdata(data) {
      this.xData1 = [];
      this.yData1 = [];
      this.taskDate1 = [];
      this.taskDate2 = [];
      if (data) {
        data.forEach(item => {
          // console.log(item.productName);
          this.xData1.push(item.productName);
          this.yData1.push(item.cost);
          this.taskDate1.push(item.amount);
          this.taskDate2.push(item.roi);
        });
      }
      this.initEcharts1();
    },
    initEcharts1() {
      // 多列柱状图
      const mulColumnZZTData1 = {
        tooltip: {
          show: true,
          trigger: "axis"
        },
        xAxis: {
          data: this.xData1
        },
        yAxis: [
          {
            name: "花费/成交金额",
            min: 0,
            max: this.costMax,
            splitLine: { show: false },
            position: "left"
          },
          {
            name: "ROI",
            min: 0,
            max: 2,
            // interval: 0.3,
            position: "right"
          }
        ],
        // 图例
        legend: {
          data: ["花费", "成交金额", "ROI"],
          bottom: "0%"
        },
        series: [
          {
            type: "bar", //形状为柱状图
            data: this.yData1,
            name: "花费", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: false,
              position: "top"
            }
          },
          {
            type: "bar", //形状为柱状图
            data: this.taskDate1,
            name: "成交金额", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: false,
              position: "top"
            }
          },
          {
            type: "bar", //形状为柱状图
            data: this.taskDate2,
            name: "ROI", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: false,
              position: "top"
            }
          }
        ]
      };
      const myChart = echarts.init(document.getElementById("mychart1"));
      myChart.setOption(mulColumnZZTData1);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  }
};
</script>

